import { FormikValues, useFormikContext } from "formik";
import { get } from "lodash";
import { ComponentPropsWithoutRef } from "react";
import PillarFormInputWrapper, {
  PillarFormInputWrapperProps,
} from "shared/components/pillar-form/components/PillarFormInputWrapper";
import { SessionView } from "shared/mappers/database/session/session";
import { SocietyView } from "shared/mappers/database/society/society";
import RichTextEditor from "shared/components/editor/RichTextEditor";
import { VariablesType, ToolbarItemsType } from "shared/types/ckEditor";

export type PillarFormTemplateEditorProps = PillarFormInputWrapperProps &
  ComponentPropsWithoutRef<"div"> & {
    collapse?: boolean;
    templateVariables?: VariablesType;
    session?: SessionView | SocietyView;
    hideToolbarItems?: ToolbarItemsType[];
  };

export const defaultTemplateVariables = [
  { label: "Display Name", value: "displayName" },
  { label: "Token URL", value: "tokenUrl" },
  { label: "Society", value: "society" },
  { label: "Profile", value: "profile" },
  { label: "Invoice", value: "invoice" },
  { label: "Submission Instance", value: "submissionInstance" },
  {
    label: "Logo (for IMG src)",
    value: "logo",
  },
  { label: "Message (for Directories)", value: "message" },
  { label: "Invoice Receipt Template", value: "invoiceReceiptHtml" },
  { label: "Display Name", value: "displayName" },
  { label: "First Name", value: "profile.societyUser.firstName" },
  { label: "Last Name", value: "profile.societyUser.lastName" },
  {
    label: "Format Number as USD (1337 to 1,3337)",
    value: "formatUsdString 1337",
  },
  {
    label: "Format Date (2022-01-01 to January 1, 2022)",
    value: "formatDate 2022-01-01",
  },
  { label: "Format Address Object", value: "formatAddress address" },
  { label: "Greater Than (0 > 1)", value: "greaterThan 0 1" },
  { label: "If Equal (0 != 1)", value: "ifEqual 0 1" },
  {
    label: "ThemeToHex (H,S,L modifers)",
    value: "themeToHex society.theme 0 0 0",
  },
  { label: "Format Membership Data (IDK?)", value: "formatMembershipData" },
];

const PillarFormTemplateEditor = ({
  name,
  label,
  labelClassName,
  additionalClasses,
  collapse = false,
  templateVariables,
  session,
  hideToolbarItems,
  ...props
}: PillarFormTemplateEditorProps) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const value = get(values, name);

  return (
    <PillarFormInputWrapper
      name={name}
      labelClassName={labelClassName}
      label={label}
      additionalClasses={`${additionalClasses ?? ""}`}
      collapse={collapse}
    >
      <RichTextEditor
        hideToolbarItems={hideToolbarItems}
        templateVariables={templateVariables ?? defaultTemplateVariables}
        editorData={value}
        handleOnChange={(data) => {
          setFieldValue(name, data);
        }}
        session={session}
        {...props}
      />
    </PillarFormInputWrapper>
  );
};

export default PillarFormTemplateEditor;
