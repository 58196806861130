import queryString from "query-string";
import { PaginationRequest } from "shared/api/types/pagination";
import { HttpMethod } from "shared/api/http";
import { FiltersRequest } from "shared/filter-where-clause";
import { SessionView } from "shared/mappers/database/session/session";
export const RequestHeader = {
  defaultJSON: { "Content-Type": "application/json" },
};

export interface ApiResponse<T = any> {
  body?: T;
  message?: string;
  status: number;
  isSuccessful: boolean;
  serverSideContext?: SessionView;
  error?: string;
}

export const addFiltersAndPaginationToUrl = (
  url: string,
  filters: FiltersRequest,
  paginationRequest?: PaginationRequest,
) => {
  const stringified = queryString.stringifyUrl(
    {
      url: url,
      query: { ...filters, ...paginationRequest },
    },
    {
      skipNull: true,
      skipEmptyString: true,
      arrayFormat: "bracket",
    },
  );
  return stringified;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ApiRequest = async <T = any>(
  route: string,
  requestMethod: HttpMethod,
  requestBody?: object | string | number,
  customHeaders?: HeadersInit,
  customParser?: (Response: Response) => ApiResponse["body"],
  filters?: FiltersRequest,
  pagination?: PaginationRequest,
  //This was needed to support legacy code but still allow for the return of the axios response
  //In several places, our code needs to know the status or other meta of the response, not just the data.
  returnRawResponse = false,
): Promise<ApiResponse<T> | Response | null> => {
  let response;

  const isPillarAdmin = !process.env.REACT_APP_PILLAR_API_URL;

  if (filters || pagination) {
    route = addFiltersAndPaginationToUrl(route, filters ?? {}, pagination);
  }
  if (requestMethod === HttpMethod.GET) {
    response = await fetch(route, {
      credentials: "include",
      ...( isPillarAdmin ? {next:{ revalidate: 0 }} : {}),
      cache: "no-store",
      redirect: "follow",
      headers: { ...(isPillarAdmin ? { "pillar-admin": "true" } : {}) },
    });
  } else {
    response = await fetch(route, {
      credentials: "include",
      method: requestMethod,
      headers: {
        ...(customHeaders != undefined && customHeaders != null
          ? customHeaders
          : RequestHeader.defaultJSON),
        ...(isPillarAdmin ? { "pillar-admin": "true" } : {}),
      },
      redirect: "follow",
      body: JSON.stringify(requestBody),
      ...( isPillarAdmin ? {next:{ revalidate: 0 }} : {}),
      cache: "no-store",
    });
  }
  if (response.status === 204) {
    return null;
  }
  if (response.status >= 500 && response.status <= 599) {
    const responseBody = (await response.json()) as ApiResponse;
    if (responseBody.isSuccessful === false) {
      throw new Error(responseBody.message ?? "Server Error");
    }
  }

  if (response.status === 403) {
    window.location.href = "/forbidden?redirectUrl=" + window.location.pathname;
    throw new Error("Forbidden");
  }
  if (response.status === 401) {
    window.location.href = "/login?redirectUrl=" + window.location.pathname;
    throw new Error("Unauthorized");
  }

  if (response.status >= 400 && response.status <= 499) {
    const responseBody = (await response.json()) as ApiResponse;
    if (responseBody.isSuccessful === false) {
      throw new Error(responseBody.message ?? JSON.stringify(responseBody));
    }
  }

  return returnRawResponse
    ? response
    : customParser == undefined
    ? await response.json()
    : await customParser(response);
};
export default ApiRequest;
