import { SessionView } from "shared/mappers/database/session/session";
import { SurveyCreatorModel } from "survey-creator-core";

export type ProductsQuestionChoice = {
  value: number; //VALUE IS PRODUCT ID.  SURVEYJS REQUIRES A VALUE A PRODUCT OPTION'S VALUE IS THE PRODUCT ID
  price?: number;
  showPrice: boolean;
  userPriceOverride: boolean;
  maximumQuantity?: number | undefined;
  minumumQuantity?: number | undefined;
  text: string;
  visibleIf?: string;
  enableIf?: string;
};

export type ProductsSurveyJSQuestionProps = {
    session?: SessionView;
    creator?: SurveyCreatorModel;
  };
  export type ProductsSurveyJSQuestionValueBase = {
    productId: number;
    price?: number;
    quantity: number;
    invoiceId?: number;
    invoiceItemId?: number;
  };
  
  export type ProductsSurveyJSQuestionValueSingle = {
    singleChoiceProductQuestion: number;
  } & ProductsSurveyJSQuestionValueBase;
  
  export type ProductsSurveyJSQuestionValueMultiple = {
    multipleChoiceProduct: number[];
    multipleChoiceProductPanel: ProductsSurveyJSQuestionValueBase[];
  };
  
  export type ProductsSurveyJSQuestionValue =
    | ProductsSurveyJSQuestionValueSingle
    | ProductsSurveyJSQuestionValueMultiple;
    
  export enum ProductsSurveyJSQuestionProperties {
    multipleChoice = "multipleChoice",
    options = "options",
  }