import { FormikValues, useFormikContext } from "formik";
import { get } from "lodash";
import { DateTime } from "luxon";
import React, {
  ChangeEvent,
  ComponentPropsWithoutRef,
  useEffect,
  useState,
} from "react";

import PillarFormInputWrapper, {
  PillarFormInputWrapperProps,
} from "./PillarFormInputWrapper";

export type PillarFormDateTimeInputProps = PillarFormInputWrapperProps &
  Omit<ComponentPropsWithoutRef<"input">, "id" | "name" | "value" | "type"> & {
    // Added this option. In the future, this component can replace PillarFormTimeInput and PillarFormDateInput.
    dateTimeType: "date" | "time" | "datetime-local";
    zone?: string;
    testid?: string;
  };

const PillarFormDateTimeInput = ({
  testid,
  name,
  label,
  labelClassName,
  additionalClasses,
  className,
  onChange,
  dateTimeType,
  zone,
  defaultValue,
  ...props
}: PillarFormDateTimeInputProps) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const [formattedDateTime, setFormattedDateTime] = useState("");

  const value = get(values, name);

  useEffect(() => {
    if (!value) return;

    const dateTime = DateTime.fromISO(value, { zone: zone ?? "utc" });
    setFormattedDateTime(
      dateTimeType === "date"
        ? dateTime.toFormat("yyyy-MM-dd")
        : dateTimeType === "time"
        ? dateTime.toFormat("HH:mm")
        : dateTime.toFormat("yyyy-MM-dd'T'HH:mm")
    );
  }, [dateTimeType, name, values, zone, value]);

  useEffect(() => {
    if (value) return;
    setFormattedDateTime("");
  }, [props.min, props.max, value]);

  useEffect(() => {
    if (!defaultValue) return;
    if (value) return;

    setFormattedDateTime(String(defaultValue));
    setFieldValue(name, String(defaultValue));
  }, [defaultValue, value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormattedDateTime(e.target.value);
    setFieldValue(name, e.target.value);
    onChange?.(e);
  };

  return (
    <PillarFormInputWrapper
      name={name}
      labelClassName={labelClassName}
      label={label}
      additionalClasses={additionalClasses ?? ""}
    >
      <input
        data-testid={testid}
        type={dateTimeType}
        className={className ?? ""}
        value={formattedDateTime}
        name={name}
        id={name}
        onChange={handleChange}
        {...props}
      />
    </PillarFormInputWrapper>
  );
};

export default PillarFormDateTimeInput;
