import { useState, useEffect, ComponentPropsWithoutRef } from "react";
import { MyUploadAdapter } from "shared/components/editor/UploadAdapterPlugin";
import { SessionView } from "shared/mappers/database/session/session";
import { SocietyView } from "shared/mappers/database/society/society";
import clone from "lodash/clone";
import cloneDeep from "lodash/cloneDeep";
import {
  Editor,
  FileLoader,
  ToolbarItemsType,
  VariablesType,
} from "shared/types/ckEditor";

export type RichTextEditorProps = ComponentPropsWithoutRef<"div"> & {
  editorData: string;
  handleOnChange?: (markupData: string) => void;
  isReadyOnly?: boolean;
  templateVariables?: VariablesType;
  session?: SessionView | SocietyView;
  hideToolbarItems?: ToolbarItemsType[];
};

const RichTextEditor = ({
  editorData,
  handleOnChange,
  isReadyOnly = false,
  templateVariables,
  session,
  hideToolbarItems,
  ...props
}: RichTextEditorProps) => {
  const [CKEditor, setCKEditor] = useState<any>(null);
  const [ClassicEditor, setClassicEditor] = useState<any>(null);

  useEffect(() => {
    import("@ckeditor/ckeditor5-react")
      .then((mod) => setCKEditor(() => mod.CKEditor))
      .catch((error) => console.error("Failed to load CKEditor", error));

    import("../../../shared/ckeditor5/build/ckeditor.js")
      .then((mod) => {
        const Editor = mod.default;
        class NewEditor extends Editor {}

        let newConfig = cloneDeep(Editor.defaultConfig);
        let newPlugins = clone(Editor.builtinPlugins);

        if (hideToolbarItems?.length) {
          const setHide = new Set(
            hideToolbarItems.map((item) => item.toLowerCase())
          );

          newPlugins = newPlugins.filter((plugin: { pluginName?: string }) => {
            const pluginName = plugin.pluginName?.toLowerCase();
            return pluginName ? !setHide.has(pluginName) : true;
          });

          newConfig.toolbar.items = newConfig.toolbar.items.filter(
            (item: string) => item === "|" || !setHide.has(item)
          );
        }

        (NewEditor as typeof Editor).builtinPlugins = newPlugins;
        (NewEditor as typeof Editor).defaultConfig = newConfig;

        return setClassicEditor(() => NewEditor);
      })
      .catch((error) => console.error("Failed to load ClassicEditor", error));
  }, []);

  function UploadAdapterPlugin(editor: Editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (
      loader: FileLoader
    ) => {
      return new MyUploadAdapter(loader, Number(session?.societyId || 0));
    };
  }

  if (!CKEditor || !ClassicEditor) return <p>Loading editor...</p>;

  return (
    <div {...props}>
      <CKEditor
        config={session ? {
          society: Number(session.societyId),
          variables: templateVariables ?? [],
          extraPlugins: [UploadAdapterPlugin],
        } : {
          variables: templateVariables ?? [],
        }}
        editor={ClassicEditor}
        data={editorData}
        disabled={isReadyOnly}
        onChange={(event: Event, editor: Editor) => {
          const data = editor.getData();
          handleOnChange && handleOnChange(data);
        }}
      />
    </div>
  );
};

export default RichTextEditor;
