import { useQuery } from "@tanstack/react-query";

import Spinner from "admin/src/ui/components/common/Spinner";

import { Fragment } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { apiRequestContractHandler } from "shared/api/apiRequestContractHandler";
import { getSocietyDashboardIdContract } from "shared/api/contracts/society/societyId/dashboards/dashboardId";
import CustomTooltip from "shared/components/dashboard/CustomTooltip";
import { ChartDataType } from "shared/components/dashboard/dashboardMapper";

type Props = {
  dashboardId: number;
  societyId: number;
};

const StackedBarChartModuleType = ({ dashboardId, societyId }: Props) => {
  const { data, isLoading } = useQuery({
    queryKey: ["dashboardModule", dashboardId],
    queryFn: async () =>
      apiRequestContractHandler(getSocietyDashboardIdContract, {
        params: {
          societyId,
          dashboardId: dashboardId,
        },
      }),
    refetchOnWindowFocus: false,
  });

  const overrideDefaultShortcuts = {
    last12Months: "Last 12 Months",
    last6Months: "Last 6 Months",
  };

  if (isLoading)
    return (
      <div className="col-span-6 flex items-center justify-center">
        <Spinner />
      </div>
    );
  if (!data) return <></>;

  return (
    <div
      className={
        (data.dashboardModule.classNames,
        `h-50 col-span-${data.dashboardModule.colSpan} row-start-${data.dashboardModule.positionRow} col-start-${data.dashboardModule.positionColumn}`)
      }
    >
      <div className="flex items-center justify-between">
        <a href="#" className="mb-2 inline-block text-xl font-semibold">
          {data.dashboardModule.title}
        </a>
      </div>

      <div className="h-40">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart width={500} height={300} data={data.data as ChartDataType}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip
              content={
                <CustomTooltip
                  allData={data.data as ChartDataType}
                  previousDataLabel="Change from previous month"
                />
              }
            />
            <Legend />
            {data.details.setsConfig?.map(({ dataKey, fill, stackId }) => {
              if (dataKey === "default") return <Fragment key={dataKey} />;

              return (
                <Bar
                  key={dataKey}
                  dataKey={dataKey}
                  fill={fill}
                  stackId={stackId}
                />
              );
            })}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default StackedBarChartModuleType;
