import { useEffect, useRef, useState } from "react";
import { SessionView } from "shared/mappers/database/session/session";
import { StylesManager, SurveyModel } from "survey-core";
import { Survey } from "survey-react-ui";

import {
  InitializeSurveyModelProps,
  initializeSurveyModel,
} from "shared/components/survey-js-form/preview/initializeSurveyModel";
import { removeHTMLTags } from "shared/components/survey-js-form/utils/removeHTMLTags";

StylesManager.applyTheme("defaultV2");

export interface SurveyJSFormComponentProps
  extends React.ComponentProps<typeof Survey>,
    InitializeSurveyModelProps {
  session: SessionView;
  spinner: any;
  overrideTitle?: string;
  overrideDescription?: string;
}

const SurveyJSFormComponent = (props: SurveyJSFormComponentProps) => {
  const initializedRef = useRef(false);
  const modelRef = useRef<SurveyModel | null>(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const initializeModel = async () => {
      const newModel = await initializeSurveyModel({ ...props });

      if (props.overrideTitle) {
        newModel.title = props.overrideTitle;
      }
      if (props.overrideDescription) {
        newModel.description = props.overrideDescription;
      }
      newModel.setVariable("profile", props.session.profile);
      newModel.getAllQuestions().forEach((question) => {
        if (question.title.includes("<p>")) {
          question.title = removeHTMLTags(question.title);
        }
      });
      modelRef.current = newModel;
      initializedRef.current = true;
      setIsReady(true);
    };

    initializeModel();

    return () => {
      if (modelRef.current) {
        modelRef.current.dispose();
        modelRef.current = null;
      }
      initializedRef.current = false;
      setIsReady(false);
    };
  }, []);

  if (!modelRef.current && !initializedRef.current) {
    return props.spinner;
  }

  if (isReady) {
    return <Survey className="surveyjs" model={modelRef.current} {...props} />;
  }
};
SurveyJSFormComponent.displayName = "SurveyJSFormComponent";
export default SurveyJSFormComponent;