import { ProductsQuestionChoice } from "shared/components/survey-js-form/questions/products/IProductsQuestion";
import {
  ItemValue,
  PanelModel,
  QuestionDropdownModel,
  QuestionTextModel,
  QuestionCompositeModel,
} from "survey-core";
export const setQuantityValueOnSurvey = ({
  question,
  newValue,
  productId,
}: {
  question: QuestionCompositeModel;
  newValue: number;
  productId: number;
}) => {
  const survey = question.getSurveyData();
  survey.setVariable(
    `${question.name}_${productId}_productId_quantity`,
    newValue,
  );
};
export const addQuantityQuestion = ({
  question,
  panel,
  productOption,
  isDropdown,
}: {
  question: QuestionCompositeModel;
  panel: PanelModel;
  productOption: ProductsQuestionChoice;
  isDropdown: boolean;
}) => {
  const existingQuantityField = panel.getQuestionByName("quantity");
  if (existingQuantityField) panel.removeElement(existingQuantityField);

  const title = `Quantity for ${productOption.text}`;
  if (
    isDropdown &&
    productOption.minumumQuantity &&
    productOption.maximumQuantity
  ) {
    const quantityDropdownQuestion = new QuestionDropdownModel("quantity");
    quantityDropdownQuestion.title = title;
    quantityDropdownQuestion.titleLocation = "left";

    quantityDropdownQuestion.choices = Array.from(
      {
        length:
          productOption.maximumQuantity - productOption.minumumQuantity + 1,
      },
      (_, i) => i + productOption.minumumQuantity!,
    ).map((value) => new ItemValue(value, value.toString()));
    quantityDropdownQuestion.defaultValue = productOption.minumumQuantity;
    quantityDropdownQuestion.isRequired = true;
    panel.addElement(quantityDropdownQuestion);
    setQuantityValueOnSurvey({
      question,
      newValue: productOption.minumumQuantity,
      productId: productOption.value,
    });
  } else {
    const quantityTextQuestion = new QuestionTextModel("quantity");
    quantityTextQuestion.title = title;
    quantityTextQuestion.titleLocation = "left";
    quantityTextQuestion.inputType = "number";
    quantityTextQuestion.defaultValue = productOption.minumumQuantity;
    quantityTextQuestion.min = productOption.minumumQuantity?.toString() ?? "1";
    quantityTextQuestion.max = productOption.maximumQuantity?.toString() ?? "";
    quantityTextQuestion.isRequired = true;
    setQuantityValueOnSurvey({
      question,
      newValue: productOption.minumumQuantity ?? 1,
      productId: productOption.value,
    });
    panel.addElement(quantityTextQuestion);
  }
};
