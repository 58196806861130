import { ComponentPropsWithoutRef } from "react";
import { twMerge } from "tailwind-merge";

// https://github.com/aaronfagan/svg-credit-card-payment-icons/tree/main/mono-outline
const VisaIcon = ({ className, ...props }: ComponentPropsWithoutRef<"svg">) => {
  const defaultClassnames = "icon icon-tabler icon-tabler-caret-left h-4 w-4";

  return (
    <svg
      className={twMerge(defaultClassnames, className)}
      width="780"
      height="500"
      viewBox="0 0 780 500"
      fill="none"
      {...props}
    >
      <g clip-path="url(#clip0_6278_125896)">
        <path
          d="M53.487 14H727.011C748.268 14 765.499 30.816 765.499 51.559V445.939C765.499 466.683 748.268 483.5 727.011 483.5H53.487C32.231 483.5 15 466.684 15 445.939V51.559C15 30.816 32.231 14 53.487 14Z"
          stroke="#393939"
          stroke-width="30"
        />
        <path
          d="M486.052 147.154C440.988 147.154 400.717 170.518 400.717 213.684C400.717 263.188 472.139 266.607 472.139 291.476C472.139 301.948 460.142 311.321 439.652 311.321C410.573 311.321 388.838 298.223 388.838 298.223L379.539 341.783C379.539 341.783 404.576 352.847 437.817 352.847C487.086 352.847 525.855 328.336 525.855 284.431C525.855 232.122 454.135 228.804 454.135 205.721C454.135 197.518 463.983 188.531 484.415 188.531C507.468 188.531 526.276 198.056 526.276 198.056L535.378 155.985C535.378 155.985 514.913 147.154 486.052 147.154ZM73.763 150.329L72.6719 156.679C72.6719 156.679 91.6302 160.15 108.705 167.073C130.691 175.012 132.257 179.634 135.96 193.988L176.308 349.572H230.396L313.721 150.329H259.758L206.216 285.796L184.368 170.968C182.364 157.826 172.215 150.329 159.792 150.329H73.763ZM335.421 150.329L293.088 349.572H344.547L386.73 150.329H335.421ZM622.423 150.329C610.015 150.329 603.44 156.974 598.616 168.586L523.226 349.572H577.189L587.63 319.408H653.373L659.721 349.572H707.336L665.797 150.329H622.423ZM629.441 204.158L645.437 278.924H602.584L629.441 204.158Z"
          fill="#393939"
        />
      </g>
      <defs>
        <clipPath id="clip0_6278_125896">
          <rect width="780" height="500" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VisaIcon;
