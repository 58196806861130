import { ReactElement } from "react";
import { PillarTableCheckboxColumnProps } from "shared/components/pillar-table/display-columns/PillarTableCheckboxColumn";
import { PillarTableColumnProps } from "shared/components/pillar-table/PillarTableColumn";

export const getColumnStyles = <T>(
  column: ReactElement<PillarTableColumnProps<T> | PillarTableCheckboxColumnProps<T>>,
): React.CSSProperties => {
  const styles: React.CSSProperties = {
    zIndex: 7,
  };

  if (column.props?.maxWidth) {
    styles.maxWidth = `${column.props.maxWidth}px`;
  }

  if (column.props?.minWidth) {
    styles.minWidth = `${column.props.minWidth}px`;
  }

  return styles;
};
