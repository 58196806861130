import { SocietyTheme } from "shared/mappers/database/society/theme";
import { CustomSessionContext } from "shared/context/CustomSessionContext";
import LoadingPage from "hub/src/components/common/LoadingPage";
import { useEffect, useMemo, useState } from "react";
import { getSocietyId } from "hub/src/utils/getSocietyId";
import { SessionView } from "shared/mappers/database/session/session";
import AblyComponent from "hub/src/components/common/AblyComponent";
import { apiRequestContractHandler } from "shared/api/apiRequestContractHandler";
import { postSocietyProfileLogoutContract } from "shared/api/contracts/society/societyId/profiles/profileId/logout";
import {
  getSocietyAuthContract,
  postSocietyAuthContract,
} from "shared/api/contracts/society/societyId/auth";
import { SocietyAuthBodyInput } from "shared/api/types/society/[societyId]/auth";
import { getSocietyTokenTokenValueContract } from "shared/api/contracts/society/societyId/token/tokenValue";
import {
  CustomRouterType,
  useCustomRouter,
} from "shared/context/CustomRouterContext";
import { useHubAbly } from "hub/src/hooks/useHubAbly";
import { ENV } from "hub/src/appConstants/env";
import { RouteParams } from "shared/api/types/society/[societyId]/app-route-config";
import { SocietySessionContext } from "shared/context/SocietySessionContext";

const redirectParentAfterLogout = (): void => {
  if (
    ENV.REACT_APP_IFRAME_SSO_REDIRECT_URL &&
    typeof ENV.REACT_APP_IFRAME_SSO_REDIRECT_URL === "string"
  ) {
    const parentOrigin =
      ENV.REACT_APP_IFRAME_SSO_REDIRECT_URL!.match(/https:\/\/[^/]+/)![0];
    window.parent.postMessage(
      {
        type: "REDIRECT_PARENT",
        source: "pillar",
        redirectParentUrl: ENV.REACT_APP_IFRAME_SSO_REDIRECT_URL,
      },
      parentOrigin,
    );
  }
};

const checkParentLoggedInState = ({
  router,
  redirectUrlAfterLogin,
}: {
  router: CustomRouterType;
  redirectUrlAfterLogin?: string | null;
}): void => {
  if (
    ENV.REACT_APP_IFRAME_SSO_REDIRECT_URL &&
    typeof ENV.REACT_APP_IFRAME_SSO_REDIRECT_URL === "string" &&
    ENV.REACT_APP_IFRAME_SSO_PATH &&
    typeof ENV.REACT_APP_IFRAME_SSO_PATH === "string" &&
    top !== self
  ) {
    const parentOrigin =
      ENV.REACT_APP_IFRAME_SSO_REDIRECT_URL!.match(/https:\/\/[^/]+/)![0];
    const ssoPagePath = ENV.REACT_APP_IFRAME_SSO_PATH;
    window.parent.postMessage(
      {
        type: "REQUEST_LOGGED_IN_STATE",
        source: "pillar",
      },
      parentOrigin,
    );
    //We need to wait for the parent to respond with the logged in state
    let RelayState = ENV.REACT_APP_IFRAME_SSO_REDIRECT_URL;
    if (redirectUrlAfterLogin) {
      RelayState += "#" + redirectUrlAfterLogin;
    }
    window.addEventListener("message", (event) => {
      if (event.origin === parentOrigin) {
        if (event.data.type === "RESPOND_LOGGED_IN_STATE") {
          if (!event.data.loggedIn) {
            router.push(ssoPagePath, {
              ...router.state,
              RelayState,
            });
          }
        }
      }
    });
  }
};
export const UserHubCustomSessionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  //Hooks
  // const history = useHistory();
  const [session, setSession] = useState<SessionView | null>(null);
  const [sessionLoading, setSessionLoading] = useState<boolean>(false);

  const router = useCustomRouter();
  useEffect(() => {
    if (router.state?.token) {
      setSessionLoading(true);
      tokenCallback(router.state?.token as string);
      router.removeFromState("token");
    }
    if (router.state?.impersonate) {
      setSessionLoading(true);
      tokenCallback(router.state?.impersonate as string);
      router.removeFromState("impersonate");
    }
  }, [router.state?.token, router.state?.impersonate]);

  const setSocietyTheme = (theme: SocietyTheme[]) => {
    theme.forEach((theme: SocietyTheme) => {
      document.documentElement.style.setProperty(
        `--${theme.property}`,
        theme.value,
      );
    });
  };

  const logoutCallback = async () => {
    setSessionLoading(true);
    const response = await apiRequestContractHandler(
      postSocietyProfileLogoutContract,
      {
        params: {
          societyId: getSocietyId(),
          profileId: session!.profileId!,
        },
      },
    );
    setSession(null);
    // setRedirectUrlAfterLogin(null);
    await refreshSessionCallback();
    redirectParentAfterLogout();

    router.push("/login");
  };
  const refreshSessionCallback = async (
    redirectUrlAfterLogin?: string,
    routeParms?: RouteParams,
  ) => {
    try {
      setSessionLoading(true);
      const response = await apiRequestContractHandler(getSocietyAuthContract, {
        params: {
          societyId: getSocietyId(),
        },
      });
      setSession(response);
      await redirectUrlAfterLoginCallback(
        response,
        redirectUrlAfterLogin,
        routeParms,
      );
    } catch (error) {
      console.error(error);
      router.push("/login", {
        ...(routeParms ?? {}),
        redirectUrlAfterLogin: router.pathname,
      });
    }
  };
  const redirectUrlAfterLoginCallback = async (
    session?: SessionView | null,
    redirectUrlAfterLogin?: string | null,
    routeParms?: RouteParams,
  ) => {
    if (session?.societyUserId) {
      checkParentLoggedInState({ router, redirectUrlAfterLogin });

      const tokenUsageFile = router.state?.t;
      if (tokenUsageFile) {
        const response = await apiRequestContractHandler(
          getSocietyTokenTokenValueContract,
          {
            params: {
              societyId: session!.societyId!,
              tokenValue: tokenUsageFile.toString(),
            },
          },
        );

        if ("usageFile" in response) {
          router.push(`/shared-urls/${response.usageFile?.fileId}`, routeParms);
        }
      } else if (redirectUrlAfterLogin) {
        router.push(redirectUrlAfterLogin, routeParms);
      } else if (router.pathname === "/" || router.pathname === "/login") {
        router.push("/overview", routeParms);
      }
    } else if (session) {
      setSessionLoading(false);
    }
  };

  const loginCallback = async (
    loginDetails: Required<SocietyAuthBodyInput>,
  ) => {
    try {
      setSessionLoading(true);
      const response = await apiRequestContractHandler(
        postSocietyAuthContract,
        {
          params: {
            societyId: getSocietyId(),
          },
          body: loginDetails,
        },
      );
      setSession(response);
      await redirectUrlAfterLoginCallback(
        response,
        router.state?.redirectUrlAfterLogin as string | undefined,
      );
    } catch (error) {
      setSessionLoading(false);
      throw error;
    }
  };

  const tokenCallback = async (tokenValue: string) => {
    const response = await apiRequestContractHandler(
      getSocietyTokenTokenValueContract,
      {
        params: {
          societyId: getSocietyId()!,
          tokenValue,
        },
      },
    );
    if ("usageLogin" in response) {
      const loginUsage = response.usageLogin!;
      let url = loginUsage.routePath;
      for (const key in loginUsage.pathParam) {
        url = url.replace(`:${key}`, loginUsage.pathParam[key].toString());
      }
      // setRedirectUrlAfterLogin(url);
      router.removeFromState("token");
      router.removeFromState("impersonate");
      await refreshSessionCallback(url, {
        ...router.state,
        token: null,
        impersonate: null,
      });
    } else {
      await refreshSessionCallback();
    }
  };

  //Watch Session, sometimes we set it to Null and need to refresh it.
  //I dont know why we need to refresh it, but we did? - GS 11/18/24 6:44PM
  useEffect(() => {
    if (!session && !sessionLoading) {
      refreshSessionCallback();
    }
    if (session?.society && session.society.theme) {
      setSocietyTheme(session.society.theme);
    }
    if (router.state?.logout !== undefined && session?.profileId) {
      setSessionLoading(true);
      logoutCallback();
    }
  }, [session, sessionLoading, router.state?.logout, session?.profileId]);

  //The juice of the context
  const value = useMemo(
    () => ({
      setSession,
      logout: logoutCallback,
      login: loginCallback,
      token: tokenCallback,
      refreshSession: refreshSessionCallback,
      environment: session?.environment ?? "prod",
      societyUser: session?.societyUser ?? undefined,
      society: session?.society ?? undefined,
      profile: session?.profile ?? undefined,
      societyAdmin: session?.societyAdmin ?? undefined,
      iat: session?.iat ?? 0,
      authInvalidBefore: session?.authInvalidBefore ?? "",
      societyUserId: session?.societyUserId ?? undefined,
      societyAdminId: session?.societyAdminId ?? undefined,
      adminMode: session?.adminMode ?? false,
      profileId: session?.profileId ?? undefined,
      societyId: session?.societyId ?? undefined,
      societies: session?.societies ?? [],
      profileIds: session?.profileIds ?? [],
      tags: session?.tags ?? [],
      loading: sessionLoading,
    }),
    [
      logoutCallback,
      loginCallback,
      tokenCallback,
      refreshSessionCallback,
      session?.societyUserId,
      session?.profileId,
      session?.societyAdminId,
      session?.societyUser,
      sessionLoading,
    ],
  );

  const ablyReady = useHubAbly(value);

  if (
    !session?.societyId ||
    (sessionLoading && !session.profileId) ||
    session.society == undefined ||
    session.society == null
  ) {
    return <LoadingPage />;
  }

  if (ablyReady) {
    return (
      <CustomSessionContext.Provider value={value}>
        <SocietySessionContext.Provider value={session.society}>
          <AblyComponent>{children}</AblyComponent>
        </SocietySessionContext.Provider>
      </CustomSessionContext.Provider>
    );
  }
  return (
    <CustomSessionContext.Provider value={value}>
      <SocietySessionContext.Provider value={session.society}>
        {children}
      </SocietySessionContext.Provider>
    </CustomSessionContext.Provider>
  );
};
