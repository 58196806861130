import { Serializer } from "survey-core";

export const fileQuestionProperties = () => {
  Serializer.addProperty("file", {
    name: "isPublic",
    type: "boolean",
    category: "general",
    default: true,
    visibleIndex: 1,
  });
};
