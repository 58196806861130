import { createContext } from "react";
import { DeepKeys } from "shared/types/deepkeys";

export interface PillarTableNestedTableContextProps<T> {
  rowData: T;
  valueProperty: DeepKeys<T>;
  isRowExpanded: boolean;
  parentTableColumnsLength: number;
}

export const PillarTableNestedTableContext = createContext<
PillarTableNestedTableContextProps<any>
>({} as PillarTableNestedTableContextProps<any>);
