import { FormikValues, useFormikContext } from "formik";
import { DateTime } from "luxon";

import React, {
  ChangeEvent,
  ComponentPropsWithoutRef,
  useEffect,
  useState,
} from "react";

import PillarFormInputWrapper, {
  PillarFormInputWrapperProps,
} from "./PillarFormInputWrapper";

export type PillarFormTimeInputProps = PillarFormInputWrapperProps &
  Omit<ComponentPropsWithoutRef<"input">, "id" | "name" | "value" | "type">;

const PillarFormTimeInput = ({
  name,
  label,
  labelClassName,
  additionalClasses,
  className,
  onChange,
  ...props
}: PillarFormTimeInputProps) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const [formattedTime, setFormattedTime] = useState("");

  useEffect(() => {
    const dateTime = DateTime.fromISO(values[name], { zone: "utc" });
    setFormattedTime(dateTime.toFormat("HH:mm"));
  }, [name, values]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormattedTime(e.target.value);
    setFieldValue(name, e.target.value);
    onChange?.(e);
  };

  return (
    <PillarFormInputWrapper
      name={name}
      labelClassName={labelClassName}
      label={label}
      additionalClasses={additionalClasses ?? ""}
    >
      <input
        type="time"
        className={className ?? ""}
        value={formattedTime}
        name={name}
        id={name}
        onChange={handleChange}
        {...props}
      />
    </PillarFormInputWrapper>
  );
};

export default PillarFormTimeInput;
