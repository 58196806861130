import PillarTableHeaderCell from "shared/components/pillar-table/PillarTableHeaderCell";
import { PillarTableSubmenuItem } from "shared/components/pillar-table/PillarTablePopoverMenu";
import { PillarTableQueryContext } from "shared/components/pillar-table/query/PillarTableQueryContext";
import React, { useContext } from "react";
import { PaginationRequest } from "shared/api/types/pagination";

interface PillarTableHeaderProps<T> {
  paginationState?: PaginationRequest;
  toggleSorting: (orderBy: string) => void;
  fixedColumnWidths: number[];
  testidTable?: string;
  handleInlineEdit?: (row: T) => Promise<boolean>;
  handleInlineDelete?: (row: T) => Promise<boolean>;
  columnRefs: React.RefObject<(HTMLDivElement | null)[]>;
  menu?:
    | PillarTableSubmenuItem<T>[]
    | PillarTableSubmenuItem<T>
    | ((row: T) => PillarTableSubmenuItem<T>[] | PillarTableSubmenuItem<T>);
}

const PillarTableHeader = <T extends object>({
  paginationState,
  toggleSorting,
  fixedColumnWidths,
  testidTable,
  columnRefs,
}: PillarTableHeaderProps<T>) => {
  const context = useContext(PillarTableQueryContext);
  const columns = context?.columns || [];
  return (
    <thead
      className="pillartable-table-header whitespace-nowrap"
      data-testid={testidTable ? `${testidTable}-table-head` : undefined}
    >
      <tr>
        {columns?.map((column, index) => (
          <PillarTableHeaderCell
            key={index}
            column={column}
            index={index}
            paginationState={paginationState}
            toggleSorting={toggleSorting}
            fixedColumnWidths={fixedColumnWidths}
            columnRefs={columnRefs}
          />
        ))}
      </tr>
    </thead>
  );
};

export default PillarTableHeader;
