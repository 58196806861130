import { SocietyProfileInvoiceBodyInput } from "shared/api/types/society/[societyId]/profiles/[profileId]/accounting/invoice";
import { SocietyProfileInvoiceParamsInput } from "shared/api/types/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]/index";
import { InvoiceView } from "shared/mappers/database/accounting/invoice/invoice";
import { BaseApiContract } from "shared/api/apiRequestContractHandler";
import { HttpMethod } from "shared/api/http";
export const getSocietyProfileAccountingInvoiceIdContract: BaseApiContract<
  SocietyProfileInvoiceParamsInput,
  undefined,
  InvoiceView
> = {
  method: HttpMethod.GET,
  apiRoute:
    "/api/v1/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]",
  paramsType: {} as SocietyProfileInvoiceParamsInput,
  responseType: {} as InvoiceView,
};
export const deleteSocietyProfileAccountingInvoiceIdContract: BaseApiContract<
  SocietyProfileInvoiceParamsInput,
  undefined,
  void
> = {
  method: HttpMethod.DELETE,
  apiRoute:
    "/api/v1/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]",
  paramsType: {} as SocietyProfileInvoiceParamsInput,
  responseType: undefined as unknown as void,
};