import dayjs from 'dayjs';
import React, { useCallback, useContext, useMemo } from 'react';

import { DATE_FORMAT } from 'shared/components/common/datepicker/src/constants';
import getShortcuts from 'shared/components/common/datepicker/src/constants/shortcuts';
import DatepickerContext from 'shared/components/common/datepicker/src/contexts/DatepickerContext';
import { Period, ShortcutsItem } from 'shared/components/common/datepicker/src/types';

interface ItemTemplateProps {
  children: JSX.Element;
  key: string;
  item: ShortcutsItem | ShortcutsItem[];
  id: string;
  tt: string;
}

// eslint-disable-next-line react/display-name
const ItemTemplate = React.memo((props: ItemTemplateProps) => {
  const { period, changePeriod, updateFirstDate, dayHover, changeDayHover, hideDatepicker, changeDatepickerValue } = useContext(DatepickerContext);

  // Functions
  const chosePeriod = useCallback(
    (item: Period) => {
      if (dayHover) {
        changeDayHover(null);
      }
      if (period.start || period.end) {
        changePeriod({
          start: null,
          end: null,
        });
      }
      changePeriod(item);
      changeDatepickerValue({
        startDate: item.start,
        endDate: item.end,
      });
      updateFirstDate(dayjs(item.start));
      hideDatepicker();
    },
    [changeDatepickerValue, changeDayHover, changePeriod, dayHover, hideDatepicker, period.end, period.start, updateFirstDate],
  );

  const children = props?.children;

  return (
    <li
      className="whitespace-nowrap w-1/2 min-md:w-1/3 min-lg:w-auto transition-all duration-300 text-society hover:bg-gray-100 p-0.5 rounded cursor-pointer"
      id={`${props.id}-${props.tt}`}
      onClick={() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        chosePeriod(props?.item.period);
      }}
    >
      {children}
    </li>
  );
});

interface ShortcustsProp {
  id: string;
  startingTime?: {
    startDay: number;
    startMonth: number;
  };
}

const Shortcuts = (prop: ShortcustsProp) => {
  // Contexts
  const { configs } = useContext(DatepickerContext);

  const callPastFunction = useCallback((data: unknown, numberValue: number) => {
    return typeof data === 'function' ? data(numberValue) : null;
  }, []);

  const shortcutOptions = useMemo<[string, ShortcutsItem | ShortcutsItem[]][]>(() => {
    const DEFAULT_SHORTCUTS = getShortcuts(prop.startingTime);
    if (!configs?.shortcuts) {
      return Object.entries(DEFAULT_SHORTCUTS);
    }

    return Object.entries(configs.shortcuts).flatMap(([key, customConfig]) => {
      if (Object.prototype.hasOwnProperty.call(DEFAULT_SHORTCUTS, key)) {
        return [[key, DEFAULT_SHORTCUTS[key as keyof typeof DEFAULT_SHORTCUTS]]];
      }

      const { text, period } = customConfig as {
        text: string;
        period: { start: string; end: string };
      };
      if (!text || !period) {
        return [];
      }

      const start = dayjs(period.start);
      const end = dayjs(period.end);

      if (start.isValid() && end.isValid() && (start.isBefore(end) || start.isSame(end))) {
        return [
          [
            text,
            {
              text,
              period: {
                start: start.format(DATE_FORMAT),
                end: end.format(DATE_FORMAT),
              },
            },
          ],
        ];
      }

      return [];
    });
  }, [configs]);

  const printItemText = useCallback((item: ShortcutsItem) => {
    return item?.text ?? null;
  }, []);

  return shortcutOptions?.length ? (
    <div className="min-md:border-b mb-3 min-lg:mb-0 min-lg:border-r min-lg:border-b-0 border-neutral-mid-200 pr-1">
      <ul data-testid={`${prop.id}`} className="w-full list-none flex flex-wrap min-lg:flex-col pb-1 min-lg:pb-0">
        {shortcutOptions.map(([key, item], index: number) =>
          Array.isArray(item) ? (
            item.map((item, index) => (
              <ItemTemplate id={prop.id} key={index + ''} item={item} tt={'fff'}>
                <>
                  {key === 'past' && configs?.shortcuts && key in configs.shortcuts && item.daysNumber
                    ? callPastFunction(configs.shortcuts[key as 'past'], item.daysNumber)
                    : item.text}
                </>
              </ItemTemplate>
            ))
          ) : (
            <ItemTemplate id={prop.id} key={index + ''} item={item} tt={index + ''}>
              <>
                {configs?.shortcuts && key in configs.shortcuts
                  ? typeof configs.shortcuts[key as keyof typeof configs.shortcuts] === 'object'
                    ? printItemText(item)
                    : configs.shortcuts[key as keyof typeof configs.shortcuts]
                  : printItemText(item)}
              </>
            </ItemTemplate>
          ),
        )}
      </ul>
    </div>
  ) : null;
};

export default Shortcuts;
