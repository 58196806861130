export enum AdminUserPermissions {
  super_admin = "super_admin",
  view_staff_notes = "view_staff_notes",
  manage_venues = "manage_venues",
  manage_users = "manage_users",
  kanban_board = "kanban_board",
}
export enum SocietyAdminPermissions {
  //main
  settings = "settings",
  forms = "forms",
  profiles = "profiles",
  groups = "groups",
  accounting = "accounting",
  chatbot = "chatbot",
  submissions = "submissions",
  reviews = "reviews",
  conference = "conference",
  reports = "reports",
  workflow_builder = "workflow_builder",
  //sublist
  //accounting
  modify_payments = "modify_payments",
  list_invoices = "list_invoices",
  list_invoice_items = "list_invoice_items",
  deferement_length = "deferement_length",
  deferement_range = "deferement_range",
  list_payments = "list_payments",
  list_payment_items = "list_payment_items",
  discount = "discount",
  //groups
  group_members = "group_members",
  //submissions
  submission_definitions = "submission_definitions",
  submission_instances = "submission_instances",
  //non-navigation
  //profile
  profiles_overview = "profiles_overview",
  profiles_accounting = "profiles_accounting",
  profiles_education = "profiles_education",
  profiles_communication = "profiles_communication",
  profiles_files = "profiles_files",
  profiles_tags = "profiles_tags",
  profiles_groups = "profiles_groups",
  profiles_accounting_invoice = "profiles_accounting_invoice",
  //groups
  group_details = "group_details",
  //settings
  settings_general = "settings_general",
  settings_forms = "settings_forms",
  settings_communication = "settings_communication",
  settings_routes = "settings_routes",
  settings_society_files = "settings_society_files",
  settings_products = "settings_products",
  settings_batch_tasks = "settings_batch_tasks",
  settings_advanced = "settings_advanced",
  settings_aws = "settings_aws",
  settings_reference = "settings_reference",
  settings_reference_detail = "settings_reference_detail",
  //elements
  //button
  report_edit = "report_edit",
}
//combine the two enums into one
export type AdminPermissions = AdminUserPermissions | SocietyAdminPermissions;
