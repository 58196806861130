import { postSocietyFilesContract } from "shared/api/contracts/society/societyId/files";
import { uploadFileContractHandler } from "shared/api/uploadFileContractHandler";
import { FileLoader } from "shared/types/ckEditor";

export class MyUploadAdapter {
  loader: FileLoader;
  societyId: number;
  constructor(loader: FileLoader, societyId: number) {
    this.loader = loader;
    this.societyId = societyId;
  }

  upload() {
    return this.loader.file.then(
      (file: File) =>
        new Promise((resolve, reject) => {
          return uploadFileContractHandler({
            file,
            isPublic: true,
            societyId: this.societyId,
            contract: postSocietyFilesContract,
          }).then((res) => {
            if (!res?.publicUrl)
              return reject(`Couldn't upload file: ${file.name}.`);

            this.loader.uploaded = true;
            resolve({
              default: res?.publicUrl,
            });
          });
        })
    );
  }
}
