import debounce from "lodash.debounce";
import { ComponentPropsWithoutRef, useEffect, useState } from "react";
import withFilterComponent from "shared/components/pillar-table/filters/withFilterComponent";
import { usePillarTableQueryContext } from "shared/components/pillar-table/query/PillarTableQueryContext";
type PillarTableFilterTextInputProps = Omit<
  ComponentPropsWithoutRef<"input">,
  "onChange" | "value" | "type" | "name" | "title"
> & {
  title: string;
  filterKey: string;
  testId?: string;
};

//This function needs defined outside the component or it is recreated on each render.
//If it inside the component, it will be a new debounced function each render, making the
//use of debounce useless.
const debouncedSetFilters = debounce(
  (filters, filterKey, filterValue, setFilters) => {
    setFilters({ ...filters, [filterKey]: filterValue });
  },
  500,
  { maxWait: 2000, trailing: true, leading: false },
);

const PillarTableFilterTextInput = ({
  filterKey,
  title,
  testId,
  ...props
}: PillarTableFilterTextInputProps) => {
  const filterContext = usePillarTableQueryContext();
  const { setFilters, filters } = filterContext;
  const [filterValue, setFilterValue] = useState<string>(
    (filters[filterKey] as string) ?? "",
  );

  useEffect(() => {
    if (filters[filterKey] != filterValue) {
      // debouncedSetFilters.cancel();
      debouncedSetFilters(filters, filterKey, filterValue, setFilters);
    }
  }, [filterValue]);

  return (
    <div className="flex flex-col justify-start items-start">
      <label
        className="text-filter-label"
        htmlFor={`filter-text-input-${filterKey}`}
      >
        {title}
      </label>
      <input
        name={`filter-text-input-${filterKey}`}
        data-testid={`filter-text-input-${testId}`}
        title={title}
        value={filterValue}
        type="text"
        onChange={(e) => {
          setFilterValue(e.target.value);
        }}
        {...props}
      />
    </div>
  );
};

export default withFilterComponent(PillarTableFilterTextInput);
