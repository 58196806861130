import { SocietyProfileSubmissionDefintionSubmissionInstanceWorkflowActionEndpointParamsInput } from "shared/api/types/society/[societyId]/profiles/[profileId]/submission/[submissionDefinitionId]/instance/[submissionInstanceId]/[workflowActionEndpoint]";
import { SocietyWorkflowBodyInput } from "shared/api/types/society/[societyId]/workflow/[workflowId]/instance";
import { WorkflowInstanceStopped } from "shared/types/workflow";
import { BaseApiContract } from "shared/api/apiRequestContractHandler";
import { HttpMethod } from "shared/api/http";
export const postSocietyProfileDefinitionSubmissionInstanceWorkflowActionEndpointContract: BaseApiContract<
  SocietyProfileSubmissionDefintionSubmissionInstanceWorkflowActionEndpointParamsInput,
  SocietyWorkflowBodyInput,
  WorkflowInstanceStopped
> = {
  method: HttpMethod.POST,
  apiRoute:
    "/api/v1/society/[societyId]/profiles/[profileId]/submission/[submissionDefinitionId]/instance/[submissionInstanceId]/[workflowActionEndpoint]",
  paramsType:
    {} as SocietyProfileSubmissionDefintionSubmissionInstanceWorkflowActionEndpointParamsInput,
  bodyType: {} as SocietyWorkflowBodyInput,
  responseType: {} as WorkflowInstanceStopped,
};
