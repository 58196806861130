import { get as lodashGet } from "lodash";
import React from "react";

import { PillarTableColumnProps } from "shared/components/pillar-table/PillarTableColumn";
import { PillarTableRowContext } from "shared/components/pillar-table/PillarTableRowContext";
import { renderTemplateUtil } from "shared/components/template/renderTemplateUtil";

export type PillarTableTemplateColumnProps<T> = {
  templatePropertyPaths?: string[];
  templatePropertyPath?: string;
  template?: string;
} & PillarTableColumnProps<T>;

const PillarTableTemplateColumn = <T,>({
  templatePropertyPaths = [],
  templatePropertyPath,
  template = "",
}: PillarTableTemplateColumnProps<T>) => {
  const rowContext = React.useContext(PillarTableRowContext);

  if (templatePropertyPath) {
    templatePropertyPaths.push(templatePropertyPath);
  }

  templatePropertyPaths?.forEach((path) => {
    if (template == "") {
      template = lodashGet(rowContext?.rowValue, path) ?? "";
    }
  });

  return (
    <>
      {renderTemplateUtil({
        template: template,
        templateData: rowContext?.rowValue,
      })}
    </>
  );
};

export default PillarTableTemplateColumn;
