import PageComponent from "hub/src/components/common/PageComponent";
import PromoBanner from "hub/src/components/overview/PromoBanner";
import UserDetail from "hub/src/components/overview/UserDetail";
import { useCustomSession } from "shared/context/CustomSessionContext";

const OverviewPage = () => {
  const session = useCustomSession();

  return (
    <PageComponent>
      <PageComponent.Header />
      <PageComponent.Content>
        {session.society?.abbreviation === "TESAWG" && <PromoBanner />}
        <UserDetail />
      </PageComponent.Content>
    </PageComponent>
  );
};

export default OverviewPage;
