import React from "react";

interface CreateComponentWrapper<T> {
  children: React.ReactNode;
  componentType: React.ElementType<any>;
}

const PillarTableComponentWrapper = <T,>({
  children,
  componentType,
}: CreateComponentWrapper<T>) => {
  const componentWrapper = React.Children.toArray(children).find(
    (child): child is React.ReactElement =>
      React.isValidElement(child) && child.type === componentType,
  );

  if (!componentWrapper) return null;

  return componentWrapper;
};

export const createComponentWrapper = <T,>(
  componentType: React.ElementType<any>,
  children: React.ReactNode,
) => {
  return () => PillarTableComponentWrapper<T>({ children, componentType });
};
