import withFilterComponent from "shared/components/pillar-table/filters/withFilterComponent";
import { usePillarTableQueryContext } from "shared/components/pillar-table/query/PillarTableQueryContext";
export type PillarTableFilterHiddenProps = {
  filterKey: string;
};
const PillarTableFilterHidden = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  filterKey,
}: PillarTableFilterHiddenProps) => {
  const filterContext = usePillarTableQueryContext();
  return null;
};

export default withFilterComponent(PillarTableFilterHidden);
