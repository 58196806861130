import { ComponentPropsWithoutRef } from "react";

type BadgeProps = {
  testid?: string;
} & ComponentPropsWithoutRef<"span">;

const Badge = ({ testid, className, children, ...props }: BadgeProps) => {
  return (
    <span
      data-testid={testid}
      className={`badgepill-container bg-neutral-light-600 text-neutral-mid-700 flex items-center justify-center px-1 py-0.25 ${
        className ?? ""
      }`}
      {...props}
    >
      {children}
    </span>
  );
};

export default Badge;
