import { useQuery } from "@tanstack/react-query";
import PillarForm from "shared/components/pillar-form/PillarForm";
import XMarkIcon from "shared/components/icons/XMarkIcon";
import usePDFDownload from "hub/src/hooks/usePDFDownload";
import { ComponentPropsWithoutRef, useRef, useState } from "react";
import ApplyDiscountCodeModal from "./ApplyDiscountCodeModal";

import { IonSpinner } from "@ionic/react";
import { InvoiceItemDiscountView } from "shared/mappers/database/accounting/discount/invoice-item-discount";
import { InvoiceItemView } from "shared/mappers/database/accounting/invoice/invoice-item";
import { useCustomSession } from "shared/context/CustomSessionContext";
import { apiRequestContractHandler } from "shared/api/apiRequestContractHandler";
import { getSocietyProfileAccountingInvoiceIdContract } from "shared/api/contracts/society/societyId/profiles/profileId/accounting/invoice/invoiceId";
import { getSocietyProfileAccountingInvoiceGeneratePdfContract } from "shared/api/contracts/society/societyId/profiles/profileId/accounting/invoice/invoiceId/generatePdf";
import { PillarModalHandle } from "shared/components/pillar-modal/PillarModalBody";
import { formatUsdString } from "shared/helpers/invoice/formatUsdString";

type OrderSummaryProps = {
  invoiceId: number;
} & ComponentPropsWithoutRef<"div">;

const OrderSummary = ({
  className,
  invoiceId,
  ...props
}: OrderSummaryProps) => {
  const session = useCustomSession();

  const invoicePdf = usePDFDownload(async (invoiceId, profileId) =>
    apiRequestContractHandler(
      getSocietyProfileAccountingInvoiceGeneratePdfContract,
      {
        params: {
          societyId: session.societyId!,
          profileId: profileId,
          invoiceId: invoiceId,
        },
      },
    ),
  );
  const applyDiscountCodeRef = useRef<PillarModalHandle>(null);
  const [selectedInvoiceItem, setSelectedInvoiceItem] =
    useState<InvoiceItemView | null>(null);

  const invoiceQuery = useQuery(
    [`invoiceId-${invoiceId}`],
    async () =>
      await apiRequestContractHandler(
        getSocietyProfileAccountingInvoiceIdContract,
        {
          params: {
            societyId: session.societyId!,
            profileId: session.profileId!,
            invoiceId: Number(invoiceId),
          },
        },
      ),
    {
      refetchOnWindowFocus: false,
    },
  );

  const openDiscountCodeModal = (invoiceItem: InvoiceItemView) => {
    setSelectedInvoiceItem(invoiceItem);
    applyDiscountCodeRef.current?.open();
  };

  return (
    <>
      {invoiceQuery?.data && (
        <ApplyDiscountCodeModal
          modalRef={applyDiscountCodeRef}
          invoiceItem={selectedInvoiceItem}
          invoice={invoiceQuery?.data}
          refetchInvoice={async () => {
            await invoiceQuery.refetch();
          }}
        />
      )}
      <div
        className={`container-light border-r border-neutral-mid-50 pt-4.5 ${
          className ?? ""
        }`}
        {...props}
      >
        <h1 className="sr-only">Order information</h1>
        <section aria-labelledby="summary-heading">
          {/* ANCHOR: Header */}
          <div className="flex justify-between">
            <div className="text-title">Order Summary</div>
            {invoicePdf.isLoading ? (
              <IonSpinner className="h-5 w-5" />
            ) : (
              <PillarForm.Button
                className="button-regular-general-filled w-fit whitespace-nowrap"
                onClick={() =>
                  invoicePdf.downloadPDF(Number(invoiceId), session.profileId!)
                }
              >
                Print Invoice
              </PillarForm.Button>
            )}
          </div>
          <div className="flex flex-col space-y-2 pt-3">
            <div className="flex flex-col !max-h-30 overflow-y-auto">
              {invoiceQuery &&
                invoiceQuery?.data?.invoiceItems?.map(
                  (invoiceItem: InvoiceItemView, index) => (
                    <div
                      key={`invoice-item-${invoiceItem.invoiceItemId}`}
                      data-testid={`order-summary-invoice-item-${invoiceItem.invoiceItemId}`}
                      className={
                        "py-1 pl-1 flex justify-between rounded-lg " +
                        (index % 2 == 0
                          ? "bg-neutral-light"
                          : "bg-neutral-light-550")
                      }
                    >
                      <div className="flex flex-col space-y-0.5">
                        <p className="text-detailblock-title !capitalize">
                          {invoiceItem?.product?.name?.toLowerCase()}
                        </p>
                        <div className="flex space-x-1">
                          <div>
                            <span className="text-detailblock-base">
                              Quantity:&nbsp;
                            </span>
                            <span className="text-detailblock-subtle">
                              {invoiceItem?.quantity}
                            </span>
                          </div>
                          <div>
                            <span className="text-detailblock-base">
                              Total Price:&nbsp;
                            </span>
                            <span className="text-detailblock-subtle">
                              {formatUsdString(invoiceItem.total ?? 0)}
                            </span>
                          </div>
                        </div>
                      </div>
                      {invoiceItem?.product?.type?.description ===
                      "Donation" ? (
                        <PillarForm.Button
                          className="button-regular-destructive-unfilled"
                          onClick={() => {
                            //TODO: Implement deleteInvoiceItem for donations
                            // invoiceItem?.id &&
                            //   deleteInvoiceItem.mutateAsync(invoiceItem.id);
                          }}
                        >
                          <XMarkIcon className="h-2.5 w-2.5" />
                        </PillarForm.Button>
                      ) : null}
                      <div
                        className="flex pt-1"
                        data-testid={`order-summary-discount-code-wrapper-${invoiceItem.invoiceItemId}`}
                      >
                        <div className="pt-1">
                          {invoiceItem.discount?.length ?? 0 > 0 ? (
                            <div>Discount code applied</div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <PillarForm.Button
                          testid={`order-summary-apply-discount-code-button-${invoiceItem.invoiceItemId}`}
                          className="button-small-neutral-filled"
                          onClick={() => openDiscountCodeModal(invoiceItem)}
                        >
                          Apply Discount Code
                        </PillarForm.Button>
                      </div>
                    </div>
                  ),
                )}
            </div>
            <div className="container-divider-border pb-2 flex flex-col">
              {!!invoiceQuery.data?.salesTaxSum && (
                <div
                  data-testid="order-summary-invoice-total"
                  className="text-right"
                >
                  <span className="text-detailblock-base text-neutral-dark">
                    Sales Tax:&nbsp;
                  </span>
                  <span className="text-detailblock-base text-neutral-dark">
                    {formatUsdString(invoiceQuery?.data?.salesTaxSum ?? 0)}
                  </span>
                </div>
              )}
              <div
                data-testid="order-summary-invoice-total"
                className="text-right"
              >
                <span className="text-detailblock-title text-neutral-dark">
                  Total:&nbsp;
                </span>
                <span className="text-detailblock-title text-neutral-dark">
                  {formatUsdString(invoiceQuery?.data?.total ?? 0)}
                </span>
              </div>
              {invoiceQuery?.data?.invoiceItems?.some(
                (invoiceItem) => invoiceItem.discount?.length ?? 0 > 0,
              ) && (
                <div className="text-subtle">
                  Discount Codes Applied:
                  <div className="flex flex-col">
                    {invoiceQuery?.data?.invoiceItems.flatMap((invoiceItem) => {
                      return invoiceItem.discount?.flatMap(
                        (iiDc: InvoiceItemDiscountView) => {
                          return (
                            <div
                              className="
                              bg-neutral-light-600
                          rounded-md my-1 p-1
                              w-full"
                            >
                              {iiDc.discountCode?.discountCodeName}
                            </div>
                          );
                        },
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default OrderSummary;
