import { FormikValues, useFormikContext } from "formik";
import { get } from "lodash";
import React, {
  ChangeEvent,
  ComponentPropsWithoutRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { RouteParams } from "shared/api/types/society/[societyId]/app-route-config";
import {
  PillarTableRowContext,
  PillarTableRowContextType,
} from "shared/components/pillar-table/PillarTableRowContext";
import { usePillarTableQueryContext } from "shared/components/pillar-table/query/PillarTableQueryContext";
import { PillarDataTableFixedColumn } from "shared/components/pillar-table/helpers/addFixedStyleToColumn";

export type PillarTableCheckboxColumnProps<T> = {
  isHeader?: boolean;
  valueProperty?: string;
  checkedIcon?: ReactNode;
  uncheckedIcon?: ReactNode;
  disabled?: boolean | ((row: T) => boolean);
  readonly?: boolean;
  onCheckboxClick?: (event: ChangeEvent<HTMLInputElement>, rowValue: T) => void;
  formValueProperty?: string;
  rowValueProperty?: string;
  row?: PillarTableRowContextType<T>;
  testid?: string;
  isCheckbox?: boolean;
  linkTo?: { path: string; params?: RouteParams };
  fixedColumn?: PillarDataTableFixedColumn;
  minWidth?: string;
  maxWidth?: string;
  contentClassName?: string;
  tableData?: T[];
} & Omit<
  ComponentPropsWithoutRef<"input">,
  "type" | "onChange" | "checked" | "disabled"
>;

const PillarTableCheckboxColumn = <T extends object>({
  isHeader = false,
  valueProperty,
  checkedIcon,
  uncheckedIcon,
  className,
  onCheckboxClick,
  disabled,
  formValueProperty,
  readonly,
  rowValueProperty,
  isCheckbox,
  tableData: propTableData,
  ...props
}: PillarTableCheckboxColumnProps<T>) => {
  const rowContext = React.useContext(PillarTableRowContext);

  const [checked, setChecked] = useState<boolean>(false);
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const intermediateHeaderCheckBoxRef = useRef<HTMLInputElement>(null);
  const { tableRowResults, filters } = usePillarTableQueryContext<T>();
  const hasInitialized = useRef<boolean>(false);

  const { values, setFieldValue } = useFormikContext<FormikValues>() || {};

  useEffect(() => {
    if (formValueProperty && rowValueProperty && isHeader) {
      const currentValues = tableRowResults.map((row) =>
        get(row, rowValueProperty),
      );

      setFieldValue(formValueProperty, [...currentValues]);
      setIndeterminate(false);
      setChecked(true);
    }
  }, [filters]);

  useEffect(() => {
    if (isHeader && tableRowResults.length > 0 && formValueProperty) {
      const selectedRows = values[formValueProperty].length;
      setChecked(() => selectedRows === tableRowResults.length);
      setIndeterminate(
        selectedRows > 0 && selectedRows < tableRowResults.length,
      );
    }
  }, [isHeader, tableRowResults, values, formValueProperty]);

  useEffect(() => {
    if (isHeader && intermediateHeaderCheckBoxRef.current) {
      intermediateHeaderCheckBoxRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate, isHeader]);

  useEffect(() => {
    if (
      isHeader &&
      formValueProperty &&
      intermediateHeaderCheckBoxRef.current
    ) {
      const totalRowsCount = tableRowResults.length;
      intermediateHeaderCheckBoxRef.current.indeterminate =
        values?.[formValueProperty]?.length > 0 &&
        values?.[formValueProperty]?.length < totalRowsCount;
    }
  }, [isHeader, values, formValueProperty]);

  useEffect(() => {
    if (
      !isHeader &&
      formValueProperty &&
      !!tableRowResults.length &&
      rowValueProperty
    ) {
      if (!hasInitialized.current) {
        const valuesFromTable = tableRowResults.map((row) =>
          get(row, rowValueProperty),
        );
        setFieldValue(formValueProperty, [...valuesFromTable]).then(() =>
          setChecked(true),
        );
        hasInitialized.current = true;
        return;
      }

      const value = rowContext.rowValue.paymentId;
      const setOfValues = new Set([...values[formValueProperty]]);
      setChecked(setOfValues.has(value));
    }
  }, [isHeader, values, tableRowResults]);

  if (!isHeader && !hasInitialized.current) {
    setChecked(valueProperty ? get(rowContext.rowValue, valueProperty) : false);
    hasInitialized.current = true;
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (!readonly) {
      setChecked(isChecked);
    }

    if (onCheckboxClick) {
      onCheckboxClick(event, rowContext?.rowValue as T);
    }

    if (readonly) {
      return;
    }

    if (isHeader && formValueProperty) {
      if (!isChecked) {
        setFieldValue(formValueProperty, []);
      } else {
        const valuesFromTable = tableRowResults.map(
          (row) => row[rowValueProperty as keyof typeof row],
        );
        setFieldValue(formValueProperty, [...valuesFromTable]);
      }
    }

    if (formValueProperty && rowContext?.rowValue) {
      const value = get(rowContext.rowValue, rowValueProperty as string);
      const selectedValues = isChecked
        ? [...(values[formValueProperty] || []), value]
        : (values[formValueProperty] || []).filter(
            (val: string | number) => val !== value,
          );

      setFieldValue(formValueProperty, selectedValues);
    }
  };

  return (
    <input
      type="checkbox"
      checked={checked}
      onClick={(event) => event.stopPropagation()}
      ref={intermediateHeaderCheckBoxRef}
      onChange={handleChange}
      className={`pillartable-table-cell-checkbox ${className ?? ""}`}
      disabled={
        typeof disabled === "function"
          ? disabled(rowContext?.rowValue as T)
          : disabled
      }
      {...props}
    />
  );
};

export default PillarTableCheckboxColumn;
