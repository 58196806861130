import PillarForm from "shared/components/pillar-form/PillarForm";
import CloudDownloadIcon from "shared/components/icons/cloud/CloudDownloadIcon";
import MailForwardIcon from "shared/components/icons/mail/MailForwardIcon";
import { APIContracts } from "authorizenet";
import { IonSpinner } from "@ionic/react";
import { InvoiceView } from "shared/mappers/database/accounting/invoice/invoice";
import { InstitutionRosterMemberView } from "shared/mappers/database/profile/institution-societyuser-relation";
import PencilIcon from "shared/components/icons/pencil/PencilIcon";
import useDeviceInfo from "hub/src/hooks/useDeviceInfo";
import { useCustomSession } from "shared/context/CustomSessionContext";
import usePDFDownload from "hub/src/hooks/usePDFDownload";
import { ComponentPropsWithoutRef, useRef, useState } from "react";
import { useCustomRouter } from "shared/context/CustomRouterContext";
import { AppMenuItem } from "hub/src/types/appMenu";
import TemplateComponent from "admin/src/ui/components/TemplateComponent";
import { getSocietyProfileInvoicesContract } from "shared/api/contracts/society/societyId/profiles/profileId/invoices";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { apiRequestContractHandler } from "shared/api/apiRequestContractHandler";
import { SocietyProfileEmailBodyInput } from "shared/api/types/society/[societyId]/profiles/[profileId]/email/[emailHistoryId]";
import EmailPreviewModal from "admin/src/ui/components/common/modal/EmailPreviewModal";
import {
  getSocietyProfileAccountingInvoiceEmailContract,
  postSocietyProfileAccountingInvoiceEmailContract,
} from "shared/api/contracts/society/societyId/profiles/profileId/accounting/invoice/invoiceId/email";
import { invoice_status } from "@prisma/client";
import { formatUsdString } from "shared/helpers/invoice/formatUsdString";
import { getSocietyProfileAccountingInvoiceGeneratePdfContract } from "shared/api/contracts/society/societyId/profiles/profileId/accounting/invoice/invoiceId/generatePdf";
import { getSocietyProfileMembershipCardPdfContract } from "shared/api/contracts/society/societyId/profiles/profileId/membershipCardPdf";
import { PillarModalHandle } from "shared/components/pillar-modal/PillarModalBody";
import { Link } from "react-router-dom";
import PillarTable from "shared/components/pillar-table/PillarTable";
import { DateTime } from "luxon";
import Badge from "shared/components/common/Badge";
import LoadingPage from "hub/src/components/common/LoadingPage";
import { getFormattedDegreeData } from "shared/helpers/profile/getFormattedDegreeData";
import { LuxonDateFormats } from "shared/datetime/dateFormats";
import { getInvoiceItemWithEffectiveAndExpirationToShow } from "shared/helpers/invoice/getInvoiceItemWithEffectiveAndExpirationToShow";

type UserDetailProps = ComponentPropsWithoutRef<"div">;

const itemsLimit = 3;
export const allInvoicesQueryKey = "all-invoices-ProfileId";
export const unPaidInvoicesQueryKey = "invoices-ProfileId";
export const invoicesTableKey = "invoices";

const UserDetail = ({ className, ...props }: UserDetailProps) => {
  const [selectedInvoice, setSelectedInvoice] = useState<
    InvoiceView | undefined
  >(undefined);
  const modalRef = useRef<PillarModalHandle>(null);

  const queryClient = useQueryClient();
  const session = useCustomSession();
  const router = useCustomRouter();
  const device = useDeviceInfo();

  const relationships = session?.societyUser
    ?.institutionSocietyUserRelation && [
    ...(session?.societyUser ? [session?.societyUser] : []),
    ...session.societyUser.institutionSocietyUserRelation.map(
      (relation: InstitutionRosterMemberView) => {
        return relation.institutionProfile;
      },
    ),
  ];
  const invoicePdf = usePDFDownload(async (invoiceId, profileId) =>
    apiRequestContractHandler(
      getSocietyProfileAccountingInvoiceGeneratePdfContract,
      {
        params: {
          societyId: session.societyId!,
          profileId: profileId,
          invoiceId: invoiceId,
        },
      },
    ),
  );
  const membershipCardPdf = usePDFDownload(async () =>
    apiRequestContractHandler(getSocietyProfileMembershipCardPdfContract, {
      params: {
        societyId: session.societyId!,
        profileId: session.profileId!,
      },
    }),
  );

  const allInvoicesQuery = useQuery(
    [`${allInvoicesQueryKey}-${session.profileId}`],
    async () =>
      await apiRequestContractHandler(
        getSocietyProfileInvoicesContract,
        {
          params: {
            societyId: session.societyId!,
            profileId: session.profileId!,
          },
        },
        {
          status: [
            invoice_status.OPEN,
            invoice_status.PAID,
            invoice_status.PARTIAL_PAID,
          ],
        },
        {
          page: 1,
          pageSize: itemsLimit,
          orderBy: "total",
          orderType: "desc",
        },
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  );

  const unPaidInvoicesQuery = useQuery(
    [`${unPaidInvoicesQueryKey}-${session.profileId}`],
    async () =>
      await apiRequestContractHandler(
        getSocietyProfileInvoicesContract,
        {
          params: {
            societyId: session.societyId!,
            profileId: session.profileId!,
          },
        },
        {
          status: [invoice_status.OPEN],
        },
        {
          page: 1,
          pageSize: 25,
        },
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  );

  const menuOptions: AppMenuItem[] = [
    {
      icon: <PencilIcon className="h-2.5 w-2.5" />,
      label: "Edit Profile",
      onSelect: () => router.push("/edit-user-detail"),
    },
  ];

  //removing membership card for Flux release.
  if (session.profile?.societyUser) {
    if (session.society?.societySettingsPublic?.membershipCardEnabled) {
      menuOptions.push({
        icon: membershipCardPdf.isLoading ? (
          <IonSpinner />
        ) : (
          <CloudDownloadIcon className="h-2.5 w-2.5" />
        ),
        label: "Membership Card",
        onSelect: () => membershipCardPdf.downloadPDF(),
      });
    }
  }

  const handleInvoiceDownload = async (itemId: number) => {
    await invoicePdf.downloadPDF(itemId, session.profileId!);
  };

  const handleInvoiceForwardMail = (item: InvoiceView) => {
    setSelectedInvoice(item);
    modalRef.current?.open();
  };

  const mustacheDetails = () => {
    if (
      session.profile &&
      ((session.profile.societyUser &&
        session.society?.societySettingsPublic?.templates
          ?.userHubOverviewSocietyUser) ||
        (session.profile?.institution &&
          session.society?.societySettingsPublic?.templates
            ?.userHubOverviewInstitution))
    ) {
      const template = session.profile?.societyUser
        ? session.society.societySettingsPublic?.templates
            ?.userHubOverviewSocietyUser
        : session.society.societySettingsPublic?.templates
            ?.userHubOverviewInstitution;

      return (
        <TemplateComponent
          template={template!}
          data={{
            ...session.profile,
            sessionProfileId: session.profileId,
            hasUnpaidInvoices:
              Number(unPaidInvoicesQuery?.data?.results.length) > 0,
          }}
        />
      );
    }

    return null;
  };

  const shouldShowImpersonatorDropdownIcon = Boolean(
    relationships?.filter(
      (relationship: any) => relationship?.profileId !== session.profileId,
    )?.length,
  );

  if (
    allInvoicesQuery.isLoading ||
    unPaidInvoicesQuery.isLoading ||
    allInvoicesQuery.isFetching ||
    unPaidInvoicesQuery.isFetching
  )
    return <LoadingPage />;

  return (
    <div
      className={`container-page-padding flex flex-col gap-y-2  ${
        className ?? ""
      }`}
      {...props}
    >
      {/* ANCHOR: User Details Template */}
      <div className="flex flex-col">
        <div className="section-border" />
        <div className="flex items-end justify-between tablet:flex-wrap tablet:justify-end">
          {typeof unPaidInvoicesQuery?.data?.sum === "number" && (
            <>
              <div className="flex gap-x-1 mr-auto items-end tablet:mb-1">
                <div className="text-subheader phone:text-lg">
                  Total Account Balance:
                </div>
                <div className="text-detailblock-base-medium text-status-success text-2xl">
                  {formatUsdString(unPaidInvoicesQuery?.data?.sum)}
                </div>
              </div>

              <PillarForm.Button
                className="mr-1.5 button-regular-general-filled w-fit h-fit"
                disabled={unPaidInvoicesQuery?.data?.sum === 0}
                onClick={() => router.push("/finance")}
              >
                Pay Balance
              </PillarForm.Button>
            </>
          )}

          <PillarForm.Button
            testid="edit-profile-button"
            className="button-regular-general-filled h-fit w-fit"
            onClick={() => {
              router.push("/edit-user-detail");
            }}
          >
            Edit Profile
          </PillarForm.Button>
        </div>

        <div className="section-border" />

        {mustacheDetails()}
      </div>

      <div className="section-border" />

      {/* ANCHOR: Financials */}
      {device.isWeb && (
        <>
          <PillarTable<InvoiceView>
            dataQuery={{ data: allInvoicesQuery.data?.results ?? [] }}
            queryKey={invoicesTableKey}
            className="w-full"
            emptyTableMessage="No Receipts"
          >
            <PillarTable.Column<InvoiceView> heading="Finance">
              {(row) => (
                <>
                  {row.invoiceItems &&
                    row.invoiceItems.map((invoiceItem, index) => {
                      return (
                        invoiceItem.product!.name +
                        (index === row.invoiceItems!.length - 1 ? "" : ", ")
                      );
                    })}
                </>
              )}
            </PillarTable.Column>
            <PillarTable.Column<InvoiceView> heading="Created">
              {(row) => (
                <>
                  {row?.status === invoice_status.PAID ? (
                    DateTime.fromISO(
                      row?.created?.timestamp ?? row?.created?.timestamp ?? "",
                    ).isValid ? (
                      DateTime.fromISO(
                        row?.created?.timestamp ??
                          row?.modified?.timestamp ??
                          "",
                      ).toFormat(LuxonDateFormats.D)
                    ) : (
                      <></>
                    )
                  ) : (
                    <Badge className="w-fit bg-status-danger-tint !text-neutral-light-100 !text-xs">
                      Payment Due
                    </Badge>
                  )}
                </>
              )}
            </PillarTable.Column>
            <PillarTable.Column<InvoiceView> heading="Payment">
              {(row) => (
                <>
                  {row?.status === invoice_status.PAID ? (
                    DateTime.fromISO(
                      row?.payments?.[0]?.created?.timestamp ??
                        row?.modified?.timestamp ??
                        "",
                    ).isValid ? (
                      DateTime.fromISO(
                        row?.payments?.[0]?.created?.timestamp ??
                          row?.modified?.timestamp ??
                          "",
                      ).toFormat(LuxonDateFormats.D)
                    ) : (
                      <></>
                    )
                  ) : (
                    <Badge className="w-fit bg-status-danger-tint !text-neutral-light-100 !text-xs">
                      Payment Due
                    </Badge>
                  )}
                </>
              )}
            </PillarTable.Column>
            <PillarTable.Column<InvoiceView> heading="Effective">
              {(row) => (
                <>
                  {getInvoiceItemWithEffectiveAndExpirationToShow(
                    row.invoiceItems!,
                  ).effectiveDate
                    ? DateTime.fromISO(
                        getInvoiceItemWithEffectiveAndExpirationToShow(
                          row.invoiceItems!,
                        ).effectiveDate ?? "",
                      ).toFormat(LuxonDateFormats.D)
                    : ""}
                </>
              )}
            </PillarTable.Column>
            <PillarTable.Column<InvoiceView> heading="Expiration">
              {(row) => (
                <>
                  {getInvoiceItemWithEffectiveAndExpirationToShow(
                    row.invoiceItems!,
                  ).expirationDate
                    ? DateTime.fromISO(
                        getInvoiceItemWithEffectiveAndExpirationToShow(
                          row.invoiceItems!,
                        ).expirationDate ?? "",
                      ).toFormat(LuxonDateFormats.D)
                    : ""}
                </>
              )}
            </PillarTable.Column>
            <PillarTable.Column<InvoiceView> heading="">
              {(row) => (
                <p className="mr-2 text-subtle font-bold text-status-success">
                  ${row.total}
                </p>
              )}
            </PillarTable.Column>
            <PillarTable.Column<InvoiceView> heading="">
              {(row) => (
                <p className="text-base-semibold phone:text-end">
                  {`${
                    (
                      row.payments?.[0]
                        ?.additionalInfo as unknown as APIContracts.TransactionResponse
                    )?.accountType ?? ""
                  }
                  ${
                    (
                      row.payments?.[0]
                        ?.additionalInfo as unknown as APIContracts.TransactionResponse
                    )?.accountNumber ?? ""
                  }`}
                </p>
              )}
            </PillarTable.Column>
            <PillarTable.Column<InvoiceView> heading="Actions">
              {(row) => (
                <div className="flex flex-row">
                  <PillarForm.LoadingButton
                    className="button-regular-general-unfilled mr-1.5"
                    onClick={async () => {
                      await handleInvoiceDownload(row.invoiceId!);
                    }}
                  >
                    <CloudDownloadIcon className="h-2.5 w-2.5 stroke-2" />
                  </PillarForm.LoadingButton>
                  <PillarForm.Button
                    className="button-regular-general-filled"
                    onClick={() => {
                      handleInvoiceForwardMail(row);
                    }}
                  >
                    <MailForwardIcon className="h-2.5 w-2.5 stroke-2" />
                  </PillarForm.Button>

                  {row?.status !== invoice_status.PAID && (
                    <Link
                      to={`/payment/${row.invoiceId}`}
                      className="button-regular-general-filled hover:text-neutral-light mx-2"
                    >
                      Pay
                    </Link>
                  )}
                </div>
              )}
            </PillarTable.Column>
          </PillarTable>

          {(allInvoicesQuery?.data?.totalResults ?? 0) > itemsLimit && (
            <Link to={"/finance"} className="link-decorated ml-2">
              Additional invoices and receipts can be found on the finance page
            </Link>
          )}
          <div className="section-border" />
        </>
      )}

      {/* ANCHOR: Modals */}
      <EmailPreviewModal
        modalRef={modalRef}
        emailPreviewCallout={async () =>
          await apiRequestContractHandler(
            getSocietyProfileAccountingInvoiceEmailContract,
            {
              params: {
                societyId: session.societyId!,
                invoiceId: selectedInvoice?.invoiceId!,
                profileId: session.profileId!,
              },
            },
          )
        }
        emailSubmit={async function (
          bodyProps: SocietyProfileEmailBodyInput,
        ): Promise<void> {
          await apiRequestContractHandler(
            postSocietyProfileAccountingInvoiceEmailContract,
            {
              params: {
                societyId: session.societyId!,
                invoiceId: selectedInvoice?.invoiceId!,
                profileId: session.profileId!,
              },
              body: bodyProps,
            },
          );
        }}
        queryKey={`email-preview-invoice-${selectedInvoice?.invoiceId}`}
      />
    </div>
  );
};

export default UserDetail;
