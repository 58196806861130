// shared/components/pillar-table/filters/withFilterComponent.tsx

import React from "react";

const withFilterComponent = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const ComponentWithFilter = (props: P) => <WrappedComponent {...props} />;

  ComponentWithFilter.isFilterComponent = true;

  return ComponentWithFilter;
};

export default withFilterComponent;
