import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonToolbar,
} from "@ionic/react";
import PillarForm from "shared/components/pillar-form/PillarForm";
import TemplateComponent from "admin/src/ui/components/TemplateComponent";
import ChevronRightIcon from "shared/components/icons/chevron/ChevronRightIcon";
import { useCustomSession } from "shared/context/CustomSessionContext";
import React, { ComponentPropsWithoutRef } from "react";
import { useHistory } from "react-router";

type PageComponentProps = ComponentPropsWithoutRef<typeof IonPage>;
type PageContentProps = ComponentPropsWithoutRef<"div">;
type PageHeaderProps = ComponentPropsWithoutRef<typeof IonHeader> & {
  showBackButton?: boolean;
};

const PageHeader = ({ showBackButton, ...props }: PageHeaderProps) => {
  const history = useHistory();
  const session = useCustomSession();
  return (
    <IonHeader className="shadow " {...props}>
      <IonToolbar>
        <TemplateComponent
          template={
            session.society?.societySettingsPublic?.templates
              ?.hubHeadingTemplate ?? ""
          }
          data={session.society!}
        />
        <IonButtons slot="start">
          <IonMenuButton></IonMenuButton>
        </IonButtons>

        {showBackButton && (
          <PillarForm.Button
            className="rounded-full bg-neutral-light !h-5 w-5 flex justify-center items-center group"
            onClick={() => history.goBack()}
          >
            <ChevronRightIcon className="h-3 w-3 stroke-neutral-mid-600 rotate-180 group-hover:stroke-neutral-mid-900" />
          </PillarForm.Button>
        )}
      </IonToolbar>
    </IonHeader>
  );
};

const PageContent = ({ children, className, ...props }: PageContentProps) => {
  return (
    <IonContent className={"h-full w-full"}>
      {/* TODO: We should be able to just style the IonContent and not have this containg div afaik
      but the styling isn't being respected so we use this containing div to style. */}
      <div
        className={`page-content-container h-full w-full ${className ?? ""}`}
        {...props}
      >
        {children}
      </div>
    </IonContent>
  );
};

const PageComponent = ({
  children,
  className,
  ...props
}: PageComponentProps) => {
  return (
    <IonPage className={`h-full w-full ${className ?? ""}`} {...props}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          switch (child.type) {
            case PageHeader:
              return child;
            case PageContent:
              return child;
            default:
              return null;
          }
        }
        return null;
      })}
    </IonPage>
  );
};

PageComponent.Header = PageHeader;
PageComponent.Content = PageContent;

export default PageComponent;
