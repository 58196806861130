import React from "react";
import PillarFormTextInput from "shared/components/pillar-form/components/PillarFormTextInput";

type PillarFormAddressInputProps = {
  label?: string;
  valuePath?: string;
};
const PillarFormAddressInput = ({
  label = "Address",
  valuePath,
}: PillarFormAddressInputProps) => (
  <div>
    <p className="mb-0.25 text-filter-label">{label}</p>
    <PillarFormTextInput
      name={`${valuePath ? valuePath + "." : ""}line1`}
      label="Address line 1"
    />
    <PillarFormTextInput
      name={`${valuePath ? valuePath + "." : ""}line2`}
      label="Address line 2"
    />
    <PillarFormTextInput
      name={`${valuePath ? valuePath + "." : ""}line3`}
      label="Address line 3"
    />
    <PillarFormTextInput
      name={`${valuePath ? valuePath + "." : ""}city`}
      label="City"
    />
    <PillarFormTextInput
      name={`${valuePath ? valuePath + "." : ""}state`}
      label="State"
    />
    <PillarFormTextInput
      name={`${valuePath ? valuePath + "." : ""}country`}
      label="Country"
    />
    <PillarFormTextInput
      name={`${valuePath ? valuePath + "." : ""}region`}
      label="Region"
    />
    <PillarFormTextInput
      name={`${valuePath ? valuePath + "." : ""}postalCode`}
      label="Postal code"
    />
  </div>
);

export default PillarFormAddressInput;
