import { PillarTableExportToExcelContext } from "shared/components/pillar-table/export/PillarTableExportToExcelContext";
import { DeepKeys } from "shared/types/deepkeys";
import { useContext } from "react";
export interface PillarTableExportToExcelDynamicColumnProps<T> {
  valueProperty?: DeepKeys<T>;
  header: string;
  dynamicHeader?: (relativePosition: number, data: T[]) => string | string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?:
    | ((item: any) => string)
    | ((row: T, data: T[]) => string | string[]);
}
const PillarTableExportToExcelDynamicColumn = <T,>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: PillarTableExportToExcelDynamicColumnProps<T>,
) => {
  const exportToExcelContext = useContext(PillarTableExportToExcelContext);
  if (!exportToExcelContext) {
    throw new Error(
      "PillarTableExportToExcelColumn must be used within a PillarTableExportToExcel",
    );
  }
  return <></>;
};

export default PillarTableExportToExcelDynamicColumn;
