import React from "react";
import { OrderTypes, PaginationRequest } from "shared/api/types/pagination";
import ChevronDownIcon from "shared/components/icons/chevron/ChevronDownIcon";
import ChevronUpIcon from "shared/components/icons/chevron/ChevronUpIcon";
import PillarTableCheckboxColumn from "shared/components/pillar-table/display-columns/PillarTableCheckboxColumn";
import { getFixedColumnStyle } from "shared/components/pillar-table/helpers/addFixedStyleToColumn";



export interface PillarTableHeaderCellProps<T> {
  column: React.ReactElement;
  index: number;
  paginationState?:PaginationRequest;
  toggleSorting?: (orderBy: string) => void;
  fixedColumnWidths: number[];
  columnRefs: React.RefObject<(HTMLDivElement | null)[]>;
}

const orderDirectionArrows = {
  [OrderTypes.asc]: <ChevronUpIcon className="w-2 h-2" />,
  [OrderTypes.desc]: <ChevronDownIcon className="h-2 w-2" />,
};

const isCheckboxColumn = (column: React.ReactElement): boolean =>
  column.props.isCheckbox === true;

const PillarTableHeaderCell = <T,>({
  column,
  index,
  paginationState,
  toggleSorting,
  fixedColumnWidths,
  columnRefs,
}: PillarTableHeaderCellProps<T>) => {
  // if (!column) return null;
  const sortBy = column?.props?.sortBy;
  const isCheckbox = isCheckboxColumn(column);

  const handleClick = sortBy
    ? () => toggleSorting?.(sortBy as string)
    : undefined;

  return (
    <th
      key={`heading-${index}`}
      ref={(el) => {
        if (columnRefs.current) {
          columnRefs.current[index] = el;
        }
      }}
      scope="col"
      className={`${
        isCheckbox
          ? "pillartable-table-header-cell-centered justify-center"
          : "pillartable-table-header-cell-general"
      } ${column.props.headingClassName ?? ""} ${
        sortBy ? "cursor-pointer" : ""
      }`}
      onClick={handleClick}
      style={getFixedColumnStyle(column, index, fixedColumnWidths)}
    >
      <div className="flex flex-row items-center">
        {isCheckbox ? (
          <PillarTableCheckboxColumn
            isHeader={true}
            valueProperty={column.props.valueProperty}
            {...column.props}
          />
        ) : (
          <>
            {column.props.heading}
            {paginationState &&
              paginationState.orderBy === sortBy &&
              orderDirectionArrows[paginationState.orderType ?? OrderTypes.asc]}
          </>
        )}
      </div>
    </th>
  );
};

export default PillarTableHeaderCell;
