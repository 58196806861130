import { ContinuousDeploymentPolicyAlreadyExists } from "@aws-sdk/client-cloudfront";

interface downloadFileFunctionProps {
  url: string;
  fileName?: string;
}

export const useDownloadFileByLink = () => {
  const downloadFileFunction = async ({
    url,
    fileName,
  }: downloadFileFunctionProps) => {
    const response = await fetch(url);

    if (!response.ok)
      throw new Error(
        `Error downloading file: ${response.status} ${response.statusText}`,
      );

    const isRedirected = response.redirected;

    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute(
      "download",
     (isRedirected && !fileName
      ? response.url.split("/").pop()?.split("?").shift()
      : fileName) ?? "",
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  };

  return { downloadFileFunction };
};
