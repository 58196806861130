import { ComponentPropsWithoutRef } from "react";
import { twMerge } from "tailwind-merge";

// https://tabler.io/icons/icon/pencil
const PencilIcon = ({
  className,
  ...props
}: ComponentPropsWithoutRef<"svg">) => {
  const defaultClassnames = "icon icon-tabler icon-tabler-pencil h-3 w-3";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge(defaultClassnames, className)}
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
      <line x1="13.5" y1="6.5" x2="17.5" y2="10.5"></line>
    </svg>
  );
};

export default PencilIcon;
