import { FormikValues, useFormikContext } from "formik";
import { get } from "lodash";
import { ComponentPropsWithoutRef, forwardRef } from "react";
import PillarFormInputWrapper, {
  PillarFormInputWrapperProps,
} from "shared/components/pillar-form/components/PillarFormInputWrapper";

export type PillarFormTextInputProps = PillarFormInputWrapperProps &
  Omit<ComponentPropsWithoutRef<"input">, "id" | "name" | "value" | "type">;

// TODO: We should rename "additionalClasses" to "containerClasses" or something more descriptive.
const PillarFormTextInput = forwardRef<
  HTMLInputElement,
  PillarFormTextInputProps
>(
  (
    {
      name,
      label,
      labelClassName,
      additionalClasses,
      className,
      testid,
      tooltip,
      onChange,
      ...props
    },
    ref
  ) => {
    const { values, setFieldValue } = useFormikContext<FormikValues>();
    const value = get(values, name);
    return (
      <PillarFormInputWrapper
        name={name}
        labelClassName={labelClassName}
        label={label}
        tooltip={tooltip}
        additionalClasses={`${additionalClasses ?? ""}`}
      >
        <input
          type="text"
          className={`${className ? className : ""}`}
          value={value}
          name={name}
          id={name}
          data-testid={testid}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
            if (onChange) {
              onChange(e);
            }
          }}
          ref={ref}
          {...props}
        />
      </PillarFormInputWrapper>
    );
  }
);

PillarFormTextInput.displayName = "PillarFormTextInput";

export default PillarFormTextInput;
