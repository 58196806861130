import { useQuery } from "@tanstack/react-query";
import { ComponentPropsWithoutRef, useEffect, useState } from "react";
import withFilterComponent from "shared/components/pillar-table/filters/withFilterComponent";
import { usePillarTableQueryContext } from "shared/components/pillar-table/query/PillarTableQueryContext";
import { FilterChoice } from "shared/filter-where-clause";

type PillarTableFilterSelectMenuProps<T> = ComponentPropsWithoutRef<"select"> & {
  initalValue?: string;
  selectOptionQuery?: () => Promise<FilterChoice[]>;
  title: string;
  wrapperClassName?: string;
  filterKey: string;
  testId?: string;
};
const PillarTableFilterSelectMenu = <T extends Record<string, any>>({
  initalValue,
  filterKey,
  title,
  wrapperClassName,
  selectOptionQuery,
  testId,
  ...props
}: PillarTableFilterSelectMenuProps<T>) => {
  const filterContext = usePillarTableQueryContext();
  const { setFilters, filters } = filterContext;

  const [filterValue, setFilterValue] = useState<string>(
    (filters[filterKey] as string) ?? initalValue ?? "",
  );

  useEffect(() => {
    if (filters[filterKey] != filterValue) {
      setFilters({ ...filters, [filterKey]: filterValue });
    }
  }, [filterValue]);

  const selectOptions = useQuery<FilterChoice[]>(
    ["selectOptions", filterKey, filterContext.dynamicFilterChoices],
    () => {
      if (selectOptionQuery) {
        return (selectOptionQuery && selectOptionQuery()) ?? [];
      }
      const dynamicFilterOption =
        filterContext?.dynamicFilterChoices?.[filterKey];
      return dynamicFilterOption ?? [];
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <div className={wrapperClassName}>
      {title && (
        <label className="text-filter-label" htmlFor={title}>
          {title}
        </label>
      )}
      <select
        title="select-options"
        className="w-full block pillartable-filters-selectmenu-dropdown-container"
        id="select-options"
        data-testid={`select-options-${filterKey}-${testId}`}
        value={filterValue}
        onChange={(e) => {
          setFilterValue(e.target.value);
        }}
        {...props}
      >
        {selectOptions.data &&
          selectOptions.data.map((option, index) => (
            <option
              key={`filter-select-menu-${filterKey}-${index}`}
              data-testid={`filter-select-menu-${filterKey}-${option.value}`}
              value={option.value}
            >
              {String(option.display)}
            </option>
          ))}
      </select>
    </div>
  );
};

export default withFilterComponent(PillarTableFilterSelectMenu);
