/* eslint-disable @typescript-eslint/no-unused-vars */
import { profile_type, Prisma } from "@prisma/client";
import {
  ComponentCollection,
  ICustomQuestionTypeConfiguration,
  IQuestion,
  Question,
  Serializer,
} from "survey-core";
import { SurveyCreatorModel } from "survey-creator-core";
const test = Prisma.ProfileScalarFieldEnum;
const show_hide = (
  user_type_question: IQuestion,
  field: string,
  show: boolean
) => {
  if (field === "id") {
    return;
  }
  const question = user_type_question.survey.getQuestionByName(field);
  if (!question) {
    return;
  }
  if (question.getPropertyByName("force_hide")?.value) {
    return;
  }
  question.visible = show;
};

export const custom_question_profile_type = "profile_type";
export const registerProfileTypeQuestion = async (creator?: SurveyCreatorModel) => {
  creator?.onQuestionAdded.add((sender: SurveyCreatorModel, options) => {
    if (options.question?.getType() == custom_question_profile_type) {
      options.question.name = custom_question_profile_type;
    }
  });

  const login_details_object: ICustomQuestionTypeConfiguration = {
    name: custom_question_profile_type,
    title: "Profile Type",
    questionJSON: {
      type: "dropdown",
      name: custom_question_profile_type,
      choices: [
        { value: profile_type.Society_User, text: "Society User" },
        { value: profile_type.Institution, text: "Institution" },
      ],
      required: true,
    },
    onLoaded(question: Question) {
      Serializer.getProperty(custom_question_profile_type, "name").readOnly =
        true;
      Serializer.addProperty(custom_question_profile_type, {
        name: "changePasswordTitle",
        type: "text",
        default: "Change Password",
        category: "general",
      });
      Serializer.getProperty(custom_question_profile_type, "name").visible =
        false;
      Serializer.getProperty(custom_question_profile_type, "visible").visible =
        false;
      Serializer.getProperty(
        custom_question_profile_type,
        "isRequired"
      ).visible = false;
      Serializer.getProperty(custom_question_profile_type, "readOnly").visible =
        false;

      Serializer.getProperty(
        custom_question_profile_type,
        "visibleIf"
      ).visible = false;

      Serializer.getProperty(custom_question_profile_type, "enableIf").visible =
        false;
      Serializer.getProperty(
        custom_question_profile_type,
        "requiredIf"
      ).visible = false;
      Serializer.getProperty(
        custom_question_profile_type,
        "clearIfInvisible"
      ).visible = false;
      Serializer.getProperty(
        custom_question_profile_type,
        "defaultValueExpression"
      ).visible = false;
      Serializer.getProperty(
        custom_question_profile_type,
        "defaultValue"
      ).visible = false;
      Serializer.getProperty(
        custom_question_profile_type,
        "startWithNewLine"
      ).visible = false;
      Serializer.getProperty(
        custom_question_profile_type,
        "valueName"
      ).visible = false;
      Serializer.getProperty(
        custom_question_profile_type,
        "useDisplayValuesInTitle"
      ).visible = false;
      Serializer.getProperty(
        custom_question_profile_type,
        "correctAnswer"
      ).visible = false;

      Serializer.getProperty(
        custom_question_profile_type,
        "requiredErrorText"
      ).visible = false;

      Serializer.getProperty(
        custom_question_profile_type,
        "validators"
      ).visible = false;
      // throw new Error("Function not implemented.");
    },
    onInit() {
      //Override titleLocation property attributes for "shippingaddress" class by making it invisible in property grid and change its default value
    },
    onCreated(question: Question) {
      // throw new Error("Function not implemented.");
    },
    onAfterRender: function (
      question: Question,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      htmlElement: any
    ): void {
      // throw new Error("Function not implemented.");
    },
    onAfterRenderContentElement: function (
      question: Question,
      element: Question,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      htmlElement: any
    ): void {
      //  throw new Error("Function not implemented.");
    },
    onPropertyChanged: function (
      question: Question,
      propertyName: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newValue: any
    ): void {
      //  throw new Error("Function not implemented.");
    },
    onValueChanged: function (
      question: Question,
      name: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newValue: any
    ): void {
      Object.keys({
        ...Prisma.ProfileScalarFieldEnum,
        login_details: "login_details",
      }).forEach((societyUserField) => {
        show_hide(
          question,
          societyUserField,
          newValue === profile_type.Society_User
        );
      });
      Object.keys(Prisma.InstitutionScalarFieldEnum).forEach(
        (institutionField) => {
          show_hide(
            question,
            institutionField,
            newValue === profile_type.Institution
          );
        }
      );
      // throw new Error("Function not implemented.");
    },
    onItemValuePropertyChanged: function (
      question: Question,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      options: any
    ): void {
      // throw new Error("Function not implemented.");
    },
  };
  if (
    !ComponentCollection.Instance.getCustomQuestionByName(
      custom_question_profile_type
    )
  ) {
    ComponentCollection.Instance.add(login_details_object);
  }
};
