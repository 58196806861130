import { SocietyDirectoryParamsInput } from "shared/api/types/society/[societyId]/directory/[directoryId]/index";
import { DirectoryView } from "shared/mappers/database/directory/directory";
import { ProfileView } from "shared/mappers/database/profile/profile";
import { BaseApiContract } from "shared/api/apiRequestContractHandler";
import { PaginatedResults } from "shared/api/types/pagination";
import { HttpMethod } from "shared/api/http";
export const getSocietyDirectoryIdContract: BaseApiContract<
  SocietyDirectoryParamsInput,
  undefined,
  PaginatedResults<ProfileView, DirectoryView>
> = {
  method: HttpMethod.GET,
  apiRoute: "/api/v1/society/[societyId]/directory/[directoryId]",
  paramsType: {} as SocietyDirectoryParamsInput,
  responseType: {} as PaginatedResults<ProfileView, DirectoryView>,
};
export const deleteSocietyDirectoryIdContract: BaseApiContract<
  SocietyDirectoryParamsInput,
  undefined,
  DirectoryView
> = {
  method: HttpMethod.DELETE,
  apiRoute: "/api/v1/society/[societyId]/directory/[directoryId]",
  paramsType: {} as SocietyDirectoryParamsInput,
  responseType: {} as DirectoryView,
};
