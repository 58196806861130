import React, { useCallback } from "react";

interface IconProps {
  className: string;
}

interface Button {
  children: JSX.Element | JSX.Element[];
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  roundedFull?: boolean;
  padding?: string;
  active?: boolean;
  filterKey?: string;
  testId?: string;
}

export const DateIcon: React.FC<IconProps> = ({ className = "w-3 h-3" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="#d1d5db"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
      />
    </svg>
  );
};

export const CloseIcon: React.FC<IconProps> = ({ className = "w-3 h-3" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="datepicker-gray-500"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  );
};

export const ChevronLeftIcon: React.FC<IconProps> = ({
  className = "chevron-icon",
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 19.5L8.25 12l7.5-7.5"
      />
    </svg>
  );
};

export const DoubleChevronLeftIcon: React.FC<IconProps> = ({
  className = "chevron-icon",
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      data-testid="GenericDoubleChevronLeftIcon"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
      />
    </svg>
  );
};

export const ChevronRightIcon: React.FC<IconProps> = ({
  className = "chevron-icon",
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.25 4.5l7.5 7.5-7.5 7.5"
      />
    </svg>
  );
};

export const DoubleChevronRightIcon: React.FC<IconProps> = ({
  className = "chevron-icon",
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
      />
    </svg>
  );
};

export const SecondaryButton: React.FC<Button> = ({
  children,
  onClick,
  filterKey,
  disabled = false,
}) => {
  return (
    <button
      type="button"
      className="footer-button-prefix button-regular-neutral-filled"
      onClick={onClick}
      disabled={disabled}
      id={`button-datepicker-cancel-${filterKey}`}
    >
      {children}
    </button>
  );
};

export const PrimaryButton: React.FC<Button> = ({
  children,
  onClick,
  filterKey,
  disabled = false,
}) => {
  return (
    <button
      type="button"
      className="footer-button-prefix button-regular-general-filled"
      onClick={onClick}
      disabled={disabled}
      id={`button-datepicker-submit-${filterKey}`}
    >
      {children}
    </button>
  );
};

export const ConfirmButton: React.FC<Button> = ({
  children,
  onClick,
  filterKey,
  disabled = false,
}) => {
  return (
    <button
      type="button"
      className="py-0.5 px-0.5 w-8 button-regular-general-filled text-xs"
      onClick={onClick}
      disabled={disabled}
      id={`button-datepicker-submit-${filterKey}`}
    >
      {children}
    </button>
  );
};

export const RoundedButton: React.FC<Button> = ({
  children,
  onClick,
  disabled,
  roundedFull = false,
  padding = "py-[0.15rem]",
  active = false,
  testId,
}) => {
  // Functions
  const getClassName = useCallback(() => {
    const activeClass = active ? "font-semibold bg-datepicker-gray-100" : "";
    const defaultClass = !roundedFull
      ? ` ${activeClass} ${padding} w-full transition-all duration-300 px-3  uppercase hover:bg-datepicker-gray-100 rounded-md focus:ring-1
      `
      : `${activeClass} transition-all duration-300 hover:bg-datepicker-gray-100 rounded-full focus:ring-1`;
    /*const buttonFocusColor =
      BUTTON_COLOR.focus[primaryColor as keyof typeof BUTTON_COLOR.focus];*/
    const disabledClass = disabled ? "line-through" : "";

    return `${defaultClass} ${disabledClass}`; /*${buttonFocusColor}*/
  }, [disabled, padding, roundedFull, active]);

  return (
    <button
      type="button"
      className={getClassName()}
      data-testid={testId}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
