import { useQuery } from "@tanstack/react-query";

import Spinner from "admin/src/ui/components/common/Spinner";
import { getSocietyDashboardIdContract } from "shared/api/contracts/society/societyId/dashboards/dashboardId";
import CustomTooltip from "shared/components/dashboard/CustomTooltip";

import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { apiRequestContractHandler } from "shared/api/apiRequestContractHandler";
import { ChartDataType } from "shared/components/dashboard/dashboardMapper";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RingGraphModuleType = ({
  dashboardId,
  societyId,
}: {
  dashboardId: number;
  societyId: number;
}) => {
  const { data, isLoading } = useQuery({
    queryKey: ["dashboardModule", dashboardId],
    queryFn: async () =>
      apiRequestContractHandler(getSocietyDashboardIdContract, {
        params: {
          societyId,
          dashboardId: dashboardId,
        },
      }),
    refetchOnWindowFocus: false,
  });

  if (isLoading)
    return (
      <div className="col-span-6 flex items-center justify-center">
        <Spinner />
      </div>
    );

  const chartData = (data?.data ?? []) as ChartDataType;

  if (!data) return <></>;

  return (
    <div
      className={`col-span-${data.dashboardModule.colSpan} row-start-${data.dashboardModule.positionRow} col-start-${data.dashboardModule.positionColumn} flex flex-col`}
    >
      <div className="flex items-center justify-between">
        <a className="mb-2 inline-block text-xl font-semibold">
          {data.dashboardModule.title}
        </a>
      </div>
      <ResponsiveContainer width="100%" height={600}>
        {chartData.length ? (
          <PieChart>
            <Pie
              data={data.data as ChartDataType}
              dataKey={data.details.setsConfig![0].dataKey}
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={100}
              paddingAngle={5}
              label
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    data.details.setsConfig![index]?.fill ??
                    COLORS[index % COLORS.length]
                  }
                />
              ))}
            </Pie>
            <Tooltip
              content={<CustomTooltip allData={data.data as ChartDataType} />}
            />
            <Legend />
          </PieChart>
        ) : (
          <div className="flex items-center justify-center h-full text-lg opacity-50">
            No Records Found
          </div>
        )}
      </ResponsiveContainer>
    </div>
  );
};

export default RingGraphModuleType;
