import { SessionView } from "shared/mappers/database/session/session";

export type WorkflowInstancePayloadBase<
  T extends Record<string, any> = Record<string, any>,
> = T & {
  formId?: number;
};
export type WorkflowFunctionStateInvokeParamsBase<
  T extends Record<string, any> = Record<string, any>,
> = T;
export type WorkflowStateConfigBase<
  T extends Record<string, any> = Record<string, any> & any,
> = T;

export type WorkflowInstanceStopped = {
  payload: WorkflowInstancePayloadBase;
  userhubRedirect?: string;
  status?: WorkflowStatus;
  profileId?: number;
  workflowInstanceId: number;
} & Record<string, any>;

// Base interfaces for workflow definition, state, transitions, and parameters.
export enum WorkflowStateType {
  FUNCTION = "function",
  WAIT = "wait",
  CONDITIONAL_STATE_HISTORY = "conditional_state_history",
  CONDITIONAL_PAYLOAD_VALUE = "conditional_payload_value",
}
export interface WorkflowDefinition {
  states: (
    | WorkflowFunctionState
    | WorkflowStateHistoryConditionState
    | WorkflowWaitState
    | WorkflowPayloadValueConditionState
  )[];
  initialStateName: string;
  name: string;
}

export interface WorkflowStateBase {
  description: string;
  type: WorkflowStateType;
  name: string;
}

export interface WorkflowWaitState extends WorkflowStateBase {
  type: WorkflowStateType.WAIT;
  status: WorkflowStatus;
  userhubRedirect?: string;
  possibleActions?: (WorkflowActionForm | WorkflowActionModal)[];
  nextState?: WorkflowStateTransition;
  updateChildren?: boolean;
}

export interface WorkflowStatus {
  instanceStatus: string;
  displayStatus: string;
  className?: string;
}
export enum WorkflowActionRoles {
  Admin = "Admin",
  User = "User",
}

export enum WorkflowActionType {
  MODAL = "modal",
  FORM = "form",
}
export interface WorkflowAction {
  type: WorkflowActionType;
  roles: WorkflowActionRoles[];
  endpoint: string;
  actionName: string;
  event: string;
  title: string;
  className?: string;
}

export interface WorkflowActionModal extends WorkflowAction {
  type: WorkflowActionType.MODAL;
  title: string;
  message: string;
  confirmText: string;
  cancelText: string;
}

export interface WorkflowActionForm extends WorkflowAction {
  type: WorkflowActionType.FORM;
  userhubFormRoute: string;
}
export interface WorkflowStateHistoryConditionState extends WorkflowStateBase {
  condition: WorkflowStateHistoryCondition[];
  type: WorkflowStateType.CONDITIONAL_STATE_HISTORY;
}

export interface WorkflowPayloadValueConditionState extends WorkflowStateBase {
  property: string;
  condition: WorkflowPayloadValueCondition[];
  type: WorkflowStateType.CONDITIONAL_PAYLOAD_VALUE;
}

export interface WorkflowPayloadValueCondition extends WorkflowStateTransition {
  [key: string]: any;
}

export interface WorkflowFunctionState<WorkflowStateConfig = any>
  extends WorkflowStateBase {
  transitions?: WorkflowStateTransition[];
  function: string;
  type: WorkflowStateType.FUNCTION;
  config: WorkflowStateConfig;
}
export interface WorkflowStateTransition {
  event: string;
  next: string;
}

export interface WorkflowStateHistoryCondition extends WorkflowStateTransition {
  stateName: string;
}

export interface WorkflowInstanceStateHistory {
  stateName: string;
  caller: string;
  session?: SessionView;
  event: string;
  timestamp: string;
  workflowInstanceId: number;
  workflowId: number;
  submissionInstanceId?: number;
  submissionDefinitionId?: number;
  invokeParams?: any;
  status: WorkflowStatus;
  stopped?: WorkflowInstanceStopped;
  type: WorkflowStateType;
}
