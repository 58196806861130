import React from "react";
import { ElementFactory, Question, Serializer, settings } from "survey-core";
import { SurveyCreator } from "survey-creator-react";
import { ReactQuestionFactory } from "survey-react-ui";

import ObfuscateInput, {
  ObfuscateInputProps,
} from "shared/components/survey-js-form/questions/obfuscate/ObfuscateInput";

export const CUSTOM_TYPE_OBFUSCATE_INPUT = "obfuscate_input";

const ObfuscateQuestionProperties = {
  regexPattern: {
    name: "regexPattern",
    display: "Regex Pattern",
    default: "^(\\d{2})(\\d+)$",
  },
  obfuscatePattern: {
    name: "obfuscatePattern",
    display: "Obfuscation Pattern (Replace with)",
    default: "$1***",
  },
};

export class ObfuscateQuestionModel extends Question {
  getType() {
    return CUSTOM_TYPE_OBFUSCATE_INPUT;
  }

  get regexPattern() {
    return this.getPropertyValue(ObfuscateQuestionProperties.regexPattern.name);
  }

  set regexPattern(val: string) {
    this.setPropertyValue(ObfuscateQuestionProperties.regexPattern.name, val);
  }

  get obfuscatePattern() {
    return this.getPropertyValue(
      ObfuscateQuestionProperties.obfuscatePattern.name,
    );
  }

  set obfuscatePattern(val: string) {
    this.setPropertyValue(
      ObfuscateQuestionProperties.obfuscatePattern.name,
      val,
    );
  }
}

(settings as { customIcons: { [index: string]: string } }).customIcons[
  "icon-" + CUSTOM_TYPE_OBFUSCATE_INPUT
] = "icon-text";

export type ObfuscateQuestionProps = {
  creator: SurveyCreator;
  isDisplayMode: boolean;
  question: Question;
};

export const ObfuscateQuestion = (props: ObfuscateQuestionProps) => {
  props.question.errorLocation = "bottom";

  return ObfuscateInput({
    question: props.question,
    onChange: (val: string) => {
      props.question.value = val;
    },
    regexPattern: props.question.regexPattern,
    obfuscatePattern: props.question.obfuscatePattern,
  } as ObfuscateInputProps);
};

export const RegisterObfuscateQuestionComponent = () => {
  ElementFactory.Instance.registerElement(
    CUSTOM_TYPE_OBFUSCATE_INPUT,
    (name) => {
      return new ObfuscateQuestionModel(name);
    },
  );

  Serializer.addClass(
    CUSTOM_TYPE_OBFUSCATE_INPUT,
    [
      {
        name: ObfuscateQuestionProperties.regexPattern.name,
        default: ObfuscateQuestionProperties.regexPattern.default,
        category: "general",
      },
    ],
    function () {
      return new ObfuscateQuestionModel("");
    },
    "question",
  );

  Serializer.addProperty(CUSTOM_TYPE_OBFUSCATE_INPUT, {
    name: ObfuscateQuestionProperties.regexPattern.name,
    displayName: ObfuscateQuestionProperties.regexPattern.display,
    type: "string",
    isRequired: true,
    default: ObfuscateQuestionProperties.regexPattern.default,
    category: "general",
  });

  Serializer.addProperty(CUSTOM_TYPE_OBFUSCATE_INPUT, {
    name: ObfuscateQuestionProperties.obfuscatePattern.name,
    displayName: ObfuscateQuestionProperties.obfuscatePattern.display,
    type: "string",
    isRequired: true,
    default: ObfuscateQuestionProperties.obfuscatePattern.default,
    category: "general",
  });

  // Serializer.getProperty(CUSTOM_TYPE_OBFUSCATE_INPUT, "name").readOnly = true;

  ReactQuestionFactory.Instance.registerQuestion(
    CUSTOM_TYPE_OBFUSCATE_INPUT,
    (props: any) => {
      return React.createElement(ObfuscateQuestion, props);
    },
  );
};
