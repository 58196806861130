import dayjs from 'dayjs';
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import ChevronLeftIcon from 'shared/components/icons/chevron/ChevronLeftIcon';
import ChevronRightIcon from 'shared/components/icons/chevron/ChevronRightIcon';
import Days from 'shared/components/common/datepicker/src/components/Calendar/Days';
import Months from 'shared/components/common/datepicker/src/components/Calendar/Months';
import Week from 'shared/components/common/datepicker/src/components/Calendar/Week';
import Years from 'shared/components/common/datepicker/src/components/Calendar/Years';
import { DoubleChevronLeftIcon, DoubleChevronRightIcon, RoundedButton } from 'shared/components/common/datepicker/src/components/utils';
import { CALENDAR_SIZE, DATE_FORMAT } from 'shared/components/common/datepicker/src/constants';
import DatepickerContext from 'shared/components/common/datepicker/src/contexts/DatepickerContext';
import {
  formatDate,
  getDaysInMonth,
  getFirstDayInMonth,
  getFirstDaysInMonth,
  getLastDaysInMonth,
  getNumberOfDay,
  loadLanguageModule,
  nextMonth,
  previousMonth,
} from 'shared/components/common/datepicker/src/helpers';
import { Period } from '../../types';

export type DateType = string | null | Date;

interface Props {
  date: dayjs.Dayjs;
  minDate?: DateType | null;
  maxDate?: DateType | null;
  onClickPrevious: () => void;
  onClickNext: () => void;
  changeMonth: (month: number) => void;
  changeYear: (year: number) => void;
  setPeriod: Dispatch<SetStateAction<Period>>;
  innerDatePickerClass?: string | undefined;
  id: string;
  hideDaysAndWeeks: boolean;
  showForMonths: boolean;
  showForYears: boolean;
}

const startDay = 1;
const startMonth = 1;

const Calendar: React.FC<Props> = ({
  date,
  minDate,
  maxDate,
  onClickPrevious,
  onClickNext,
  changeMonth,
  changeYear,
  setPeriod,
  id,
  hideDaysAndWeeks,
  showForMonths,
  showForYears,
}) => {
  // Contexts
  const { period, changePeriod, changeDayHover, showFooter, changeDatepickerValue, hideDatepicker, asSingle, i18n, startWeekOn, input } = useContext(DatepickerContext);
  loadLanguageModule(i18n);

  // States
  const [showMonths, setShowMonths] = useState(showForMonths);
  const [showYears, setShowYears] = useState(showForYears);
  const [year, setYear] = useState(date.year());
  // Functions
  const previous = useCallback(() => {
    return getLastDaysInMonth(previousMonth(date), getNumberOfDay(getFirstDayInMonth(date).ddd, startWeekOn));
  }, [date, startWeekOn]);

  const current = useCallback(() => {
    return getDaysInMonth(formatDate(date));
  }, [date]);

  const next = useCallback(() => {
    return getFirstDaysInMonth(previousMonth(date), CALENDAR_SIZE - (previous().length + current().length));
  }, [current, date, previous]);

  const hideMonths = useCallback(() => {
    showMonths && setShowMonths(false);
  }, [showMonths]);

  const hideYears = useCallback(() => {
    showYears && setShowYears(false);
  }, [showYears]);

  const clickMonth = useCallback(
    (month: number) => {
      setTimeout(() => {
        changeMonth(month);
        !hideDaysAndWeeks && setShowMonths(!showMonths);
        hideDaysAndWeeks && clickDay(startDay, month, year);
      }, 250);
    },
    [changeMonth, showMonths],
  );

  const clickYear = useCallback(
    (year: number) => {
      setTimeout(() => {
        changeYear(year);
        !hideDaysAndWeeks && setShowYears(!showYears);
        hideDaysAndWeeks && !showForMonths && clickDay(startDay, startMonth, year);
      }, 250);
    },
    [changeYear, showYears],
  );

  const clickDay = useCallback(
    (day: number, month = date.month() + 1, year = date.year()) => {
      const fullDay = `${year}-${month}-${day}`;
      let newStart;
      let newEnd = null;

      /**
       * convert start and end dates to period and save it
       *
       * @param {string} start - start date
       * @param {string} end = end date
       */
      function chosePeriod(start: string, end: string) {
        const ipt = input?.current;
        if (start != end) {
          changeDatepickerValue(
            {
              startDate: dayjs(start).format(DATE_FORMAT),
              endDate: dayjs(end).format(DATE_FORMAT),
            },
            ipt,
          );
          hideDatepicker();
        } else {
          setPeriod({
            start: period.start,
            end: period.start,
          });
        }
      }

      if (period.start && period.end) {
        if (changeDayHover) {
          changeDayHover(null);
        }
        changePeriod({
          start: null,
          end: null,
        });
      }

      if ((!period.start && !period.end) || (period.start && period.end)) {
        if (!period.start && !period.end) {
          changeDayHover(fullDay);
        }
        newStart = fullDay;
        if (asSingle) {
          newEnd = fullDay;
          chosePeriod(fullDay, fullDay);
        }
      } else {
        if (period.start && !period.end) {
          // start not null
          // end null
          const condition = dayjs(fullDay).isSame(dayjs(period.start)) || dayjs(fullDay).isAfter(dayjs(period.start));
          newStart = condition ? period.start : fullDay;
          newEnd = condition ? fullDay : period.start;
        } else {
          // Start null
          // End not null
          const condition = dayjs(fullDay).isSame(dayjs(period.end)) || dayjs(fullDay).isBefore(dayjs(period.end));
          newStart = condition ? fullDay : period.start;
          newEnd = condition ? period.end : fullDay;
        }

        if (!showFooter) {
          if (newStart && newEnd) {
            chosePeriod(newStart, newEnd);
          }
        }
      }

      if (!(newEnd && newStart) || showFooter) {
        changePeriod({
          start: newStart,
          end: newEnd,
        });
      }
    },
    [asSingle, changeDatepickerValue, changeDayHover, changePeriod, date, hideDatepicker, period.end, period.start, showFooter, input],
  );

  const clickPreviousDays = useCallback(
    (day: number) => {
      const newDate = previousMonth(date);
      clickDay(day, newDate.month() + 1, newDate.year());
      onClickPrevious();
    },
    [clickDay, date, onClickPrevious],
  );

  const clickNextDays = useCallback(
    (day: number) => {
      const newDate = nextMonth(date);
      clickDay(day, newDate.month() + 1, newDate.year());
      onClickNext();
    },
    [clickDay, date, onClickNext],
  );

  // UseEffects & UseLayoutEffect
  useEffect(() => {
    setYear(date.year());
  }, [date]);

  // Variables
  const calendarData = useMemo(() => {
    return {
      date: date,
      days: {
        previous: previous(),
        current: current(),
        next: next(),
      },
    };
  }, [current, date, next, previous]);
  const minYear = React.useMemo(() => (minDate && dayjs(minDate).isValid() ? dayjs(minDate).year() : null), [minDate]);
  const maxYear = React.useMemo(() => (maxDate && dayjs(maxDate).isValid() ? dayjs(maxDate).year() : null), [maxDate]);

  return (
    <div data-testId={id} className="w-full min-md:w-[186px] min-md:min-w-[206px]">
      <div data-testid={`datepicker-${id}-header`} className="flex items-center border border-neutral-mid-200 rounded-md">
        {!showMonths && !showYears && (
          <div className="datepicker-flex justify-end">
            <RoundedButton testId={`datepicker-${id}-ye`} roundedFull={true} onClick={onClickPrevious}>
              <ChevronLeftIcon className="datepicker-chevron-icon h-2 w-2" data-testid="ChevronLeftIconPrevious" />
            </RoundedButton>
          </div>
        )}
        {showYears && (
          <div className="datepicker-flex justify-end">
            <RoundedButton
              roundedFull={true}
              testId={`datepicker-${id}-year`}
              onClick={() => {
                setYear(year - 12);
              }}
            >
              <DoubleChevronLeftIcon className="datepicker-chevron-icon" data-testid="DoubleChevronLeftIconSetLessYear" />
            </RoundedButton>
          </div>
        )}

        <div className="datepicker-flex">
          {(!showYears || showForMonths) && (
            <div className="w-1/2">
              <RoundedButton
                testId={`datepicker-${id}-toggle-month`}
                onClick={() => {
                  showYears && setShowMonths(!showMonths);
                  hideYears();
                }}
              >
                <>{calendarData.date.locale(i18n).format('MMM')}</>
              </RoundedButton>
            </div>
          )}

          <div className="w-1/2">
            <RoundedButton
              testId={`datepicker-${id}-toggle-year`}
              onClick={() => {
                setShowYears(!showYears);
                hideMonths();
              }}
            >
              <>{calendarData.date.year()}</>
            </RoundedButton>
          </div>
        </div>
        {showYears && (
          <div className="datepicker-flex">
            <RoundedButton
              roundedFull={true}
              testId={`datepicker-${id}-year`}
              onClick={() => {
                setYear(year + 12);
              }}
            >
              <DoubleChevronRightIcon className="datepicker-chevron-icon" data-testid="DoubleChevronRightIconSetMoreYear" />
            </RoundedButton>
          </div>
        )}
        {!showMonths && !showYears && (
          <div className="datepicker-flex">
            <RoundedButton testId={`datepicker-${id}-next`} roundedFull={true} onClick={onClickNext}>
              <ChevronRightIcon className="datepicker-chevron-icon h-2 w-2" data-testid="ChevronRightIconNext" />
            </RoundedButton>
          </div>
        )}
      </div>
      <div className="mt-0.5">
        {showMonths && <Months currentMonth={calendarData.date.month() + 1} clickMonth={clickMonth} />}

        {showYears && <Years year={year} minYear={minYear} maxYear={maxYear} currentYear={calendarData.date.year()} clickYear={clickYear} />}

        {!hideDaysAndWeeks && !showMonths && !showYears && (
          <>
            <Week />

            <Days calendarData={calendarData} onClickPreviousDays={clickPreviousDays} onClickDay={clickDay} onClickNextDays={clickNextDays} id={id} />
          </>
        )}
      </div>
    </div>
  );
};

export default Calendar;
