import { useFormikContext } from "formik";
import React from "react";
import PillarFormInputWrapper, {
  PillarFormInputWrapperProps,
} from "shared/components/pillar-form/components/PillarFormInputWrapper";

type Option = {
  label: string | JSX.Element;
  value: string | number;
};

type PillarFormRadioProps = {
  name: string;
  options: Option[];
  label?: string;
  labelClassName?: string;
};

const PillarFormRadio: React.FC<PillarFormRadioProps> = ({
  name,
  options,
  label,
  labelClassName,
}) => {
  return (
    <div>
      {label && <label className={labelClassName}>{label}</label>}
      {options.map((option) => (
        <PillarFormRadioOption
          key={option.value}
          name={name}
          value={option.value}
          label={option.label}
          labelClassName={labelClassName}
        />
      ))}
    </div>
  );
};

export default PillarFormRadio;

const PillarFormRadioOption = (
  props: PillarFormInputWrapperProps & {
    name: string;
    value: string | number;
    label: string | JSX.Element;
  }
) => {
  const formikCon = useFormikContext<Record<string, any>>();

  return (
    <PillarFormInputWrapper
      name={props.name}
      labelClassName={props.labelClassName}
      label={props.label}
    >
      <input
        name={props.name}
        checked={formikCon.values[props.name] === props.value}
        onChange={() => {
          formikCon.setFieldValue(props.name, props.value);
        }}
        onClick={() => {
          formikCon.setFieldValue(props.name, props.value);
        }}
        type="radio"
        className={"h-1 w-1 flex justify-end rounded border-neutral-mid-300"}
        id={`${props.name}-${props.value}`} // Unique ID if needed
        // onBlur={formikCon.handleBlur} // Mark as touched
      />
    </PillarFormInputWrapper>
  );
};
