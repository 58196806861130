import React from "react";
import {
  NewAppExcelToExcelSheetProps,
  AppExportToExcelSheet,
} from "shared/components/pillar-table/export/advanced/AppExportToExcelSheet";

export type NewAppExportToExcelBookProps<T> = {
  name: string;
  children:
    | Array<React.ReactElement<NewAppExcelToExcelSheetProps<T>>>
    | React.ReactElement<NewAppExcelToExcelSheetProps<T>>;
};

export const AppExportToExcelBook = <T,>({
  children,
}: NewAppExportToExcelBookProps<T>) => {
  if (
    (Array.isArray(children) ? children : [children]).some(
      (child) => child.type !== AppExportToExcelSheet,
    )
  ) {
    throw new Error(
      "Only AppExportToExcelSheet must be used inside AppExportToExcelBook",
    );
  }

  return <>{children}</>;
};
