import { ComponentPropsWithoutRef } from "react";
import { twMerge } from "tailwind-merge";

// https://tabler.io/icons/icon/chevron-left
const ChevronLeftIcon = ({
  className,
  ...props
}: ComponentPropsWithoutRef<"svg">) => {
  const defaultClassnames = "icon icon-tabler icon-tabler-chevron-left h-3 w-3";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge(defaultClassnames, className)}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M15 6l-6 6l6 6"></path>
    </svg>
  );
};

export default ChevronLeftIcon;
