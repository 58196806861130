import {
  usePagination,
  DOTS,
} from "shared/components/pillar-table/pagination-sorting/usePagination";
import {
  PaginatedResults,
  PaginationRequest,
} from "shared/api/types/pagination";
import { ComponentPropsWithoutRef } from "react";
import ChevronLeftIcon from "shared/components/icons/chevron/ChevronLeftIcon";
import ChevronRightIcon from "shared/components/icons/chevron/ChevronRightIcon";

export const defaultPageSize = 25;

type PillarTablePaginationProps<T> = ComponentPropsWithoutRef<"nav"> & {
  state?: PaginatedResults<T> | T[] | undefined;
  results?: number;
  paginationState?: PaginationRequest | undefined;
  handler: (paginationRequest: PaginationRequest) => void;
  testid?: string;
};

const PillarTablePagination = <T,>({
  state,
  results,
  paginationState,
  handler,
  className,
  ...props
}: PillarTablePaginationProps<T>) => {
  const currentPage = state
    ? "page" in state
      ? state.page
      : 1
    : paginationState?.page || 1;

  const pageSize = state
    ? "pageSize" in state
      ? state.pageSize
      : (state as T[]).length
    : paginationState?.pageSize || defaultPageSize;

  const totalResults = state
    ? "totalResults" in state
      ? state.totalResults
      : (state as T[]).length
    : results || 1;

  // number of sibling near current page on each side
  const siblingCount = 2;
  const totalPageCount = Math.ceil(totalResults / pageSize);

  const handlePageChange = (page: number) => {
    handler({ page, pageSize });
  };

  const paginationRange = usePagination({
    currentPage,
    totalPageCount,
    siblingCount,
  });

  if (currentPage === 0 || !paginationRange || paginationRange.length < 2) {
    return null;
  }

  return (
    <nav
      className={`pillartable-pagination-container w-full flex items-center justify-between ${
        className ? className : ""
      }`}
      aria-label="Pagination"
      {...props}
    >
      <div className="flex">
        <p className="pillartable-pagination-display">
          Showing
          <span>
            {currentPage === 1 ? (
              <> 1 </>
            ) : (
              <> {(currentPage - 1) * pageSize + 1} </>
            )}
          </span>
          to
          <span>
            {` ${
              currentPage * pageSize <= totalResults
                ? currentPage * pageSize
                : totalResults
            } `}
          </span>
          of {totalResults}
          <span> results</span>
        </p>
      </div>
      <ul
        className="flex justify-between list-none"
        data-testid={
          props.testid
            ? `pillartable-pagination-${props.testid}-flex-container`
            : undefined
        }
      >
        <li
          className={`pillartable-pagination-link-step-container ${
            currentPage <= 1 && "pointer-events-none opacity-70"
          }`}
          data-testid={
            props.testid
              ? `pillartable-pagination-${props.testid}-link-step-previous`
              : undefined
          }
          onClick={() => {
            //If current page is 1, then don't go back.
            if (currentPage - 1 >= 0) {
              handlePageChange(currentPage - 1);
            }
          }}
        >
          {/* These must be 0 height and width or they mess up the flex layout.
          Should we need this visible, we should fix this. Otherwise, It should be fine as is. */}
          <span className="sr-only h-0 w-0">Previous</span>
          <ChevronLeftIcon
            className="pillartable-pagination-link-step-svg"
            aria-hidden="true"
          />
        </li>
        {paginationRange.map((pageNumber, index) => {
          const selected =
            pageNumber === currentPage ? "selected" : "unselected";

          //each side + current page
          const dotsJump = siblingCount * 2 + 1;

          const handleDotsClick = (index: number) => {
            const isNextDots = paginationRange.indexOf(currentPage) < index;
            const newPage = isNextDots
              ? Math.min(currentPage + dotsJump, totalPageCount)
              : Math.max(currentPage - dotsJump, 1);

            handlePageChange(newPage);
          };

          return (
            <li
              data-testid={
                props.testid
                  ? `pillartable-pagination-${props.testid}-link-page-${pageNumber}-${selected}`
                  : undefined
              }
              className={`${`pillartable-pagination-link-page--${selected}`} !px-0.75 min-w-xs text-center align-middle`}
              key={`page-${pageNumber}${pageNumber === DOTS ? index : ""}`}
              onClick={() => {
                if (pageNumber === DOTS) {
                  handleDotsClick(index);
                  return;
                }

                handlePageChange(pageNumber as number);
              }}
              aria-current="page"
            >
              {pageNumber}
            </li>
          );
        })}

        <li
          data-testid={
            props.testid
              ? `pillartable-pagination-${props.testid}-link-step-next`
              : undefined
          }
          className={`pillartable-pagination-link-step-container ${
            currentPage >= totalPageCount && "pointer-events-none opacity-70"
          }`}
          onClick={() => {
            //If current page is the last page, then don't go forward.
            if (currentPage * pageSize <= totalResults) {
              handlePageChange(currentPage + 1);
            }
          }}
        >
          {/* These must be 0 height and width or they mess up the flex layout.
          Should we need this visible, we should fix this. Otherwise, It should be fine as is. */}
          <span className="sr-only h-0 w-0">Next</span>
          <ChevronRightIcon
            className="pillartable-pagination-link-step-svg"
            aria-hidden="true"
          />
        </li>
      </ul>
    </nav>
  );
};

export default PillarTablePagination;
