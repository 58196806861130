import { FormikValues, useFormikContext } from "formik";

import { ComponentPropsWithoutRef } from "react";

type PillarFormSubmitButtonProps = Omit<
  ComponentPropsWithoutRef<"button">,
  "type" | "onClick"
> & {
  label: string;
  testid?: string;
};

const PillarFormSubmitButton = ({
  label,
  className,
  testid,
  ...props
}: PillarFormSubmitButtonProps) => {
  const { handleSubmit } = useFormikContext<FormikValues>();
  return (
    <button
      data-testid={testid}
      type="button"
      className={`button-regular-general-filled h-5 w-14 ${className ?? ""}`}
      onClick={() => {
        handleSubmit();
      }}
      {...props}
    >
      {label}
    </button>
  );
};

export default PillarFormSubmitButton;
