import { useIonRouter } from "@ionic/react";
import PillarForm from "shared/components/pillar-form/PillarForm";
import { getDisplayNameHelper } from "shared/helpers/profile/getDisplayNameHelper";
import { FormikValues } from "formik";
import { useCustomSession } from "shared/context/CustomSessionContext";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import * as Yup from "yup";

type CardDetails = {
  cardNumber: number;
  expirationDate: string;
  cvc: number;
  zip: number;
};

const PaymentForm = (props: {
  invoiceId: number;
  zip: string;
  disabled?: boolean;
  errorMessage?: string;
  handleCardDetails: (details: CardDetails) => void;
  handleSubmit: () => Promise<void>;
}) => {
  const session = useCustomSession();
  const router = useIonRouter();
  const getProfileIsExpired = () => {
    if (
      parseInt(
        String(
          session.society?.societySettingsPublic?.confirmProfileOlderThanDays,
        ),
      ) === -1
    ) {
      return false;
    } else {
      return !(
        Math.floor(
          DateTime.fromISO(session.profile?.updated ?? "").diffNow("days").days,
        ) >=
        Number(
          session.society?.societySettingsPublic?.confirmProfileOlderThanDays,
        ) *
          -1
      );
    }
  };

  const [formValues, setFormValues] = useState<FormikValues>({
    fullName: getDisplayNameHelper(session.profile!) ?? "",
    cardNumber: null,
    expirationDate: null,
    cvc: null,
    zip: null,
  });

  useEffect(() => {
    props.handleCardDetails({
      cardNumber: formValues.cardNumber,
      expirationDate: formValues.expirationDate ?? "",
      cvc: formValues.cvc,
      zip: formValues.zip,
    });
  }, [formValues]);

  const validationSchema = Yup.object({
    fullName: Yup.string().required("Full name is required"),
    cardNumber: Yup.string().required("Card number is required"),
    expirationDate: Yup.string().required("Expiration date is required"),
    cvc: Yup.string().required("CVC is required"),
    zip: Yup.string().required("Zip code is required"),
  });

  return (
    <section aria-labelledby="payment-heading">
      <div id="payment-heading" className="text-subheader pb-1">
        Payment Details
      </div>

      {session.society?.societySettingsPublic?.hubPaymentTypes!.credit_card ? (
        <PillarForm
          handleSubmit={props.handleSubmit}
          handleChanged={setFormValues}
          overrideInitialValues={{ ...formValues, zip: props.zip }}
          yupValidation={validationSchema}
        >
          <div className="pb-2">
            <PillarForm.TextInput
              label="Full Name"
              name="fullName"
              testid="payment-fullname-input"
              placeholder="Enter Full Name"
            />
          </div>
          <PillarForm.TextInput
            label="Card Number"
            name="cardNumber"
            testid="payment-card-number-input"
            placeholder="0000 0000 0000 0000"
            className="pt-2"
          />
          <div className="grid grid-cols-3 gap-1 pt-2">
            <PillarForm.ExpirationDateInput
              label="Exp. Date"
              name="expirationDate"
              testid="payment-expiration-date-input"
              placeholder="MM/YY"
            />

            <PillarForm.TextInput
              label="CVC"
              name="cvc"
              testid="payment-cvc-input"
              placeholder="CVC"
            />
            <PillarForm.TextInput
              additionalClasses="rounded-md "
              name="zip"
              label="Zip"
              testid="payment-zip-input"
              placeholder="Zip Code"
            />
          </div>
          {props.errorMessage && (
            <div className="text-status-danger">{props.errorMessage}</div>
          )}
          <div className="mt-4">
            {getProfileIsExpired() ? (
              <PillarForm.Button
                disabled={props.disabled}
                className="button-regular-general-filled w-full"
                onClick={() => {
                  router.push(`/edit-user-detail?invoiceId=${props.invoiceId}`);
                }}
              >
                {
                  session.society?.societySettingsPublic
                    ?.outdatedProfileNavigateToEditText
                }
              </PillarForm.Button>
            ) : (
              <PillarForm.LoadingSubmitButton
                disabled={props.disabled}
                testid="payment-pay-button"
                className="button-regular-general-filled w-full"
              >
                Pay
              </PillarForm.LoadingSubmitButton>
            )}
          </div>
        </PillarForm>
      ) : (
        <div className="text-status-danger">
          Credit card payments are disabled. Please contact the society
          administrator.
        </div>
      )}
    </section>
  );
};
export default PaymentForm;
