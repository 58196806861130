import { PrismaClient } from "@prisma/client";
import {
  get as lodashGet,
  set as lodashSet,
  update as lodashUpdate,
  merge as lodashMerge,
} from "lodash";
import { DateTime } from "luxon";
import { DatePickerDefaultShortcuts } from "shared/components/common/datepicker/src/constants/shortcuts";
import { prismaSelectStatementFormatUtil } from "./prisma/selectStatementUtil/prismaSelectStatementUtil";
export interface FilterChoice {
  value: number | string;
  display: string;
  count?: number;
  choices?: FilterChoice[];
  filterKey?: string;
}

export interface AppFilterOption {
  filterKey?: string;
  component?: FilterOptionComponent;
  where?: (FilterOptionWhereConfig | FilterOptionWhereConfigDateRange)[];
  choices?: FilterChoice[];
  dynamicChoiceOptions?: DynamicFilterChoiceOptions;
}
export type DynamicFilterChoiceOptions = {
  tableNameForGroupBy?: ModelsWithGroupBy;
  tableNameForFindMany?: ModelsWithGroupBy;
  fieldForFindMany?: string;
};

export interface FilterOptionComponent {
  label?: string;
  type?: keyof typeof FilterComponentType;
}

export interface FilterOptionWhereConfig {
  type: FilterType;
  path: string;
  arrayPath?: string;
  nonePath?: string;
  copy?: string | Record<string, any>;
  copyPath?: string;
}

export interface FilterOptionWhereConfigDateRange
  extends FilterOptionWhereConfig {
  type: FilterType.DATE_RANGE;
  default?: keyof typeof DatePickerDefaultShortcuts;
  path: string;
  arrayPath: string;
  copy?: Record<string, any>;
  copyPath?: string;
  valueOnSelectPath?: string;
}

export type FilterRequestTypes =
  | string
  | string[]
  | number
  | number[]
  | undefined
  | boolean
  | FilterValueDateRange;
export interface FiltersRequest {
  [key: string]: FilterRequestTypes;
}

export enum FilterType {
  JSON = "JSON",
  JSON_ARRAY = "JSON_ARRAY",
  INT_ARRAY = "INT_ARRAY",
  STRING = "STRING",
  NUMBER = "NUMBER",
  STRING_ARRAY = "STRING_ARRAY",
  DATE = "DATE",
  BOOLEAN = "BOOLEAN",
  BOOLEAN_SPECIAL = "BOOLEAN_SPECIAL",
  NOT_NULL = "NOT_NULL",
  DATE_RANGE = "DATE_RANGE",
}

export enum FilterComponentType {
  none = "none",
  search = "search",
  Date = "Date",
  selectMenuCheckbox = "selectMenuCheckbox",
  selectMenu = "selectMenu",
  checkbox = "checkbox",
}

export type ModelsWithGroupBy = {
  [K in keyof PrismaClient]: PrismaClient[K] extends {
    groupBy: (...args: any[]) => any;
  }
    ? K
    : never;
}[keyof PrismaClient];

const handleArrayFilter = (
  filterValue: string[] | number[],
  filter: FilterOptionWhereConfig,
  whereClause: { [key: string]: any }
) => {
  if (filter.copy) {
    const copyPath = filter.copy as string;
    const conditions = filterValue.map((value) =>
      lodashSet({}, copyPath, value)
    );
    if (conditions.length) {
      const existingConditions = lodashGet(whereClause, filter.path, []);
      lodashSet(whereClause, filter.path, [
        ...existingConditions,
        ...conditions,
      ]);
    }
  } else {
    lodashSet(whereClause, filter.path, filterValue);
  }
};

const handleNotNullFilter = (
  filterValue: string,
  path: string,
  whereClause: { [key: string]: any }
) => {
  if (filterValue === "true") {
    lodashSet(whereClause, path, { not: { equals: null } });
  }
  if (filterValue === "false") {
    lodashSet(whereClause, path, { equals: null });
  }
};

const handleJSONFilter = (
  filterValue:
    | string
    | number
    | boolean
    | Date
    | string[]
    | number[]
    | FilterValueDateRange,
  filter: FilterOptionWhereConfig,
  whereClause: { [key: string]: any }
) => {
  if (filter.type === FilterType.JSON_ARRAY) {
    (filterValue as string[]).forEach((value) => {
      const jsonQuery = JSON.parse(JSON.stringify(filter.copy));
      lodashSet(jsonQuery, filter.copyPath!, value);
      const existingConditions = lodashGet(whereClause, filter.path, []);
      const newConditions = [...existingConditions, jsonQuery].flat();
      lodashSet(whereClause, filter.path, newConditions);
    });
  } else if (filter.type === FilterType.JSON) {
    const jsonQuery = filter.copy as object;
    lodashSet(jsonQuery, filter.copyPath!, filterValue);
    const existingConditions = lodashGet(whereClause, filter.path);
    if (existingConditions && Array.isArray(existingConditions)) {
      lodashSet(whereClause, filter.path, [...existingConditions, jsonQuery]);
    } else if (existingConditions) {
      lodashSet(whereClause, filter.path, {
        ...existingConditions,
        ...jsonQuery,
      });
    } else {
      lodashSet(whereClause, filter.path, jsonQuery);
    }
  }
};

type FilterValueDateRange = {
  from?: string;
  to?: string;
};

export const replaceWhereClauseDatePlaceholders = (obj: any): any => {
  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      replaceWhereClauseDatePlaceholders(obj[key]);
    } else if (obj[key] === "TODAY") {
      obj[key] = new Date(DateTime.now().toISO());
    }
  }
  return obj;
};

export const mappedFilterWhereClauseValues = <
  SelectType extends object,
  WhereType extends object
>({
  filtersConfig,
  filtersRequest = {},
  selectStatement = {} as SelectType,
  whereClause = {} as WhereType,
  profileId,
  societyId,
}: {
  filtersConfig?: AppFilterOption[];
  filtersRequest?: FiltersRequest;
  selectStatement: SelectType;
  whereClause?: WhereType;
  profileId?: number;
  societyId?: number;
}) => {
  if (!filtersConfig || !Array.isArray(filtersConfig)) {
    return {
      mappedWhereClause: replaceWhereClauseDatePlaceholders({
        ...whereClause,
        society_id: societyId,
        profile_id: profileId,
      }) as WhereType,
      mappedSelectStatement: prismaSelectStatementFormatUtil({
        selectStatement: replaceWhereClauseDatePlaceholders(selectStatement)
      }) as SelectType,
    };
  }

  for (const filterOption of filtersConfig) {
    if (!filterOption.where || !filterOption.filterKey) {
      continue;
    }
    let filterValue:
      | string
      | string[]
      | number
      | number[]
      | Date
      | undefined
      | boolean
      | FilterValueDateRange;

    filterValue = filtersRequest[filterOption.filterKey] as any;

    if (filterValue !== undefined) {
      filterOption.where.forEach((filterWhere) => {
        switch (filterWhere.type) {
          case FilterType.NOT_NULL: {
            handleNotNullFilter(
              filterValue as string,
              filterWhere.path,
              whereClause
            );
            break;
          }
          case FilterType.INT_ARRAY: {
            let processedArray = (filterValue as string[]).map((val) =>
              parseInt(val)
            );
            if ((filterValue as string[]).includes("none")) {
              lodashSet(
                whereClause,
                filterWhere.nonePath ?? filterWhere.path,
                null
              );
              handleArrayFilter(
                processedArray.filter((val) => !isNaN(val)),
                filterWhere,
                whereClause
              );
            } else {
              handleArrayFilter(
                processedArray.filter((val) => !isNaN(val)),
                filterWhere,
                whereClause
              );
            }
            break;
          }
          case FilterType.STRING_ARRAY: {
            handleArrayFilter(
              filterValue as string[],
              filterWhere,
              whereClause
            );
            break;
          }
          case FilterType.BOOLEAN: {
            lodashSet(whereClause, filterWhere.path, filterValue === "true");
            break;
          }
          case FilterType.DATE_RANGE: {
            //TODO: Get rid of DATE_ARRAY everything that isnt a single date is a range
            if (
              typeof filterValue == "object" &&
              "to" in filterValue &&
              filterValue.to !== undefined
            ) {
              const toDate = DateTime.fromISO(filterValue.to);
              const tempObject = {};

              if (toDate.isValid) {
                lodashSet(
                  tempObject,
                  `${filterWhere.path}.lte`,
                  toDate.endOf("day").toISO()
                );
                if (
                  "valueOnSelectPath" in filterWhere &&
                  filterWhere.valueOnSelectPath
                ) {
                  lodashSet(
                    selectStatement,
                    `${filterWhere.valueOnSelectPath}.lte`,
                    toDate.endOf("day").toISO()
                  );
                }

                if (
                  filterWhere.copyPath &&
                  filterWhere.copy &&
                  typeof filterWhere.copy === "object"
                ) {
                  const targetOnTempObject = lodashGet(
                    tempObject,
                    filterWhere.copyPath,
                    {}
                  );
                  lodashMerge(targetOnTempObject, filterWhere.copy);
                }
                lodashUpdate(
                  whereClause,
                  filterWhere.arrayPath!,
                  (arr = []) => [...arr, tempObject]
                );
              }
            }
            if (
              typeof filterValue == "object" &&
              "from" in filterValue &&
              filterValue.from !== undefined
            ) {
              const fromDate = DateTime.fromISO(filterValue.from);
              let tempObject = {};

              if (fromDate.isValid) {
                lodashSet(
                  tempObject,
                  `${filterWhere.path}.gte`,
                  fromDate.startOf("day").toISO()
                );
                if (
                  "valueOnSelectPath" in filterWhere &&
                  filterWhere.valueOnSelectPath
                ) {
                  lodashSet(
                    selectStatement,
                    `${filterWhere.valueOnSelectPath}.gte`,
                    fromDate.endOf("day").toISO()
                  );
                }
                if (
                  filterWhere.copyPath &&
                  filterWhere.copy &&
                  typeof filterWhere.copy === "object"
                ) {
                  const targetOnTempObject = lodashGet(
                    tempObject,
                    filterWhere.copyPath,
                    {}
                  );
                  lodashMerge(targetOnTempObject, filterWhere.copy);
                }
                lodashUpdate(
                  whereClause,
                  filterWhere.arrayPath!,
                  (arr = []) => [...arr, tempObject]
                );
              }
            }

            break;
          }
          case FilterType.DATE: {
            if (typeof filterValue === "string") {
              let processedDate = DateTime.fromISO(filterValue); //treats the ISO date as local central time because "America/Chicago"
              if (filterWhere.path.endsWith("lte")) {
                processedDate = processedDate.endOf("day");
              } else if (filterWhere.path.endsWith("gte")) {
                processedDate = processedDate.startOf("day");
              }
              lodashSet(whereClause, filterWhere.path, processedDate.toISO()); //Comes out as UTC time
            }
            break;
          }
          case FilterType.JSON_ARRAY:
          case FilterType.JSON: {
            handleJSONFilter(filterValue!, filterWhere, whereClause);
            break;
          }
          case FilterType.NUMBER: {
            lodashSet(whereClause, filterWhere.path, Number(filterValue));
            break;
          }
          case FilterType.BOOLEAN_SPECIAL: {
            break;
          }
          default: {
            lodashSet(whereClause, filterWhere.path, filterValue);
            break;
          }
        }
      });
    }
  }

  return {
    mappedWhereClause: replaceWhereClauseDatePlaceholders(removeEmptyItemsFromArraysRecursively({
      ...whereClause,
      society_id: societyId,
      profile_id: profileId,
    })) as WhereType,
    mappedSelectStatement: prismaSelectStatementFormatUtil({
      selectStatement:replaceWhereClauseDatePlaceholders(selectStatement)
    }) as SelectType,
  };
};

export const DONOTUSEmappedFilterWhereClauseValues = (
  config: AppFilterOption[],
  requestFilters: FiltersRequest = {}
) => {
  const whereClause: { [key: string]: any } = {};

  if (!config || !Array.isArray(config)) {
    return whereClause;
  }

  for (const filter of config) {
    if (!filter.where || !filter.filterKey) {
      continue;
    }
    let filterValue:
      | string
      | string[]
      | number
      | number[]
      | Date
      | undefined
      | boolean
      | FilterValueDateRange;

    filterValue = requestFilters[filter.filterKey] as any;
    if (!filterValue && filter.component?.type === FilterComponentType.Date) {
      filterValue = {
        to: (requestFilters[`${filter.filterKey}-to`] ?? undefined) as
          | string
          | undefined,
        from: (requestFilters[`${filter.filterKey}-from`] ?? undefined) as
          | string
          | undefined,
      };
    }

    if (filterValue !== undefined) {
      filter.where.forEach((filterWhere) => {
        switch (filterWhere.type) {
          case FilterType.NOT_NULL: {
            handleNotNullFilter(
              filterValue as string,
              filterWhere.path,
              whereClause
            );
            break;
          }
          case FilterType.INT_ARRAY: {
            let processedArray = (filterValue as string[]).map((val) =>
              parseInt(val)
            );
            if ((filterValue as string[]).includes("none")) {
              lodashSet(
                whereClause,
                filterWhere.nonePath ?? filterWhere.path,
                null
              );
              handleArrayFilter(
                processedArray.filter((val) => !isNaN(val)),
                filterWhere,
                whereClause
              );
            } else {
              handleArrayFilter(
                processedArray.filter((val) => !isNaN(val)),
                filterWhere,
                whereClause
              );
            }
            break;
          }
          case FilterType.STRING_ARRAY: {
            handleArrayFilter(
              filterValue as string[],
              filterWhere,
              whereClause
            );
            break;
          }
          case FilterType.BOOLEAN: {
            lodashSet(whereClause, filterWhere.path, filterValue === "true");
            break;
          }
          case FilterType.DATE_RANGE: {
            //TODO: Get rid of DATE_ARRAY everything that isnt a single date is a range
            if (
              typeof filterValue == "object" &&
              "to" in filterValue &&
              filterValue.to !== undefined
            ) {
              const toDate = DateTime.fromISO(filterValue.to);
              const tempObject = {};

              if (toDate.isValid) {
                lodashSet(
                  tempObject,
                  `${filterWhere.path}.lte`,
                  toDate.endOf("day").toISO()
                );

                if (
                  filterWhere.copyPath &&
                  filterWhere.copy &&
                  typeof filterWhere.copy === "object"
                ) {
                  const targetOnTempObject = lodashGet(
                    tempObject,
                    filterWhere.copyPath,
                    {}
                  );
                  lodashMerge(targetOnTempObject, filterWhere.copy);
                }
                lodashUpdate(
                  whereClause,
                  filterWhere.arrayPath!,
                  (arr = []) => [...arr, tempObject]
                );
              }
            }
            if (
              typeof filterValue == "object" &&
              "from" in filterValue &&
              filterValue.from !== undefined
            ) {
              const fromDate = DateTime.fromISO(filterValue.from);
              let tempObject = {};

              if (fromDate.isValid) {
                lodashSet(
                  tempObject,
                  `${filterWhere.path}.gte`,
                  fromDate.startOf("day").toISO()
                );
                if (
                  filterWhere.copyPath &&
                  filterWhere.copy &&
                  typeof filterWhere.copy === "object"
                ) {
                  const targetOnTempObject = lodashGet(
                    tempObject,
                    filterWhere.copyPath,
                    {}
                  );
                  lodashMerge(targetOnTempObject, filterWhere.copy);
                }
                lodashUpdate(
                  whereClause,
                  filterWhere.arrayPath!,
                  (arr = []) => [...arr, tempObject]
                );
              }
            }

            break;
          }
          case FilterType.DATE: {
            if (typeof filterValue === "string") {
              let processedDate = DateTime.fromISO(filterValue); //treats the ISO date as local central time because "America/Chicago"
              if (filterWhere.path.endsWith("lte")) {
                processedDate = processedDate.endOf("day");
              } else if (filterWhere.path.endsWith("gte")) {
                processedDate = processedDate.startOf("day");
              }
              lodashSet(whereClause, filterWhere.path, processedDate.toISO()); //Comes out as UTC time
            }
            break;
          }
          case FilterType.JSON_ARRAY:
          case FilterType.JSON: {
            handleJSONFilter(filterValue!, filterWhere, whereClause);
            break;
          }
          case FilterType.NUMBER: {
            lodashSet(whereClause, filterWhere.path, Number(filterValue));
            break;
          }
          case FilterType.BOOLEAN_SPECIAL: {
            break;
          }
          default: {
            lodashSet(whereClause, filterWhere.path, filterValue);
            break;
          }
        }
      });
    }
  }

  return removeEmptyItemsFromArraysRecursively(whereClause);
};

const removeEmptyItemsFromArraysRecursively = (obj: any) => {
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      obj[key] = obj[key].filter((item: any) => {
        if (typeof item === "object") {
          removeEmptyItemsFromArraysRecursively(item);
          return Object.keys(item).length > 0;
        }
        return item !== undefined;
      });
    }
  }
  return obj;
};
