import { BaseApiContract } from "shared/api/apiRequestContractHandler";
import { HttpMethod } from "shared/api/http";
import { SocietyFormBodyInput } from "shared/api/types/society/[societyId]/form";
import { SocietyFormIdParamsInput } from "shared/api/types/society/[societyId]/form/[formId]";
import { FormView } from "shared/mappers/database/form/form";
export const postSocietyFormIdContract: BaseApiContract<
  SocietyFormIdParamsInput,
  SocietyFormBodyInput,
  FormView
> = {
  method: HttpMethod.POST,
  apiRoute: "/api/v1/society/[societyId]/forms/[formId]",
  paramsType: {} as SocietyFormIdParamsInput,
  bodyType: {} as SocietyFormBodyInput,
  responseType: {} as FormView,
};
export const getSocietyFormIdContract: BaseApiContract<
  SocietyFormIdParamsInput,
  undefined,
  FormView
> = {
  method: HttpMethod.GET,
  apiRoute: "/api/v1/society/[societyId]/forms/[formId]",
  paramsType: {} as SocietyFormIdParamsInput,
  responseType: {} as FormView,
};
export const deleteSocietyFormIdContract: BaseApiContract<
  SocietyFormIdParamsInput,
  undefined,
  void
> = {
  method: HttpMethod.DELETE,
  apiRoute: "/api/v1/society/[societyId]/forms/[formId]",
  paramsType: {} as SocietyFormIdParamsInput,
  responseType: undefined as unknown as void,
};
