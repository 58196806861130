import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Calendar from 'shared/components/common/datepicker/src/components/Calendar';
import Footer from 'shared/components/common/datepicker/src/components/Footer';
import Input from 'shared/components/common/datepicker/src/components/Input';
import Shortcuts from 'shared/components/common/datepicker/src/components/Shortcuts';
import { DATE_FORMAT, LANGUAGE } from 'shared/components/common/datepicker/src/constants';
import DatepickerContext from 'shared/components/common/datepicker/src/contexts/DatepickerContext';
import { formatDate, nextMonth, previousMonth } from 'shared/components/common/datepicker/src/helpers';
import useOnClickOutside from 'shared/components/common/datepicker/src/hooks';
import { DatepickerType, Period } from 'shared/components/common/datepicker/src/types';
import SingleDateOptions from 'shared/components/common/datepicker/src/components/SingleDateOptions';

const Datepicker: React.FC<DatepickerType> = ({
  value = null,
  onChange,
  useRange = true,
  showFooter = false,
  showShortcuts = false,
  configs = undefined,
  asSingle = false,
  placeholder = null,
  separator = '~',
  startFrom = null,
  i18n = LANGUAGE,
  disabled = false,
  inputClassName = null,
  containerClassName = null,
  calendarContainerClass = '',
  toggleClassName = null,
  toggleIcon = undefined,
  displayFormat = DATE_FORMAT,
  readOnly = false,
  minDate = null,
  maxDate = null,
  dateLooking = 'forward',
  disabledDates = null,
  postfixID = '',
  inputName,
  startWeekOn = 'sun',
  classNames = undefined,
  popoverDirection = undefined,
  startingTime = undefined,
  hideDaysAndWeeks = false,
  showForMonths = false,
  showForYears = false,
}) => {
  // Ref
  const containerRef = useRef<HTMLDivElement | null>(null);
  const calendarContainerRef = useRef<HTMLDivElement | null>(null);
  const calendarContainerShadowRef = useRef<HTMLDivElement | null>(null);

  // State
  const [firstDate, setFirstDate] = useState<dayjs.Dayjs>(startFrom && dayjs(startFrom).isValid() ? dayjs(startFrom) : dayjs());
  const [secondDate, setSecondDate] = useState<dayjs.Dayjs>(nextMonth(firstDate));
  const [period, setPeriod] = useState<Period>({
    start: null,
    end: null,
  });
  const [dayHover, setDayHover] = useState<string | null>(null);
  const [inputText, setInputText] = useState<string>('');
  const [inputRef, setInputRef] = useState(React.createRef<HTMLInputElement>());
  const showSingleDateOptions = period.start && period.end && period.start === period.end;

  // Custom Hooks use
  useOnClickOutside(containerRef, () => {
    const container = containerRef.current;
    if (container) {
      hideDatepicker();
    }
  });

  // Functions
  const hideDatepicker = useCallback(() => {
    //if (period && period.start != period.end) {
    const div = calendarContainerRef.current;
    const shadow = calendarContainerShadowRef.current;
    if (shadow && div && div.classList.contains('block')) {
      div.classList.remove('block');
      div.classList.remove('-translate-y-1/2');
      div.classList.remove('opacity-1');
      div.classList.add('translate-y-4');
      div.classList.add('opacity-0');
      shadow.classList.remove('datepicker-shadow');
      shadow.classList.add('opacity-0');
      shadow.classList.add('hidden');
      setTimeout(() => {
        div.classList.remove('bottom-full');
        div.classList.add('hidden');
        div.classList.add('mb-2.5');
        div.classList.add('mt-2.5');
      }, 300);
    }
    //}
  }, []);

  /* Start First */
  const firstGotoDate = useCallback(
    (date: dayjs.Dayjs) => {
      const newDate = dayjs(formatDate(date));
      const reformatDate = dayjs(formatDate(secondDate));
      if (newDate.isSame(reformatDate) || newDate.isAfter(reformatDate)) {
        setSecondDate(nextMonth(date));
      }
      setFirstDate(date);
    },
    [secondDate],
  );

  const previousMonthFirst = useCallback(() => {
    setFirstDate(previousMonth(firstDate));
  }, [firstDate]);

  const nextMonthFirst = useCallback(() => {
    firstGotoDate(nextMonth(firstDate));
  }, [firstDate, firstGotoDate]);

  const changeFirstMonth = useCallback(
    (month: number) => {
      firstGotoDate(dayjs(`${firstDate.year()}-${month < 10 ? '0' : ''}${month}-01`));
    },
    [firstDate, firstGotoDate],
  );

  const changeFirstYear = useCallback(
    (year: number) => {
      firstGotoDate(dayjs(`${year}-${firstDate.month() + 1}-01`));
    },
    [firstDate, firstGotoDate],
  );
  /* End First */

  /* Start Second */
  const secondGotoDate = useCallback(
    (date: dayjs.Dayjs) => {
      const newDate = dayjs(formatDate(date, displayFormat));
      const reformatDate = dayjs(formatDate(firstDate, displayFormat));
      if (newDate.isSame(reformatDate) || newDate.isBefore(reformatDate)) {
        setFirstDate(previousMonth(date));
      }
      setSecondDate(date);
    },
    [firstDate, displayFormat],
  );

  const previousMonthSecond = useCallback(() => {
    secondGotoDate(previousMonth(secondDate));
  }, [secondDate, secondGotoDate]);

  const nextMonthSecond = useCallback(() => {
    setSecondDate(nextMonth(secondDate));
  }, [secondDate]);

  const changeSecondMonth = useCallback(
    (month: number) => {
      secondGotoDate(dayjs(`${secondDate.year()}-${month < 10 ? '0' : ''}${month}-01`));
    },
    [secondDate, secondGotoDate],
  );

  const changeSecondYear = useCallback(
    (year: number) => {
      secondGotoDate(dayjs(`${year}-${secondDate.month() + 1}-01`));
    },
    [secondDate, secondGotoDate],
  );
  /* End Second */

  // UseEffects & UseLayoutEffect
  useEffect(() => {
    if (value && value.startDate && value.endDate) {
      const startDate = dayjs(value.startDate);
      const endDate = dayjs(value.endDate);
      const validDate = startDate.isValid() && endDate.isValid();
      const condition = validDate && (startDate.isSame(endDate) || startDate.isBefore(endDate));
      if (condition) {
        setPeriod({
          start: formatDate(startDate),
          end: formatDate(endDate),
        });
        setInputText(`${formatDate(startDate, displayFormat)}${asSingle ? '' : ` ${separator} ${formatDate(endDate, displayFormat)}`}`);
      }
    } else if (value && value.startDate) {
      const startDate = dayjs(value.startDate);
      if (startDate.isValid()) {
        setPeriod({
          start: formatDate(startDate),
          end: formatDate(startDate),
        });
        setInputText(`${formatDate(startDate, displayFormat)}${asSingle ? '' : ` ${separator} ${displayFormat}`}`);
      }
    } else if (value && value.endDate) {
      const endDate = dayjs(value.endDate);
      if (endDate.isValid()) {
        setPeriod({
          start: formatDate(endDate),
          end: formatDate(endDate),
        });
        setInputText(`${displayFormat}${asSingle ? '' : ` ${separator} ${formatDate(endDate, displayFormat)}`}`);
      }
    }

    if (value && value.startDate === null && value.endDate === null) {
      setPeriod({
        start: null,
        end: null,
      });
      setInputText('');
    }
  }, [asSingle, value, displayFormat, separator]);

  useEffect(() => {
    if (startFrom && dayjs(startFrom).isValid()) {
      const startDate = value?.startDate;
      const endDate = value?.endDate;
      if (startDate && dayjs(startDate).isValid()) {
        setFirstDate(dayjs(startDate));
        if (!asSingle) {
          if (endDate && dayjs(endDate).isValid() && dayjs(endDate).startOf('month').isAfter(dayjs(startDate))) {
            setSecondDate(dayjs(endDate));
          } else {
            setSecondDate(nextMonth(dayjs(startDate)));
          }
        }
      } else {
        setFirstDate(dayjs(startFrom));
        setSecondDate(nextMonth(dayjs(startFrom)));
      }
    }
  }, [asSingle, startFrom, value]);

  // Variables
  const contextValues = useMemo(() => {
    return {
      asSingle,
      configs,
      calendarContainer: calendarContainerRef,
      calendarContainerShadow: calendarContainerShadowRef,
      hideDatepicker,
      period,
      changePeriod: (newPeriod: Period) => setPeriod(newPeriod),
      dayHover,
      changeDayHover: (newDay: string | null) => setDayHover(newDay),
      inputText,
      changeInputText: (newText: string) => setInputText(newText),
      updateFirstDate: (newDate: dayjs.Dayjs) => firstGotoDate(newDate),
      changeDatepickerValue: onChange,
      showFooter,
      placeholder,
      separator,
      i18n,
      value,
      disabled,
      inputClassName,
      containerClassName,
      toggleClassName,
      toggleIcon,
      readOnly,
      displayFormat,
      minDate,
      maxDate,
      dateLooking,
      disabledDates,
      postfixID,
      inputName,
      startWeekOn,
      classNames,
      onChange,
      input: inputRef,
      popoverDirection,
    };
  }, [
    asSingle,
    configs,
    hideDatepicker,
    period,
    dayHover,
    inputText,
    onChange,
    showFooter,
    placeholder,
    separator,
    i18n,
    value,
    disabled,
    inputClassName,
    containerClassName,
    calendarContainerClass,
    toggleClassName,
    toggleIcon,
    readOnly,
    displayFormat,
    minDate,
    maxDate,
    dateLooking,
    disabledDates,
    postfixID,
    inputName,
    startWeekOn,
    classNames,
    inputRef,
    popoverDirection,
    firstGotoDate,
  ]);

  const containerClassNameOverload = useMemo(() => {
    const defaultContainerClassName = 'relative w-full';
    return typeof containerClassName === 'function'
      ? containerClassName(defaultContainerClassName)
      : typeof containerClassName === 'string' && containerClassName !== ''
      ? containerClassName
      : defaultContainerClassName;
  }, [containerClassName]);

  return (
    <DatepickerContext.Provider value={contextValues}>
      <div className={containerClassNameOverload} ref={containerRef}>
        <Input setContextRef={setInputRef} idSuffix={postfixID} />

        <div className="hidden opacity-0" ref={calendarContainerShadowRef} onClick={hideDatepicker} />
        <div className={calendarContainerClass} ref={calendarContainerRef}>
          <div className="mt-0.5 p-0.5 rounded-lg">
            <div className="flex flex-col min-lg:flex-row">
              {showShortcuts && <Shortcuts id={`filter-datepicker-${postfixID}-shortcut`} startingTime={startingTime} />}
              <div className={'flex flex-col'}>
                <div className={'flex items-stretch flex-col min-md:flex-row space-y-4 min-md:space-y-0 min-md:space-x-1.5 min-md:pl-1 pr-2 min-lg:pr-1'}>
                  <Calendar
                    date={firstDate}
                    onClickPrevious={previousMonthFirst}
                    onClickNext={nextMonthFirst}
                    changeMonth={changeFirstMonth}
                    changeYear={changeFirstYear}
                    minDate={minDate}
                    maxDate={maxDate}
                    id={`${postfixID}-left`}
                    setPeriod={setPeriod}
                    hideDaysAndWeeks={hideDaysAndWeeks}
                    showForMonths={showForMonths}
                    showForYears={showForYears}
                  />

                  {useRange && (
                    <>
                      <Calendar
                        date={secondDate}
                        onClickPrevious={previousMonthSecond}
                        onClickNext={nextMonthSecond}
                        changeMonth={changeSecondMonth}
                        changeYear={changeSecondYear}
                        minDate={minDate}
                        maxDate={maxDate}
                        id={`${postfixID}-right`}
                        setPeriod={setPeriod}
                        hideDaysAndWeeks={hideDaysAndWeeks}
                        showForMonths={showForMonths}
                        showForYears={showForYears}
                      />
                    </>
                  )}
                </div>
                <div>{showSingleDateOptions && <SingleDateOptions filterkey={postfixID} />}</div>
              </div>
            </div>
            {showFooter && <Footer filterkey={postfixID} />}
          </div>
        </div>
      </div>
    </DatepickerContext.Provider>
  );
};

export default Datepicker;
