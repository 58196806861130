import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import LineChartModuleType from "shared/components/dashboard/LineChartModuleType";
import RingGraphModuleType from "shared/components/dashboard/RingGraphModuleType";
import ShortTableType from "shared/components/dashboard/ShortTableType";
import SimpleModuleType from "shared/components/dashboard/SimpleModuleType";
import StackedBarChartModuleType from "shared/components/dashboard/StackedBarChartModuleType";

export type ChartDataType = Array<{ [key: NameType]: ValueType }>;

export const filterKeys = {
  start: "effective-from",
  end: "expiration-to",
};

export const DashboardModuleType = {
  SUM: "sum",
  TOTAL: "total",
  LINE_CHART: "lineChart",
  RING_GRAPH: "ringGraph",
  STACKED_BAR_CHART: "stackedBarChart",
  SHORT_TABLE: "shortTable",
};

export const dashboardMapper = {
  [DashboardModuleType.SUM]: (id: number, societyId: number) => (
    <SimpleModuleType dashboardId={id} societyId={societyId} />
  ),
  [DashboardModuleType.TOTAL]: (id: number, societyId: number) => (
    <SimpleModuleType dashboardId={id} societyId={societyId} />
  ),
  [DashboardModuleType.LINE_CHART]: (id: number, societyId: number) => (
    <LineChartModuleType dashboardId={id} societyId={societyId} />
  ),
  [DashboardModuleType.RING_GRAPH]: (id: number, societyId: number) => (
    <RingGraphModuleType dashboardId={id} societyId={societyId} />
  ),
  [DashboardModuleType.STACKED_BAR_CHART]: (id: number, societyId: number) => (
    <StackedBarChartModuleType dashboardId={id} societyId={societyId} />
  ),
  [DashboardModuleType.SHORT_TABLE]: (id: number, societyId: number) => (
    <ShortTableType dashboardId={id} societyId={societyId} />
  ),
};
