import { apiRequestContractHandler } from "shared/api/apiRequestContractHandler";
import { postSocietyFilesContract } from "shared/api/contracts/society/societyId/files";
import { getSocietyFileIdOrSlugContract } from "shared/api/contracts/society/societyId/files/fileIdOrSlug";
import { postSocietyProfileFilesContract } from "shared/api/contracts/society/societyId/profiles/profileId/files";
import { SocietyFileInfoOutput } from "shared/api/types/society/[societyId]/files";
import { uploadFileContractHandler } from "shared/api/uploadFileContractHandler";
import {
  DownloadFileEvent,
  QuestionFileModel,
  Serializer,
  SurveyModel,
  UploadFilesEvent,
} from "survey-core";
import { SurveyCreator } from "survey-creator-react";
Serializer.addProperty("FileValue", {
  name: "publicUrl",
});
export const fileUploadSetup = async ({
  societyId,
  profileId,
  model,
  creator,
}: {
  societyId: number;
  profileId?: number;
  model: SurveyModel;
  creator?: SurveyCreator;
}) => {
  model.onAfterRenderQuestion.add((_, options) => {
    if (options.question.getType() === "file") {
      options.question.storeDataAsText = false;
    }
  });

  const fileDownloadCallback = async (
    surveyModel: SurveyModel,
    downloadEvent: DownloadFileEvent,
  ) => {
    const fileUrl = isNaN(parseInt(downloadEvent.content))
      ? downloadEvent.content
      : (
          (await apiRequestContractHandler(getSocietyFileIdOrSlugContract, {
            params: {
              societyId,
              fileIdOrSlug: downloadEvent.content,
              returnInfo: true,
            },
          })) as SocietyFileInfoOutput
        ).signedUrl;
    const fileResponse = await fetch(fileUrl);
    const blob = await fileResponse.blob();
    const file = new File([blob], downloadEvent.fileValue.name, {
      type: downloadEvent.fileValue.type,
    });
    const reader = new FileReader();
    reader.onload = (e) => {
      downloadEvent.callback("success", e.target!.result);
    };
    reader.readAsDataURL(file);
  };
  model.onDownloadFile.add(fileDownloadCallback);

  const fileUploadCallback = (
    surveyModel: SurveyModel,
    uploadEvent: UploadFilesEvent,
  ) => {
    const formData = new FormData();

    uploadEvent.files.forEach(async (file) => {
      formData.append(file.name, file);
      //TODO: We are attaching submissionDefinitionId, etc to the from on render
      //so it would be great if we could use other upload contracts

      const uploadedFile = profileId
        ? await uploadFileContractHandler({
            file,
            contract: postSocietyProfileFilesContract,
            isPublic: uploadEvent.question.isPublic,
            societyId,
            profileId,
          })
        : await uploadFileContractHandler({
            file,
            contract: postSocietyFilesContract,
            isPublic: uploadEvent.question.isPublic,
            societyId,
          });

      uploadEvent.callback(
        [file].map((file) => {
          return {
            file: file,
            content: uploadedFile?.publicUrl ?? uploadedFile?.fileId,
          };
        }),
      );
    });
  };

  model.onUploadFiles.add(fileUploadCallback);

  if (creator) {
    creator?.onQuestionAdded.add((sender, options) => {
      if (options.question.getType() === "file") {
        const question: QuestionFileModel =
          options.question as unknown as QuestionFileModel;
        question.storeDataAsText = false;
        question.getPropertyByName("storeDataAsText").visible = false;
      }
    });
  }
};
