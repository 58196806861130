import React, { ComponentPropsWithoutRef } from "react";
import PillarForm from "shared/components/pillar-form/PillarForm";
import { PillarTableRowContext } from "shared/components/pillar-table/PillarTableRowContext";
export type PillarTableButtonProps = Omit<
  ComponentPropsWithoutRef<"button">,
  "type" | "onClick"
> & {
  buttonText: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (rowValue: any) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PillarTableButtonCellContent = ({
  buttonText,
  onClick,
  ...props
}: PillarTableButtonProps) => {
  const rowContext = React.useContext(PillarTableRowContext);

  return (
    <PillarForm.Button
      type="button"
      onClick={() => {
        //In this case, we know this should exist by now. If it does not, it is a code issue that
        //needs to be refactor to prevent an unexpected failure, so we require it to exist here.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        onClick(rowContext!.rowValue);
      }}
      {...props}
    >
      {buttonText}
    </PillarForm.Button>
  );
};

export default PillarTableButtonCellContent;
