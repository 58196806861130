import { twMerge } from "tailwind-merge";
import { ComponentPropsWithoutRef } from "react";

// https://tabler.io/icons/icon/selector
const ChevronUpDownIcon = ({
  className,
  ...props
}: ComponentPropsWithoutRef<"svg">) => {
  const defaultClassnames = "icon icon-tabler icon-tabler-selector h-3 w-3";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge(defaultClassnames, className)}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M8 9l4 -4l4 4" />
      <path d="M16 15l-4 4l-4 -4" />
    </svg>
  );
};

export default ChevronUpDownIcon;
