import PillarForm from "shared/components/pillar-form/PillarForm";
import XMarkIcon from "shared/components/icons/XMarkIcon";
import { ComponentPropsWithoutRef, useState } from "react";
import { Link } from "react-router-dom";

type PromoBannerProps = ComponentPropsWithoutRef<"div">;

const PromoBanner = ({ className, ...props }: PromoBannerProps) => {
  const [showBanner, setShowBanner] = useState(true);

  if (!showBanner) {
    return null;
  }

  return (
    <div
      className={`flex bg-society-50 p-2 shadow-sm justify-between ${
        className ?? ""
      }`}
      {...props}
    >
      {/* TODO: This needs to pull from the api and link dynamically based on id */}
      <Link
        to="/conference-details/1"
        className="flex gap-x-1 items-center no-underline"
      >
        <div className="font-semibold text-neutral-mid-900 text-lg phone:text-sm">
          Placeholder Conference 2023
        </div>
        <div className="divider-circle shrink-0 !bg-neutral-mid-900 h-0.5 w-0.5" />
        <div className="font-semibold text-neutral-mid-900 text-lg phone:text-sm">
          Placeholder conference blurb!
        </div>
      </Link>
      <PillarForm.Button
        className="button-regular-neutral-unfilled !h-4 !w-4 p-0 flex justify-center items-center"
        onClick={() => {
          setShowBanner(false);
        }}
      >
        <span className="sr-only">Dismiss</span>
        <XMarkIcon className="h-2.5 w-2.5 stroke-2" />
      </PillarForm.Button>
    </div>
  );
};

export default PromoBanner;
