/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ComponentCollection,
  ICustomQuestionTypeConfiguration,
  Question,
  Serializer,
} from "survey-core";
import { SurveyCreatorModel } from "survey-creator-core";
export const custom_question_degree = "degree_picker";

export const degree_question = async (creator?: SurveyCreatorModel) => {
  creator?.onQuestionAdded.add((sender: SurveyCreatorModel, options) => {
    if (options.question.getType() === custom_question_degree) {
      options.question.name = "degree";
    }
  });

  const questionTitle = "Degree Picker";

  const degree_question_object: ICustomQuestionTypeConfiguration = {
    name: custom_question_degree,
    title: questionTitle,
    questionJSON: {
      type: "paneldynamic",
      name: "degree_picker",
      title: "Degree Picker",
      titleLocation: "hidden",
      templateElements: [
        {
          type: "pillar_choice_dropdown",
          name: "degreeType",
          title: "Degree",
          titleLocation: "top",
          pillar_choices_question_name: "degree",
        },
        {
          type: "pillar_choice_dropdown",
          name: "university",
          title: "University",
          titleLocation: "top",
          pillar_choices_question_name: "university",
          startWithNewLine: false,
        },
        {
          title: "Graduation Date",
          titleLocation: "top",
          name: "graduationDate",
          startWithNewLine: false,
          type: "text",
          inputType: "date",
        },
      ],
    },

    onInit: function (): void {
      Serializer.getProperty(custom_question_degree, "name").readOnly = true;
      Serializer.addProperty(custom_question_degree, {
        name: "degreeTypeTitle",
        displayName: "Title",
        default: "Degree",
        type: "string",
        category: "Degree Type Options",
      });

      Serializer.addProperty(custom_question_degree, {
        name: "universityTitle",
        displayName: "Title",
        default: "University",
        type: "string",
        category: "University Options",
      });

      Serializer.addProperty(custom_question_degree, {
        name: "graduationDateTitle",
        displayName: "Title",
        default: "Graduation Date",
        type: "string",
        category: "Graduation Date Options",
      });

      Serializer.addProperty(custom_question_degree, {
        name: "degreeTypeRequired",
        displayName: "Required",
        default: false,
        type: "boolean",
        category: "Degree Type Options",
      });

      Serializer.addProperty(custom_question_degree, {
        name: "universityRequired",
        displayName: "Required",
        default: false,
        type: "boolean",
        category: "University Options",
      });

      Serializer.addProperty(custom_question_degree, {
        name: "graduationDateRequired",
        displayName: "Required",
        default: false,
        type: "boolean",
        category: "Graduation Date Options",
      });

      Serializer.addProperty(custom_question_degree, {
        name: "degreeTypeOtherOption",
        displayName: "Other Option",
        type: "boolean",
        category: "Degree Type Options",
      });

      Serializer.addProperty(custom_question_degree, {
        name: "universityOtherOption",
        displayName: "Other Option",
        type: "boolean",
        category: "University Options",
      });
    },

    onAfterRender: (question: Question) => {
      const {
        degreeTypeTitle,
        degreeTypeRequired,
        degreeTypeOtherOption,
        universityTitle,
        universityRequired,
        universityOtherOption,
        graduationDateTitle,
        graduationDateRequired,
      } = question;

      const degreePicker = question.getQuestionByName("degree_picker");
      if (degreePicker) {
        degreePicker.templateElements.forEach((element: any) => {
          if (element.name === "degreeType") {
            element.title = degreeTypeTitle ? degreeTypeTitle : "Degree";
            element.isRequired = degreeTypeRequired
              ? degreeTypeRequired
              : false;
            element.other_option = degreeTypeOtherOption
              ? degreeTypeOtherOption
              : false;
          }
          if (element.name === "university") {
            element.title = universityTitle ? universityTitle : "University";
            element.isRequired = universityRequired
              ? universityRequired
              : false;
            element.other_option = universityOtherOption
              ? universityOtherOption
              : false;
          }
          if (element.name === "graduationDate") {
            element.title = graduationDateTitle
              ? graduationDateTitle
              : "Graduation Date";
            element.isRequired = graduationDateRequired
              ? graduationDateRequired
              : false;
          }
        });
      }
    },
  };

  if (
    !ComponentCollection.Instance.getCustomQuestionByName(
      custom_question_degree,
    )
  ) {
    ComponentCollection.Instance.add(degree_question_object);
  }
};
