import { ParsedUrlQuery } from "querystring";
import { createContext, useContext } from "react";
import { RouteParams } from "shared/api/types/society/[societyId]/app-route-config";

export interface CustomRouterType {
  asPath: string;
  pathname: string;
  replace: (path: string) => void;
  query?: ParsedUrlQuery;
  state?: RouteParams;
  push: (path: string, params?: RouteParams) => void;
  removeFromState: (key: string) => void;
  setInState: (key: string, value: string) => void;
}

const CustomRouterContext = createContext<CustomRouterType | null>(null);

const useCustomRouter = (): CustomRouterType => {
  const router = useContext(CustomRouterContext);
  if (router === undefined) {
    throw new Error(
      "useCustomRouter must be used within a CustomRouterContext",
    );
  }
  if (router === null) {
    throw new Error("CustomRouterContext is null");
  }
  return router;
};

export { CustomRouterContext, useCustomRouter };
