import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";

import React, { useState } from "react";

import { PillarTableRowContext } from "shared/components/pillar-table/PillarTableRowContext";
import { PillarTableCellEditProps } from "shared/components/pillar-table/edit/PillarTableCellEdit";
import { DeepKeys } from "shared/types/deepkeys";
export type PillarTableCellEditSelectMenuProps<T, V> = {
  valueDisplayProperty: DeepKeys<T>;
  optionValueProperty: keyof T;
  optionDisplayProperty: keyof T;
  selectQuery: () => Promise<T[]>;
} & PillarTableCellEditProps<T, V>;

const PillarTableCellEditSelectMenu = <
  T,
  V extends string | number | readonly string[] | undefined,
>(
  props: PillarTableCellEditSelectMenuProps<T, V>,
) => {
  const rowContext = React.useContext(PillarTableRowContext);
  if (!rowContext) {
    throw new Error(
      "PillarTableCellEditSelectMenu must be used inside a PillarTableColumn",
    );
  }
  const editedValue = get(
    rowContext?.editedValue,
    props.valueProperty as string,
  );
  const [selectValue, setSelectValue] = useState(editedValue);
  const selectOptions = useQuery<T[]>(
    ["selectOptions", props.valueProperty],
    props.selectQuery,
  );
  return (
    <div>
      <select
        name={props.valueProperty}
        className="pr-3"
        value={selectValue}
        onChange={(e) => {
          const optionDisplay = selectOptions?.data?.find(
            (value) => value[props.optionValueProperty] == e.target.value,
          );
          rowContext.updateEditedValue(
            e.target.value,
            props.valueProperty as string,
          );

          setSelectValue(e.target.value);
        }}
      >
        {selectOptions.data &&
          selectOptions.data.map((option) => {
            return (
              <option
                key={`edit-cell-select-menu-${rowContext.rowIndex}-${
                  props.columnIndex
                }-${option[props.optionValueProperty]}`}
                //TODO: This is brittle. In practice should be fine, but we should check for type validity to reduce unexpected runtime bugs.
                value={option[props.optionValueProperty] as string | number}
              >
                {String(option[props.optionDisplayProperty])}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default PillarTableCellEditSelectMenu;
