import { useQuery } from "@tanstack/react-query";
import Spinner from "admin/src/ui/components/common/Spinner";
import get from "lodash/get";
import { useMemo } from "react";
import { apiRequestContractHandler } from "shared/api/apiRequestContractHandler";
import { getSocietyDashboardIdContract } from "shared/api/contracts/society/societyId/dashboards/dashboardId";
import { ChartDataType } from "shared/components/dashboard/dashboardMapper";
import PillarTable from "shared/components/pillar-table/PillarTable";

type Props = {
  dashboardId: number;
  societyId: number;
};

const ShortTableType = ({ dashboardId, societyId }: Props) => {
  const { data, isLoading } = useQuery({
    queryKey: ["dashboardModule", dashboardId],
    queryFn: async () =>
      apiRequestContractHandler(getSocietyDashboardIdContract, {
        params: {
          societyId,
          dashboardId: dashboardId,
        },
      }),
    refetchOnWindowFocus: false,
  });

  const tableData = useMemo(() => {
    return {
      data: (data?.data ?? []) as ChartDataType,
    };
  }, [data]);
  const displayColumns = useMemo(() => {
    const dataValues = tableData.data;
    const columnNameSet = new Set<string>();
    dataValues.forEach((v) => Object.keys(v).map((k) => columnNameSet.add(k)));
    return Array.from(columnNameSet.values());
  }, [tableData]);

  if (isLoading)
    return (
      <div className="col-span-6 flex items-center justify-center">
        <Spinner />
      </div>
    );
  if (!data) return <></>;

  return (
    <div
      className={
        (data.dashboardModule.classNames,
        `max-h-50 col-span-${data.dashboardModule.colSpan} row-start-${data.dashboardModule.positionRow} col-start-${data.dashboardModule.positionColumn}`)
      }
    >
      <a href="#" className="mb-2 inline-block text-xl font-semibold">
        {data.dashboardModule.title}
      </a>
      <PillarTable
        className="flex-1 w-full"
        dataQuery={tableData}
        queryKey={["dashboardModule", String(dashboardId)]}
      >
        {displayColumns.map((name) => {
          return (
            <PillarTable.Column key={name} heading={name}>
              {(row) => get(row, name)}
            </PillarTable.Column>
          );
        })}
      </PillarTable>
    </div>
  );
};

export default ShortTableType;
