import {
  SocietyReportInputParams,
  ReportResponseType,
  SocietyReportUpdateBodyInput,
} from "shared/api/types/society/[societyId]/reports/[reportId]/index";
import { CustomReportView } from "shared/mappers/database/reports/report";
import { BaseApiContract } from "shared/api/apiRequestContractHandler";
import { PaginatedResults } from "shared/api/types/pagination";
import { HttpMethod } from "shared/api/http";
export const getCustomReportIdContract: BaseApiContract<
  SocietyReportInputParams,
  undefined,
  PaginatedResults<ReportResponseType, CustomReportView>
> = {
  method: HttpMethod.GET,
  apiRoute: "/api/v1/society/[societyId]/reports/[reportId]",
  paramsType: {} as SocietyReportInputParams,
  responseType: {} as PaginatedResults<ReportResponseType, CustomReportView>,
};

export const postCustomReportIdContract: BaseApiContract<
  SocietyReportInputParams,
  SocietyReportUpdateBodyInput,
  CustomReportView
> = {
  method: HttpMethod.POST,
  apiRoute: "/api/v1/society/[societyId]/reports/[reportId]",
  paramsType: {} as SocietyReportInputParams,
  bodyType: {} as SocietyReportUpdateBodyInput,
  responseType: {} as CustomReportView,
};

export const deleteCustomReportIdContract: BaseApiContract<
  SocietyReportInputParams,
  undefined,
  void
> = {
  method: HttpMethod.DELETE,
  apiRoute: "/api/v1/society/[societyId]/reports/[reportId]",
  paramsType: {} as SocietyReportInputParams,
  bodyType: {} as unknown as undefined,
  responseType: {} as unknown as void,
};
