import {
  ProfileDto,
  ProfileView,
} from "shared/mappers/database/profile/profile";
import { ProfileAddressView } from "shared/mappers/database/profile/profile-address";

//TODO: This is a bit temporary,  I'd rather the default email by set by an ID in society settings instead of this string ~ GS 8/16/23
export const defaultAddressFromProfile = ({
  profile,
  use,
  type,
}: {
  profile: ProfileDto | ProfileView | undefined;
  use?: "Billing" | "Shipping" | string;
  type?: "Postal" | "Physical" | string;
}): ProfileAddressView | null => {
  return (
    profile?.address?.find((ci) => {
      if (use && !ci.use?.some((ciUse) => ciUse.description == use)) {
        return false;
      }
      if (type && ci.type?.description !== type) {
        return false;
      }
      if (!use && !type) {
        return true;
      }
    }) ??
    profile?.address?.[0] ??
    null
  );
};
