import React from "react";
import {
  DateIcon,
  CloseIcon,
} from "shared/components/common/datepicker/src/components/utils";

interface ToggleButtonProps {
  isEmpty: boolean;
}

const ToggleButton: React.FC<ToggleButtonProps> = (
  e: ToggleButtonProps,
): JSX.Element => {
  return e.isEmpty ? (
    <DateIcon className="h-3 w-3" />
  ) : (
    <CloseIcon className="h-3 w-3" />
  );
};

export default ToggleButton;
