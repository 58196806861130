import { email_status } from "@prisma/client";
import * as Yup from "yup";
import { SocietyProfileParamsSchema } from "shared/yup-validators/schemas/society/[societyId]/profiles/[profileId]/societyProfileSchema";

export const SocietyProfileEmailHistoryParamsSchema =
  SocietyProfileParamsSchema.concat(
    Yup.object().shape({
      emailHistoryId: Yup.number().required(),
    }),
  );

export const SocietyProfileEmailBodySchema = Yup.object({
  toEmail: Yup.string().emailPossiblyWithName().required("Email is required"),
  fromEmail: Yup.string().emailPossiblyWithName().optional(),
  emailCc: Yup.array().of(Yup.string().emailPossiblyWithName()).optional(),
  replyTo: Yup.string().emailPossiblyWithName().optional(),
});

export const SocietyProfileEmailStatusBodySchema = Yup.object({
  newStatus: Yup.mixed().oneOf(Object.keys(email_status)).required(),
});
