import { get } from "lodash";
import React from "react";
import { PillarTableColumnProps } from "shared/components/pillar-table/PillarTableColumn";
import { PillarTableRowContext } from "shared/components/pillar-table/PillarTableRowContext";
const PillarTableTextCellContent = <T,>(props: PillarTableColumnProps<T>) => {
  const rowContext = React.useContext(PillarTableRowContext);
  let value = get(rowContext?.rowValue, props.valueProperty as string);
  const editedValue = get(
    rowContext?.editedValue,
    props.valueProperty as string,
  );

  if (!props.doNotEdit && rowContext?.inlineEditConfirm) {
    if (props.cellEditComponent) {
      return <>{props.cellEditComponent}</>;
    }
    return (
      <input
        type="text"
        value={editedValue}
        onChange={(e) => {
          rowContext.updateEditedValue(
            e.target.value,
            props.valueProperty as string,
          );
        }}
      />
    );
  }

  if (props.valueDisplayProperty) {
    value = get(value, props.valueDisplayProperty as string);
  }
  return value || value == 0 ? (
    props.linkTo ? (
      <a>{value}</a>
    ) : (
      <>{value}</>
    )
  ) : (
    <></>
  );
};

export default PillarTableTextCellContent;
