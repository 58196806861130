import PillarTableFilterCheckbox from "shared/components/pillar-table/filters/PillarTableFilterCheckbox";
import PillarTableFilterDatePicker from "shared/components/pillar-table/filters/PillarTableFilterDatePicker";
import PillarTableFilterSelectMenu from "shared/components/pillar-table/filters/PillarTableFilterSelectMenu";
import PillarTableFilterSelectMenuCheckbox from "shared/components/pillar-table/filters/PillarTableFilterSelectMenuCheckbox";
import PillarTableFilterTextInput from "shared/components/pillar-table/filters/PillarTableFilterTextInput";
import {
  AppFilterOption,
  FilterComponentType,
} from "shared/filter-where-clause";
import { SocietyView } from "shared/mappers/database/society/society";

export const filtersMapper = {
  [FilterComponentType.none]: () => {
    return <> </>;
  },
  [FilterComponentType.search]: (filter: AppFilterOption) => {
    return (
      <PillarTableFilterTextInput
        filterKey={filter!.filterKey!}
        title={filter.component!.label!}
        className="w-full min-w-4xl"
      />
    );
  },

  [FilterComponentType.Date]: (
    filter: AppFilterOption,
    society?: SocietyView
  ) => {
    return (
      <div className="flex flex-col min-w-6xl">
        <PillarTableFilterDatePicker
          filterKey={filter!.filterKey!}
          title={filter.component!.label!}
          society={society}
        />
      </div>
    );
  },

  [FilterComponentType.selectMenuCheckbox]: (filter: AppFilterOption) => {
    return (
      <PillarTableFilterSelectMenuCheckbox
        choices={filter.choices ?? []}
        filterKey={filter!.filterKey!}
        title={filter.component!.label!}
        className="mr-1"
      />
    );
  },

  [FilterComponentType.selectMenu]: (filter: AppFilterOption) => {
    return (
      <PillarTableFilterSelectMenu
        filterKey={filter!.filterKey!}
        title={filter.component!.label!}
        className="w-20 block pillartable-filters-selectmenu-dropdown-container"
      />
    );
  },

  [FilterComponentType.checkbox]: (filter: AppFilterOption) => {
    return (
      <PillarTableFilterCheckbox
        filterKey={filter!.filterKey!}
        title={filter.component!.label!}
        defaultChecked={true}
      />
    );
  },
};
