export const profileAddressContectInformationQuestionElements = [
  {
    type: "text",
    name: "line1",
    title: "Street Address",
  },
  {
    type: "text",
    name: "line2",
    title: "Address Line 2",
  },
  {
    type: "text",
    name: "line3",
    title: "Address Line 3",
  },
  {
    type: "dropdown",
    name: "country",
    title: "Country",
    choices: [
      "Aruba",
      "Afghanistan",
      "Angola",
      "Albania",
      "Andorra",
      "United Arab Emirates",
      "Argentina",
      "Armenia",
      "American Samoa",
      "Antigua and Barbuda",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Burundi",
      "Belgium",
      "Benin",
      "Burkina Faso",
      "Bangladesh",
      "Bulgaria",
      "Bahrain",
      "Bahamas, The",
      "Bosnia and Herzegovina",
      "Belarus",
      "Belize",
      "Bermuda",
      "Bolivia",
      "Brazil",
      "Barbados",
      "Brunei Darussalam",
      "Bhutan",
      "Botswana",
      "Central African Republic",
      "Canada",
      "Switzerland",
      "Channel Islands",
      "Chile",
      "China",
      "Côte d’Ivoire",
      "Cameroon",
      "Congo, Dem. Rep.",
      "Congo, Rep.",
      "Colombia",
      "Comoros",
      "Cabo Verde",
      "Costa Rica",
      "Cuba",
      "Curaçao",
      "Cayman Islands",
      "Cyprus",
      "Czechia",
      "Germany",
      "Djibouti",
      "Dominica",
      "Denmark",
      "Dominican Republic",
      "Algeria",
      "Ecuador",
      "Egypt, Arab Rep.",
      "Eritrea",
      "Spain",
      "Estonia",
      "Ethiopia",
      "Finland",
      "Fiji",
      "France",
      "Faroe Islands",
      "Micronesia, Fed. Sts.",
      "Gabon",
      "United Kingdom",
      "Georgia",
      "Ghana",
      "Gibraltar",
      "Guinea",
      "Gambia, The",
      "Guinea-Bissau",
      "Equatorial Guinea",
      "Greece",
      "Grenada",
      "Greenland",
      "Guatemala",
      "Guam",
      "Guyana",
      "Hong Kong SAR, China",
      "Honduras",
      "Croatia",
      "Haiti",
      "Hungary",
      "Indonesia",
      "Isle of Man",
      "India",
      "Ireland",
      "Iran, Islamic Rep.",
      "Iraq",
      "Iceland",
      "Israel",
      "Italy",
      "Jamaica",
      "Jordan",
      "Japan",
      "Kazakhstan",
      "Kenya",
      "Kyrgyz Republic",
      "Cambodia",
      "Kiribati",
      "St. Kitts and Nevis",
      "Korea, Rep.",
      "Kuwait",
      "Lao PDR",
      "Lebanon",
      "Liberia",
      "Libya",
      "St. Lucia",
      "Liechtenstein",
      "Sri Lanka",
      "Lesotho",
      "Lithuania",
      "Luxembourg",
      "Latvia",
      "Macao SAR, China",
      "St. Martin (French part)",
      "Morocco",
      "Monaco",
      "Moldova",
      "Madagascar",
      "Maldives",
      "Mexico",
      "Marshall Islands",
      "North Macedonia",
      "Mali",
      "Malta",
      "Myanmar",
      "Montenegro",
      "Mongolia",
      "Northern Mariana Islands",
      "Mozambique",
      "Mauritania",
      "Mauritius",
      "Malawi",
      "Malaysia",
      "Namibia",
      "New Caledonia",
      "Niger",
      "Nigeria",
      "Nicaragua",
      "Netherlands",
      "Norway",
      "Nepal",
      "Nauru",
      "New Zealand",
      "Oman",
      "Pakistan",
      "Panama",
      "Peru",
      "Philippines",
      "Palau",
      "Papua New Guinea",
      "Poland",
      "Puerto Rico",
      "Korea, Dem. People's Rep.",
      "Portugal",
      "Paraguay",
      "West Bank and Gaza",
      "French Polynesia",
      "Qatar",
      "Romania",
      "Russian Federation",
      "Rwanda",
      "Saudi Arabia",
      "Sudan",
      "Senegal",
      "Singapore",
      "Solomon Islands",
      "Sierra Leone",
      "El Salvador",
      "San Marino",
      "Somalia",
      "Serbia",
      "South Sudan",
      "São Tomé and Príncipe",
      "Suriname",
      "Slovak Republic",
      "Slovenia",
      "Sweden",
      "Eswatini",
      "Sint Maarten (Dutch part)",
      "Seychelles",
      "Syrian Arab Republic",
      "Turks and Caicos Islands",
      "Chad",
      "Togo",
      "Thailand",
      "Tajikistan",
      "Turkmenistan",
      "Timor-Leste",
      "Tonga",
      "Trinidad and Tobago",
      "Tunisia",
      "Türkiye",
      "Tuvalu",
      "Taiwan, China",
      "Tanzania",
      "Uganda",
      "Ukraine",
      "Uruguay",
      "United States",
      "Uzbekistan",
      "St. Vincent and the Grenadines",
      "Venezuela, RB",
      "British Virgin Islands",
      "Virgin Islands (U.S.)",
      "Vietnam",
      "Vanuatu",
      "Samoa",
      "Kosovo",
      "Yemen, Rep.",
      "South Africa",
      "Zambia",
      "Zimbabwe",
    ],
  },
  {
    type: "dropdown",
    name: "region",
    startWithNewLine: false,
    title: "Region",
    choices: [
      "Asia",
      "Africa",
      "Caribbean",
      "Europe",
      "North America",
      "Central America",
      "South America",
      "Austraila/ Oceania",
    ],
  },
  {
    type: "text",
    name: "city",
    title: "City",
  },
  {
    type: "text",
    name: "state",
    title: "State",
    startWithNewLine: false,
  },
  {
    type: "dropdown",
    name: "us_state",
    startWithNewLine: false,
    title: "State / Province / Region",
    choices: [
      "Alabama",
      "Alaska",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "Florida",
      "Georgia",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ],
  },
  {
    type: "text",
    name: "postal_code",
    startWithNewLine: false,
    title: "Zip / Postal Code",
  },
  {
    type: "text",
    name: "phone_display",
    title: "Phone",
    textUpdateMode: "onTyping",
    showInResults: false,
    placeholder: "XXXXXXXXXX",
    validators: [
      {
        type: "regex",
        text: "Please enter a valid phone number",
        regex:
          "\\+1 \\([0-9]{3}\\) [0-9]{3}-[0-9]{4}|\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}|[0-9]{3}-[0-9]{3}-[0-9]{4}|^1 \\([0-9]{3}\\) [0-9]{3}-[0-9]{5}$|[0-9]{10}",
      },
    ],
  },
  {
    type: "expression",
    name: "phone",
    title: "Phone",
    visibleIf: "false",
  },
  {
    type: "text",
    name: "fax_display",
    required: false,
    startWithNewLine: false,
    title: "Fax",
    placeholder: "XXXXXXXXXX",
    showInResults: false,
    textUpdateMode: "onTyping",
    validators: [
      {
        type: "regex",
        text: "Please enter a valid fax number",
        regex:
          "\\+1 \\([0-9]{3}\\) [0-9]{3}-[0-9]{4}|\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}|[0-9]{3}-[0-9]{3}-[0-9]{4}|^1 \\([0-9]{3}\\) [0-9]{3}-[0-9]{5}$|[0-9]{10}",
      },
    ],
  },
  {
    type: "expression",
    name: "fax",
    title: "Fax",
    visibleIf: "false",
  },
  {
    type: "text",
    name: "email",
    startWithNewLine: false,
    title: "Email",
  },
  {
    type: "text",
    name: "contact_information_id",
    title: "contact_information_id",
    readOnly: true,
    visible: false,
  },
  {
    type: "text",
    name: "profile_address_id",
    title: "profile_address_id",
    readOnly: true,
    visible: false,
  },
  {
    type: "checkbox",
    name: "use_id",
    title: "use_id",
    readOnly: true,
    visible: false,
  },
  {
    type: "text",
    name: "type_id",
    title: "type_id",
    readOnly: true,
    visible: false,
  },
];
