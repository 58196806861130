import { FormDesignElement } from "admin/src/ui/types/reports/types";

export const findMatchingFormDesignElement = (
  elements: FormDesignElement[],
  path: string
) => {
  let matchingElement = elements.find(
    (element: FormDesignElement) => element.name === path
  );
  if (matchingElement) return matchingElement;

  for (const element of elements) {
    matchingElement = element.children?.find(
      (child: FormDesignElement) => child.name === path
    );
    if (matchingElement) {
      matchingElement = {
        ...matchingElement,
        parentTitle: element.title,
      };
      break;
    }
  }

  return matchingElement;
};
