import { InvoiceView } from "shared/mappers/database/accounting/invoice/invoice";
import { BaseApiContract } from "shared/api/apiRequestContractHandler";
import {
  PaginatedResults,
  SumResult,
} from "shared/api/types/pagination";
import { HttpMethod } from "shared/api/http";
import { SocietyProfileParamsInput } from "shared/api/types/society/[societyId]/profiles/[profileId]";
export const getSocietyProfileInvoicesContract: BaseApiContract<
SocietyProfileParamsInput,
  undefined,
  PaginatedResults<InvoiceView, SumResult>
> = {
  method: HttpMethod.GET,
  apiRoute: "/api/v1/society/[societyId]/profiles/[profileId]/invoices",
  paramsType: {} as SocietyProfileParamsInput,
  responseType: {} as PaginatedResults<InvoiceView, SumResult>,
};
