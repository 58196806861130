import { BaseApiContract } from "shared/api/apiRequestContractHandler";
import { HttpMethod } from "shared/api/http";
import {
  SocietyFileInfoOutput,
  SocietyFileKeyOutput,
  SocietyFileKeyPostBodyInput,
} from "shared/api/types/society/[societyId]/files";
import {
  SocietyFileIdParamsInput,
  SocietyFileOrSlugParamsInput,
} from "shared/api/types/society/[societyId]/files/[fileIdOrSlug]";
export const getSocietyFileIdOrSlugContract: BaseApiContract<
  SocietyFileOrSlugParamsInput,
  undefined,
  SocietyFileInfoOutput | string
> = {
  method: HttpMethod.GET,
  apiRoute: "/api/v1/society/[societyId]/files/[fileIdOrSlug]",
  paramsType: {} as SocietyFileOrSlugParamsInput,
  responseType: {} as SocietyFileInfoOutput,
};
export const deleteSocietyFileIdOrSlugContract: BaseApiContract<
  SocietyFileOrSlugParamsInput,
  undefined,
  any
> = {
  method: HttpMethod.DELETE,
  apiRoute: "/api/v1/society/[societyId]/files/[fileIdOrSlug]",
  paramsType: {} as SocietyFileOrSlugParamsInput,
  responseType: undefined as unknown as any,
};
export const postFileIdContract: BaseApiContract<
  SocietyFileIdParamsInput,
  SocietyFileKeyPostBodyInput,
  SocietyFileKeyOutput
> = {
  method: HttpMethod.POST,
  apiRoute: "/api/v1/society/[societyId]/files/[fileIdOrSlug]",
  paramsType: {} as SocietyFileIdParamsInput,
  bodyType: {} as SocietyFileKeyPostBodyInput,
  responseType: {} as SocietyFileKeyOutput,
};
