import { SpinnerSize } from "admin/src/constants/enums/spinner-sizes";
import Spinner from "admin/src/ui/components/common/Spinner";
import React, { ComponentPropsWithoutRef, useState } from "react";

type PillarFormLoadingButtonProps = ComponentPropsWithoutRef<"button"> & {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void> | void;
  label?: string;
  icon?: React.ReactElement;
  testid?: string;
};

const PillarFormLoadingButton = ({
  onClick,
  label,
  icon,
  testid,
  className,
  disabled,
  ...props
}: PillarFormLoadingButtonProps) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsButtonLoading(true);
    try {
      const result = onClick(event) as Promise<void> | void;
      if (result instanceof Promise) {
        await result;
      }
      setIsButtonLoading(false);
    } catch (response) {
      setIsButtonLoading(false);
    }
  };

  return (
    <button
      data-testid={testid}
      type="button"
      className={`flex items-center justify-center gap-x-0.5 button-regular-general-filled ${
        className ?? ""
      }`}
      disabled={disabled || isButtonLoading}
      onClick={handleClick}
      {...props}
    >
      {isButtonLoading ? (
        <Spinner spinnerSize={SpinnerSize.Small} />
      ) : (
        <>
          {icon && icon}
          {label && label}
          {props.children}
        </>
      )}
    </button>
  );
};

export default PillarFormLoadingButton;
