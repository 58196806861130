export const formDesignTypeConverter = (type: string): string => {
  const typeConvertMapper: { [key: string]: string } = {
    text: "string",
    number: "number",
    boolean: "boolean",
    dropdown: "string",
    checkbox: "boolean",
  };

  return typeConvertMapper[type] || "string";
};
