import { ComponentPropsWithoutRef, useEffect, useState } from "react";
import withFilterComponent from "shared/components/pillar-table/filters/withFilterComponent";
import { usePillarTableQueryContext } from "shared/components/pillar-table/query/PillarTableQueryContext";
type PillarTableFilterSingleDateProps = Omit<
  ComponentPropsWithoutRef<"input">,
  "value" | "name" | "onChange" | "type" | "title"
> & {
  title: string;
  filterKey: string;
};

const PillarTableFilterSingleDate = ({
  filterKey,
  title,
  ...props
}: PillarTableFilterSingleDateProps) => {
  const filterContext = usePillarTableQueryContext();
  const { setFilters, filters } = filterContext;

  const [filterValue, setFilterValue] = useState<string>(
    (filters[filterKey] as string) ?? "",
  );

  useEffect(() => {
    if (filters[filterKey] != filterValue) {
      setFilters({ ...filters, [filterKey]: filterValue });
    }
  }, [filterValue]);

  return (
    <>
      <label
        className="text-filter-label"
        htmlFor={`filter-single-date-${filterKey}`}
      >
        {title}
      </label>
      <input
        value={filterValue}
        name={`filter-single-date-${filterKey}`}
        title={title}
        onChange={(e) => {
          setFilterValue(e.target.value);
        }}
        type="date"
        className="!rounded !border-neutral-mid-200 !text-neutral-mid-600 !font-medium"
        {...props}
      />
    </>
  );
};

export default withFilterComponent(PillarTableFilterSingleDate);
