import { get as lodashGet } from "lodash";
import { DateTime } from "luxon";
import { PillarDataTableFixedColumn } from "shared/components/pillar-table/helpers/addFixedStyleToColumn";
import { renderTemplateUtil } from "shared/components/template/renderTemplateUtil";
import {
  AliasDateFormats,
  LuxonDateFormats,
  dateMapperFormats,
} from "shared/datetime/dateFormats";
import { defaultAddressFromProfile } from "shared/helpers/profile/address/defaultAddressFromProfile";
import { defaultContactInformationFromProfile } from "shared/helpers/profile/defaultContactInformationFromProfile";
import { defaultEmailFromProfile } from "shared/helpers/profile/defaultEmailFromProfile";
import { getDisplayNameHelper } from "shared/helpers/profile/getDisplayNameHelper";
import { getFormattedDegreeData } from "shared/helpers/profile/getFormattedDegreeData";
import { ContactInformationView } from "shared/mappers/database/profile/contact-information";
import { ProfileView } from "shared/mappers/database/profile/profile";
import { ProfileAddressView } from "shared/mappers/database/profile/profile-address";
import { SocietyTagView } from "shared/mappers/database/tag/society-tag";

type CustomReportColumnPropsMapping = {
  [K in keyof typeof customReportColumnsMapper]: Omit<
    Parameters<(typeof customReportColumnsMapper)[K]>[0],
    "rowValue"
  >;
};
export type CustomReportColumnConfig<
  T extends keyof typeof customReportColumnsMapper = keyof typeof customReportColumnsMapper
> = CustomReportColumnPropsMapping[T] & {
  name: string;
  type: T;
  fixedColumn?: PillarDataTableFixedColumn;
  contentClassName?: string;
};
export const ColumnTypes = {
  string: "string",
  json: "json",
  number: "number",
  Date: "Date",
  displayName: "displayName",
  email: "email",
  boolean: "boolean",
  address: "address",
  contactInformation: "contactInformation",
  Month: "Month",
  "string[]": "string[]",
  tag: "tag",
  template: "template",
  degree: "degree",
} as const;

type DisplayColumnPropsBase<T = any> = {
  rowValue: T;
};

type DisplayColumnPropsCellValuePath<T = any> = DisplayColumnPropsBase<T> & {
  cellValuePath: string;
};

export const customReportColumnsMapper = {
  [ColumnTypes.degree]: ({
    rowValue,
    pick,
  }: DisplayColumnPropsBase & {
    pick: "degreeType" | "university" | "graduationDate";
  }) => {
    return getFormattedDegreeData(rowValue.societyUser?.degree ?? [], pick);
  },
  [ColumnTypes.boolean]: (props: DisplayColumnPropsCellValuePath) => {
    const cellValue = lodashGet(props.rowValue, props.cellValuePath);

    const objectExists =
      cellValue &&
      typeof cellValue === "object" &&
      Object.keys(cellValue).length > 0;

    return objectExists || cellValue === true ? "Yes" : "No";
  },
  [ColumnTypes.string]: (props: DisplayColumnPropsCellValuePath) => {
    const cellValue = lodashGet(props.rowValue, props.cellValuePath) as string;
    return cellValue ?? "";
  },
  [ColumnTypes["string[]"]]: (props: DisplayColumnPropsCellValuePath) => {
    const cellValue = lodashGet(
      props.rowValue,
      props.cellValuePath
    ) as string[];
    return Array.isArray(cellValue) ? cellValue.join(", ") : cellValue ?? "";
  },
  [ColumnTypes.tag]: (
    props: DisplayColumnPropsCellValuePath & {
      property: keyof SocietyTagView;
      matching?: string | string[];
      outputTrue?: string;
      outputFalse?: string;
    }
  ) => {
    const profile = props.cellValuePath
      ? (lodashGet(props.rowValue, props.cellValuePath) as ProfileView)
      : (props.rowValue as ProfileView);
    const tags = profile.tags;
    if (props.matching) {
      if (!Array.isArray(props.matching)) {
        props.matching = [props.matching];
      }
      const tag = tags?.find(
        (tag) =>
          Array.isArray(props.matching) &&
          props.matching.some((match) => tag[props.property] === match)
      );
      if (props.outputTrue && tag) {
        return props.outputTrue;
      }
      if (props.outputFalse && !tag) {
        return props.outputFalse;
      }
      return tag ? tag[props.property]?.toString() : "";
    }
    return tags?.map((tag) => tag[props.property]?.toString()).join(", ") ?? "";
  },
  [ColumnTypes.json]: (props: DisplayColumnPropsCellValuePath) => {
    const cellValue = lodashGet(props.rowValue, props.cellValuePath) as object;
    return cellValue ? JSON.stringify(cellValue) : "";
  },
  [ColumnTypes.number]: (props: DisplayColumnPropsCellValuePath) => {
    const cellValue = lodashGet(props.rowValue, props.cellValuePath) as number;
    return cellValue?.toString() ?? "";
  },
  [ColumnTypes.Date]: (props: DisplayColumnPropsCellValuePath) => {
    const cellValue = lodashGet(props.rowValue, props.cellValuePath) as string;
    const dateTime = DateTime.fromISO(cellValue);
    return dateTime.isValid ? dateTime.toFormat(LuxonDateFormats.D) : "";
  },
  [ColumnTypes.Month]: (props: DisplayColumnPropsCellValuePath) => {
    const cellValue = lodashGet(props.rowValue, props.cellValuePath) as string;
    const dateTime = DateTime.fromISO(cellValue);
    return dateTime.isValid
      ? dateTime.toFormat(dateMapperFormats[AliasDateFormats.monthLong])
      : "";
  },
  [ColumnTypes.displayName]: (
    props: DisplayColumnPropsBase & {
      cellValuePath?: string;
    }
  ) => {
    const profile = props.cellValuePath
      ? (lodashGet(props.rowValue, props.cellValuePath) as ProfileView)
      : (props.rowValue as ProfileView);
    return getDisplayNameHelper(profile);
  },
  [ColumnTypes.email]: (
    props: DisplayColumnPropsBase & {
      cellValuePath?: string;
    }
  ) => {
    const profile = props.cellValuePath
      ? (lodashGet(props.rowValue, props.cellValuePath) as ProfileView)
      : (props.rowValue as ProfileView);
    return defaultEmailFromProfile(profile);
  },
  [ColumnTypes.address]: (
    props: DisplayColumnPropsBase & {
      cellValuePath?: string;
      property: keyof ProfileAddressView;
      use?: string;
      type?: string;
    }
  ) => {
    const profile = props.cellValuePath
      ? (lodashGet(props.rowValue, props.cellValuePath) as ProfileView)
      : (props.rowValue as ProfileView);
    return (
      defaultAddressFromProfile({
        profile,
        use: props.use,
        type: props.type,
      })?.[props.property]?.toString() ?? ""
    );
  },
  [ColumnTypes.contactInformation]: (
    props: DisplayColumnPropsBase & {
      cellValuePath?: string;
      property: keyof ContactInformationView;
      use?: string;
      type?: string;
    }
  ) => {
    const profile = props.cellValuePath
      ? (lodashGet(props.rowValue, props.cellValuePath) as ProfileView)
      : (props.rowValue as ProfileView);
    return (
      defaultContactInformationFromProfile({
        profile,
        use: props.use,
        type: props.type,
      })?.[props.property]?.toString() ?? ""
    );
  },
  [ColumnTypes.template]: (
    props: DisplayColumnPropsBase & {
      template: string;
    }
  ) => {
    return renderTemplateUtil({
      template: props.template,
      templateData: props.rowValue,
    });
  },
};
