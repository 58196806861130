import ApiRequest from "shared/api/api-request-builder";
import { HttpMethod } from "shared/api/http";

export type CheckUsernameExistsCalloutProps = {
  username: string;
  societyId: number;
};
export const checkUsernameExistsCallout = async ({
  username,
  societyId,
}: CheckUsernameExistsCalloutProps) => {
  const response = await ApiRequest(
    `${
      process.env.REACT_APP_PILLAR_API_URL ?? ""
    }/api/v1/society/${societyId}/identity?username=${username}`,
    HttpMethod.GET,
  );
  return response?.body;
};
