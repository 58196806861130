import { SessionView } from "shared/mappers/database/session/session";
import {
  AdminPermissions,
  AdminUserPermissions,
} from "shared/types/admin/permissions";

export const hasAdminPermission = ({
  session,
  permissions,
}: {
  session?: Partial<SessionView>;
  permissions: AdminPermissions[];
}) => {
  if (!session || !session.adminMode) {
    return false;
  }
  const userPermissions = session?.societyAdmin?.permissions ?? [];
  if (userPermissions.includes(AdminUserPermissions.super_admin)) {
    return true;
  }
  const societyPermissions =
    session?.societyAdmin?.societies?.find(
      (perm) => perm.societyId === session.societyId
    )?.permissions ?? [];

  return (
    permissions.every((permission) => userPermissions.includes(permission)) ||
    permissions.every((permission) =>
      societyPermissions.includes(permission)
    ) ||
    false
  );
};
