import React from "react";
import { NewAppExportToExcelBookProps } from "shared/components/pillar-table/export/advanced/AppExportToExcelBook";
import * as XLSX from "xlsx";
import { NewAppExcelToExcelSheetProps } from "shared/components/pillar-table/export/advanced/AppExportToExcelSheet";
import { getWorksheetData } from "./getWorksheetData";

export const getWorkbookData = <T, AdditionalQueryTypes extends object[]>(
  data: Array<T>,
  workbookElement: React.ReactElement<NewAppExportToExcelBookProps<T>>,
  additionalData: { [p: string]: AdditionalQueryTypes[number][] },
): { sheet: XLSX.WorkSheet; name: string }[] =>
  React.Children.map(workbookElement.props.children, (worksheetChild) => {
    const dataSheet = worksheetChild.props.additionalQueryId
      ? getWorksheetData<AdditionalQueryTypes[number]>(
          additionalData[worksheetChild.props.additionalQueryId],
          worksheetChild as React.ReactElement<
            NewAppExcelToExcelSheetProps<AdditionalQueryTypes[number]>
          >,
        )
      : getWorksheetData<T>(data, worksheetChild);

    return {
      sheet: XLSX.utils.aoa_to_sheet([...dataSheet]),
      name: worksheetChild.props.name,
    };
  });
