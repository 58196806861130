import { useQueryClient } from "@tanstack/react-query";
import find from "lodash/find";
import merge from "lodash/merge";
import set from "lodash/set";
import { useMemo } from "react";
import { apiRequestContractHandler } from "shared/api/apiRequestContractHandler";
import { postCustomReportIdContract } from "shared/api/contracts/society/societyId/reports/reportId";
import { PaginatedResults } from "shared/api/types/pagination";
import { ReportResponseType } from "shared/api/types/society/[societyId]/reports/[reportId]";
import PillarForm from "shared/components/pillar-form/PillarForm";
import PillarModalBody, {
  ModalMinWidth,
  PillarModalHandle,
} from "shared/components/pillar-modal/PillarModalBody";
import { CustomReportColumnConfig } from "shared/components/pillar-table/custom-reports/customReportColumnsMapper";
import { usePillarTableQueryContext } from "shared/components/pillar-table/query/PillarTableQueryContext";
import { useCustomRouter } from "shared/context/CustomRouterContext";
import { useSocietySession } from "shared/context/SocietySessionContext";
import { findMatchingFormDesignElement } from "shared/helpers/report/findMatchingFormDesignElement";
import { getTrueKeys } from "shared/helpers/report/getTrueKeys";
import { processFormDesignData } from "shared/helpers/report/processFormDesignData";
import { CustomReportView } from "shared/mappers/database/reports/report";
import ToolTip from "admin/src/ui/components/common/ToolTip";
import { FormDesignElement } from "admin/src/ui/types/reports/types";

type EditCustomProfileReportColumnsModalProps = {
  modalRef: React.RefObject<PillarModalHandle>;
};

const defaultCheckboxesTestId: Record<string, string> = {
  profileId: "test-profile-id",
  "societyUser.lastName": "test-society-user-last-name",
  "societyUser.firstName": "test-society-user-first-name",
};

const EditCustomProfileReportColumnsModal = ({
  modalRef,
}: EditCustomProfileReportColumnsModalProps) => {
  const society = useSocietySession();
  const queryClient = useQueryClient();
  const tableContext = usePillarTableQueryContext();
  const router = useCustomRouter();

  const reportId = router?.query?.reportId;

  const tableQueryValues = tableContext.tableQuery?.data;

  const displayColumns = (
    tableQueryValues as
      | PaginatedResults<ReportResponseType, CustomReportView>
      | undefined
  )?.displayColumns;

  const initialDisplayColumns =
    [...(displayColumns as CustomReportColumnConfig<"string">[])] || [];

  const createObjectFromArray = (paths: string[]) => {
    return paths.reduce((acc, path) => {
      return merge(acc, set({}, path, true));
    }, {});
  };

  const overrideInitialValues = initialDisplayColumns
    ? createObjectFromArray(
        initialDisplayColumns.map((item) => item.cellValuePath),
      )
    : undefined;

  const formDesign = society?.profileForm?.formDesign;
  const formDesignElements = formDesign?.pages?.[0].elements;
  const processedFormDesignData = useMemo(
    () => processFormDesignData(formDesignElements),
    [formDesignElements],
  );

  const profileAddressContactInformationType =
    formDesign?.profileaddresscontactinformation_type;
  const profileAddressContactInformationUse =
    formDesign?.profileaddresscontactinformation_use;

  const lastSingleItemIndex = useMemo(
    () =>
      processedFormDesignData
        ? processedFormDesignData.filter(
            (item) => item && !item.children?.length,
          ).length - 1
        : -1,
    [processedFormDesignData],
  );

  const truncateText = (text: string) => {
    const MAX_WORD_LENGTH = 40;

    if (text.length <= MAX_WORD_LENGTH) return text;

    const wordCutIndex = MAX_WORD_LENGTH / 2;
    return `${text.slice(0, wordCutIndex)}...${text.slice(-wordCutIndex)}`;
  };

  const escapeHTMLElements = (text?: string) => text?.replace(/<[^>]*>/g, "");

  return (
    <PillarModalBody
      ref={modalRef}
      className="w-60 overflow-visible"
      title="Reporting Profile Fields Settings"
      testid="profile-fields-setting-modal"
      minW={ModalMinWidth.Xl_1200}
    >
      <p className="mb-2 -mt-1 text-center text-sm">
        Selected profile fields below will be appended to the beginning of each
        report/table in Pillar Admin. Click Save to proceed
      </p>
      <PillarForm
        overrideInitialValues={{
          ...overrideInitialValues,
        }}
        handleSubmit={async (values) => {
          const formDesignData = processedFormDesignData;
          if (!formDesignData) return;

          const result = getTrueKeys(values).map((item: string) => {
            const matchingElement = findMatchingFormDesignElement(
              formDesignData,
              item,
            );

            return {
              name: matchingElement
                ? matchingElement.parentTitle
                  ? `${matchingElement.parentTitle} - ${matchingElement.title}`
                  : matchingElement.title || matchingElement.name
                : "",
              cellValuePath: item,
              type: matchingElement?.type || "",
            };
          });
          await apiRequestContractHandler(postCustomReportIdContract, {
            params: {
              societyId: society.societyId,
              reportId: Number(reportId)!,
            },
            body: {
              displayColumns: result,
              exportColumns: result,
            },
          });

          queryClient.invalidateQueries({
            queryKey: ["pillarTable", "report", String(reportId)],
          });

          modalRef.current?.close();
        }}
      >
        <div className="grid grid-cols-3 gap-y-1 mb-2">
          {processedFormDesignData?.map((item: FormDesignElement, index) =>
            item?.children ? (
              <div
                className="grid grid-cols-subgrid gap-y-1 col-span-3"
                key={item.name}
              >
                <div className="mt-1.5 col-span-3 inline-flex">
                  <p>
                    {find(
                      profileAddressContactInformationType,
                      (type) => type.value === item.type_id,
                    )?.text || escapeHTMLElements(item.title)}
                  </p>
                  {!!item.use_id?.length && <p className="mx-1">-</p>}
                  {item.use_id?.map((id, index) => {
                    const use = find(
                      profileAddressContactInformationUse,
                      (item) => item.value === id,
                    )?.text;
                    return (
                      <p className="mx-0.5" key={id * index}>
                        {`${use}${
                          index !== item.use_id!.length - 1 ? "," : ""
                        }`}
                      </p>
                    );
                  })}
                </div>
                {item.children.map((subItem: FormDesignElement) => (
                  <div key={subItem.name}>
                    <PillarForm.Checkbox
                      label={
                        <div className="flex flex-col gap-0.5 leading-normal">
                          <span className="text-title text-sm ml-0.5 font-semibold">
                            {escapeHTMLElements(subItem.title) ??
                              truncateText(subItem.name)}
                          </span>
                          <ToolTip
                            className="ml-1 flex items-center"
                            tooltipClassName="mt-0.25 py-1 px-2 bg-neutral-light text-xs rounded-md border border-neutral-mid-300"
                            tooltip={<p className="text-sm">{subItem.name}</p>}
                            placement="top-start"
                          >
                            <p className="text-sm">
                              ({truncateText(subItem.name)})
                            </p>
                          </ToolTip>
                        </div>
                      }
                      name={subItem.name}
                      additionalClasses="!flex-row-reverse items-center justify-end gap-x-1 mb-0.5"
                      disabled={subItem.isDisabled}
                      isChecked={subItem.isChecked}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <>
                <div key={item.name}>
                  <PillarForm.Checkbox
                    label={
                      <div className="flex flex-col gap-0.5 leading-normal">
                        <span className="text-title text-sm ml-0.5 font-semibold">
                          {escapeHTMLElements(item.title) || item.name}
                        </span>
                        <ToolTip
                          className="ml-1 flex items-center"
                          tooltipClassName="mt-0.25 py-1 px-2 bg-neutral-light text-xs rounded-md border border-neutral-mid-300"
                          tooltip={<p className="text-sm">{item.name}</p>}
                          placement="top-start"
                        >
                          <p className="text-sm">({truncateText(item.name)})</p>
                        </ToolTip>
                      </div>
                    }
                    data-testid={
                      lastSingleItemIndex === index
                        ? "test-custom-profile-report-checkbox"
                        : defaultCheckboxesTestId?.[item.name]
                        ? defaultCheckboxesTestId[item.name]
                        : undefined
                    }
                    name={item.name}
                    additionalClasses="!flex-row-reverse items-center justify-end gap-x-1 mb-0.5"
                    disabled={item.isDisabled}
                    isChecked={item.isChecked}
                  />
                </div>
                {lastSingleItemIndex === index && (
                  <div className="col-span-3"></div>
                )}
              </>
            ),
          )}
        </div>

        <div className="flex w-full items-center justify-end gap-x-2">
          <PillarForm.Button
            className="button-regular-neutral-filled"
            label="Cancel"
            onClick={() => modalRef.current?.close()}
          />
          <PillarForm.LoadingSubmitButton
            className="w-fit"
            label="Save"
            disabled={!reportId}
            testid="custom-profile-report-modal-form-submit-button"
          />
        </div>
      </PillarForm>
    </PillarModalBody>
  );
};

export default EditCustomProfileReportColumnsModal;
