import React, { useState, useContext } from "react";
import DotsVerticalIcon from "shared/components/icons/dot/DotsVerticalIcon";
import {
  PillarTableRowContext,
  PillarTableRowContextType,
} from "shared/components/pillar-table/PillarTableRowContext";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "admin/src/ui/components/common/Popover";

export interface PillarTableSubmenuItem<T> {
  label: string;
  onClick: (
    rowContext: PillarTableRowContextType<T>,
    refetchTable: void
  ) => void;
  disabled?: boolean;
  icon?: React.ReactNode;
}

export interface PillarTableSubmenuProps<T> {
  items: PillarTableSubmenuItem<T>[];
  refetchTable: () => void;
}

const PillarTablePopoverMenu = <T,>({
  items,
  refetchTable,
}: PillarTableSubmenuProps<T>) => {
  const rowContext = useContext(PillarTableRowContext);

  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen} placement="bottom-end">
      <PopoverTrigger
        onClick={(e) => {
          e.stopPropagation();
          setOpen((v) => !v);
        }}
      >
        <DotsVerticalIcon />
      </PopoverTrigger>
      <PopoverContent className="pillartable-popover-menu-container z-[100]">
        {items.map((item, index) => (
          <div
            data-testid={`popover-menu-item-${
              !item.disabled ? "enabled" : "disabled"
            }`}
            key={`submenu-item-${index}`}
            className={`pillartable-popover-menu-item-${
              !item.disabled ? "enabled" : "disabled"
            } px-4 py-2 flex items-center gap-x-3`}
            onClick={
              !item.disabled
                ? (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    item.onClick(rowContext!, refetchTable());
                  }
                : undefined
            }
          >
            {item.icon && item.icon}
            {item.label}
          </div>
        ))}
      </PopoverContent>
    </Popover>
  );
};

export default PillarTablePopoverMenu;
