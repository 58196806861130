import { Types as AblyTypes } from "ably";
import { ApiResponse } from "shared/api/api-request-builder";
import { ApiQueryConfig } from "hub/src/api/types";

const GetAblyTokenCreation: ApiQueryConfig<
  ApiResponse<AblyTypes.TokenDetails>,
  { societyId: string },
  Record<string, never>
> = {
  apiPath: "/api/v1/society/{societyId}/ably-create-token-request",
  queryKey: {
    baseQueryKey: ["GetAblyTokenCreation"],
    dynamicQueryKey: ["societyId"],
  },
};

export { GetAblyTokenCreation };
