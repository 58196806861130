import { useQueryClient } from "@tanstack/react-query";
import { InvoiceView } from "shared/mappers/database/accounting/invoice/invoice";
import { InvoiceItemView } from "shared/mappers/database/accounting/invoice/invoice-item";
import { apiRequestContractHandler } from "shared/api/apiRequestContractHandler";
import PillarForm from "shared/components/pillar-form/PillarForm";

import { FormikHelpers } from "formik";
import { useCustomSession } from "shared/context/CustomSessionContext";
import { getSocietyId } from "hub/src/utils/getSocietyId";
import { postSocietyProfileAccountingInvoiceItemDiscountCodeStringContract } from "shared/api/contracts/society/societyId/profiles/profileId/accounting/invoice/invoiceId/item/invoiceItemId/discountCode/discountCodeString";
import PillarModalBody, {
  PillarModalHandle,
} from "shared/components/pillar-modal/PillarModalBody";

interface props {
  modalRef: React.RefObject<PillarModalHandle>;
  invoiceItem: InvoiceItemView | null;
  invoice: InvoiceView;
  refetchInvoice: () => Promise<void>;
}

const ApplyDiscountCodeModal = ({
  modalRef,
  invoiceItem,
  invoice,
  refetchInvoice,
}: props) => {
  const session = useCustomSession();
  const queryClient = useQueryClient();
  type ApplyDiscountCodeForm = {
    discountCodeString: string;
  };

  const handleApplyDiscountCode = async (
    { discountCodeString }: ApplyDiscountCodeForm,
    formikHelpers?: FormikHelpers<ApplyDiscountCodeForm>,
  ) => {
    try {
      await apiRequestContractHandler(
        postSocietyProfileAccountingInvoiceItemDiscountCodeStringContract,
        {
          params: {
            societyId: getSocietyId(),
            profileId: session.profile!.profileId,
            invoiceId: invoice.invoiceId!,
            invoiceItemId: invoiceItem!.invoiceItemId!,
            discountCodeString: discountCodeString,
          },
        },
      );
    } catch (e: any) {
      formikHelpers?.setStatus({ error: e.message });
      formikHelpers?.setSubmitting(false);
      return;
    }
    await refetchInvoice();
    modalRef.current?.close();
  };
  return (
    <PillarModalBody
      ref={modalRef}
      title="Apply Discount Code"
      testid="apply-discount-code-modal"
    >
      <PillarForm<ApplyDiscountCodeForm>
        className="mt-1"
        handleSubmit={handleApplyDiscountCode}
      >
        <div className="flex flex-col items-center ">
          <PillarForm.TextInput
            name="discountCodeString"
            label="Enter Discount Code"
            testid="apply-discount-code-form-discountCodeString"
          />
          <div className="flex w-full justify-end mt-2">
            <PillarForm.LoadingSubmitButton
              label="Apply"
              className="w-full h-6"
              testid="apply-discount-code-modal-form-submit"
            />
          </div>
        </div>
      </PillarForm>
    </PillarModalBody>
  );
};

export default ApplyDiscountCodeModal;
