import Spinner from "admin/src/ui/components/common/Spinner";

interface Props {
  content: string;
}
const Template = ({ content }: Props) => {
  return content ? (
    <div
      dangerouslySetInnerHTML={{ __html: content }}
      className="shadow ring-1 ring-inset ring-neutral-mid-50 p-2"
    />
  ) : (
    <Spinner />
  );
};

export default Template;
