import { SocietyProfileConferenceParamsInput } from "shared/api/types/society/[societyId]/profiles/[profileId]/conference/[conferenceId]/registration/upsert";
import { ConferenceRegistrationView } from "shared/mappers/database/conference/conference-registration";
import { BaseApiContract } from "shared/api/apiRequestContractHandler";
import { HttpMethod } from "shared/api/http";
export const getSocietyProfileConferenceRegistrationUpsertContract: BaseApiContract<
  SocietyProfileConferenceParamsInput,
  undefined,
  ConferenceRegistrationView
> = {
  method: HttpMethod.GET,
  apiRoute:
    "/api/v1/society/[societyId]/profiles/[profileId]/conference/[conferenceId]/registration/upsert",
  paramsType: {} as SocietyProfileConferenceParamsInput,
  responseType: {} as ConferenceRegistrationView,
};
