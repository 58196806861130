import { ComponentPropsWithoutRef, useEffect, useState } from "react";
import withFilterComponent from "shared/components/pillar-table/filters/withFilterComponent";
import { usePillarTableQueryContext } from "shared/components/pillar-table/query/PillarTableQueryContext";

type PillarTableFilterCheckboxProps = Omit<
  ComponentPropsWithoutRef<"input">,
  "onChange" | "value" | "type" | "name" | "title"
> & {
  title: string;
  filterKey: string;
  isRowStyle?: boolean;
};

const PillarTableFilterCheckbox = ({
  filterKey,
  title,
  isRowStyle,
  defaultChecked = false,
  ...props
}: PillarTableFilterCheckboxProps) => {
  const filterContext = usePillarTableQueryContext();
  const { setFilters, filters } = filterContext;

  const [filterValue, setFilterValue] = useState<boolean>(
    defaultChecked ? true : (filters[filterKey] as boolean),
  );

  useEffect(() => {
    if (filters[filterKey] != filterValue) {
      setFilters({ ...filters, [filterKey]: filterValue });
    }
  }, [filterValue]);

  return (
    <div
      className={
        isRowStyle
          ? "flex flex-row justify-center items-center gap-0.5 pb-0.75 pt-0.5"
          : "flex flex-col justify-start items-start"
      }
    >
      <label
        className="pillartable-filters-selectmenu-button-title"
        htmlFor={`filter-checkbox-input-${filterKey}`}
      >
        {title}
      </label>
      <input
        name={`filter-checkbox-input-${filterKey}`}
        title={title}
        checked={filterValue}
        defaultChecked
        type="checkbox"
        onChange={(e) => {
          setFilterValue(e.target.checked);
        }}
        {...props}
      />
    </div>
  );
};

export default withFilterComponent(PillarTableFilterCheckbox);
