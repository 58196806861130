import { ComponentPropsWithoutRef } from "react";
import { twMerge } from "tailwind-merge";

// This icon is taken from ionicons package which is under MIT licence https://github.com/ionic-team/ionicons/blob/main/LICENSE

// https://ionic.io/ionicons type in input chevron-back-outline -> in outline section
const ChevronBackIcon = ({
  className,
  ...props
}: ComponentPropsWithoutRef<"svg">) => {
  const defaultClassnames =
    "icon icon-ionicons icon-ionicons-chevron-back-outline h-2.75 w-2.75";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge(defaultClassnames, className)}
      {...props}
      viewBox="0 0 512 512"
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="48"
        d="M328 112L184 256l144 144"
      />
    </svg>
  );
};

export default ChevronBackIcon;
