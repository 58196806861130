import { society_icon } from "@prisma/client";
import { FunctionComponent } from "react";
import BookOutlineIcon from "shared/components/icons/book/BookOutlineIcon";
import CashOutlineIcon from "shared/components/icons/CashOutlineIcon";
import ChatBubblesOutlineIcon from "shared/components/icons/chat/ChatBubblesOutlineIcon";
import EaselOutlineIcon from "shared/components/icons/EaselOutlineIcon";
import FileTrayFullOutlineIcon from "shared/components/icons/file/FileTrayFullOutlineIcon";
import FileTrayStackedOutlineIcon from "shared/components/icons/file/FileTrayStackedOutlineIcon";
import FolderOpenOutlineIcon from "shared/components/icons/FolderOpenOutlineIcon";
import IdCardOutlineIcon from "shared/components/icons/IdCardOutlineIcon";
import ListOutlineIcon from "shared/components/icons/list/ListOutlineIcon";
import MailOutlineIcon from "shared/components/icons/mail/MailOutlineIcon";
import PeopleOutlineIcon from "shared/components/icons/PeopleOutlineIcon";
import RibbonOutlineIcon from "shared/components/icons/RibbonOutlineIcon";
import TodayOutlineIcon from "shared/components/icons/TodayOutlineIcon";
import { CommonIconProps } from "@parthenon-management/pillar-admin/src/ui/types/icon";

export const hubIconMapper: Record<
  society_icon,
  FunctionComponent<CommonIconProps>
> = {
  [society_icon.bookOutline]: BookOutlineIcon,
  [society_icon.cashOutline]: CashOutlineIcon,
  [society_icon.chatbubblesOutline]: ChatBubblesOutlineIcon,
  [society_icon.easelOutline]: EaselOutlineIcon,
  [society_icon.folderOpenOutline]: FolderOpenOutlineIcon,
  [society_icon.listOutline]: ListOutlineIcon,
  [society_icon.mailOutline]: MailOutlineIcon,
  [society_icon.peopleOutline]: PeopleOutlineIcon,
  [society_icon.ribbonOutline]: RibbonOutlineIcon,
  [society_icon.idCardOutline]: IdCardOutlineIcon,
  [society_icon.fileTrayFullOutline]: FileTrayFullOutlineIcon,
  [society_icon.todayOutline]: TodayOutlineIcon,
  [society_icon.fileTrayStackedOutline]: FileTrayStackedOutlineIcon,
};
