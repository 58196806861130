import React from "react";
import PillarTableExportToExcelDynamicColumn, {
  PillarTableExportToExcelDynamicColumnProps,
} from "./AppExportToExcelDynamicColumn";

export type NewAppExcelToExcelSheetProps<T> = {
  name: string;
  children:
    | React.ReactElement<PillarTableExportToExcelDynamicColumnProps<T>>[]
    | React.ReactElement<PillarTableExportToExcelDynamicColumnProps<T>>
    | React.ReactNode;
  additionalQueryId?: string;
};

export const AppExportToExcelSheet = <T,>({
  children,
}: NewAppExcelToExcelSheetProps<T>) => {
  const childArray = React.Children.toArray(children);
  if (
    childArray.some(
      (child) =>
        React.isValidElement(child) &&
        child.type !== PillarTableExportToExcelDynamicColumn,
    )
  ) {
    throw new Error(
      "Only PillarTableExportToExcelDynamicColumn must be used inside NewAppExcelToExcelSheet",
    );
  }

  return <>{children}</>;
};
