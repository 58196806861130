import React, { ComponentPropsWithoutRef } from "react";

//TODO: Remove label and just pass through children.
type PillarFormButtonProps = ComponentPropsWithoutRef<"button"> & {
  label?: string;
  icon?: React.ReactElement;
  testid?: string;
};

const PillarFormButton = ({
  label,
  icon,
  testid,
  className,
  ...props
}: PillarFormButtonProps) => {
  return (
    <button
      type="button"
      className={`flex items-center justify-center gap-x-0.5 button-regular-general-filled ${
        className ? className : ""
      }`}
      data-testid={testid}
      {...props}
    >
      {icon && icon}
      {label && label}
      {props.children}
    </button>
  );
};

export default PillarFormButton;
