import { FormikValues, useFormikContext } from "formik";

import useOutsideClick from "admin/src/ui/hooks/useOutsideClick";
import { ComponentPropsWithoutRef, useCallback, useRef, useState } from "react";
import { HexAlphaColorPicker } from "react-colorful";
import PillarFormInputWrapper from "shared/components/pillar-form/components/PillarFormInputWrapper";

export type PillarFormHexColorInputProps = {
  name: string;
  label: string;
  labelClassName?: string;
  additionalClasses?: string;
  onChange?: (hsl: string) => void;
} & Omit<ComponentPropsWithoutRef<"div">, "id" | "name" | "value" | "type">;

const PillarFormHexColorInput = ({
  name,
  label,
  labelClassName,
  additionalClasses,
  onChange,
  className,
  ...props
}: PillarFormHexColorInputProps) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const popover = useRef(null);
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useOutsideClick(popover, close);

  return (
    <PillarFormInputWrapper
      name={name}
      labelClassName={labelClassName}
      label={label}
      additionalClasses={additionalClasses}
    >
      <div className={`relative ${className ?? ""}`} {...props}>
        <div
          className="colorpicker-swatch w-15 h-3"
          style={{
            backgroundColor: `${values[name]}`,
          }}
          onClick={() => toggle(true)}
        />

        {isOpen && (
          <div
            className="colorpicker-popover left-0 absolute z-10"
            ref={popover}
          >
            <HexAlphaColorPicker
              color={values[name]}
              onChange={(hexColor) => {
                setFieldValue(name, hexColor);
                onChange?.(hexColor);
              }}
            />
          </div>
        )}
      </div>
    </PillarFormInputWrapper>
  );
};

export default PillarFormHexColorInput;
