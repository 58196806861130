import { SocietyProfileInvoiceParamsInput } from "shared/api/types/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]";
import { SocietyProfileEmailBodyInput } from "shared/api/types/society/[societyId]/profiles/[profileId]/email/[emailHistoryId]";
import { EmailPreviewOutput } from "shared/api/types/society/[societyId]/profiles/[profileId]/email/[emailHistoryId]";
import { BaseApiContract } from "shared/api/apiRequestContractHandler";
import { HttpMethod } from "shared/api/http";
export const getSocietyProfileAccountingInvoiceEmailContract: BaseApiContract<
  SocietyProfileInvoiceParamsInput,
  undefined,
  EmailPreviewOutput
> = {
  method: HttpMethod.GET,
  apiRoute:
    "/api/v1/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]/email",
  paramsType: {} as SocietyProfileInvoiceParamsInput,
  responseType: {} as EmailPreviewOutput,
};
export const postSocietyProfileAccountingInvoiceEmailContract: BaseApiContract<
  SocietyProfileInvoiceParamsInput,
  SocietyProfileEmailBodyInput,
  void
> = {
  method: HttpMethod.POST,
  apiRoute:
    "/api/v1/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]/email",
  paramsType: {} as SocietyProfileInvoiceParamsInput,
  bodyType: {} as SocietyProfileEmailBodyInput,
  responseType: undefined as unknown as void,
};
