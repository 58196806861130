/* eslint-disable @typescript-eslint/no-explicit-any */
import { DateTime } from "luxon";
import React from "react";
import EducationInstanceQuestionComponent, {
  EducationInstanceQuestionComponentProps,
} from "shared/components/survey-js-form/questions/education/EducationInstanceQuestionComponent";
import { SessionView } from "shared/mappers/database/session/session";
import { ElementFactory, Question, Serializer, settings } from "survey-core";
import { SurveyCreator } from "survey-creator-react";
import { ReactQuestionFactory } from "survey-react-ui";

export const CUSTOM_TYPE_EDUCATION_INSTANCE = "education_instance";

export class EducationInstanceQuestionModel extends Question {
  getType() {
    return CUSTOM_TYPE_EDUCATION_INSTANCE;
  }
}

(settings as { customIcons: { [index: string]: string } }).customIcons[
  "icon-" + CUSTOM_TYPE_EDUCATION_INSTANCE
] = "icon-search";

export const EducationInstanceQuestionProperties = {
  hoursRequired: "hoursRequired",
  completedAgoDays: "completedAgoDays",
  completedAgoMonths: "completedAgoMonths",
  completedAgoYears: "completedAgoYears",
  completedAfter: "completedAfter",
  // place,
};

export type EducationInstanceQuestionRendererInputProps = {
  creator: SurveyCreator;
  isDisplayMode: boolean;
  question: Question;
  session: SessionView;
  baseUrl: string;
};

export const EducationInstanceQuestionRenderer = (
  props: EducationInstanceQuestionRendererInputProps
) => {
  props.question.titleLocation = "hidden";
  props.question.errorLocation = "bottom";

  const existingValue = props.question.value;
  const hoursRequired = props.question.getPropertyValue(
    EducationInstanceQuestionProperties.hoursRequired
  );
  const completedAgoDays = props.question.getPropertyValue(
    EducationInstanceQuestionProperties.completedAgoDays
  );
  const completedAgoMonths = props.question.getPropertyValue(
    EducationInstanceQuestionProperties.completedAgoMonths
  );
  const completedAgoYears = props.question.getPropertyValue(
    EducationInstanceQuestionProperties.completedAgoYears
  );

  const completedAfter = props.question.getPropertyValue(
    EducationInstanceQuestionProperties.completedAfter
  );

  return EducationInstanceQuestionComponent({
    handleChanged: (val) => {
      props.question.value = val;
    },
    session: props.session,
    existingValue,
    hoursRequired,
    completedAfter: completedAfter
      ? completedAfter
      : completedAgoDays || completedAgoYears || completedAgoMonths
      ? DateTime.now()
          .minus({ years: completedAgoYears ?? 0 })
          .minus({ months: completedAgoMonths ?? 0 })
          .minus({ days: completedAgoDays ?? 0 })
          .toISODate()
      : undefined,
  } as EducationInstanceQuestionComponentProps);
};

export const RegisterEducationInstanceQuestionType = (
  session: SessionView,
  baseUrl?: string
) => {
  ElementFactory.Instance.registerElement(
    CUSTOM_TYPE_EDUCATION_INSTANCE,
    (name) => {
      return new EducationInstanceQuestionModel(name);
    }
  );

  Serializer.addClass(
    CUSTOM_TYPE_EDUCATION_INSTANCE,
    [{ name: "height", default: "200px", category: "layout" }],
    function () {
      return new EducationInstanceQuestionModel("");
    },
    "question"
  );

  Serializer.addProperty(CUSTOM_TYPE_EDUCATION_INSTANCE, {
    name: EducationInstanceQuestionProperties.hoursRequired,
    displayName: "Require hours for certification",
    type: "boolean",
    isRequired: false,
    default: false,
    category: "General",
  });
  Serializer.addProperty(CUSTOM_TYPE_EDUCATION_INSTANCE, {
    name: EducationInstanceQuestionProperties.completedAgoDays,
    displayName: "Only show Certifications Completed this many days ago",
    type: "number",
    isRequired: false,
    category: "General",
  });
  Serializer.addProperty(CUSTOM_TYPE_EDUCATION_INSTANCE, {
    name: EducationInstanceQuestionProperties.completedAgoMonths,
    displayName: "Only show Certifications Completed this many months ago",
    type: "number",
    isRequired: false,
    category: "General",
  });
  Serializer.addProperty(CUSTOM_TYPE_EDUCATION_INSTANCE, {
    name: EducationInstanceQuestionProperties.completedAgoYears,
    displayName: "Only show Certifications Completed this many years ago",
    type: "number",
    isRequired: false,
    category: "General",
  });

  Serializer.addProperty(CUSTOM_TYPE_EDUCATION_INSTANCE, {
    name: EducationInstanceQuestionProperties.completedAfter,
    displayName: "Only show Certifications Completed after this date",
    type: "string",
    isRequired: false,
    category: "General",
  });

  Serializer.getProperty(CUSTOM_TYPE_EDUCATION_INSTANCE, "name").readOnly =
    true;

  ReactQuestionFactory.Instance.registerQuestion(
    CUSTOM_TYPE_EDUCATION_INSTANCE,
    (props: any) => {
      return React.createElement(EducationInstanceQuestionRenderer, {
        ...props,
        session,
        baseUrl,
      });
    }
  );
};
