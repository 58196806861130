import { SocietyProfileSubmissionDefintionSubmissionInstanceParamsInput } from "shared/api/types/society/[societyId]/profiles/[profileId]/submission/[submissionDefinitionId]/instance/[submissionInstanceId]/index";
import { SocietyWorkflowBodyInput } from "shared/api/types/society/[societyId]/workflow/[workflowId]/instance";
import { SubmissionInstanceView } from "shared/mappers/database/submission/instance/submission-instance";
import { BaseApiContract } from "shared/api/apiRequestContractHandler";
import { HttpMethod } from "shared/api/http";
export const getSocietyProfileDefinitionSubmissionInstanceIdContract: BaseApiContract<
  SocietyProfileSubmissionDefintionSubmissionInstanceParamsInput,
  undefined,
  SubmissionInstanceView
> = {
  method: HttpMethod.GET,
  apiRoute:
    "/api/v1/society/[societyId]/profiles/[profileId]/submission/[submissionDefinitionId]/instance/[submissionInstanceId]",
  paramsType:
    {} as SocietyProfileSubmissionDefintionSubmissionInstanceParamsInput,
  responseType: {} as SubmissionInstanceView,
};
export const postSocietyProfileDefinitionSubmissionInstanceIdContract: BaseApiContract<
  SocietyProfileSubmissionDefintionSubmissionInstanceParamsInput,
  SocietyWorkflowBodyInput,
  SubmissionInstanceView
> = {
  method: HttpMethod.POST,
  apiRoute:
    "/api/v1/society/[societyId]/profiles/[profileId]/submission/[submissionDefinitionId]/instance/[submissionInstanceId]",
  paramsType:
    {} as SocietyProfileSubmissionDefintionSubmissionInstanceParamsInput,
  bodyType: {} as SocietyWorkflowBodyInput,
  responseType: {} as SubmissionInstanceView,
};
