import * as Yup from "yup";

export const SocietyParamsSchema = Yup.object().shape({
  societyId: Yup.number().required(),
});

Yup.addMethod(Yup.string, "emailPossiblyWithName", function (errorMessage) {
  return this.test("email-possibly-with-name", errorMessage, function (value) {
    const { path, createError } = this;

    if (!value || value === "") {
      return true;
    }

    // Regex to match "Name <email@example.com>"
    const regex = /^[^<>]+<\s*[\w.%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}\s*>$/;

    // Or just an email address
    const emailRegex = /^[\w.%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}$/;

    return (
      regex.test(value) ||
      emailRegex.test(value) ||
      createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(
  Yup.mixed,
  "forbidden",
  function (errorMessage = "This field is not allowed") {
    return this.transform((value) => (value === "" ? undefined : value)) // Transform empty strings to undefined
      .test("forbidden", errorMessage, function (value) {
        const { path, createError } = this;
        return (
          value === undefined || createError({ path, message: errorMessage })
        );
      })
      .notRequired(); // Ensure it's treated as optional when using forbidden
  },
);
