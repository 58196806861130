import { FormikValues, useFormikContext } from "formik";
import { get } from "lodash";
import { ComponentPropsWithoutRef, useEffect } from "react";
import PillarFormInputWrapper, {
  PillarFormInputWrapperProps,
} from "./PillarFormInputWrapper";

export type PillarFormUSDInputProps = PillarFormInputWrapperProps &
  Omit<ComponentPropsWithoutRef<"input">, "id" | "name" | "value" | "type">;

const PillarFormUSDInput = ({
  name,
  label,
  labelClassName,
  additionalClasses,
  className,
  onChange,
  ...props
}: PillarFormUSDInputProps) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const value = get(values, name);
  useEffect(() => {
    setFieldValue(name, value);
  }, [value]);

  return (
    <PillarFormInputWrapper
      name={name}
      labelClassName={labelClassName}
      label={label}
      additionalClasses={additionalClasses}
    >
      <div className="relative flex items-center">
        <div className="text-subtle absolute left-1">$</div>
        <input
          type="number"
          value={value}
          name={name}
          id={name}
          data-testid={props.testid}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
            if (onChange) {
              onChange(e);
            }
          }}
          className={`appearance-textfield w-full !pl-2.5 ${className ?? ""}`}
          {...props}
        />
        <div className="text-subtle absolute right-5">USD</div>
      </div>
    </PillarFormInputWrapper>
  );
};

export default PillarFormUSDInput;
