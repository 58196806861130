import {
  TokenQuery,
  SpecialResponse,
} from "shared/api/types/society/[societyId]/token/[tokenValue]";
import { BaseApiContract } from "shared/api/apiRequestContractHandler";
import { HttpMethod } from "shared/api/http";
export const getSocietyTokenTokenValueContract: BaseApiContract<
  TokenQuery,
  undefined,
  SpecialResponse
> = {
  method: HttpMethod.GET,
  apiRoute: "/api/v1/society/[societyId]/token/[tokenValue]",
  paramsType: {} as TokenQuery,
  responseType: {} as SpecialResponse,
};
