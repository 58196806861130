import { ErrorMessage, FormikContextType, useFormikContext } from "formik";
import { get } from "lodash";
import { useEffect, useState } from "react";
import {
  OptionsOrGroups,
  GroupBase,
  ActionMeta,
  OnChangeValue,
  MultiValue,
  SingleValue,
} from "react-select";
import { AsyncPaginate, LoadOptions } from "react-select-async-paginate";
import {
  PaginatedResults,
  PaginationRequest,
} from "shared/api/types/pagination";
import { FiltersRequest } from "shared/filter-where-clause";
import PillarFormInputWrapper, {
  PillarFormInputWrapperProps,
} from "./PillarFormInputWrapper";

export type PillarFormProfileSelectValueType<ValueType> = {
  value: ValueType;
  label: string;
};
export type PillarFormProfileSelectProps<ENTITYTYPE, ValueType, FormType> =
  PillarFormInputWrapperProps & {
    queryCallback: (
      filters: FiltersRequest,
      pagination: PaginationRequest
    ) => Promise<PaginatedResults<ENTITYTYPE, any>>;

    onChangeCallback?: (
      newValue: OnChangeValue<
        PillarFormProfileSelectValueType<ValueType>,
        boolean
      >,
      actionMeta: ActionMeta<PillarFormProfileSelectValueType<ValueType>>,
      queryResults: ENTITYTYPE[],
      formikContext: FormikContextType<FormType>
    ) => void;

    labelCallback: (
      entity: ENTITYTYPE,
      queryResponse?: PaginatedResults<ENTITYTYPE>
    ) => string;

    valueCallback: (
      entity: ENTITYTYPE,
      queryResponse?: PaginatedResults<ENTITYTYPE>
    ) => ValueType;

    additionalOptionsEndOfListCallback?: (
      queryResponse?: PaginatedResults<ENTITYTYPE>
    ) => PillarFormProfileSelectValueType<ValueType>[];

    additionalOptionsStartOfListCallback?: (
      queryResponse?: PaginatedResults<ENTITYTYPE>
    ) => PillarFormProfileSelectValueType<ValueType>[];

    placeholder?: string;
    additionalClasses?: string;
    presetOptions?: PillarFormProfileSelectValueType<ValueType>[];
    pageSize?: number;
    isMultiple?: boolean;
    cacheKey?: string[];
    selectedValuesKey?: string;
  };

const PillarFormProfileSelect = <ENTITYTYPE, ValueType, FormType>({
  name,
  label,
  labelClassName,
  additionalClasses,
  queryCallback,
  onChangeCallback,
  labelCallback,
  valueCallback,
  additionalOptionsEndOfListCallback,
  additionalOptionsStartOfListCallback,
  placeholder = "Search...",
  pageSize = 25,
  isMultiple = false,
  selectedValuesKey,
  presetOptions,
  cacheKey,
  testid,
  ...props
}: PillarFormProfileSelectProps<ENTITYTYPE, ValueType, FormType>) => {
  const [queryResults, setQuryResults] = useState<ENTITYTYPE[]>([]);
  const [fieldOptions, setFieldOptions] = useState<
    PillarFormProfileSelectValueType<ValueType>[]
  >(presetOptions || []);
  const [valueState, setValueState] = useState<
    | MultiValue<PillarFormProfileSelectValueType<ValueType>>
    | SingleValue<PillarFormProfileSelectValueType<ValueType>>
    | undefined
  >();
  const formikContext = useFormikContext<FormType>();

  const {
    setFieldTouched,
    setFieldValue,
    values,
    errors,
    touched,
    getFieldHelpers,
  } = formikContext;

  useEffect(() => {
    let value = get(values, name);
    if (isMultiple && Array.isArray(value)) {
      value = value
        .map((item: any) =>
          fieldOptions.find((option) => option?.value === item)
        )
        .filter(
          (option): option is PillarFormProfileSelectValueType<ValueType> =>
            !!option
        );
    } else {
      value = fieldOptions.find((option) => option?.value === value) || null;
    }
    setValueState(value);
  }, []);

  const onChange = (
    newValue: OnChangeValue<
      PillarFormProfileSelectValueType<ValueType>,
      boolean
    >,
    actionMeta: ActionMeta<PillarFormProfileSelectValueType<ValueType>>
  ) => {
    if (onChangeCallback) {
      onChangeCallback(newValue, actionMeta, queryResults, formikContext);
    }

    // Update valueState directly
    setValueState(newValue);

    if (isMultiple) {
      if (Array.isArray(newValue)) {
        setFieldValue(
          name,
          newValue.map((option) => option.value)
        );

        if (selectedValuesKey) {
          setFieldValue(selectedValuesKey, newValue);
        }
      } else {
        setFieldValue(name, []);
      }
    } else {
      if (newValue && "value" in newValue) {
        setFieldValue(name, newValue.value);

        if (selectedValuesKey) {
          setFieldValue(selectedValuesKey, newValue);
        }
      } else {
        setFieldValue(name, null);
      }
    }
  };

  const loadOptions: LoadOptions<
    PillarFormProfileSelectValueType<ValueType>,
    GroupBase<PillarFormProfileSelectValueType<ValueType>>,
    number
  > = async (
    inputValue: string,
    options: OptionsOrGroups<
      PillarFormProfileSelectValueType<ValueType>,
      GroupBase<PillarFormProfileSelectValueType<ValueType>>
    >,
    page = 1
  ) => {
    const queryResponse: PaginatedResults<ENTITYTYPE> = await queryCallback(
      {
        text: inputValue,
      },
      {
        page,
        pageSize,
      }
    );
    setQuryResults([...queryResults, ...queryResponse.results]);

    const responseOptions: PillarFormProfileSelectValueType<ValueType>[] =
      queryResponse.results.map((entity) => ({
        value: valueCallback(entity, queryResponse),
        label: labelCallback(entity, queryResponse),
      }));

    const additionalOptionsEndOfList =
      additionalOptionsEndOfListCallback?.(queryResponse) || [];

    const additionalOptionsStartOfList =
      additionalOptionsStartOfListCallback?.(queryResponse) || [];

    const formattedOptions: PillarFormProfileSelectValueType<ValueType>[] = [
      ...additionalOptionsStartOfList,
      ...responseOptions,
      ...additionalOptionsEndOfList,
    ];
    setFieldOptions(formattedOptions);
    const hasMore =
      queryResponse.page < Math.ceil(queryResponse.totalResults / pageSize);
    return {
      options: formattedOptions,
      hasMore,
      additional: page + 1,
    };
  };

  return (
    <>
      <PillarFormInputWrapper
        label={label}
        labelClassName={labelClassName}
        name={name}
        additionalClasses={additionalClasses}
        testid={testid ? `${testid}-select` : undefined}
        {...props}
      >
        <AsyncPaginate
          cacheUniqs={cacheKey}
          loadOptions={loadOptions}
          debounceTimeout={500}
          onChange={onChange}
          value={valueState}
          placeholder={placeholder}
          isMulti={isMultiple}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              borderRadius: "5px",
              border: "1px solid #ccc",
            }),
            option: (baseStyles) => ({
              ...baseStyles,
              cursor: "pointer",
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              backgroundColor: "#f0f0f0",
            }),
          }}
        />
        <ErrorMessage
          component="span"
          className="text-danger-small"
          name={name}
        />
      </PillarFormInputWrapper>
    </>
  );
};

export default PillarFormProfileSelect;
