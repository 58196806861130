import React, { ReactElement } from "react";
import { PillarTableCheckboxColumnProps } from "shared/components/pillar-table/display-columns/PillarTableCheckboxColumn";
import { PillarTableColumnProps } from "shared/components/pillar-table/PillarTableColumn";

export enum PillarDataTableTestModes {
  exists = "exists",
  notExists = "notExists",
}
export type PillarDataTableColumn = {
  position?: number; // Note how position is optional
  testid: string;
  value: string;
  mode: PillarDataTableTestModes;
};
export type PillarDataTableRow = {
  position?: number; // Note how position is optional
  columns:
    | PillarDataTableColumn[]
    | {
        columns: PillarDataTableColumn[];
        nestedRows: PillarDataTableRow[];
      };
  mode: PillarDataTableTestModes;
};

export type ExpectPillarDataTableProps = {
  columns?: PillarDataTableColumn[];
  rows?: PillarDataTableRow[];
  exactMatch?: boolean; //default false
  contains?: string;
  name?: string;
};

export enum PillarDataTableFixedColumn {
  left = "left",
  right = "right",
}


export const getFixedColumnStyle = <T>(
  column: ReactElement<PillarTableColumnProps<T> | PillarTableCheckboxColumnProps<T>>,
  index: number,
  fixedColumnWidths: number[],
  customStyles?: string,
): React.CSSProperties => {
  const commonStyles: React.CSSProperties = {
    position: "sticky",
    zIndex: 10,
    backgroundColor: customStyles ?? "hsl(200, 10%, 90%)",
  };

  let leftOffset = 0;
  let rightOffset = 0;

  if (column.props?.fixedColumn === PillarDataTableFixedColumn.left) {
    leftOffset = fixedColumnWidths
      .slice(0, index)
      .reduce((acc, width) => (width ? acc + Math.ceil(width) : acc), 0);

    return {
      ...commonStyles,
      left: `${leftOffset}px`,
      borderRight: "2px solid",
    };
  }

  if (column.props?.fixedColumn === PillarDataTableFixedColumn.right) {
    rightOffset = fixedColumnWidths
      .slice(index + 1)
      .reduce((acc, width) => acc + (width || 0), 0);

    return {
      ...commonStyles,
      right: `${rightOffset}px`,
      borderLeft: "2px solid",
    };
  }

  return {
    zIndex: 7,
  };
};
