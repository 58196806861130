import { Combobox } from "@headlessui/react";
import { society_icon } from "@prisma/client";
import { ComponentPropsWithoutRef, ReactNode } from "react";
import PillarFormCombobox, {
  ComboboxOptionProps,
  PillarFormComboboxOption,
} from "shared/components/pillar-form/components/PillarFormCombobox";

export type IconOption = { id: string; name: society_icon; icon: ReactNode };

//Changed export type ExtractProps<T> = T extends ComponentType<infer P> ? P : T;
//To ComponentPropsWithoutRef
//Maybe should use ComponentProps instead
//Or leave it as is
export type PillarFormIconPickerProps = Omit<
  ComponentPropsWithoutRef<typeof Combobox>,
  "value" | "id" | "refName" | "as" | "multiple" | "onChange"
> & {
  name: string;
  additionalClasses?: string;
  iconsList: IconOption[];
  label: string;
  placeholder?: string;
};

const IconOptionElement = ({ option }: ComboboxOptionProps<IconOption>) => {
  return (
    <Combobox.Option
      value={option.value}
      className="cursor-pointer rounded hover:bg-society-50 p-1 flex items-center text-neutral-mid-850"
    >
      {option.rawData?.icon || ""}
      <span className="ml-1 text-neutral-mid-850">{option.display}</span>
    </Combobox.Option>
  );
};

const PillarFormIconPicker = ({
  name,
  iconsList,
  label,
  additionalClasses,
  placeholder,
}: PillarFormIconPickerProps) => {
  const staticIconOptionsList: PillarFormComboboxOption<IconOption>[] =
    iconsList.map((icon: IconOption) => ({
      key: `${icon.id}`,
      display: icon.name,
      value: icon.id,
      rawData: icon,
    }));

  return (
    <PillarFormCombobox<IconOption>
      name={name}
      label={label}
      comboOptionQuery={staticIconOptionsList}
      OptionComponent={IconOptionElement}
      multiple={false}
      placeholder={placeholder}
      additionalClasses={additionalClasses}
      nullable={true}
    />
  );
};

export default PillarFormIconPicker;
