import {
  apiRequestContractHandler,
} from "shared/api/apiRequestContractHandler";
import { HttpMethod } from "shared/api/http";
import { postSocietyFilesContract } from "shared/api/contracts/society/societyId/files";
import { postSocietyGroupFilesContract } from "shared/api/contracts/society/societyId/groups/groupId/files";
import { postSocietyProfileFilesContract } from "shared/api/contracts/society/societyId/profiles/profileId/files";
import { postSocietyReviewSessionFilesContract } from "shared/api/contracts/society/societyId/review/session/reviewSessionId/files";
import { postSocietySubmissionDefinitionFilesContract } from "shared/api/contracts/society/societyId/submission/submissionDefinitionId/files";
export type FileReferenceId = {
  profileId?: number;
  groupId?: number;
  reviewSessionId?: number;
  submissionDefinitionId?: number;
  societyId: number;
};
type UploadFileContractType =
  | typeof postSocietySubmissionDefinitionFilesContract
  | typeof postSocietyReviewSessionFilesContract
  | typeof postSocietyProfileFilesContract
  | typeof postSocietyGroupFilesContract
  | typeof postSocietyFilesContract;
export const uploadFileContractHandler = async({
  file,
  contract,
  societyId,
  isPublic = false,
  ...fileReferenceId
}: {
  file: File;
  contract: UploadFileContractType;
  isPublic?: boolean;
} & FileReferenceId) => {
  const newFileResponse = (await apiRequestContractHandler(contract, {
    params: {
      societyId,
      ...fileReferenceId,
    },
    body: {
      isPublic,
      originalFileName: file.name,
      fileType: file.type,
    },
  }));
  await fetch(newFileResponse.signedUrl, {
    method: HttpMethod.PUT,
    headers: {
      "Content-Type": file.type,
    },
    body: file,
  });
  return newFileResponse;
};
