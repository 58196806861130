import { Prisma, payment_method } from "@prisma/client";
import Handlebars from "handlebars";
import { get as lodashGet } from "lodash";
import { DateTime } from "luxon";
import { createElement } from "react";
import { renderToString } from "react-dom/server";
import { hubIconMapper } from "shared/components/icons/hub-icons-mapper";
import { renderTemplateUtil } from "shared/components/template/renderTemplateUtil";
import { LuxonDateFormats } from "shared/datetime/dateFormats";
import { formatUsdString } from "shared/helpers/invoice/formatUsdString";
import { generateInitials } from "shared/helpers/profile/generateInitials";
import { getDisplayNameHelper } from "shared/helpers/profile/getDisplayNameHelper";
import { getFormattedDegreeData } from "shared/helpers/profile/getFormattedDegreeData";
import { getFormattedMembershipData } from "shared/helpers/profile/getFormattedMembershipData";
import { splitFullName } from "shared/helpers/profile/splitFullName";
import { hslToHex } from "shared/helpers/theme/color-helpers";
import {
  InvoiceDto,
  InvoiceView,
  mapInvoiceDtoToInvoiceView,
} from "shared/mappers/database/accounting/invoice/invoice";
import { AddressView } from "shared/mappers/database/address";
import { CertificationDefinitionDto } from "shared/mappers/database/certification/certification-definition";
import {
  mapProfileDtoToProfileView,
  ProfileDto,
  ProfileView,
} from "shared/mappers/database/profile/profile";
import {
  mapSocietyDtoToSocietyView,
  SocietyDto,
  SocietyView,
} from "shared/mappers/database/society/society";
import { SocietyTheme } from "shared/mappers/database/society/theme";
import {
  mapSubmissionInstanceDtoToSubmissionInstanceView,
  SubmissionInstanceDto,
  SubmissionInstanceView,
} from "shared/mappers/database/submission/instance/submission-instance";
import { getPaymentDetailsByMethod } from "shared/components/template/extractPaymentCardTypeAndNumber";

type HandlebarsTemplateHelper = {
  name: string;
  helper: Handlebars.HelperDelegate;
};

type RegisterHandleBarHelpersProps = {
  timeZone: string;
};

export const registerHandleBarHelpers = ({
  timeZone = "America/Chicago",
}: RegisterHandleBarHelpersProps) => {
  const helpersRaw: HandlebarsTemplateHelper[] = [
    {
      name: "greaterThan",
      helper: function (arg1, arg2, options) {
        return arg1 > arg2 ? options.fn(this) : options.inverse(this);
      },
    },
    {
      name: "ifEqualString",
      helper: function (arg1, arg2, options) {
        if (arg1 === arg2) {
          return options.fn(this); // Render the block
        } else {
          return options.inverse(this); // Render the else block
        }
      },
    },
    {
      name: "ifArrayHasObjectWithValue",
      helper: (array: any[], property: string, value: string, options) => {
        if (array?.some((item) => lodashGet(item, property) === value)) {
          return options.fn(this);
        } else {
          return options.inverse(this);
        }
      },
    },
    {
      name: "ifNotEqual",
      helper: function (arg1, arg2, options) {
        if (arg1 !== arg2) {
          return options.fn(this);
        } else {
          return options.inverse(this);
        }
      },
    },
    {
      name: "formatUsdString",
      helper: (text: string) => formatUsdString(Number(text)),
    },
    {
      name: "formatDate",
      helper: (iso: string) => {
        const dateTime = DateTime.fromISO(iso);
        if (!dateTime.isValid) {
          return "";
        }
        return dateTime.setZone(timeZone).toFormat(LuxonDateFormats.D);
      },
    },
    {
      name: "formatTimeRangeToDisplay",
      helper: (startTime: string, endTime: string, timezone: string) => {
        if (!startTime || !endTime) return "";
        const start = DateTime.fromISO(startTime, { zone: timezone }).toFormat(
          "h:mma"
        );
        const end = DateTime.fromISO(endTime, { zone: timezone }).toFormat(
          "h:mma"
        );
        return `${start.toLowerCase()} - ${end.toLowerCase()}`;
      },
    },
    {
      name: "formatLuxonDate",
      helper: (date: string, format: LuxonDateFormats, timezone?: string) => {
        if (!date) {
          return "";
        }
        const isoDate = DateTime.fromISO(date.toString(), {
          zone: timezone ?? timeZone,
        });
        if (!isoDate.isValid) {
          return "";
        }

        return isoDate.toFormat(format);
      },
    },
    {
      name: "includes",
      helper: (array: string[], value: string) => array?.includes(value),
    },
    {
      name: "hasTag",
      helper: (array: { tagName: string }[] | undefined, value: string) =>
        Array.isArray(array)
          ? array.some(
              (item) => item?.tagName?.toLowerCase() === value.toLowerCase()
            )
          : false,
    },
    {
      name: "formatAddress",
      helper: (address: AddressView) => {
        if (!address) return "";
        let addressString = "";
        if (address.line1) {
          addressString += "<div>" + address.line1 + "</div>";
        }
        if (address.line2) {
          addressString += "<div>" + address.line2 + "</div>";
        }
        if (address.line3) {
          addressString += "<div>" + address.line3 + "</div>";
        }
        if (address.city || address.state || address.postalCode) {
          addressString += "<div>";
        }
        if (address.city) {
          addressString += address.city;
        }
        if (address.city && address.state) {
          addressString += ", ";
        }
        if (address.state) {
          addressString += address.state;
        }
        if ((address.state || address.city) && address.postalCode) {
          addressString += " ";
        }
        if (address.postalCode) {
          addressString += address.postalCode;
        }
        if (address.city || address.state || address.postalCode) {
          addressString += "</div>";
        }
        return addressString;
      },
    },
    {
      name: "formattedMembershipData",
      helper: (profile: ProfileView) =>
        JSON.stringify(getFormattedMembershipData(profile)),
    },
    {
      name: "themeToHex",
      helper: (
        societyTheme: SocietyTheme[],
        hueModify?: string,
        saturationModify?: string,
        lightnessModify?: string
      ) => hslToHex(societyTheme, hueModify, saturationModify, lightnessModify),
    },
    {
      name: "isNotLastFiltered",
      helper: function (currentIndex, items, property, value) {
        const filtered = items.filter((item: any) => item[property] === value);
        return currentIndex < filtered.length - 1;
      },
    },
    {
      name: "getMembershipDescription",
      helper: function (type, description, options) {
        const validDescriptions = [
          "Lifetime Member",
          "Distinguished Member",
          "Member Emeritus",
        ];
        if (
          type === "DynamicMembership" &&
          validDescriptions.includes(description)
        ) {
          return options.fn(this);
        } else {
          return options.inverse(this);
        }
      },
    },
    {
      name: "getFormattedDegreeData",
      helper: (degree, value) => {
        return getFormattedDegreeData(degree, value);
      },
    },
    {
      name: "getDisplayNameHelper",
      helper: (profile: ProfileView, includeAffiliation?: boolean) =>
        getDisplayNameHelper(profile, includeAffiliation),
    },
    {
      name: "getInitials",
      helper: (profile: ProfileView) => {
        const { firstName, lastName } = splitFullName(
          getDisplayNameHelper(profile)
        );
        return generateInitials(firstName, lastName);
      },
    },
    {
      name: "getIcon",
      helper: (iconName: keyof typeof hubIconMapper) => {
        const IconComponent = hubIconMapper[iconName];
        if (!IconComponent) {
          throw new Error(`Icon "${iconName}" not found.`);
        }
        return renderToString(createElement(IconComponent));
      },
    },
    {
      name: "withArray",
      helper: (options: Handlebars.HelperOptions) => {
        // Create a new "frame" from existing data to avoid mutating the parent context
        const frame = Handlebars.createFrame(options.data);

        // Initialize an array inside this frame
        frame._myArray = [];

        // Pass control to the block with the new frame
        return options.fn(options.data.root, { data: frame });
      },
    },
    {
      name: "arrayPush",
      helper: (
        options: Handlebars.HelperOptions & { hash: { value: any } }
      ) => {
        // Grab the array from the data frame
        const frame = options.data as any;
        const valueToPush = options.hash.value;

        // Push the item
        frame._myArray.push(valueToPush);
      },
    },
    {
      name: "arrayJoin",
      helper: (
        options: Handlebars.HelperOptions & { hash: { separator?: string } }
      ) => {
        const frame = options.data as any;
        const separator = options.hash.separator ?? ", ";

        return frame._myArray.join(separator);
      },
    },
    {
      name: "formatPaymentDetails",
      helper: (additionalInfo: any, method: payment_method) => {
        return getPaymentDetailsByMethod(additionalInfo, method);
      },
    },
    {
      name: "assign",
      helper: function (
        varName: string,
        value: any,
        options: Handlebars.HelperOptions
      ) {
        if (!options.data.root) {
          options.data.root = {};
        }
        options.data.root[varName] = value;
      },
    },
  ];

  /**
   * push: helper that takes a "value" hash param and pushes it into the current array.
   * Usage in template: {{push value="FCSI"}}
   */
  // const push = ;

  /**
   * join: helper that joins all items in the array into a string, with a separator.
   * Usage in template: {{join separator=", "}}
   */
  // const join = ;
  // Handlebars.registerHelper("push", push);
  // Handlebars.registerHelper("join", join);

  helpersRaw.forEach(({ name, helper }) => {
    Handlebars.registerHelper(name, helper);
  });
};
export type StandardTemplateVariables = {
  society?: SocietyView;
  profile?: ProfileView;
  displayName?: string;
  invoice?: InvoiceView;
  submissionInstance?: SubmissionInstanceView;
  tokenUrl?: string;
  message?: string;
  logo?: string;
  additionalTemplateVariables?: Prisma.InputJsonValue;
  invoiceReceiptHtml?: string;
  certificationDefinition?: CertificationDefinitionDto;
};
export type GetStandardTemplateVariablesUtilProps = {
  society?: SocietyDto;
  profile?: ProfileDto;
  invoice?: InvoiceDto;
  submissionInstance?: SubmissionInstanceDto;
  tokenUrl?: string;
  message?: string;
  logoBase64?: string;
  logoUrl?: string;
  additionalTemplateVariables?: Prisma.InputJsonValue;
  invoiceReceiptHtmlTemplate?: string;
  certificationDefinition?: CertificationDefinitionDto;
  timeZone?: string;
};
export const getStandardTemplateVariablesUtil = ({
  profile,
  society,
  invoice,
  submissionInstance,
  tokenUrl,
  message,
  logoBase64,
  logoUrl,
  additionalTemplateVariables,
  invoiceReceiptHtmlTemplate,
  certificationDefinition,
  timeZone = "America/Chicago",
}: GetStandardTemplateVariablesUtilProps) => {
  registerHandleBarHelpers({ timeZone });
  const standardVariables: StandardTemplateVariables = {
    society: society ? mapSocietyDtoToSocietyView(society) : undefined,
    profile: profile ? mapProfileDtoToProfileView(profile) : undefined,
    displayName: profile ? getDisplayNameHelper(profile) : undefined,
    invoice: invoice ? mapInvoiceDtoToInvoiceView(invoice) : undefined,
    submissionInstance: submissionInstance
      ? mapSubmissionInstanceDtoToSubmissionInstanceView(submissionInstance)
      : undefined,
    tokenUrl,
    message,
    logo: logoBase64 || logoUrl,
    additionalTemplateVariables,
    certificationDefinition,
  };
  if (invoiceReceiptHtmlTemplate && invoice) {
    standardVariables.invoiceReceiptHtml = renderTemplateUtil({
      template: invoiceReceiptHtmlTemplate,
      templateData: standardVariables,
    });
  }
  return standardVariables;
};
