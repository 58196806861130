import { ContactInformationView } from "shared/mappers/database/profile/contact-information";
import {
  ProfileDto,
  ProfileView,
} from "shared/mappers/database/profile/profile";

export const defaultContactInformationFromProfile = ({
  profile,
  use,
  type,
}: {
  profile: ProfileDto | ProfileView | undefined;
  use?: "Billing" | "Shipping" | string;
  type?: "Postal" | "Physical" | string;
}): ContactInformationView | null => {
  return (
    profile?.contactInformation?.find((ci) => {
      if (use && !ci.use?.some((ciUse) => ciUse.description == use)) {
        return false;
      }
      if (type && ci.type?.description !== type) {
        return false;
      }
      if (!use && !type) {
        return true;
      }
    }) ??
    profile?.contactInformation?.[0] ??
    null
  );
};
