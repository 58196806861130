import { ComponentPropsWithoutRef } from "react";
import { twMerge } from "tailwind-merge";

// This icon is taken from ionicons package which is under MIT licence https://github.com/ionic-team/ionicons/blob/main/LICENSE

// https://ionic.io/ionicons type in input tray -> in outline section
const FileTrayFullOutlineIcon = ({
  className,
  ...props
}: ComponentPropsWithoutRef<"svg">) => {
  const defaultClassnames =
    "icon icon-ionicons icon-ionicons-file-tray-full h-2.75 w-2.75";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge(defaultClassnames, className)}
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        d="M384 80H128c-26 0-43 14-48 40L48 272v112a48.14 48.14 0 0048 48h320a48.14 48.14 0 0048-48V272l-32-152c-5-27-23-40-48-40z"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth="32"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
        d="M48 272h144M320 272h144M192 272a64 64 0 00128 0M144 144h224M128 208h256"
      />
    </svg>
  );
};

export default FileTrayFullOutlineIcon;
