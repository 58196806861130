import React from "react";
import PillarTableCheckboxColumn, {
  PillarTableCheckboxColumnProps,
} from "shared/components/pillar-table/display-columns/PillarTableCheckboxColumn";
import { PillarTableColumnProps } from "shared/components/pillar-table/PillarTableColumn";
import { PillarTableRowContext } from "shared/components/pillar-table/PillarTableRowContext";
import PillarTableTextCellContent from "shared/components/pillar-table/cell-content/PillarTableTextCellContent";
import PillarTableCellEdit from "shared/components/pillar-table/edit/PillarTableCellEdit";
import EllipsisTooltip from "shared/components/common/EllipsisTooltip";

const PillarTableCellContent = <T extends object>(
  props: PillarTableColumnProps<T> | PillarTableCheckboxColumnProps<T>,
) => {
  const rowContext = React.useContext(PillarTableRowContext);

  const { children, ...restProps } = props;
  if ("formValueProperty" in restProps && restProps.formValueProperty) {
    return (
      <PillarTableCheckboxColumn
        formValueProperty={restProps.formValueProperty}
        rowValueProperty={restProps.rowValueProperty}
        row={rowContext}
      />
    );
  }

  if ("children" in props === false) {
    return (
      <EllipsisTooltip className={restProps?.contentClassName || ""}>
        <PillarTableTextCellContent<T> {...(restProps ?? null)} />
      </EllipsisTooltip>
    );
  }

  if (typeof children === "function") {
    return (
      <EllipsisTooltip className={restProps?.contentClassName || ""}>
        {children(rowContext?.rowValue)}
      </EllipsisTooltip>
    );
  }

  const cellEditComponentElement = React.Children.toArray(children).find(
    (child) =>
      React.isValidElement<PillarTableColumnProps<T>>(child) &&
      child.type === PillarTableCellEdit,
  ) as React.ReactElement | undefined;

  const validChildren = React.Children.toArray(children)
    .filter(
      (child) =>
        React.isValidElement<PillarTableColumnProps<T>>(child) &&
        child.type !== PillarTableCellEdit,
    )
    .map((child) =>
      React.isValidElement<PillarTableColumnProps<T>>(child)
        ? React.cloneElement(child, {
            ...restProps,
            children: child.props.children,
            valueProperty: child.props.valueProperty ?? restProps.valueProperty,
          })
        : child,
    );
  if (
    validChildren.length === 1 &&
    React.isValidElement<PillarTableColumnProps<T>>(validChildren[0])
  ) {
    return (
      <EllipsisTooltip className={restProps?.contentClassName || ""}>
        {React.cloneElement(validChildren[0], {
          ...restProps,
          cellEditComponent: cellEditComponentElement
            ? React.cloneElement(cellEditComponentElement, {
                ...restProps,
                children: cellEditComponentElement.props.children,
                valueProperty:
                  cellEditComponentElement.props.valueProperty ??
                  restProps.valueProperty,
              })
            : undefined,
        })}
      </EllipsisTooltip>
    );
  }

  return (
    <EllipsisTooltip className={restProps?.contentClassName || ""}>
      {React.cloneElement(<PillarTableTextCellContent<T> />, {
        ...restProps,
        cellEditComponent: cellEditComponentElement
          ? React.cloneElement(cellEditComponentElement, {
              ...restProps,
              children: cellEditComponentElement.props.children,
              valueProperty:
                cellEditComponentElement.props.valueProperty ??
                restProps.valueProperty,
            })
          : null,
      })}
    </EllipsisTooltip>
  );
};

export default PillarTableCellContent;
