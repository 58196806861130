import { useCustomRouter } from "shared/context/CustomRouterContext";
import React, { useMemo } from "react";
import { RouteParams } from "shared/api/types/society/[societyId]/app-route-config";
import { AppRouteConfigView } from "shared/mappers/database/app-route/app-route-config/app-route-config";
import { useParams } from "react-router";
import { determinParamType } from "hub/src/context/UserHubCustomRouterProvider";

export interface HubRouteConfig<RP = RouteParams>
  extends Partial<Omit<AppRouteConfigView, "routeParams">> {
  routeParams?: RP;
}

type RouteConfigWrapperProps = {
  children: React.ReactNode;
  routeConfig?: Partial<AppRouteConfigView>;
};

export const RouteConfigWrapper = ({
  children,
  routeConfig,
}: RouteConfigWrapperProps) => {
  const router = useCustomRouter();
  const dynamicParams = useParams<{ [key: string]: string }>();

  const mergedRouteParams = useMemo(
    () => ({
      ...(router.state || {}),
      ...Object.entries(dynamicParams).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: determinParamType(value),
        }),
        {},
      ),
      ...routeConfig?.routeParams,
    }),
    [routeConfig, router.state, dynamicParams],
  );
  return React.cloneElement(children as React.ReactElement<HubRouteConfig>, {
    ...routeConfig,
    routeParams: {
      ...mergedRouteParams,
    },
  });
};

export default RouteConfigWrapper;
