import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { SocietyProfileEmailBodyInput } from "shared/api/types/society/[societyId]/profiles/[profileId]/email/[emailHistoryId]";
import { EmailPreviewOutput } from "shared/api/types/society/[societyId]/profiles/[profileId]/email/[emailHistoryId]";
import PillarForm from "shared/components/pillar-form/PillarForm";
import PillarModalBody, {
  PillarModalBodyProps,
  PillarModalHandle,
} from "shared/components/pillar-modal/PillarModalBody";
import { SocietyProfileEmailBodySchema } from "shared/yup-validators/schemas/society/[societyId]/profiles/[profileId]/societyProfileEmailSchema";
import Spinner from "admin/src/ui/components/common/Spinner";
import Template from "admin/src/ui/components/common/Template";

interface EmailPreviewModalProps extends PillarModalBodyProps {
  modalRef: React.RefObject<PillarModalHandle>;
  initalEmail?: string;
  emailPreviewCallout: () => Promise<EmailPreviewOutput>;
  emailSubmit: (props: SocietyProfileEmailBodyInput) => Promise<void>;
  queryKey: string;
}

const EmailPreviewModal = ({
  modalRef,
  queryKey,
  emailPreviewCallout,
  emailSubmit,
  initalEmail,
}: EmailPreviewModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const emailTemplatePreview = useQuery<EmailPreviewOutput | undefined>(
    ["emailTemplatePreview", queryKey],
    () => emailPreviewCallout(),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      enabled: isOpen,
    },
  );

  return (
    <PillarModalBody
      ref={modalRef}
      title="Send Email"
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    >
      <div className="p-0.5 flex flex-col">
        <div>
          {/* TODO: Use new formik components */}
          {emailTemplatePreview.isLoading || !emailTemplatePreview.data ? (
            <>
              <Spinner />
            </>
          ) : (
            <Template content={emailTemplatePreview.data.body!} />
          )}
          <PillarForm<SocietyProfileEmailBodyInput>
            yupValidation={SocietyProfileEmailBodySchema}
            handleSubmit={async (values) => {
              await emailSubmit(values);
              modalRef.current?.close();
            }}
            overrideInitialValues={{ toEmail: initalEmail }}
            className="space-y-1 mt-1"
          >
            <PillarForm.TextInput
              label="Email"
              name="toEmail"
              testid="email-preview-modal-toEmail"
            />
            <PillarForm.FieldTextArray
              label="CC"
              name="ccEmail"
              testid="email-preview-modal-ccEmail"
            />
            <div className="flex justify-end">
              <PillarForm.LoadingSubmitButton testid="email-preview-modal-send-button">
                Send
              </PillarForm.LoadingSubmitButton>
            </div>
          </PillarForm>
        </div>
      </div>
    </PillarModalBody>
  );
};
export default EmailPreviewModal;
