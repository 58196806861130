// PillarTable/usePillarTableColumnsValues.tsx

import { UseQueryResult } from "@tanstack/react-query";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ReportResponseType } from "shared/api/types/society/[societyId]/reports/[reportId]";
import { CustomReportView } from "shared/mappers/database/reports/report";
import PillarTable from "shared/components/pillar-table/PillarTable";
import PillarTableCheckboxColumn from "shared/components/pillar-table/display-columns/PillarTableCheckboxColumn";

import PillarTableColumn from "shared/components/pillar-table/PillarTableColumn";
import { PaginatedResults } from "shared/api/types/pagination";
import {
  CustomReportColumnConfig,
  ColumnTypes,
  customReportColumnsMapper,
} from "shared/components/pillar-table/custom-reports/customReportColumnsMapper";
import { PillarDataTableFixedColumn } from "shared/components/pillar-table/helpers/addFixedStyleToColumn";

interface UsePillarTableColumnsProps<T> {
  children: React.ReactNode;
  checkboxColumnFormValueProperty?: boolean | string;
  checkboxColumnRowValueProperty?: boolean | string;
  pagination?: boolean;
  tableQuery?: UseQueryResult<T[] | PaginatedResults<T>, unknown>;
}

export const usePillarTableColumnsValues = <T extends object>({
  children,
  checkboxColumnFormValueProperty = false,
  checkboxColumnRowValueProperty = false,
  pagination,
  tableQuery,
}: UsePillarTableColumnsProps<T>) => {
  const [displayColumns, setDisplayColumns] = useState<CustomReportColumnConfig[]>([]);
  const [fixedColumnWidths, setFixedColumnWidths] = useState<number[]>([]);
  const columnRefs = useRef<(HTMLDivElement | null)[]>([]);

  // Extract the `customReportResponse` early
  const customReportResponse = tableQuery?.data as PaginatedResults<
    ReportResponseType,
    CustomReportView
  >;
  const displayColumnsBeforeMapping = customReportResponse?.displayColumns;

  const canNotHaveBothPaginationAndCheckboxColumn = ({
    checkboxColumnRowValueProperty,
    pagination,
    checkboxColumnFormValueProperty,
  }: {
    checkboxColumnFormValueProperty?: boolean | string;
    checkboxColumnRowValueProperty?: boolean | string;
    pagination?: boolean;
  }) => {
    if (
      (checkboxColumnRowValueProperty || checkboxColumnFormValueProperty) &&
      pagination
    ) {
      throw new Error(
        "You cannot have both a checkbox column and pagination enabled.",
      );
    }
    if (checkboxColumnFormValueProperty && !checkboxColumnRowValueProperty) {
      throw new Error(
        "You must provide a row value property for the checkbox column.",
      );
    }
    if (!checkboxColumnFormValueProperty && checkboxColumnRowValueProperty) {
      throw new Error(
        "You must provide a form value property for the checkbox column.",
      );
    }
  };

  useEffect(() => {
    if (displayColumnsBeforeMapping) {
      setDisplayColumns(displayColumnsBeforeMapping);
    }
  }, [displayColumnsBeforeMapping]);

  canNotHaveBothPaginationAndCheckboxColumn({
    checkboxColumnFormValueProperty,
    checkboxColumnRowValueProperty,
    pagination,
  });

  let columns: React.ReactElement[] =
    React.Children.map(children, (child): React.ReactElement | null => {
      if (
        React.isValidElement(child) &&
        (child.type === PillarTableColumn || child.type === PillarTableCheckboxColumn)
      ) {
        return child;
      }
      return null;
    })?.filter((e) => e !== null) ?? [];

  const sortColumns = (columns: React.ReactElement[]): React.ReactElement[] => {
    const priority = {
      [PillarDataTableFixedColumn.left]: -1,
      [PillarDataTableFixedColumn.right]: 1,
      default: 0,
    };

    return columns.sort((a, b) => {
      const fixedA: PillarDataTableFixedColumn | "default" =
        a.props.fixedColumn || "default";
      const fixedB: PillarDataTableFixedColumn | "default" =
        b.props.fixedColumn || "default";

      return (
        (priority[fixedA] || priority.default) -
        (priority[fixedB] || priority.default)
      );
    });
  };

  if (columns.length === 0 && displayColumnsBeforeMapping?.length) {
    columns = displayColumns.map((customReportColumnConfig:CustomReportColumnConfig) => (
      <PillarTable.Column key={customReportColumnConfig.name} heading={customReportColumnConfig.name}>
        {(rowValue) => {
          const pipe =
            customReportColumnsMapper[customReportColumnConfig.type] ??
            customReportColumnsMapper[ColumnTypes.string];

          const cellValue = pipe(
            { ...customReportColumnConfig, rowValue } as any,
          );
          return <>{cellValue ?? ""}</>
        }}
      </PillarTable.Column>
    ));
  }
  columns = sortColumns(columns);

  const updateWidths = () => {
    const widths: number[] = columnRefs.current.map(
      (ref) => ref?.getBoundingClientRect().width || 0,
    );
    setFixedColumnWidths(widths);
  };

  useLayoutEffect(() => {
    updateWidths();
    setTimeout(() => {
      updateWidths();
    }, 300);
  }, []);

  if (
    checkboxColumnFormValueProperty &&
    typeof checkboxColumnFormValueProperty === "string" &&
    checkboxColumnRowValueProperty &&
    typeof checkboxColumnRowValueProperty === "string"
  ) {
    columns.unshift(
      (
        <PillarTableCheckboxColumn
          formValueProperty={checkboxColumnFormValueProperty}
          rowValueProperty={checkboxColumnRowValueProperty}
          isCheckbox
        />
      ) as React.ReactElement,
    );
  }

  return {
    columns,
    fixedColumnWidths,
    columnRefs,
    displayColumns,
    setDisplayColumns,
  };
};
