import { useState, useEffect } from "react";

type Props = {
  initialSeconds: number;
  callback: () => void;
};

export const useSecondsTimerWithCallback = ({
  initialSeconds,
  callback,
}: Props) => {
  const [time, setTime] = useState<number>(initialSeconds);

  useEffect(() => {
    if (time === 0) {
      callback();
      return;
    }

    const countdownInterval = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(countdownInterval);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [time, callback]);

  return { time };
};
