import PillarFormInputWrapper, {
  PillarFormInputWrapperProps,
} from "./PillarFormInputWrapper";
import { FormikValues, useFormikContext } from "formik";
import { get } from "lodash";
import React, { ComponentPropsWithoutRef } from "react";

export type PillarFormJSONInputProps = PillarFormInputWrapperProps &
  Omit<
    ComponentPropsWithoutRef<"textarea">,
    "id" | "name" | "value" | "type"
  > & {
    collapse?: boolean;
  };

const PillarFormJSONInput = ({
  name,
  label,
  labelClassName,
  className,
  onChange,
  collapse = false,
  ...props
}: PillarFormJSONInputProps) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const value = get(values, name);
  return (
    <PillarFormInputWrapper
      name={name}
      labelClassName={labelClassName}
      label={label}
      collapse={collapse}
    >
      <textarea
        className={`w-full ${className ?? ""}`}
        rows={10}
        id={name}
        value={value}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
          if (onChange) onChange(e);
        }}
        {...props}
      />
    </PillarFormInputWrapper>
  );
};

export default PillarFormJSONInput;
