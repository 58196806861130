import { MapperInt } from "shared/mappers/database/MapperInt";
import { FilterChoice } from "shared/filter-where-clause";
export interface OrderByConfig {
  [key: string]: OrderByOption;
}

export interface OrderByOption {
  path: string;
  staticOrderType?: keyof typeof OrderTypes;
  additionalSorts?: OrderByOption[];
  default?: keyof typeof OrderTypes;
}
export enum OrderTypes {
  asc = "asc",
  desc= "desc",
};
export interface OrderByRequest {
  orderBy?: string;
  orderType?: keyof typeof OrderTypes;
}
export type SumResult = {
  sum: number;
};
export interface PaginationRequest extends OrderByRequest{
  page: number;
  pageSize: number;
};
export type DynamicFilterChoices = {
  [key: string]: FilterChoice[];
};
export type PaginatedResults<T, ExtraPropsT = Record<string, never>> = {
  results: T[];
  page: number;
  totalResults: number;
  pageSize: number;
  dynamicFilterChoices?: DynamicFilterChoices;
} & ExtraPropsT;

export const mapPaginatedResults = <
  InputType,
  OutputType,
  ExtraPropsInputType = Record<string, never>,
  ExtraPropsOutputType = Record<string, never>,
>(params: {
  results?: InputType[];
  paginationRequest?: PaginationRequest;
  paginatedResults?: PaginatedResults<InputType, ExtraPropsInputType>;
  totalResults?: number;
  resultsMapper: MapperInt<InputType, OutputType>;
  extraProps?: ExtraPropsInputType;
  extraPropsMapper?: (extraProps: ExtraPropsInputType) => ExtraPropsOutputType;
  dynamicFilterChoices?: DynamicFilterChoices;
}): PaginatedResults<OutputType, ExtraPropsOutputType> => {
  if (typeof params.totalResults !== "number" && !params.paginatedResults) {
    throw new Error("Either totalResults or paginatedResults must be provided");
  }
  if (!params.results && params.paginatedResults) {
    params.results = params.paginatedResults.results;
  }
  const mappedResults = params.results!.map(params.resultsMapper);

  if (!params.extraProps && params.paginatedResults) {
    //We need to pass the extra props to the extraPropsMapper if it exists
    params.extraProps = params.paginatedResults;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const extraProps: ExtraPropsOutputType =
    params.extraPropsMapper && params.extraProps
      ? params.extraPropsMapper(params.extraProps)
      : ({} as ExtraPropsOutputType);

  const mappedResponse: PaginatedResults<OutputType, ExtraPropsOutputType> = {
    dynamicFilterChoices: params.dynamicFilterChoices,
    ...params.paginatedResults, //If there are any extra props that not being mapped, they will be copied over
    page: params.paginationRequest?.page ?? params.paginatedResults?.page ?? 1,
    totalResults:
      params.totalResults ??
      params.paginatedResults?.totalResults ??
      params.results!.length,
    pageSize:
      params.paginationRequest?.pageSize ??
      params.paginatedResults?.pageSize ??
      params.results!.length,
    results: mappedResults,
    ...extraProps,
  };

  return mappedResponse;
};
