export const getCustomQuestionProfileAddressContactInformation = (
  questionName: string
) => {
  const items = [
    { key: "email", title: "Email" },
    { key: "phone", title: "Phone number" },
    { key: "fax", title: "Fax" },
    { key: "country", title: "Country" },
    { key: "region", title: "Region" },
    { key: "state", title: "State" },
    { key: "city", title: "City" },
    { key: "postal_code", title: "Zip / Postal Code" },
    { key: "line1", title: "Line 1" },
    { key: "line2", title: "Line 2" },
    { key: "line3", title: "Line 3" },
  ];

  return items.map(({ key, title }) => ({
    name: `${questionName}.${key}`,
    title,
    type: "string",
  }));
};
