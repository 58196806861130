import { SocietyProfileInvoiceParamsInput } from "shared/api/types/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]";
import {
  SocietyProfileInvoicePaymentBodyInput,
  ProcessPaymentOutput,
} from "shared/api/types/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]/payment";
import { BaseApiContract } from "shared/api/apiRequestContractHandler";
import { HttpMethod } from "shared/api/http";
export const postSocietyProfileAccountingInvoicePaymentContract: BaseApiContract<
  SocietyProfileInvoiceParamsInput,
  SocietyProfileInvoicePaymentBodyInput,
  ProcessPaymentOutput
> = {
  method: HttpMethod.POST,
  apiRoute:
    "/api/v1/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]/payment",
  paramsType: {} as SocietyProfileInvoiceParamsInput,
  bodyType: {} as SocietyProfileInvoicePaymentBodyInput,
  responseType: {} as ProcessPaymentOutput,
};
