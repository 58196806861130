import { getCustomQuestionProfileAddressContactInformation } from "shared/helpers/report/getCustomQuestionProfileAddressContactInformation";
import { custom_question_login_details } from "shared/components/survey-js-form/questions/login-details/registerLoginDetailsQuestion";
import { custom_question_profile_address_contact_information } from "shared/components/survey-js-form/questions/profile-address-contact-information/registerProfileAddressContactInformationQuestion";
import { FormDesignElement } from "admin/src/ui/types/reports/types";
import { FormDesign } from "shared/components/survey-js-form/types/form-design";
import { formDesignTypeConverter } from "shared/helpers/report/formDesignTypeConverter";

export const processFormDesignData = (formDesignElements: FormDesign) => {
  const mapper: Record<
    string,
    (element: FormDesignElement) => FormDesignElement
  > = {
    [custom_question_login_details]: (element) => ({
      name: "login_details.user_name",
      title: element.title,
      type: formDesignTypeConverter(element.type),
    }),
    [custom_question_profile_address_contact_information]: (element) => ({
      title: element.title,
      name: element.name,
      use_id: element.use_id,
      type_id: element.type_id,
      type: formDesignTypeConverter(element.type),
      children: getCustomQuestionProfileAddressContactInformation(element.name),
    }),
    elements: (element) => ({
      title: element.title,
      name: element.name,
      type: formDesignTypeConverter(element.type),
      children: flattenChildren(element.name, element.elements!),
    }),
  };

  const flattenChildren = (
    questionName: string,
    items: FormDesignElement[]
  ): FormDesignElement[] => {
    return items.flatMap((item) => {
      if (item.elements) {
        return flattenChildren(`${questionName}.${item.name}`, item.elements);
      }
      return {
        name: `${questionName}.${item.name}`,
        title: item.title,
        type: formDesignTypeConverter(item.type),
      };
    });
  };

  const defaultElements: FormDesignElement[] = [
    {
      name: "profileId",
      title: "Profile Id",
      type: "string",
    },
    {
      name: "societyUser.lastName",
      title: "Last Name",
      type: "string",
    },
    {
      name: "societyUser.firstName",
      title: "First Name",
      type: "string",
    },
  ];

  const processed: FormDesignElement[] | undefined = defaultElements.concat(
    formDesignElements.map((element: FormDesignElement) => {
      const mapperFn =
        mapper[element.name] ||
        mapper[element.type] ||
        (element.elements ? mapper.elements : undefined);
      if (mapperFn) return mapperFn(element);

      return {
        name: element.name,
        title: element.title,
        type: formDesignTypeConverter(element.type),
      };
    })
  );

  const singleItems = processed?.filter(
    (item) => item && !item.children?.length
  );
  const groups = processed
    ?.filter((item) => item && item.children?.length)
    .sort((a, b) => b.children!.length - a.children!.length);

  if (!singleItems || !groups) return;
  return [...singleItems, ...groups];
};
