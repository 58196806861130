type NestedObject = { [key: string]: boolean | NestedObject };

export const getTrueKeys = (obj: NestedObject, prefix = ""): string[] => {
  const trueKeys: string[] = [];

  for (const key in obj) {
    const value = obj[key];
    const fullKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "object" && value !== null) {
      // Recursively handle nested objects
      trueKeys.push(...getTrueKeys(value, fullKey));
    } else if (value === true) {
      // Add key if its value is true
      trueKeys.push(fullKey);
    }
  }

  return trueKeys;
};
