import { FormikValues, useFormikContext } from "formik";
import { get } from "lodash";
import { ComponentPropsWithoutRef } from "react";
import PillarFormInputWrapper, {
  PillarFormInputWrapperProps,
} from "./PillarFormInputWrapper";

export type PillarFormEmailInputProps = PillarFormInputWrapperProps &
  Omit<ComponentPropsWithoutRef<"input">, "id" | "name" | "value" | "type">;

//TODO: We should rename "additionalClasses" to "containerClasses" or something more descriptive.
const PillarFormEmailInput = ({
  name,
  label,
  labelClassName,
  additionalClasses,
  className,
  testid,
  onChange,
  ...props
}: PillarFormEmailInputProps) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const value = get(values, name);
  return (
    <PillarFormInputWrapper
      name={name}
      labelClassName={labelClassName}
      label={label}
      additionalClasses={`${additionalClasses ?? ""}`}
    >
      <input
        type="email"
        className={`${className ? className : ""}`}
        value={value}
        name={name}
        id={name}
        data-testid={testid}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
          if (onChange) {
            onChange(e);
          }
        }}
        {...props}
      />
    </PillarFormInputWrapper>
  );
};

export default PillarFormEmailInput;
