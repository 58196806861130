import { SocietyProfileParamsInput } from "shared/api/types/society/[societyId]/profiles/[profileId]";
import { EducationInstanceView } from "shared/mappers/database/education/education-instance";
import { BaseApiContract } from "shared/api/apiRequestContractHandler";
import { PaginatedResults } from "shared/api/types/pagination";
import { HttpMethod } from "shared/api/http";
export const getSocietyProfileEducationInstanceContract: BaseApiContract<
  SocietyProfileParamsInput,
  undefined,
  PaginatedResults<EducationInstanceView>
> = {
  method: HttpMethod.GET,
  apiRoute:
    "/api/v1/society/[societyId]/profiles/[profileId]/education/instance",
  paramsType: {} as SocietyProfileParamsInput,
  responseType: {} as PaginatedResults<EducationInstanceView>,
};
