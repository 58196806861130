import { PaginationRequest } from "shared/api/types/pagination";

import { useQuery } from "@tanstack/react-query";
import { FormikValues, useFormikContext } from "formik";

import { FiltersRequest } from "shared/filter-where-clause";

import { SpinnerSize } from "admin/src/constants/enums/spinner-sizes";
import { PaginatedResults } from "shared/api/types/pagination";
import { PillarFormSelectOption } from "shared/components/pillar-form/components/PillarFormSelectMenu";
import { ComponentPropsWithoutRef } from "react";
import PillarFormInputWrapper, {
  PillarFormInputWrapperProps,
} from "./PillarFormInputWrapper";
import Spinner from "admin/src/ui/components/common/Spinner";
export type PillarFormSelectMenuPaginatedProps<T> =
  PillarFormInputWrapperProps & {
    name: string;
    valueProperty: string;
    displayProperty: string;
    selectOptionQuery: (
      filters: FiltersRequest,
      pagination?: PaginationRequest
    ) => Promise<PaginatedResults<T>>;
    defaultOption?: PillarFormSelectOption;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChangeCallbackAction?: (currentValue?: any) => void;
  } & Omit<
      ComponentPropsWithoutRef<"select">,
      "selected" | "id" | "name" | "value" | "type"
    >;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PillarFormSelectMenuPaginated = <T extends { [key: string]: any }>(
  props: PillarFormSelectMenuPaginatedProps<T>
) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const value = String(
    props.name.split(".").reduce((obj, key) => obj?.[key], values)
  );
  const selectOptions = useQuery(
    ["selectOptions", props.name],
    () => props.selectOptionQuery({}, undefined),
    {
      select: (responseData): PillarFormSelectOption[] => {
        return responseData.results.map((option: T) => ({
          key:
            option[props.valueProperty ?? "id"] ??
            option[props.valueProperty ?? "value"],
          label: option[props.displayProperty ?? "display"],
          value: option[props.valueProperty ?? "value"],
        }));
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
  if (selectOptions.isLoading || !selectOptions.data) {
    return <Spinner spinnerSize={SpinnerSize.Small} />;
  }
  return (
    <PillarFormInputWrapper
      label={props.label}
      labelClassName={props.labelClassName}
      name={props.name}
    >
      <select
        id={props.name}
        data-testid={props.testid}
        name={props.name}
        className={props.additionalClasses}
        value={value ?? props?.defaultOption?.value ?? ""}
        onChange={(e) => {
          setFieldValue(props.name, e.target.value);
          if (props.onChangeCallbackAction) {
            props.onChangeCallbackAction(e.target.value);
          }
        }}
        required={props.required}
      >
        {props.defaultOption && (
          <option
            key="default"
            value={props.defaultOption.value}
            disabled={
              props.defaultOption.disabled === undefined
                ? false
                : props.defaultOption.disabled
            }
          >
            {props.defaultOption.label}
          </option>
        )}
        {selectOptions.data.map((option: PillarFormSelectOption) => (
          <option key={option.value} value={option.value ?? ""}>
            {option.label}
          </option>
        ))}
      </select>
    </PillarFormInputWrapper>
  );
};

export default PillarFormSelectMenuPaginated;
