import { SocietyWorkflowBodyInput } from "shared/api/types/society/[societyId]/workflow/[workflowId]/instance";
import { SocietyWorkflowInstanceActionEndpointParamsInput } from "shared/api/types/society/[societyId]/workflow/[workflowId]/instance/[workflowInstanceId]/[workflowActionEndpoint]";
import { WorkflowInstanceStopped } from "shared/types/workflow";
import { BaseApiContract } from "shared/api/apiRequestContractHandler";
import { HttpMethod } from "shared/api/http";
export const postSocietyInstanceWorkflowActionEndpointContract: BaseApiContract<
  SocietyWorkflowInstanceActionEndpointParamsInput,
  SocietyWorkflowBodyInput,
  WorkflowInstanceStopped
> = {
  method: HttpMethod.POST,
  apiRoute:
    "/api/v1/society/[societyId]/workflow/[workflowId]/instance/[workflowInstanceId]/[workflowActionEndpoint]",
  paramsType: {} as SocietyWorkflowInstanceActionEndpointParamsInput,
  bodyType: {} as SocietyWorkflowBodyInput,
  responseType: {} as WorkflowInstanceStopped,
};
