import { get } from "lodash";
import React, { ComponentPropsWithoutRef, useEffect, useState } from "react";
import FileExportIcon from "shared/components/icons/file/FileExportIcon";
import PillarForm from "shared/components/pillar-form/PillarForm";
import * as XLSX from "xlsx";
import {
  QueryTableData,
  StaticTableData,
} from "shared/components/pillar-table/PillarTable";
import { preprocessData } from "shared/components/pillar-table/PillarTableBody";
import { usePillarTableQueryContext } from "shared/components/pillar-table/query/PillarTableQueryContext";
import { PaginationRequest } from "shared/api/types/pagination";
import { FiltersRequest } from "shared/filter-where-clause";
import { PillarTableExportToExcelColumnProps } from "shared/components/pillar-table/export/PillarTableExportToExcelColumn";
import { PillarTableExportToExcelContext } from "shared/components/pillar-table/export/PillarTableExportToExcelContext";

export type PillarTableExportToExcelProps<T extends object> = Omit<
  ComponentPropsWithoutRef<"button">,
  "onClick"
> & {
  worksheetName: string;
  children:
    | Array<React.ReactElement<PillarTableExportToExcelColumnProps<T>>>
    | React.ReactElement<PillarTableExportToExcelColumnProps<T>>;
  dataQuery?: QueryTableData<T> | StaticTableData<T>;
  filters?: FiltersRequest;
  rowFilters?: number[];
  filterRowValueProperty?: string;
};

const PillarTableExportToExcel = <T extends object>({
  worksheetName,
  children,
  rowFilters,
  filterRowValueProperty,
  dataQuery: dataQueryProps,
  filters: filtersProps,
  ...props
}: PillarTableExportToExcelProps<T>) => {
  const queryContext = usePillarTableQueryContext();

  if (!!rowFilters !== !!filterRowValueProperty) {
    throw new Error("If you use one filter property then you must use both");
  }

  const filters = {
    ...filtersProps,
    ...queryContext.filters,
    paymentId: Array.from(rowFilters ?? []),
  };
  const totalResults = rowFilters?.length ?? queryContext.totalResults;
  const tableRowResults = rowFilters ?? queryContext.tableRowResults;

  const dataQuery = dataQueryProps ?? queryContext.dataQuery;
  const multiRowPropertyKeys = queryContext.multiRowPropertyKeys;

  const [estimatedFileSizeKb, setEstimatedFileSizeKb] = useState<number>(0);
  const [averageRowSizeBytes, setAverageRowSizeBytes] = useState<number>(0);

  useEffect(() => {
    // Convert the data to a JSON string and calculate byte size
    const dataSize = new TextEncoder().encode(
      JSON.stringify(queryContext.tableRowResults ?? [])
    ).length;
    // Divided by the number of results to get the average size of each row
    const averageRowSizeBytesCalc = dataSize / (tableRowResults.length ?? 1);
    setAverageRowSizeBytes(averageRowSizeBytesCalc);
    // Estimate the file size by multiplying the average row size by the total number of records
    const newEstimatedFileSizeKb =
      (averageRowSizeBytesCalc * (totalResults ?? 1)) / 1024;
    if (newEstimatedFileSizeKb > estimatedFileSizeKb) {
      setEstimatedFileSizeKb(newEstimatedFileSizeKb);
    }
  }, [
    filters,
    totalResults,
    tableRowResults,
    estimatedFileSizeKb,
    averageRowSizeBytes,
  ]);

  const processAndExportData = (combinedData: any[]) => {
    // console.log("Processing data for export");
    const headers = React.Children.map(children, (child) => child.props.header);
    // console.log("Headers processed for export", headers);
    const rows =
      multiRowPropertyKeys && multiRowPropertyKeys.length > 0
        ? preprocessData(combinedData, multiRowPropertyKeys)
        : combinedData;

    const processRows = (rows: T[], children: React.ReactNode): any[][] => {
      const outputData: any[][] = [];

      // console.log("Rows", rows.length);

      rows.forEach((item, rowIndex) => {
        const rowOutput: any[] = [];
        // Make sure we have an actual array of children
        const childArray = React.Children.toArray(children);

        try {
          childArray.forEach((child, childIndex) => {
            if (!React.isValidElement<any>(child)) {
              // Safely skip anything that isn't a valid React element
              console.warn("Invalid child at index", childIndex, child);
              return;
            }

            const { valueProperty, children: childFn } = child.props;
            const value = valueProperty ? get(item, valueProperty) : item;
            const output = childFn ? childFn(value) : value;
            rowOutput.push(output);
          });
        } catch (e) {
          console.error(e);
        }
        outputData.push(rowOutput);
      });
      return outputData;
    };

    const wsData = [headers, ...processRows(rows, children)];
    //if wsData is more than 34,000 rows we want a csv file otherwise we want an xlsx file
    if (wsData.length > 34000) {
      const csvData = wsData.map((row) => row.join(",")).join("\n");
      const blob = new Blob([csvData], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${worksheetName}.csv`;
      a.click();
      // console.log("CSV file created");
      return;
    } else {
      // console.log("XLSX file created");
      const ws = XLSX.utils.aoa_to_sheet(wsData);
      const wb = XLSX.utils.book_new();
      if (worksheetName.length > 31) {
        worksheetName = worksheetName.substring(0, 31);
      }

      XLSX.utils.book_append_sheet(wb, ws, `${worksheetName}`);
      XLSX.writeFile(wb, `${worksheetName}.xlsx`);
    }
  };

  const handleExport = async (
    paginationRequest: PaginationRequest = {
      page: 1,
      pageSize: totalResults!,
    }
  ) => {
    if (!dataQuery) {
      throw new Error("dataQuery is required");
    }
    if (!filters) {
      throw new Error("filters is required");
    }

    if (dataQuery instanceof Function) {
      if (estimatedFileSizeKb <= 3500) {
        const data = await dataQuery(filters, {
          page: 1,
          pageSize: totalResults,
        });
        if ("results" in data) {
          processAndExportData(data.results);
        } else {
          processAndExportData(data);
        }
      } else {
        const numberOfChunks = Math.ceil(estimatedFileSizeKb / 3500);
        const pageSize = Math.ceil(totalResults / numberOfChunks);

        // Create an array of page indexes [1, 2, 3, ..., numberOfChunks]
        const chunkIndexes = Array.from(
          { length: numberOfChunks },
          (_, i) => i + 1
        );

        const allData: T[] = [];

        // Process 10 chunks at a time
        const concurrency = 10;
        for (let i = 0; i < chunkIndexes.length; i += concurrency) {
          // Slice out the next up-to-10 pages
          const slice = chunkIndexes.slice(i, i + concurrency);

          // Build an array of promises for this batch
          const dataPromises = slice.map((pageIndex) =>
            dataQuery(filters, { page: pageIndex, pageSize })
          );

          // Wait for the batch to finish
          const batchResults = await Promise.all(dataPromises);
          // console.log(`Finished batch ${i + 1} of ${chunkIndexes.length}`);
          // Flatten the batch results into allData
          for (const result of batchResults) {
            if ("results" in result) {
              allData.push(...result.results);
            } else {
              allData.push(...result);
            }
            // console.log(`allData length: ${allData.length}`);
          }
          // console.log(
          //   `Finished processing batch ${i + 1} of ${chunkIndexes.length}`,
          // );
        }
        // console.log(`Finished processing all ${chunkIndexes.length} batches`);
        processAndExportData(allData);
      }
    } else {
      processAndExportData(dataQuery.data);
    }
  };

  return (
    <PillarForm.LoadingButton
      onClick={() => handleExport()}
      title="Export"
      label="Export"
      className="w-12"
      icon={<FileExportIcon />}
      {...props}
    >
      <PillarTableExportToExcelContext.Provider value={true}>
        {children}
      </PillarTableExportToExcelContext.Provider>
    </PillarForm.LoadingButton>
  );
};

export default PillarTableExportToExcel;
