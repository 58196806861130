export interface PillarTableHeaderBarProps {
  children?: React.ReactNode | (() => React.ReactNode);
}

const PillarTableHeaderBar = ({ children }: PillarTableHeaderBarProps) => {
  if (typeof children === "function") {
    return <>{children()}</>;
  }
  return <>{children}</>;
};

export default PillarTableHeaderBar;
