import { FormikValues, useFormikContext } from "formik";
import { ComponentPropsWithoutRef, useEffect, useState } from "react";
import PillarFormInputWrapper, {
  PillarFormInputWrapperProps,
} from "./PillarFormInputWrapper";

export type PillarFormPercentageInputProps = PillarFormInputWrapperProps &
  Omit<ComponentPropsWithoutRef<"input">, "id" | "name" | "value" | "type">;

const PillarFormPercentageInput = ({
  name,
  label,
  labelClassName,
  additionalClasses,
  className,
  onChange,
  ...props
}: PillarFormPercentageInputProps) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const [value, setValue] = useState<string>(values[name]);
  useEffect(() => {
    setFieldValue(name, value);
  }, [value]);
  return (
    <PillarFormInputWrapper
      name={name}
      labelClassName={labelClassName}
      label={label}
      additionalClasses={additionalClasses}
    >
      <div className="relative flex items-center">
        <div className="text-subtle absolute left-1">%</div>
        <input
          type="number"
          value={value}
          name={name}
          id={name}
          onChange={(e) => {
            setValue(e.target.value);
            onChange?.(e);
          }}
          className={`appearance-textfield w-full !pl-2.5 indent-0.5 ${
            className ?? ""
          }`}
          {...props}
        />
      </div>
    </PillarFormInputWrapper>
  );
};

export default PillarFormPercentageInput;
