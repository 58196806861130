import debounce from "lodash.debounce";
import { apiRequestContractHandler } from "shared/api/apiRequestContractHandler";
import { getSocietyChoicesQuestionNameContract } from "shared/api/contracts/society/societyId/choices/questionName";
import { SocietyView } from "shared/mappers/database/society/society";
import {
  ChoicesLazyLoadEvent,
  ChoicesRestful,
  QuestionSelectBase,
  Serializer,
  SurveyModel,
} from "survey-core";
import {
  SurveyCreatorModel,
  SurveyInstanceCreatedEvent,
} from "survey-creator-core";

export type SetupChoicesFromPillarProps = {
  society: SocietyView;
  surveyModel?: SurveyModel;
  surveyCreatorModel?: SurveyCreatorModel;
  baseUrl?: string;
};
export const setupChoicesFromPillar = ({
  society,
  surveyModel,
  surveyCreatorModel,
  baseUrl,
}: SetupChoicesFromPillarProps): void => {
  if (!surveyModel && !surveyCreatorModel) {
    throw new Error(
      "Either surveyModel or surveyCreatorModel must be provided",
    );
  }
  //Lazy load dropdown config is used when the dropdown is loaded after a delay
  const lazyLoadDropdownConfig = {
    name: "pillar_choices_question_name",
    displayName: "Choice Name",
    type: "dropdown",
    choices:
      society.choiceCategory?.map((category) => category.questionName ?? "") ??
      [],
    category: "Choices from Pillar",
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSetValue: (surveyElement: QuestionSelectBase, value: string) => {
      const elementType = surveyElement.getType();
      if (
        elementType === "tagbox" ||
        elementType === "checkbox" ||
        elementType === "radiogroup"
      ) {
        //These dont support lazy loading
        const result = {
          url: `${baseUrl ?? ""}/api/v1/society/${
            society.societyId
          }/choices/${value}`, // A RESTful service's URL.
          valueName: "text", // Specifies which field contains choice values.
          titleName: "text", // Specifies which field contains display texts for choice values.
          path: "body,results",
        };
        surveyElement.setPropertyValue("pillar_choices_question_name", value);

        const choicesByUrl: ChoicesRestful =
          surveyElement.getPropertyValue("choicesByUrl");

        choicesByUrl.url = result.url;
        choicesByUrl.valueName = result.valueName;
        choicesByUrl.titleName = result.titleName;
        choicesByUrl.path = result.path;
      } else {
        //Everything Else does
        surveyElement.setPropertyValue("pillar_choices_question_name", value);
        surveyElement.setPropertyValue("choicesLazyLoadEnabled", true);
      }
    },
  };

  //Register the properties for the survey creator
  Serializer.addProperty("checkbox", lazyLoadDropdownConfig);
  Serializer.addProperty("radiogroup", lazyLoadDropdownConfig);
  Serializer.addProperty("tagbox", lazyLoadDropdownConfig); //this one doesn't work well with lazy loading...
  Serializer.addProperty("rating", lazyLoadDropdownConfig);
  Serializer.addProperty("ranking", lazyLoadDropdownConfig);
  Serializer.addProperty("dropdown", lazyLoadDropdownConfig);
  Serializer.addProperty("matrixdropdowncolumn", lazyLoadDropdownConfig);

  //bit confusing here, basically we need to run "setupchoicesfrom pillar"
  //twice for the builder, once for the ... builder, then for preview
  if (surveyCreatorModel) {
    surveyCreatorModel.onSurveyInstanceCreated.add(
      (sender: SurveyCreatorModel, options: SurveyInstanceCreatedEvent) => {
        if (options.area === "designer-tab" || options.area === "preview-tab") {
          setupChoicesFromPillar({
            society: society,
            surveyModel: options.survey,
          });
        }
      },
    );
    return;
  }
  const setChoices = async (
    options: ChoicesLazyLoadEvent,
    choiceCategory: string,
  ) => {
    const choices = await apiRequestContractHandler(
      getSocietyChoicesQuestionNameContract,
      {
        params: {
          societyId: society.societyId,
          questionName: choiceCategory,
        },
      },
      { text: options.filter },
      {
        page: options.skip / options.take + 1,
        pageSize: options.take,
      },
    );

    // const choices = await getCustomChoicesCallout({
    //   societyId: society.societyId,
    //   questionName: choiceCategory,
    //   filters: { text: options.filter },
    //   pagination: {
    //     pageSize: options.take,
    //     page: options.skip / options.take + 1,
    //   },
    //   baseUrl: baseUrl,
    // });

    const items = choices.results.map((choice) => choice.text);
    options.setItems(items, choices.totalResults);
  };

  const _debouncedSetChoices = debounce(setChoices, 1000);

  if (surveyModel) {
    surveyModel.onChoicesLazyLoad.add(async (sender, options) => {
      const choiceCategory = options.question.getPropertyValue(
        "pillar_choices_question_name",
      );
      if (!choiceCategory) {
        //Not a pillar choice, so we don't need to do anything
        return;
      }
      options.setItems([], 1);
      _debouncedSetChoices(options, choiceCategory);
    });
  }
};
