// shared/components/pillar-table/PillarTableBody.tsx

import { PillarTableSubmenuItem } from "shared/components/pillar-table/PillarTablePopoverMenu";
import PillarTableRow from "shared/components/pillar-table/PillarTableRow";
import { PillarTableNestedTableContext } from "shared/components/pillar-table/nested/PillarTableNestedTableContext";
import {
  PillarTableQueryContext,
  usePillarTableQueryContext,
} from "shared/components/pillar-table/query/PillarTableQueryContext";
import Spinner from "admin/src/ui/components/common/Spinner";

import { get as lodashGet, isEmpty as lodashIsEmpty } from "lodash";
import React, { useContext, useState } from "react";
import { PillarTableNestedTableProps } from "shared/components/pillar-table/nested/PillarTableNestedTable";

interface PillarTableBodyProps<T extends object> {
  testidTable?: string;
  handleRowClick?: (row: T) => void;
  handleInlineEdit?: (row: T) => Promise<boolean>;
  handleInlineDelete?: (row: T) => Promise<boolean>;
  menu?: PillarTableSubmenuItem<T>[] | ((row: T) => PillarTableSubmenuItem<T>[]);
  fixedColumnWidths: number[];
  refetchData: () => void;
  emptyTableMessage?: string;
  loadedFirstTime: boolean;
  newPillarTableNestedTableComponentWrapper?: React.ReactElement<
    PillarTableNestedTableProps<T>
  >;
}

export const preprocessData = <T,>(
  data: T[],
  multiRowPropertyKeys: (keyof T)[],
): (T & T[keyof T])[] => {
  return data
    .flatMap((item: T) => {
      const matchingKey = multiRowPropertyKeys.find((key) => {
        const value = lodashGet(item, key);
        return Array.isArray(value);
      });
      const nestedItems = lodashGet(item, matchingKey!) as T[keyof T][];

      return (
        nestedItems?.map((nestedItem) => ({
          ...item,
          ...nestedItem,
        })) ?? []
      );
    })
    .filter((item) => !lodashIsEmpty(item));
};

const PillarTableBody = <T extends object>({
  testidTable,
  handleRowClick,
  handleInlineEdit,
  handleInlineDelete,
  menu,
  fixedColumnWidths,
  refetchData,
  emptyTableMessage,
  loadedFirstTime,
  newPillarTableNestedTableComponentWrapper,
}: PillarTableBodyProps<T>) => {
  const context = useContext(PillarTableQueryContext);
  const columns = context?.columns || [];
  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>(
    {},
  );

  const toggleRowExpansion = (rowIndex: number) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [rowIndex]: !prevExpandedRows[rowIndex],
    }));
  };

  const { tableRowResults, multiRowPropertyKeys } =
    usePillarTableQueryContext<T>();
  const processedData =
    Array.isArray(multiRowPropertyKeys) && multiRowPropertyKeys.length > 0
      ? preprocessData(tableRowResults, multiRowPropertyKeys as (keyof T)[])
      : tableRowResults;

  const buildTableRows = () => {
    if (!loadedFirstTime) {
      return (
        <tr
          className="pillartable-table-row-general"
          data-testid={testidTable ? `${testidTable}-spinner` : undefined}
        >
          <td colSpan={columns.length + 1 + (menu ? 1 : 0)}>
            <div className="flex justify-center items-center">
              <Spinner />
            </div>
          </td>
        </tr>
      );
    }
    if (processedData.length === 0) {
      return (
        <tr className="pillartable-table-row-general">
          <td colSpan={columns.length + 1 + (menu ? 1 : 0)}>
            <div className="flex justify-center items-center">
              {emptyTableMessage ?? "No records found."}
            </div>
          </td>
        </tr>
      );
    }

    return processedData.map((item: any, rowIndex: number) => {
      const isExpanded = expandedRows[rowIndex];

      return (
        <React.Fragment
          key={"id" in item ? `${Number(item.id)}-${rowIndex}` : rowIndex}
        >
          <PillarTableNestedTableContext.Provider
            value={{
              rowData: item,
              valueProperty:
                newPillarTableNestedTableComponentWrapper?.props.valueProperty || "",
              isRowExpanded: isExpanded,
              parentTableColumnsLength: columns.length + 1 + (menu ? 1 : 0),
            }}
          >
            <PillarTableRow<T>
              testidTable={testidTable}
              refetchTable={refetchData}
              columns={columns}
              rowIndex={rowIndex}
              item={item}
              menu={menu}
              handleRowClick={() => handleRowClick?.(item)}
              handleInlineEdit={handleInlineEdit}
              handleInlineDelete={handleInlineDelete}
              handleToggleRowExpansion={() => toggleRowExpansion(rowIndex)}
              fixedColumnWidths={fixedColumnWidths}
            />

            {newPillarTableNestedTableComponentWrapper}
          </PillarTableNestedTableContext.Provider>
        </React.Fragment>
      );
    });
  };

  return (
    <tbody data-testid={testidTable ? `${testidTable}-table-body` : undefined}>
      {buildTableRows()}
    </tbody>
  );
};

export default PillarTableBody;
