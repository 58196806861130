import withFilterComponent from "shared/components/pillar-table/filters/withFilterComponent";
import { usePillarTableQueryContext } from "shared/components/pillar-table/query/PillarTableQueryContext";
import Datepicker, { DateValueType } from "shared/components/common/datepicker/src";
import { useEffect, useState } from "react";
import { SocietyView } from "shared/mappers/database/society/society";

type PillarTableFilterDatePickerProps = {
  filterKey: string;
  title: string;
  shortcuts?: {
    [key: string]:
      | { text: string; period: { start: string | null; end: string | null } }
      | string;
  };
  className?: string;
  society?: SocietyView;
};

const PillarTableFilterDatePicker = ({
  filterKey,
  title,
  shortcuts,
  society,
  className,
}: PillarTableFilterDatePickerProps) => {
  const filterContext = usePillarTableQueryContext();
  const { setFilters, filters } = filterContext;
  const [datePickerValue, setDatePickerValue] = useState<DateValueType>(null);

  useEffect(() => {
    if (
      filters[filterKey + "-from"] !== datePickerValue?.startDate ||
      filters[filterKey + "-to"] !== datePickerValue?.endDate
    ) {
      setDatePickerValue({
        startDate:
          typeof filters[filterKey + "-from"] == "string"
            ? (filters[filterKey + "-from"] as string)
            : "",
        endDate:
          typeof filters[filterKey + "-to"] == "string"
            ? (filters[filterKey + "-to"] as string)
            : "",
      });
    }
  }, [filters]);

  //TODO : Add sa shared context hook similar to useCustomRouter that works on admin and hub

  const startingMonthBilling = parseInt(
    society?.societySettingsPublic?.fiscalYearStartDay ?? "1",
  );
  const startingDayBilling = parseInt(
    society?.societySettingsPublic?.fiscalYearStartMonth ?? "1",
  );

  const handleValueChange = (newValue: DateValueType) => {
    const datesFilter = {
      start:
        typeof newValue?.startDate == "string" ? newValue.startDate : undefined,
      end: typeof newValue?.endDate == "string" ? newValue.endDate : undefined,
    };
    if (
      filters[filterKey + "-from"] != datesFilter.start ||
      filters[filterKey + "-to"] != datesFilter.end
    ) {
      setFilters({
        ...filters,
        [filterKey + "-from"]: datesFilter.start,
        [filterKey + "-to"]: datesFilter.end,
      });
      setDatePickerValue(newValue);
    }
  };
  const overrideDefaultShortcuts = {
    today: "Today",
    yesterday: "Yesterday",
    yearToDate: "Year-to-Date",
    monthToDate: "Month-to-Date",
    previousWeek: "Previous 7 Days",
    nextWeek: "Next 7 Days",
    previousBillingPeriod: "Previous Billing Period",
    currentBillingPeriod: "Current Billing Period",
    nextBillingPeriod: "Next Billing Period",
  };
  return (
    <>
      <label
        className="text-neutral-mid-600"
        htmlFor={`filter-single-date-${filterKey}`}
      >
        {title}
      </label>
      <div>
        <Datepicker
          showShortcuts={true}
          popoverDirection="down"
          displayFormat="MM-DD-YYYY"
          configs={{
            shortcuts: shortcuts ?? overrideDefaultShortcuts,
            footer: {
              cancel: "Cancel",
              apply: "Apply",
            },
          }}
          value={datePickerValue}
          onChange={(e) => handleValueChange(e)}
          postfixID={filterKey}
          calendarContainerClass={`transition-all ease-out duration-300 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 mt-[1px] text-sm lg:text-xs 2xl:text-sm opacity-0 hidden bg-neutral-light ${className}`}
          startingTime={{
            startDay: startingDayBilling,
            startMonth: startingMonthBilling,
          }}
        />
      </div>
    </>
  );
};
export default withFilterComponent(PillarTableFilterDatePicker);
