import { UseQueryResult } from "@tanstack/react-query";
import {
  QueryTableData,
  StaticTableData,
  UseQueryRefetch,
} from "shared/components/pillar-table/PillarTable";
import { PillarTableColumnProps } from "shared/components/pillar-table/PillarTableColumn";
import {
  DynamicFilterChoices,
  PaginatedResults,
} from "shared/api/types/pagination";
import { FiltersRequest } from "shared/filter-where-clause";
import { createContext, useContext } from "react";

export interface PillarTableQueryContextType<
  T extends object,
  ExtraPropsT = Record<string, never>,
> {
  tableRowResults: T[];
  totalResults: number;
  extraProps: ExtraPropsT;
  tableData?: PaginatedResults<T> | T[];
  columns: React.ReactElement<PillarTableColumnProps<T>>[];
  tableQuery?: UseQueryResult<T[] | PaginatedResults<T> | unknown>;
  dynamicFilterChoices: DynamicFilterChoices;
  filters: FiltersRequest;
  setFilters: (filters: FiltersRequest) => void;
  queryKey: string | string[];
  refetchData?: UseQueryRefetch;
  dataQuery: QueryTableData<T> | StaticTableData<T>;
  multiRowPropertyKeys?: string[];
}

export const PillarTableQueryContext = createContext<PillarTableQueryContextType<
  any,
  any
> | null>(null);

export const usePillarTableQueryContext = <
  T extends object,
  ExtraPropsT = Record<string, any>,
>() => {
  const context = useContext(PillarTableQueryContext);
  if (!context) {
    throw new Error(
      "usePillarTableQueryContext must be used within an PillarTableQueryContextProvider",
    );
  }
  return context as PillarTableQueryContextType<T, ExtraPropsT>;
};
