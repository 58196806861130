import { FormikValues, useFormikContext } from "formik";

import React, { ComponentPropsWithoutRef } from "react";

import PillarFormInputWrapper, {
  PillarFormInputWrapperProps,
} from "./PillarFormInputWrapper";

export type PillarFormTextAreaInputProps = PillarFormInputWrapperProps &
  Omit<ComponentPropsWithoutRef<"textarea">, "value" | "id">;

const PillarFormTextAreaInput = ({
  name,
  labelClassName,
  label,
  additionalClasses,
  onChange,
  ...props
}: PillarFormTextAreaInputProps) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  return (
    <PillarFormInputWrapper
      name={name}
      labelClassName={labelClassName}
      label={label}
      additionalClasses={additionalClasses}
    >
      <textarea
        value={values[name]}
        name={name}
        id={name}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
          onChange?.(e);
        }}
        {...props}
      />
    </PillarFormInputWrapper>
  );
};

export default PillarFormTextAreaInput;
