import {
  FormikValues,
  useFormikContext,
  FieldArray,
  ErrorMessage,
} from "formik";
import { get } from "lodash";
import React, { ComponentPropsWithoutRef, useEffect } from "react";
import PlusIcon from "shared/components/icons/sign/PlusIcon";
import { PillarFormInputWrapperProps } from "shared/components/pillar-form/components/PillarFormInputWrapper";
import PillarFormButton from "./PillarFormButton";

export type PillarFormDynamicArrayProps = PillarFormInputWrapperProps &
  Omit<ComponentPropsWithoutRef<"input">, "id" | "name" | "value" | "type"> & {
    minItems: number;
    maxItems: number;
    // defaultItem is used to fill initial value or to push item when the new one is created
    defaultItem: Record<string, unknown>;
    itemContainerClassName?: string;
    hideRemoveButton?: boolean;
    hideAddButton?: boolean;
  };

const PillarFormDynamicArray = ({
  name,
  label,
  labelClassName,
  className,
  children,
  minItems,
  maxItems,
  defaultItem,
  itemContainerClassName,
  hideRemoveButton = false,
  hideAddButton = false,
  ...props
}: PillarFormDynamicArrayProps) => {
  const { values, errors, setFieldValue } = useFormikContext<FormikValues>();
  const value = get(values, name) || [];

  useEffect(() => {
    if (!value?.length && minItems > 0) {
      setFieldValue(name, Array(minItems).fill(defaultItem));
    }

    if (value?.length < minItems) {
      setFieldValue(name, [
        ...value,
        ...Array(minItems - value.length).fill(defaultItem),
      ]);
    }
  }, [value, minItems, defaultItem, name, setFieldValue]);

  const renderChildren = (index: number) =>
    React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          ...child.props,
          name: `${name}[${index}].${child.props.name}`,
        });
      }
      return child;
    });

  return (
    <div className={`${className ?? ""}`}>
      {label && (
        <label
          className={`mb-0.5 inline-block text-base ${
            labelClassName ?? "text-filter-label"
          }`}
        >
          {label}:
        </label>
      )}
      <FieldArray name={name}>
        {({ remove, push }) => (
          <div className={`flex flex-col gap-y-2 ${label ? "pl-1" : ""}`}>
            {value.map((_: Array<Record<string, unknown>>, index: number) => (
              <div
                key={index}
                className={`${
                  itemContainerClassName ?? "flex items-center gap-x-3"
                }`}
              >
                <div className="w-full">
                  {children && renderChildren(index)}
                </div>
                {!hideRemoveButton && value.length > minItems && (
                  <PillarFormButton
                    onClick={() => remove(index)}
                    className="button-regular-destructive-filled h-5"
                  >
                    Remove
                  </PillarFormButton>
                )}
              </div>
            ))}
            {!Array.isArray(errors[name]) && !!errors[name] && (
              <ErrorMessage
                name={name}
                component="span"
                className="text-danger-small"
              />
            )}
            {!hideAddButton && value.length < maxItems && (
              <PillarFormButton
                onClick={() => push(defaultItem)}
                className="w-fit h-5"
              >
                <PlusIcon className="stroke-neutral-light" />
                Add
              </PillarFormButton>
            )}
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default PillarFormDynamicArray;
