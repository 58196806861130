import { get } from "lodash";

import React from "react";

import { PillarTableColumnProps } from "shared/components/pillar-table/PillarTableColumn";
import { PillarTableRowContext } from "shared/components/pillar-table/PillarTableRowContext";
import { DeepKeys } from "shared/types/deepkeys";
type RenderChildren<T> = (
  cellValue: T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setCellValue: (value: any, valueProperty: string) => void,
) => React.ReactElement;

export type PillarTableCellEditProps<T, V> = {
  valueProperty?: DeepKeys<T>;
  children?: RenderChildren<V> | React.ReactElement;
} & Omit<PillarTableColumnProps<T>, "children">;

const PillarTableCellEdit = <T, V>(props: PillarTableCellEditProps<T, V>) => {
  const rowContext = React.useContext(PillarTableRowContext);
  if (!rowContext) {
    throw new Error("PillarTableCellEdit must be used inside a PillarTableColumn");
  }
  const editedValue = get(
    rowContext?.editedValue,
    props.valueProperty as string,
  );
  if (typeof props.children === "function") {
    return props.children(editedValue, (value, valueProperty) => {
      rowContext.updateEditedValue(value, valueProperty);
    });
  }
  if (React.isValidElement<PillarTableCellEditProps<T, V>>(props.children)) {
    return React.cloneElement(props.children, {
      ...props,
      ...props.children.props,
    });
  }
  return <></>;
};

export default PillarTableCellEdit;
