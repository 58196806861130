import {
  MembershipView,
  ExtendedMembershipView,
} from "shared/mappers/database/profile/membership";
import { ProfileView } from "shared/mappers/database/profile/profile";
import { formatLuxonDate } from "shared/datetime/formatLuxonDate";
import { LuxonDateFormats } from "shared/datetime/dateFormats";
import { DateTime } from "luxon";
type FormattedMembershipDataReturnType = MembershipView &
  ExtendedMembershipView & {
    [key: string]: string | undefined;
  };

export const getFormattedMembershipData = (
  profile: ProfileView
): FormattedMembershipDataReturnType => {
  const currentDate = DateTime.now();
  const membershipInfo: MembershipView = {
    name: profile?.latestMembership?.name ?? "Non-Member",
    effectiveDate: profile?.latestMembership?.effectiveDate
      ? formatLuxonDate(
          profile.latestMembership.effectiveDate,
          LuxonDateFormats.D
        )
      : undefined,
    expirationDate: profile?.latestMembership?.expirationDate
      ? formatLuxonDate(
          profile.latestMembership.expirationDate,
          LuxonDateFormats.D
        )
      : undefined,
  };
  const extraMembershipInfo = {
    terminationDate:
      membershipInfo.expirationDate &&
      DateTime.fromISO(membershipInfo.expirationDate) < currentDate
        ? formatLuxonDate(membershipInfo.expirationDate, LuxonDateFormats.D)
        : undefined,
    isMember: profile.latestMembership?.name,
  };
  return { ...membershipInfo, ...extraMembershipInfo };
};
