import {
  SocietyAuthParamsInput,
  SocietyAuthBodyInput,
} from "shared/api/types/society/[societyId]/auth/index";
import { SessionView } from "shared/mappers/database/session/session";
import { BaseApiContract } from "shared/api/apiRequestContractHandler";
import { HttpMethod } from "shared/api/http";
export const postSocietyAuthContract: BaseApiContract<
  SocietyAuthParamsInput,
  SocietyAuthBodyInput,
  SessionView
> = {
  method: HttpMethod.POST,
  apiRoute: "/api/v1/society/[societyId]/auth",
  paramsType: {} as SocietyAuthParamsInput,
  bodyType: {} as SocietyAuthBodyInput,
  responseType: {} as SessionView,
};
export const getSocietyAuthContract: BaseApiContract<
  SocietyAuthParamsInput,
  SocietyAuthBodyInput,
  SessionView
> = {
  method: HttpMethod.GET,
  apiRoute: "/api/v1/society/[societyId]/auth",
  paramsType: {} as SocietyAuthParamsInput,
  bodyType: {} as SocietyAuthBodyInput,
  responseType: {} as SessionView,
};
