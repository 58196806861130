import { payment_method } from "@prisma/client";
import { createElement, FC } from "react";
import { renderToString } from "react-dom/server";
import AmexIcon from "shared/components/icons/cardType/AmexIcon";
import GenericIcon from "shared/components/icons/cardType/GenericIcon";
import MasterCardIcon from "shared/components/icons/cardType/MasterCardIcon";
import VisaIcon from "shared/components/icons/cardType/VisaIcon";
import { PaymentType } from "admin/src/constants/enums/payment-types";

export enum CardType {
  VISA = "Visa",
  MASTERCARD = "MasterCard",
  AMEX = "AmericanExpress",
  UNKNOWN = "Unknown",
}

export type CardTypeAndNumberType = {
  accountNumber: string;
  accountType: string;
};

export const cardIcons: Record<string, FC<{ className?: string }>> = {
  AmericanExpress: AmexIcon,
  Visa: VisaIcon,
  MasterCard: MasterCardIcon,
  UNKNOWN: GenericIcon,
};

export const extractPaymentCardTypeAndNumber = (
  transactionResponse: CardTypeAndNumberType,
) => {
  if (!transactionResponse) {
    return { lastDigits: "Unknown", Icon: cardIcons.UNKNOWN };
  }
  const { accountNumber, accountType } = transactionResponse;
  const cardType = Object.values(CardType).includes(accountType as CardType)
    ? (accountType as CardType)
    : CardType.UNKNOWN;
  const cleanNumber = accountNumber?.replace(/\D/g, "") || "";
  // Amex - 5 digits, Visa/MC - 4 digits
  const lastDigits =
    cardType === CardType.AMEX ? cleanNumber.slice(-5) : cleanNumber.slice(-4);
  const Icon = cardIcons[cardType] || cardIcons.UNKNOWN;
  return { cardType, lastDigits, Icon };
};

export const getPaymentDetailsByMethod = (
  additionalInfo: any,
  method: payment_method,
) => {
  if (method === payment_method.AUTHORIZE_NET) {
    const { lastDigits, Icon } =
      extractPaymentCardTypeAndNumber(additionalInfo);
    return `${renderToString(createElement(Icon))} ${lastDigits}` || "";
  }

  const info = `${additionalInfo?.accountNumber} - ${additionalInfo?.checkNumber}`;

  if (method === payment_method.CHECK) {
    return `${PaymentType.Check} - ${info}`;
  }

  if (method === payment_method.WIRETRANSFER) {
    return `${PaymentType.WireTransfer} - ${info}`;
  }

  return "";
};
