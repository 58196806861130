import { useQuery } from "@tanstack/react-query";

import { apiRequestContractHandler } from "shared/api/apiRequestContractHandler";
import { getSocietyDashboardIdContract } from "shared/api/contracts/society/societyId/dashboards/dashboardId";
import Spinner from "admin/src/ui/components/common/Spinner";

type Props = {
  dashboardId: number;
  societyId: number;
};

const SimpleModuleType = ({ dashboardId, societyId }: Props) => {
  const { data, isLoading } = useQuery({
    queryKey: ["dashboardModule", dashboardId],
    queryFn: async () =>
      apiRequestContractHandler(getSocietyDashboardIdContract, {
        params: {
          societyId,
          dashboardId,
        },
      }),
    refetchOnWindowFocus: false,
  });

  if (isLoading)
    return (
      <div className="col-span-12">
        <Spinner />
      </div>
    );
  if (!data) return <></>;

  return (
    <div
      className={
        (data.dashboardModule.classNames,
        `col-span-${data.dashboardModule.colSpan} row-start-${data.dashboardModule.positionRow} col-start-${data.dashboardModule.positionColumn}`)
      }
    >
      <span className="text-md">{data.dashboardModule.title}: </span>
      <span className="text-2xl font-bold">{String(data.data)}</span>
    </div>
  );
};

export default SimpleModuleType;
