import { Switch } from "@headlessui/react";
import { useFormikContext } from "formik";
import PillarFormInputWrapper, {
  PillarFormInputWrapperProps,
} from "shared/components/pillar-form/components/PillarFormInputWrapper";
import { ComponentType } from "react";

//TODO: This is a Stevenism,  it essentially negates our type saftey but headlessui/react doesnt seem to expose its ComboBox props
type ExtractProps<T> = T extends ComponentType<infer P> ? P : T;
export type PillarFormSwitchProps = PillarFormInputWrapperProps &
  Omit<ExtractProps<typeof Switch>, "name" | "checked"> & {
    onChangeCallbackAction?: (currentValue?: boolean) => void;
  };

const PillarFormSwitch = ({
  testid,
  name,
  label,
  labelClassName,
  additionalClasses,
  onChangeCallbackAction,
  onChange,
  className,
  tooltip,
  ...props
}: PillarFormSwitchProps) => {
  const { setFieldValue, getFieldProps } = useFormikContext();
  return (
    <PillarFormInputWrapper
      name={name}
      label={label}
      tooltip={tooltip}
      labelClassName={labelClassName}
      additionalClasses={additionalClasses}
    >
      <Switch
        data-testid={testid}
        id={`switch-${name}`}
        {...getFieldProps(name)}
        checked={getFieldProps(name)?.value}
        onChange={(bool: boolean) => {
          setFieldValue(name, bool);
          if (onChangeCallbackAction) {
            onChangeCallbackAction(bool);
          } else {
            onChange?.(bool);
          }
        }}
        //TODO: Using the tailwind modifiers ui-disabled and ui-not-disabled should work in the component token class, but they do not.
        className={`appswitch-container relative inline-flex h-4 w-7 items-center ${
          props.disabled ? "appswitch-container--disabled" : ""
        } ${className ?? ""}`}
        {...props}
      >
        <span
          aria-hidden="true"
          //TODO: Using the tailwind modifiers ui-checked, ui-not-checked, ui-disabled, and ui-not-disabled should work in the component token class, but they do not.
          className={`inline-block h-2.5 w-2.5 rounded-full transform transition ${
            getFieldProps(name).value
              ? "appswitch-toggle-span-checked translate-x-3.5"
              : "appswitch-toggle-span-notchecked translate-x-0.75"
          } ${props.disabled ? "appswitch-toggle-span--disabled" : ""}`}
        />
      </Switch>
    </PillarFormInputWrapper>
  );
};

export default PillarFormSwitch;
