// shared/components/pillar-table/filters/usePillarTableFilters.tsx

import { isEqual } from "lodash";
import queryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import PillarTableFilters, {
  PillarTableFiltersProps,
} from "shared/components/pillar-table/filters/PillarTableFilters";
import { useCustomRouter } from "shared/context/CustomRouterContext";
import { FiltersRequest } from "shared/filter-where-clause";
import { flattenChildComponents } from "shared/helpers/react/flatten-child-components";

type FilterComponentType = React.JSXElementConstructor<any> & {
  isFilterComponent: boolean;
  defaultValue?: any;
};

const isFilterComponentType = (
  type: string | React.JSXElementConstructor<any>,
): type is FilterComponentType => {
  return typeof type !== "string" && (type as any).isFilterComponent === true;
};

interface UsePillarTableFiltersProps {
  children: React.ReactNode;
  urlParams?: boolean;
  queryKey: string | string[];
}

export const usePillarTableFilters = ({
  children,
  urlParams,
  queryKey,
}: UsePillarTableFiltersProps) => {
  const router = useCustomRouter();

  const filterComponentWrapper = React.Children.toArray(children).find(
    (child) =>
      React.isValidElement<PillarTableFiltersProps>(child) &&
      child.type === PillarTableFilters,
  ) as React.ReactElement<PillarTableFiltersProps> | undefined;

  const filterComponents = flattenChildComponents(filterComponentWrapper)
    .filter(
      (child): child is React.ReactElement<{ filterKey: string }> =>
        React.isValidElement(child) && isFilterComponentType(child.type),
    )
    .reduce((acc: FiltersRequest, curr) => {
      const filterKey = curr.props.filterKey;
      const defaultValue = (curr.type as FilterComponentType).defaultValue;

      if (defaultValue !== undefined) {
        return { ...acc, [filterKey]: defaultValue };
      }
      return { ...acc, [filterKey]: "" };
    }, {});

  // The rest of your hook implementation remains the same...

  const filtersFromUrl = (
    router: ReturnType<typeof useCustomRouter>,
    filterComponents: FiltersRequest,
    urlParams?: boolean,
  ): FiltersRequest => {
    if (!urlParams) {
      return filterComponents;
    }
    const query = queryString.parseUrl(router.asPath, {
      arrayFormat: "bracket",
    });
    // Update filter components with values from URL
    for (const key in filterComponents) {
      if (query.query[key] === null) {
        // handle null value
      } else if (Array.isArray(query.query[key])) {
        filterComponents[key] = (query.query[key] as []).filter(
          (item) => item !== null,
        );
      } else {
        filterComponents[key] = query.query[key] as string;
      }
    }
    return filterComponents;
  };

  const [filters, setFilters] = useState<FiltersRequest>(
    filtersFromUrl(router, filterComponents, urlParams),
  );

  const [filtersRendered, setFiltersRendered] = useState(
    Object.keys(filterComponents).length > 0 ? false : true,
  );

  useEffect(() => {
    if (!filtersRendered) {
      setFiltersRendered(true);
    }
  }, [filters]);

  const setFiltersFun = (incomingFilters: FiltersRequest) => {
    if (!isEqual(incomingFilters, filters)) {
      // Sanitize filters
      const sanitizedFilters = Object.keys(incomingFilters).reduce(
        (acc: FiltersRequest, curr: string) => {
          const value = incomingFilters[curr];
          if (
            (Array.isArray(value) && value.length === 0) ||
            value === "" ||
            value === undefined
          ) {
            return acc; // Skip empty values
          }
          return { ...acc, [curr]: value };
        },
        {},
      );
      setFilters(sanitizedFilters);
    }
  };

  return {
    filterComponentWrapper,
    filters,
    setFilters: setFiltersFun,
    filtersRendered,
  };
};
