import { get as lodashGet } from "lodash";
import React, { ComponentPropsWithoutRef } from "react";
import Badge from "shared/components/common/Badge";
import { PillarTableRowContext } from "shared/components/pillar-table/PillarTableRowContext";
import { WorkflowInstanceView } from "shared/mappers/database/workflow/workflow-instance";

export type PillarTableStatusColumnProps = {
  workflowInstanceProperty: string;
  placeholder?: string;
} & ComponentPropsWithoutRef<"div">;
const PillarTableStatusColumn = ({
  workflowInstanceProperty,
  placeholder = "-",
  className,
  ...props
}: PillarTableStatusColumnProps) => {
  const rowContext = React.useContext(PillarTableRowContext);
  const value: WorkflowInstanceView = lodashGet(
    rowContext?.rowValue,
    workflowInstanceProperty,
  );

  if (!value) {
    return <></>;
  }

  return (
    <Badge className={`w-fit px-2 ${value.displayStatus.className}`}>
      <>{value.displayStatus.displayStatus}</>
    </Badge>
  );
};

export default PillarTableStatusColumn;
