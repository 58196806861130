import { PillarTableColumnProps } from "shared/components/pillar-table/PillarTableColumn";
import { PillarTableRowContext } from "shared/components/pillar-table/PillarTableRowContext";
import { formatUsdString } from "shared/helpers/invoice/formatUsdString";
import { get } from "lodash";
import React from "react";
const PillarTableUSDColumn = <T,>(props: PillarTableColumnProps<T>) => {
  const rowContext = React.useContext(PillarTableRowContext);
  const value = get(rowContext?.rowValue, props.valueProperty as string);
  return value || value === 0 ? <>{formatUsdString(value)}</> : <></>;
};

export default PillarTableUSDColumn;
