import { useCustomSession } from "shared/context/CustomSessionContext";

const CheckPaymentForm = () => {
  const session = useCustomSession();
  //todo remove and pass in, need to get help here.

  return (
    <section aria-labelledby="payment-heading">
      <div id="payment-heading" className="text-title pb-1">
        Check Payment Details
      </div>
      <div className="flex flex-col space-y-2">
        {session.society?.societySettingsPublic?.hubCheckMessage && (
          <h3 className="text-center p-8">
            {session.society?.societySettingsPublic?.hubCheckMessage ??
              "Check payments have not been enabled for this society. Please contact the society administrator for more information."}
          </h3>
        )}
      </div>
    </section>
  );
};
export default CheckPaymentForm;
