/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { RouteParams } from "shared/api/types/society/[societyId]/app-route-config";
import { PillarTableRowContextType } from "shared/components/pillar-table/PillarTableRowContext";
import { PillarTableCellEditProps } from "shared/components/pillar-table/edit/PillarTableCellEdit";
import { PillarDataTableFixedColumn } from "shared/components/pillar-table/helpers/addFixedStyleToColumn";
import { PaginatedResults } from "shared/api/types/pagination";
import { DeepKeys } from "shared/types/deepkeys";

export interface PillarTableBaseColumnProps<T> {
  headingClassName?: string;
  heading?: string;
  testid?: string;
  className?: string;
  sortBy?: string;
  valueProperty?: DeepKeys<T> | string;
  valueDisplayProperty?: DeepKeys<T> | string;
  minWidth?: string;
  maxWidth?: string;
  contentClassName?: string;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
  ref?: React.Ref<HTMLDivElement>;
  // Add any other common properties here
}

export type RenderChildren<T> = (
  cellValue: T,
  setCellValue: (value: any, valueProperty: string) => void,
) => React.ReactElement;

export interface PillarTableColumnProps<T> extends PillarTableBaseColumnProps<T> {
  tableData?: PaginatedResults<T> | T[] | undefined;
  doNotEdit?: boolean;
  children?: React.ReactElement | ((row: T) => JSX.Element);
  value?: any;
  row?: PillarTableRowContextType<T>;
  centered?: boolean;
  columnIndex?: number;
  cellEditComponent?: React.ReactElement<PillarTableCellEditProps<T, any>>;
  linkTo?: { path: string; params?: RouteParams };
  cancelRowClickAction?: boolean;
  fixedColumn?: PillarDataTableFixedColumn;
  isCheckbox?: boolean;
}

const PillarTableColumn = <T,>({ children }: PillarTableColumnProps<T>) => {
  return <>{children}</>;
};

export default PillarTableColumn;
