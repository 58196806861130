import { SocietyProfileConferenceRegistrationParamsInput } from "shared/api/types/society/[societyId]/profiles/[profileId]/conference/[conferenceId]/registration/[conferenceRegistrationId]/autosave";
import { SocietyWorkflowBodyInput } from "shared/api/types/society/[societyId]/workflow/[workflowId]/instance";
import { BaseApiContract } from "shared/api/apiRequestContractHandler";
import { HttpMethod } from "shared/api/http";
export const postSocietyProfileConferenceRegistrationAutosaveContract: BaseApiContract<
  SocietyProfileConferenceRegistrationParamsInput,
  SocietyWorkflowBodyInput,
  void
> = {
  method: HttpMethod.POST,
  apiRoute:
    "/api/v1/society/[societyId]/profiles/[profileId]/conference/[conferenceId]/registration/[conferenceRegistrationId]/autosave",
  paramsType: {} as SocietyProfileConferenceRegistrationParamsInput,
  bodyType: {} as SocietyWorkflowBodyInput,
  responseType: undefined as unknown as void,
};
