import { Field, FormikValues, useFormikContext } from "formik";
import React, { ChangeEvent, useEffect } from "react";
import PillarFormInputWrapper, {
  PillarFormInputWrapperProps,
} from "shared/components/pillar-form/components/PillarFormInputWrapper";
import get from "lodash/get";

export type PillarFormCheckboxProps = PillarFormInputWrapperProps & {
  name: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>, values: FormikValues) => void;
  disabled?: boolean;
  isChecked?: boolean;
};

const PillarFormCheckbox = ({
  name,
  label,
  labelClassName,
  additionalClasses,
  onChange,
  isChecked,
  ...props
}: PillarFormCheckboxProps) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  useEffect(() => {
    setFieldValue(name, isChecked ?? get(values, name));
  }, [isChecked]);

  return (
    <div>
      <PillarFormInputWrapper
        name={name}
        labelClassName={labelClassName}
        additionalClasses={additionalClasses}
        label={label}
      >
        <Field
          type="checkbox"
          className="h-2 w-2 rounded cursor-pointer"
          checked={isChecked ?? get(values, name)}
          name={name}
          id={name}
          {...props}
        />
      </PillarFormInputWrapper>
    </div>
  );
};

export default PillarFormCheckbox;
