import {
  CustomRouterContext,
  CustomRouterType,
} from "shared/context/CustomRouterContext";
import { generatePath, useHistory, useLocation } from "react-router";
import { RouteParams } from "shared/api/types/society/[societyId]/app-route-config";
import { useCallback, useEffect, useMemo, useState } from "react";
import { isIframeSSOPage } from "hub/src/utils/isIframeSSOPage";
import { ENV } from "hub/src/appConstants/env";

export const determinParamType = (value: string) => {
  if (!isNaN(Number(value))) {
    return Number(value);
  } else if (value === "true" || value === "false") {
    return value === "true";
  }
  if (value === "null") {
    return null;
  } else {
    return value;
  }
};

const getCustomSearch = (search: string) => {
  return Array.from(new URLSearchParams(search).entries()).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: determinParamType(value),
    }),
    {},
  );
};

export const UserHubCustomRouterProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const location = useLocation();
  const history = useHistory();
  const [customState, setCustomState] = useState<RouteParams>({});
  const [customSearch, setCustomSearch] = useState<RouteParams>(() => {
    if (location.search) return getCustomSearch(location.search);
    return {};
  });

  useEffect(() => {
    if (
      isIframeSSOPage &&
      ENV.REACT_APP_IFRAME_SSO_REDIRECT_URL &&
      typeof ENV.REACT_APP_IFRAME_SSO_REDIRECT_URL === "string"
    ) {
      const handleMessageFromParent = (event: MessageEvent) => {
        if (
          event &&
          event.data &&
          event.data.type === "RESPOND_PARENT_LOCATION" &&
          event.data.location
        ) {
          const parentLocation = JSON.parse(event.data.location);
          if (parentLocation) {
            //https://www.csiresources.org/home/hub#/tizra&ReturnUrl=https://www.csiresources.org/home/hub#/tizra
            //Split on &ReturnUrl=
            //if there is [0] and [1] and they match console true
            const parentLocationSplit =
              parentLocation.href.split("&ReturnUrl=");
            if (parentLocationSplit.length > 1) {
              if (parentLocationSplit[0] === parentLocationSplit[1]) {
                window.parent.postMessage(
                  {
                    type: "REDIRECT_PARENT",
                    source: "pillar",
                    redirectParentUrl: parentLocationSplit[0],
                  },
                  parentOrigin,
                );
              }
            }
          }
        }
      };
      window.addEventListener("message", handleMessageFromParent);

      const parentOrigin =
        ENV.REACT_APP_IFRAME_SSO_REDIRECT_URL!.match(/https:\/\/[^/]+/)![0];
      const sendMessageToParent = (): void => {
        window.parent.postMessage(
          {
            type: "REQUEST_PARENT_LOCATION",
            source: "pillar",
          },
          parentOrigin,
        );
      };
      sendMessageToParent();
    }
  }, []);

  useEffect(() => {
    if (location.search) {
      setCustomSearch(getCustomSearch(location.search));
    }
  }, [location.search]);
  useEffect(() => {
    if (location.state) {
      const newCustomState = Object.entries(location.state).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: determinParamType(value),
        }),
        {},
      );
      setCustomState(newCustomState);
    }
  }, [location.state]);
  const combinedState = useMemo(() => {
    const combinedStateBeforeReturn = {
      ...customState,
      ...customSearch,
    };
    return combinedStateBeforeReturn;
  }, [JSON.stringify(customState), JSON.stringify(customSearch)]);

  const getFullPathAndParams = useCallback(
    (path: string, params?: RouteParams) => {
      const fullParams = { ...combinedState, ...params };
      //TODo: Typescript doesnt like that fullParams could contain an Null
      const fullPath = generatePath(path, fullParams as any);
      return { fullPath, fullParams };
    },
    [combinedState],
  );

  const router: CustomRouterType = {
    asPath: location.pathname,
    pathname: location.pathname,
    state: combinedState,
    replace: (path: string, params?: RouteParams) => {
      const { fullPath, fullParams } = getFullPathAndParams(path, params);
      setCustomState((prev) => ({ ...prev, ...fullParams }));
      history.replace(fullPath, fullParams);
    },
    push: (path: string, params?: RouteParams) => {
      const { fullPath, fullParams } = getFullPathAndParams(path, params);
      setCustomState((prev) => ({ ...prev, ...fullParams }));
      history.push(fullPath, fullParams);
    },
    setInState: (key: string, value: string) => {
      setCustomState((prev) => ({ ...prev, [key]: value }));
    },
    removeFromState: (key: string) => {
      setCustomState((prev) => {
        const { [key]: thingToBeRemoved, ...rest } = prev;
        return rest;
      });
      setCustomSearch((prev) => {
        const { [key]: thingToBeRemoved, ...rest } = prev;
        return rest;
      });
    },
  };
  return (
    <CustomRouterContext.Provider value={router}>
      {children}
    </CustomRouterContext.Provider>
  );
};
