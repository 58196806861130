import { useRef } from "react";
import { PaginatedResults } from "shared/api/types/pagination";
import { ReportResponseType } from "shared/api/types/society/[societyId]/reports/[reportId]";
import { PillarModalHandle } from "shared/components/pillar-modal/PillarModalBody";
import PillarTable from "shared/components/pillar-table/PillarTable";
import {
  ColumnTypes,
  CustomReportColumnConfig,
  customReportColumnsMapper,
} from "shared/components/pillar-table/custom-reports/customReportColumnsMapper";
import { generateExportFileName } from "shared/components/pillar-table/export/generateExportFileName";
import PillarTableHeaderBar from "shared/components/pillar-table/header-bar/PillarTableHeaderBar";
import { CustomReportView } from "shared/mappers/database/reports/report";
import SettingsGearIcon from "shared/components/icons/SettingsGearIcon";
import EditCustomProfileReportColumnsModal from "admin/src/ui/components/report/EditCustomProfileReportColumnsModal";
import PillarForm from "shared/components/pillar-form/PillarForm";
import { useCustomSession } from "shared/context/CustomSessionContext";
import {
  AdminUserPermissions,
  SocietyAdminPermissions,
} from "shared/types/admin/permissions";
import { hasAdminPermission } from "shared/helpers/hasAdminPermission";

interface CustomReportHeaderProps {
  customReportResponse: PaginatedResults<ReportResponseType, CustomReportView>;
}

const CustomReportHeader: React.FC<CustomReportHeaderProps> = ({
  customReportResponse,
}) => {
  const sessionData = useCustomSession();

  const profileFieldsSettingsModalRef = useRef<PillarModalHandle>(null);
  return customReportResponse?.customReportId ? (
    <PillarTableHeaderBar>
      <EditCustomProfileReportColumnsModal
        modalRef={profileFieldsSettingsModalRef}
      />
      <div className="mb-1 w-full flex justify-between items-center">
        <div className="flex flex-shrink-1 flex-0">
          <h1>Report - {customReportResponse?.reportName}</h1>
        </div>
        <div className="flex justify-end gap-2">
          {customReportResponse?.customReportBaseTable === "profile" &&
            hasAdminPermission({
              session: sessionData,
              permissions: [SocietyAdminPermissions.report_edit],
            }) && (
              <PillarForm.Button
                testid="profile-fields-setting-button"
                onClick={() => profileFieldsSettingsModalRef.current?.open()}
                title="Profile Fields Settings"
                label="Profile Fields Settings"
                data-testid="custom-profile-report-modal"
                icon={<SettingsGearIcon className="h-2.5 w-2.5" />}
              />
            )}
          {customReportResponse?.exportColumns && (
            <PillarTable.ExportToExcel
              worksheetName={generateExportFileName(
                customReportResponse!.reportName!
              )}
            >
              {customReportResponse.exportColumns.map(
                (
                  customReportColumnConfig: CustomReportColumnConfig,
                  index: number
                ) => (
                  <PillarTable.ExportToExcelColumn
                    key={`${
                      customReportColumnConfig.name ?? "BLANK_COL_NAME"
                    }-${index}`}
                    header={customReportColumnConfig.name}
                  >
                    {(rowValue: ReportResponseType) => {
                      const pipe =
                        customReportColumnsMapper[
                          customReportColumnConfig.type
                        ] ?? customReportColumnsMapper[ColumnTypes.string];

                      const output = pipe({
                        ...customReportColumnConfig,
                        rowValue,
                      } as any);
                      return output ?? "";
                    }}
                  </PillarTable.ExportToExcelColumn>
                )
              )}
            </PillarTable.ExportToExcel>
          )}
        </div>
      </div>
    </PillarTableHeaderBar>
  ) : null;
};

export default CustomReportHeader;
