import { BaseApiContract } from "shared/api/apiRequestContractHandler";
import { HttpMethod } from "shared/api/http";
import { PDFOutput } from "shared/api/types/pdf";
import { SocietyProfileInvoiceParamsInput } from "shared/api/types/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]";
export const getSocietyProfileAccountingInvoiceGeneratePdfContract: BaseApiContract<
SocietyProfileInvoiceParamsInput,
  undefined,
  PDFOutput
> = {
  method: HttpMethod.GET,
  apiRoute:
    "/api/v1/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]/generate-pdf",
  paramsType: {} as SocietyProfileInvoiceParamsInput,
  responseType: undefined as unknown as PDFOutput,
};
