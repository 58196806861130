import React, { useEffect } from "react";
/**
 * close datepicker when click outside of datepicker
 *
 * @param {React.RefObject<HTMLDivElement>} ref -ref
 * @param {(e?: MouseEvent | TouchEvent) => void} handler -event
 */
export default function useOnClickOutside(
  ref: React.RefObject<HTMLDivElement>,
  handler: (e?: MouseEvent | TouchEvent) => void,
) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}
