import { Alignment, autoPlacement, Placement } from "@floating-ui/dom";
import {
  FloatingPortal,
  safePolygon,
  useFloating,
  useHover,
  useInteractions,
} from "@floating-ui/react";
import React, {
  MouseEventHandler,
  ReactNode,
  useCallback,
  useState,
} from "react";

interface Props extends React.PropsWithChildren {
  tooltip: ReactNode;
  className?: string;
  tooltipClassName?: string;
  ellipsis?: boolean;
  allowClickOverTooltip?: boolean;
  placement?: Placement;
  alignment?: Alignment;
  onDoubleClick?: MouseEventHandler<HTMLDivElement>;
}

const Tooltip = ({
  children,
  onDoubleClick,
  className,
  placement,
  alignment,
  tooltipClassName,
  tooltip = null,
  ellipsis = false,
  allowClickOverTooltip = true,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    // middleware: [
    //   autoPlacement({
    //     alignment: alignment ?? "end",
    //   }),
    // ],
    placement: placement ?? "bottom",
  });

  const hover = useHover(context, {
    handleClose: allowClickOverTooltip ? safePolygon() : undefined,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  const checkOverflowEllipsis = useCallback((el: HTMLDivElement) => {
    const ref = (el.querySelector("[data-tooltip-overflow]") ??
      el) as HTMLDivElement;
    const curOverf = ref.style.overflow;
    if (!curOverf || curOverf === "visible") ref.style.overflow = "hidden";
    const isOverflowing =
      ref.clientWidth < ref.scrollWidth || ref.clientHeight < ref.scrollHeight;
    ref.style.overflow = curOverf;
    return isOverflowing;
  }, []);

  const allowShowTooltip = () => {
    if (!ellipsis) return true;
    if (!refs.reference.current) return true;
    // check content with overflow ... - if found show tooltip
    return checkOverflowEllipsis(refs.reference.current as HTMLDivElement);
  };

  return (
    <>
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        onDoubleClick={onDoubleClick}
        className={className}
      >
        {children}
      </div>
      {isOpen && allowShowTooltip() && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={{ ...floatingStyles, zIndex: 100 }}
            {...getFloatingProps()}
            className={tooltipClassName}
          >
            {tooltip}
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
export default Tooltip;
