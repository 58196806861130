import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import { ChartDataType } from "shared/components/dashboard/dashboardMapper";

export interface CustomTooltipProps extends TooltipProps<ValueType, NameType> {
  allData: ChartDataType;
  previousDataLabel?: string;
}
const CustomTooltip = ({
  active,
  payload,
  label,
  allData,
  previousDataLabel,
}: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    const currentIndex = allData.findIndex((data) => data.name === label);
    const previousDataPoint = allData[currentIndex - 1];

    return (
      <div className="flex flex-col gap-y-1 bg-neutral-light border border-neutral-mid-200 p-2">
        <p>{label}</p>
        {payload.map((entry, index) => {
          const currentValue = entry.value as number;
          const previousValue =
            currentIndex > 0
              ? previousDataPoint[entry.dataKey as string]
              : null;
          const change = currentValue - Number(previousValue);

          const isPositive = change > 0;
          const isNegative = change < 0;

          return (
            <div key={index}>
              <p
                style={{ color: entry.color }}
              >{`${entry.name}: ${currentValue}`}</p>
              {(previousValue || previousValue === 0) && (
                <small>
                  {`${previousDataLabel ?? "Change from previous value"}: `}
                  <div
                    className={`inline ${
                      isPositive ? "text-status-success" : ""
                    } ${isNegative ? "text-status-danger" : ""}`}
                  >{`${isPositive ? "+" : ""}${change}`}</div>
                </small>
              )}
            </div>
          );
        })}
      </div>
    );
  }
  return null;
};

export default CustomTooltip;
