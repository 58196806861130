import { InvoiceItemView } from "shared/mappers/database/accounting/invoice/invoice-item";

export const getInvoiceItemWithEffectiveAndExpirationToShow = (
  invoiceItems: InvoiceItemView[],
): InvoiceItemView => {
  invoiceItems = invoiceItems.filter(
    (invoiceItem) => invoiceItem.effectiveDate && invoiceItem.expirationDate,
  );
  return invoiceItems[invoiceItems.length - 1] || {};
};
