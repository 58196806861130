import { ComponentPropsWithoutRef } from "react";
import { renderTemplateUtil } from "shared/components/template/renderTemplateUtil";

type TemplateComponentProps<T> = Omit<
  ComponentPropsWithoutRef<"div">,
  "dangerouslySetInnerHtml"
> & {
  template: string;
  data: T;
};

const TemplateComponent = <T extends object>({
  template,
  data,
  ...props
}: TemplateComponentProps<T>) => {
  const html = renderTemplateUtil({
    template,
    templateData: data,
  });
  return <div dangerouslySetInnerHTML={{ __html: html }} {...props} />;
};

export default TemplateComponent;
