import { BaseApiContract } from "shared/api/apiRequestContractHandler";
import { HttpMethod } from "shared/api/http";
import { PaginatedResults } from "shared/api/types/pagination";
import {
  SocietyFileAPIPathOutput,
  SocietyFilePostBodyInput,
  SocietyFileInfoOutput,
} from "shared/api/types/society/[societyId]/files/index";
import { SocietyProfileParamsInput } from "shared/api/types/society/[societyId]/profiles/[profileId]";
import { FileView } from "shared/mappers/database/files/file";
export const getSocietyProfileFilesContract: BaseApiContract<
  SocietyProfileParamsInput,
  undefined,
  PaginatedResults<FileView, SocietyFileAPIPathOutput>
> = {
  method: HttpMethod.GET,
  apiRoute: "/api/v1/society/[societyId]/profiles/[profileId]/files",
  paramsType: {} as SocietyProfileParamsInput,
  responseType: {} as PaginatedResults<FileView, SocietyFileAPIPathOutput>,
};
export const postSocietyProfileFilesContract: BaseApiContract<
  SocietyProfileParamsInput,
  SocietyFilePostBodyInput,
  SocietyFileInfoOutput
> = {
  method: HttpMethod.POST,
  apiRoute: "/api/v1/society/[societyId]/profiles/[profileId]/files",
  paramsType: {} as SocietyProfileParamsInput,
  bodyType: {} as SocietyFilePostBodyInput,
  responseType: {} as SocietyFileInfoOutput,
};
