import { SocietyProfileReviewAssignmentParamsInput } from "shared/api/types/society/[societyId]/profiles/[profileId]/review/assignments/[reviewAssignmentId]/index";
import { ReviewInstanceAssignmentView } from "shared/mappers/database/review/reviewInstanceAssignment";
import { BaseApiContract } from "shared/api/apiRequestContractHandler";
import { HttpMethod } from "shared/api/http";
export const getSocietyProfileReviewAssignmentIdContract: BaseApiContract<
  SocietyProfileReviewAssignmentParamsInput,
  undefined,
  ReviewInstanceAssignmentView
> = {
  method: HttpMethod.GET,
  apiRoute:
    "/api/v1/society/[societyId]/profiles/[profileId]/review/assignments/[reviewAssignmentId]",
  paramsType: {} as SocietyProfileReviewAssignmentParamsInput,
  responseType: {} as ReviewInstanceAssignmentView,
};
