import { createContext, useContext } from "react";
import { SocietyView } from "shared/mappers/database/society/society";

export type SocietySessionContextType = SocietyView | null;

const SocietySessionContext = createContext<SocietySessionContextType>(null);

const useSocietySession = (): SocietyView => {
  const societySession = useContext(SocietySessionContext);
  if (societySession === undefined) {
    throw new Error(
      "useSocietySession must be used within a SocietySessionContext",
    );
  }
  if (societySession === null) {
    throw new Error("SocietySessionContext is null");
  }
  return societySession;
};

export { SocietySessionContext, useSocietySession };
