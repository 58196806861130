import { BaseApiContract } from "shared/api/apiRequestContractHandler";
import { HttpMethod } from "shared/api/http";
import { PDFOutput } from "shared/api/types/pdf";
import { SocietyProfileParamsInput } from "shared/api/types/society/[societyId]/profiles/[profileId]";
export const getSocietyProfileMembershipCardPdfContract: BaseApiContract<
SocietyProfileParamsInput,
  undefined,
  PDFOutput
> = {
  method: HttpMethod.GET,
  apiRoute:
    "/api/v1/society/[societyId]/profiles/[profileId]/membership-card-pdf",
  paramsType: {} as SocietyProfileParamsInput,
  responseType: {} as PDFOutput,
};
