import { SessionView } from "shared/mappers/database/session/session";
import {
  QuestionCompositeModel,
  QuestionCustomModel,
  QuestionPanelDynamicModel,
  Serializer,
} from "survey-core";
import {
  ComponentCollection,
  ICustomQuestionTypeConfiguration,
} from "survey-core";
import { CUSTOM_TYPE_EDUCATION_INSTANCE } from "shared/components/survey-js-form/questions/education/EducationInstanceQuestion";

export const EducationInstanceMultipleQuestionProperties = {
  hoursToUse: "hoursToUse",
  completedAgoDays: "completedAgoDays",
  completedAgoMonths: "completedAgoMonths",
  completedAgoYears: "completedAgoYears",
};

const CUSTOM_TYPE_EDUCATION_INSTANCE_MULTI = "education_instance_multi";

export const registerPropertiesEducationInstanceMultipleQuestion = () => {
  Serializer.addProperty(CUSTOM_TYPE_EDUCATION_INSTANCE_MULTI, {
    name: "hoursRequired",
    displayName: "Require hours for certification",
    type: "boolean",
    isRequired: false,
    default: false,
    category: "General",
  });
  Serializer.addProperty(CUSTOM_TYPE_EDUCATION_INSTANCE_MULTI, {
    name: "completedAgoDays",
    displayName: "Only show education instances completed this many days ago",
    type: "number",
    isRequired: false,
    category: "General",
  });
  Serializer.addProperty(CUSTOM_TYPE_EDUCATION_INSTANCE_MULTI, {
    name: "completedAgoMonths",
    displayName:
      "Only show education instances completed  this many months ago",
    type: "number",
    isRequired: false,
    category: "General",
  });
  Serializer.addProperty(CUSTOM_TYPE_EDUCATION_INSTANCE_MULTI, {
    name: "completedAgoYears",
    displayName: "Only show education instances completed  this many years ago",
    type: "number",
    isRequired: false,
    category: "General",
  });
};

export type EducationInstanceMultipleQuestionProps = {
  session: SessionView;
};

export const RegisterEducationInstanceMultipleQuestionType = async ({
  session,
}: EducationInstanceMultipleQuestionProps) => {
  registerPropertiesEducationInstanceMultipleQuestion();

  const educationInstanceConfig: ICustomQuestionTypeConfiguration = {
    name: CUSTOM_TYPE_EDUCATION_INSTANCE_MULTI,
    defaultQuestionTitle: "Select Education Instance and Hours to Use",
    questionJSON: {
      type: "paneldynamic",
      name: "education_instance_panel",
      title: "Education Instance Selection",
      templateElements: [
        {
          type: CUSTOM_TYPE_EDUCATION_INSTANCE,
          name: CUSTOM_TYPE_EDUCATION_INSTANCE,
          title: "Education Instance",
          startWithNewLine: false,
          isRequired: true,
          requiredErrorText: "You must select a education instance.",
        },
      ],
    },
    onInit: () => {
      registerPropertiesEducationInstanceMultipleQuestion();
    },
    onLoaded: (question: QuestionCustomModel) => {
      const completedAgoDays = question.getPropertyValue(
        EducationInstanceMultipleQuestionProperties.completedAgoDays,
      );
      const completedAgoMonths = question.getPropertyValue(
        EducationInstanceMultipleQuestionProperties.completedAgoMonths,
      );
      const completedAgoYears = question.getPropertyValue(
        EducationInstanceMultipleQuestionProperties.completedAgoYears,
      );

      const panelDynamic = <QuestionPanelDynamicModel>question.contentQuestion;
      const customModel = <QuestionCompositeModel>(
        panelDynamic.template.getElementByName(CUSTOM_TYPE_EDUCATION_INSTANCE)
      );

      if (customModel) {
        customModel.setPropertyValue(
          EducationInstanceMultipleQuestionProperties.completedAgoDays,
          completedAgoDays,
        );
        customModel.setPropertyValue(
          EducationInstanceMultipleQuestionProperties.completedAgoMonths,
          completedAgoMonths,
        );
        customModel.setPropertyValue(
          EducationInstanceMultipleQuestionProperties.completedAgoYears,
          completedAgoYears,
        );
      }
    },
  };

  if (
    !ComponentCollection.Instance.getCustomQuestionByName(
      CUSTOM_TYPE_EDUCATION_INSTANCE_MULTI,
    )
  ) {
    ComponentCollection.Instance.add(educationInstanceConfig);
  }
};
