import { SocietyProfileParamsInput } from "shared/api/types/society/[societyId]/profiles/[profileId]/index";
import { ProfileView } from "shared/mappers/database/profile/profile";
import { BaseApiContract } from "shared/api/apiRequestContractHandler";
import { HttpMethod } from "shared/api/http";
export const getSocietyProfileIdContract: BaseApiContract<
  SocietyProfileParamsInput,
  undefined,
  ProfileView
> = {
  method: HttpMethod.GET,
  apiRoute: "/api/v1/society/[societyId]/profiles/[profileId]",
  paramsType: {} as SocietyProfileParamsInput,
  responseType: {} as ProfileView,
};
