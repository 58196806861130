import { get } from "lodash";
import { DateTime } from "luxon";
import React from "react";

import { PillarTableColumnProps } from "shared/components/pillar-table/PillarTableColumn";
import { PillarTableRowContext } from "shared/components/pillar-table/PillarTableRowContext";
import {
  LuxonDateFormats,
  AliasDateFormats,
  dateMapperFormats,
} from "shared/datetime/dateFormats";

export type PillarTableUnifiedDateProps<T> = {
  format?: string | AliasDateFormats;
  nullValue?: string;
  isZoned?: boolean;
  useAliasFormat?: boolean;
} & PillarTableColumnProps<T>;

const PillarTableDateTimeColumn = <T,>({
  valueProperty,
  format = LuxonDateFormats.D,
  nullValue,
  isZoned = false,
  useAliasFormat = false,
  doNotEdit,
  cellEditComponent,
}: PillarTableUnifiedDateProps<T>) => {
  const rowContext = React.useContext(PillarTableRowContext);
  const value = get(rowContext?.rowValue, valueProperty as string);
  const editedValue = get(rowContext?.editedValue, valueProperty as string);

  if (!doNotEdit && rowContext?.inlineEditConfirm) {
    if (cellEditComponent) {
      return <>{cellEditComponent}</>;
    }

    return (
      <input
        type="date"
        name={valueProperty as string}
        value={
          editedValue
            ? DateTime.fromISO(editedValue).toFormat("yyyy-MM-dd")
            : ""
        }
        className="rounded-md shadow-sm ring-1 ring-inset ring-neutral-mid-300 focus-within:ring-2 focus-within:ring-inset block bg-transparent !py-1 pl-1 text-neutral-mid-900 placeholder:text-neutral-mid-400 focus:ring-0 sm:text-sm sm:leading-relaxed"
        onChange={(e) => {
          const isoValue = DateTime.fromFormat(
            e.target.value,
            "yyyy-MM-dd",
          ).toISODate();
          rowContext.updateEditedValue(isoValue, valueProperty as string);
        }}
      />
    );
  }

  const formattedValue = value
    ? DateTime.fromISO(value, { setZone: isZoned }).toFormat(
        useAliasFormat && typeof format === "string"
          ? dateMapperFormats[format as AliasDateFormats]
          : format ?? LuxonDateFormats.D,
      )
    : nullValue ?? "";

  return <>{formattedValue}</>;
};

export default PillarTableDateTimeColumn;
