import { SocietyDashboardParams } from "shared/api/types/society/[societyId]/dashboards/[dashboardId]";
import { SocietyDashboardModuleViewWithDetails } from "shared/mappers/database/dashboard/societyDashboardModule";
import { BaseApiContract } from "shared/api/apiRequestContractHandler";
import { HttpMethod } from "shared/api/http";
export const getSocietyDashboardIdContract: BaseApiContract<
  SocietyDashboardParams,
  undefined,
  SocietyDashboardModuleViewWithDetails
> = {
  method: HttpMethod.GET,
  apiRoute: "/api/v1/society/[societyId]/dashboards/[dashboardId]",
  paramsType: {} as SocietyDashboardParams,
  responseType: {} as SocietyDashboardModuleViewWithDetails,
};
