import React from "react";

export type PillarTableRowContextType<T> = {
  rowValue: T;
  editedValue: T;
  updateEditedValue: (value: any, valueProperty: string) => void;
  resetEditedValue: () => void;
  propagateEditedValue: () => void;
  inlineEditConfirm: boolean;
  setInlineEditConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  rowIndex: number;
};

export const PillarTableRowContext = React.createContext<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PillarTableRowContextType<any>
>({
  rowValue: null,
  editedValue: null,
  rowIndex: -1,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateEditedValue: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetEditedValue: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  propagateEditedValue: () => {},
  inlineEditConfirm: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setInlineEditConfirm: () => {},
});
