import { useContext } from "react";
import { DeepKeys } from "shared/types/deepkeys";
import { PillarTableExportToExcelContext } from "shared/components/pillar-table/export/PillarTableExportToExcelContext";

export interface PillarTableExportToExcelColumnProps<T> {
  valueProperty?: DeepKeys<T>;
  header: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: (item: any) => string;
}
const PillarTableExportToExcelColumn = <T,>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: PillarTableExportToExcelColumnProps<T>,
) => {
  const exportToExcelContext = useContext(PillarTableExportToExcelContext);
  if (!exportToExcelContext) {
    throw new Error(
      "PillarTableExportToExcelColumn must be used within a PillarTableExportToExcel",
    );
  }
  return <></>;
};

export default PillarTableExportToExcelColumn;
