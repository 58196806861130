/* eslint-disable @typescript-eslint/no-explicit-any */
import { createRoot } from "react-dom/client";
import { getDisplayNameHelper } from "shared/helpers/profile/getDisplayNameHelper";
import { SessionView } from "shared/mappers/database/session/session";
import {
  ComponentCollection,
  ICustomQuestionTypeConfiguration,
  PanelModel,
  Question,
  QuestionCompositeModel,
  QuestionCustomModel,
  QuestionDropdownModel,
  QuestionPanelDynamicModel,
  QuestionRadiogroupModel,
  Serializer,
  SurveyModel,
} from "survey-core";
import { CoauthorBlock } from "shared/components/survey-js-form/questions/co-author/CoauthorBlock";
import { ProfileSearchMultipleQuestionType } from "shared/components/survey-js-form/questions/profile-search/ProfileSearchMultipleQuestionType";
import {
  CUSTOM_TYPE_PROFILE_SEARCH,
  NewProfileSearchQuestionProperties,
  ProfileSearchQuestionComponentData,
} from "shared/components/survey-js-form/questions/profile-search/ProfileSearchQuestionComponent";
export const ProfileSearchMultipleQuestionProperties = {
  profileSearchQuestionProperties: NewProfileSearchQuestionProperties,
  roleItemValueProperties: {
    value: "value",
    text: "text",
    min: "min",
    max: "max",
    trackPositions: "trackPositions",
  },
  autoAssignSubmitter: "autoAssignSubmitter",
  role: "role",
  positionTitle: "positionTitle",
  roleTitle: "roleTitle",
  profileSearchTitle: "profileSearchTitle",
  coauthorBlock: "coauthorBlock",
  blockPosition: "blockPosition",
  submitterBold: "submitterBold",
};

export enum SubmitterTypes {
  sessionProfile = "sessionProfile",
  formProfile = "formProfile",
}

export interface IRoleItemValue {
  value: string;
  text: string;
  min?: number;
  max?: number;
  trackPositions?: boolean;
}

export interface ProfileSearchMultipleQuestionData {
  role?: string;
  position?: number;
  profilesearch?: ProfileSearchQuestionComponentData;
  submissionParticipantId?: number;
}
const registerPropertiesProfileSearchMultiple = async () => {
  Serializer.addProperty(ProfileSearchMultipleQuestionType, {
    name: ProfileSearchMultipleQuestionProperties.roleTitle,
    displayName: "Title for the role column",
    type: "text",
    category: "general",
  });
  Serializer.addProperty(ProfileSearchMultipleQuestionType, {
    name: ProfileSearchMultipleQuestionProperties.positionTitle,
    displayName: "Title for the position column",
    type: "text",
    category: "general",
  });
  Serializer.addProperty(ProfileSearchMultipleQuestionType, {
    name: ProfileSearchMultipleQuestionProperties.profileSearchTitle,
    displayName: "Title for the profile search question",
    type: "text",
    category: "general",
  });
  Serializer.addProperty(ProfileSearchMultipleQuestionType, {
    name: ProfileSearchMultipleQuestionProperties
      .profileSearchQuestionProperties.directoryId,
    displayName: "Choose a directory to search for profiles",
    type: "dropdown",
    isRequired: true,
    choices: [],
    category: "general",
  });
  Serializer.addProperty(ProfileSearchMultipleQuestionType, {
    name: ProfileSearchMultipleQuestionProperties
      .profileSearchQuestionProperties.newProfileWorkflowId,
    displayName: "Choose a workflow to create a new profile",
    type: "dropdown",
    isRequired: true,
    choices: [],
    category: "general",
  });

  const buildRolesItemValues = () => {
    const roles = [
      {
        value: "submitter",
        text: "Submitter",
      },
    ];
    roles.push({ value: "chair", text: "Chair" });
    roles.push({ value: "cochair", text: "Co-Chair" });
    roles.push({ value: "speaker", text: "Speaker" });
    roles.push({ value: "moderator", text: "Moderator" });
    roles.push({ value: "coauthor", text: "Coauthor" });
    return roles;
  };

  const roleItemValues = buildRolesItemValues();
  Serializer.addClass(
    "roleitemvalue",
    [
      {
        name: ProfileSearchMultipleQuestionProperties.roleItemValueProperties
          .value,
        type: "dropdown",
        choices: roleItemValues,
        onSettingValue(obj, value) {
          const matchingRole = roleItemValues.find(
            (role) => role.value === value,
          );
          if (!matchingRole) {
            return value;
          }
          obj.text = matchingRole.text;
          return value;
        },
      },
      { name: "text", type: "string", showMode: "form" },
      {
        name: ProfileSearchMultipleQuestionProperties.roleItemValueProperties
          .min,
        type: "number",
        showMode: "form",
        displayName: "Minimum (1 for required)",
      },
      {
        name: ProfileSearchMultipleQuestionProperties.roleItemValueProperties
          .max,
        type: "number",
        showMode: "form",
        displayName: "Maximum (0 or blank for unlimited)",
      },
      {
        name: ProfileSearchMultipleQuestionProperties.roleItemValueProperties
          .trackPositions,
        type: "boolean",
        showMode: "form",
        displayName: "Track Positions",
      },
    ],
    undefined,
    "itemvalue",
  );
  Serializer.addProperty(ProfileSearchMultipleQuestionType, {
    name: ProfileSearchMultipleQuestionProperties.role,
    displayName: "Select role for the profile",
    type: "roleitemvalue[]",
    category: "general",
    choices: roleItemValues,
  });
  Serializer.addProperty(ProfileSearchMultipleQuestionType, {
    name: ProfileSearchMultipleQuestionProperties.autoAssignSubmitter,
    displayName: "Auto assign submitter",
    type: "dropdown",
    category: "general",
    choices: roleItemValues,
  });
  Serializer.addProperty(ProfileSearchMultipleQuestionType, {
    name: "emailRequired",
    displayName: "Email",
    type: "boolean",
    category: "Create Profile Required Fields",
  });

  Serializer.addProperty(ProfileSearchMultipleQuestionType, {
    name: "affiliationRequired",
    displayName: "Affiliation",
    type: "boolean",
    category: "Create Profile Required Fields",
  });

  Serializer.addProperty(ProfileSearchMultipleQuestionType, {
    name: "firstNameRequired",
    displayName: "First name",
    type: "boolean",
    category: "Create Profile Required Fields",
  });

  Serializer.addProperty(ProfileSearchMultipleQuestionType, {
    name: "lastNameRequired",
    displayName: "Last name",
    type: "boolean",
    category: "Create Profile Required Fields",
  });

  Serializer.addProperty(ProfileSearchMultipleQuestionType, {
    name: "coauthorBlock",
    displayName: "Add coauthor block",
    type: "boolean",
    category: "Co Author Block",
  });

  Serializer.addProperty(ProfileSearchMultipleQuestionType, {
    name: "blockPosition",
    displayName: "Change block positions",
    type: "boolean",
    category: "Co Author Block",
    visibleIf: (obj) => obj.coauthorBlock === true,
  });

  Serializer.addProperty(ProfileSearchMultipleQuestionType, {
    name: "submitterBold",
    displayName: "Make submitter bold",
    type: "boolean",
    default: true,
    category: "Co Author Block",
    visibleIf: (obj) => obj.coauthorBlock === true,
  });
};
export type ProfileSearchMultipleQuestionProps = {
  session: SessionView;
};
export const generatedPanelName = "generatedRolePanel";
export const autoAssignedProfileQuestion = "autoAssignedProfile";
export const ProfileSearchMultipleQuestion = async ({
  session,
}: ProfileSearchMultipleQuestionProps) => {
  registerPropertiesProfileSearchMultiple();

  const multiple_profile_search_object: ICustomQuestionTypeConfiguration = {
    name: ProfileSearchMultipleQuestionType,
    defaultQuestionTitle: "Multiple Profile Search",
    inheritBaseProps: [
      "panelAddText",
      "onPanelAdded",
      ...(session.adminMode ? [] : ["maxPanelCount"]),
    ],
    questionJSON: {
      type: "paneldynamic",
      name: "multiple_profile_search",
      title: "Multiple Profile Search",
      titleLocation: "hidden",
      templateElements: [
        {
          type: "number",
          name: "submissionParticipantId",
          visible: false,
        },
        {
          type: "panel",
          name: "rolePanel",
          elements: [
            {
              type: "radiogroup",
              name: "role",
              title: "Role",
              visible: true,
              errorLocation: "bottom",
              validators: [
                {
                  type: "expression",
                  expression: "checkRoleExists({role})",
                  text: "You must select a role for this profile.",
                },
                {
                  type: "expression",
                  expression: "checkRequiredRolesMaximum({role})",
                  text: "You have exceeded the maximum number of this role allowed.",
                },
              ],
              choices: [],
            },
            {
              type: "html",
              name: "rolePanelHtml",
              html: "",
              visible: false,
            },

            {
              type: "dropdown",
              name: "position",
              title: "Position",
              validators: [
                {
                  type: "expression",
                  expression: "checkForCorrectPositions({position})",
                  text: "You cannot leave a gap in the positions assigned.",
                },
                {
                  type: "expression",
                  expression: "checkForDuplicatePositions({position})",
                  text: "You cannot assign the same position to multiple profiles.",
                },
              ],
              errorLocation: "bottom",
              visible: false,
              choices: [],
            },
            {
              type: "dropdown",
              name: "coauthorBlock",
              title: "Block Position",
              visible: false,
              choices: [],
            },
          ],
        },
        {
          startWithNewLine: false,
          isRequired: true,
          requiredErrorText: "You must select a profile.",
          name: CUSTOM_TYPE_PROFILE_SEARCH,
          type: CUSTOM_TYPE_PROFILE_SEARCH,
          validators: [
            {
              type: "expression",
              expression: "checkForDuplicateProfileIds({profile_id})",
              text: "You cannot assign the same profile to the same role.",
            },
            {
              type: "expression",
              expression: "checkProfileIdSet({profile_id})",
              text: "A profile must be selected.",
            },
          ],
        },
      ],
    },
    onInit: () => {
      registerPropertiesProfileSearchMultiple();
    },
    onPropertyChanged: (
      question: Question,
      propertyName: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newValue: any,
    ): void => {
      const panelDynamic = <QuestionPanelDynamicModel>question.contentQuestion;
      const rolePanel = <PanelModel>(
        panelDynamic?.template.getElementByName("rolePanel")
      );
      const coauthorBlockQuestion = <QuestionDropdownModel>(
        rolePanel?.getElementByName("coauthorBlock")
      );

      if (coauthorBlockQuestion) {
        coauthorBlockQuestion.choices = Array.from(
          { length: panelDynamic.panelCount },
          (_, index) => {
            return {
              value: (index + 1).toString(),
              text: (index + 1).toString(),
            };
          },
        );
      }
    },
    onLoaded: (question: QuestionCustomModel) => {
      const roles =
        <IRoleItemValue[]>(
          question.getPropertyValue(
            ProfileSearchMultipleQuestionProperties.role,
          )
        ) ?? [];

      //Set properties of the ProfileSearchQuestion
      const directoryId = question.getPropertyValue(
        ProfileSearchMultipleQuestionProperties.profileSearchQuestionProperties
          .directoryId,
      );
      const newProfileWorkflowId = question.getPropertyValue(
        ProfileSearchMultipleQuestionProperties.profileSearchQuestionProperties
          .newProfileWorkflowId,
      );

      const autoAssignSubmitter = question.getPropertyValue(
        ProfileSearchMultipleQuestionProperties.autoAssignSubmitter,
      );

      const emailRequired = question.getPropertyValue(
        ProfileSearchMultipleQuestionProperties.profileSearchQuestionProperties
          .emailRequired,
      );

      const affiliationRequired = question.getPropertyValue(
        ProfileSearchMultipleQuestionProperties.profileSearchQuestionProperties
          .affiliationRequired,
      );

      const firstNameRequired = question.getPropertyValue(
        ProfileSearchMultipleQuestionProperties.profileSearchQuestionProperties
          .firstNameRequired,
      );

      const lastNameRequired = question.getPropertyValue(
        ProfileSearchMultipleQuestionProperties.profileSearchQuestionProperties
          .lastNameRequired,
      );

      //Fetch the profile search question from the panel dynamic template
      const panelDynamic = <QuestionPanelDynamicModel>question.contentQuestion;
      const profilesearchQuestion = <QuestionCompositeModel>(
        panelDynamic.template.getElementByName(CUSTOM_TYPE_PROFILE_SEARCH)
      );

      //Attach the properties to the profile search question inside the panel dynamic template
      if (profilesearchQuestion) {
        profilesearchQuestion.setPropertyValue(
          ProfileSearchMultipleQuestionProperties
            .profileSearchQuestionProperties.directoryId,
          directoryId,
        );
        profilesearchQuestion.setPropertyValue(
          ProfileSearchMultipleQuestionProperties
            .profileSearchQuestionProperties.newProfileWorkflowId,
          newProfileWorkflowId,
        );
        profilesearchQuestion.setPropertyValue(
          ProfileSearchMultipleQuestionProperties.autoAssignSubmitter,
          autoAssignSubmitter,
        );
        profilesearchQuestion.setPropertyValue(
          ProfileSearchMultipleQuestionProperties
            .profileSearchQuestionProperties.emailRequired,
          emailRequired,
        );
        profilesearchQuestion.setPropertyValue(
          ProfileSearchMultipleQuestionProperties
            .profileSearchQuestionProperties.affiliationRequired,
          affiliationRequired,
        );
        profilesearchQuestion.setPropertyValue(
          ProfileSearchMultipleQuestionProperties
            .profileSearchQuestionProperties.firstNameRequired,
          firstNameRequired,
        );
        profilesearchQuestion.setPropertyValue(
          ProfileSearchMultipleQuestionProperties
            .profileSearchQuestionProperties.lastNameRequired,
          lastNameRequired,
        );
      }

      //Get the roles from the question properties

      //Fetch the role panel from the panel dynamic template
      const rolePanel = <PanelModel>(
        panelDynamic.template.getElementByName("rolePanel")
      );

      if (roles && Array.isArray(roles) && roles.length > 0) {
        const roleRadiogroupQuestion = <QuestionRadiogroupModel>(
          rolePanel.getElementByName("role")
        );

        roleRadiogroupQuestion.choices = roles.map((role: IRoleItemValue) => {
          return {
            value: role.value,
            text: role.text,
          };
        });
        const positionDropdownQuestion = <QuestionDropdownModel>(
          rolePanel.getElementByName("position")
        );
        const positionTitle = question.getPropertyValue(
          ProfileSearchMultipleQuestionProperties.positionTitle,
        );
        const roleTitle = question.getPropertyValue(
          ProfileSearchMultipleQuestionProperties.roleTitle,
        );

        const coauthorBlockQuestion = <QuestionDropdownModel>(
          rolePanel.getElementByName("coauthorBlock")
        );

        const blockPosition = question.getPropertyValue(
          ProfileSearchMultipleQuestionProperties.blockPosition,
        );

        if (blockPosition) {
          coauthorBlockQuestion.visible = true;
        }

        if (positionTitle) {
          positionDropdownQuestion.validators.forEach((validator) => {
            validator.text = validator.text.replace(
              "position",
              question.getPropertyValue(
                ProfileSearchMultipleQuestionProperties.positionTitle,
              ) ?? "position",
            );
          });
        }

        if (roleTitle) {
          roleRadiogroupQuestion.validators.forEach((validator) => {
            validator.text = validator.text.replace(
              "role",
              question.getPropertyValue(
                ProfileSearchMultipleQuestionProperties.roleTitle,
              ) ?? "role",
            );
          });
        }

        positionDropdownQuestion.titleLocation = "left";
        coauthorBlockQuestion.titleLocation = "left";

        roles.forEach((role: IRoleItemValue) => {
          //add panels for each role that has a min > 0
          if (role.min && role.min > 0) {
            //for each till the min is reach add a panel with the role selected and set to readonly
            const minPanelCount = role.min;
            for (let i = 0; i < minPanelCount; i++) {
              const newPanel = panelDynamic.addPanel();
              newPanel.name = generatedPanelName;

              const roleQuestion = newPanel.getQuestionByName("role");
              const rolePanelHtml = newPanel.getQuestionByName("rolePanelHtml");

              roleQuestion.visible = false;
              if (role.trackPositions) {
                const positionQuestion = newPanel.getQuestionByName("position");
                positionQuestion.value = i + 1;
              } else {
                rolePanelHtml.visible = true;
                rolePanelHtml.html = `<p style="font-size: 16px; font-weight: 600; margin-top: 18px;">${role.text}</p>`;
              }
              roleQuestion.value = role.value;
              const profileSearchQuestion = newPanel.getQuestionByName(
                CUSTOM_TYPE_PROFILE_SEARCH,
              );
              if (role.value === autoAssignSubmitter && profileSearchQuestion) {
                profileSearchQuestion.readOnly = true;
              }
            }
          }
        });
      } else {
        rolePanel.visible = false;
      }
    },
    onAfterRender: async (question: QuestionCustomModel, htmlElement) => {
      //Need to get ProfileId from the survey
      const survey: any = question.getSurvey();
      const profileId = survey.formData?.profileId ?? undefined;
      const profile = survey.getVariable("profile");

      const coauthorBlock = question.getPropertyValue(
        ProfileSearchMultipleQuestionProperties.coauthorBlock,
      );

      if (
        question.autoAssignSubmitter !== null &&
        question.autoAssignSubmitter !== undefined
      ) {
        const panelToSet = question.contentQuestion.panels.find(
          (panel: PanelModel) => {
            const questionsOnPanel = panel.getQuestions(false);
            const roleQuestion = questionsOnPanel.find(
              (question) => question.name === "role",
            );
            return question.autoAssignSubmitter == roleQuestion?.value;
          },
        );
        const profileQuestion = panelToSet?.getQuestionByName(
          CUSTOM_TYPE_PROFILE_SEARCH,
        );

        if (profileQuestion && !profileQuestion!.value!) {
          profileQuestion.value = {
            profile_id: profile?.profileId,
            display_name: getDisplayNameHelper(profile),
            affiliation: profile?.additionalInformation?.affiliation ?? "",
          };
        }
      }
      const submitterBold = question.getPropertyValue(
        ProfileSearchMultipleQuestionProperties.submitterBold,
      );
      if (coauthorBlock)
        if (question.name === "profilesearchmultiple") {
          const existingHtml = htmlElement.nextElementSibling as HTMLElement;
          if (
            !existingHtml ||
            !existingHtml.classList.contains("coauthor-block-container")
          ) {
            const container = document.createElement("div");
            container.className = "coauthor-block-container";
            htmlElement.insertAdjacentElement("afterEnd", container);

            const root = createRoot(container);

            root.render(
              await CoauthorBlock({
                values: question.value,
                submitterBold,
                session,
                profileId,
              }),
            );
          }
        }

      survey.onValueChanged.add(async (sender: SurveyModel, options: any) => {
        if (options.name === question.name) {
          const updatedValues = question.getAllValues();
          const container = htmlElement.nextElementSibling as HTMLElement;
          if (
            container &&
            container.classList.contains("coauthor-block-container")
          ) {
            const root = createRoot(container);
            root.render(
              await CoauthorBlock({
                values: updatedValues,
                submitterBold,
                session,
                profileId,
              }),
            );
          }
        }
      });
    },
    onValueChanging: (
      question: QuestionCustomModel,
      name: string,
      newValues: ProfileSearchMultipleQuestionData[],
    ) => {
      const roles =
        <IRoleItemValue[]>(
          question.getPropertyValue(
            ProfileSearchMultipleQuestionProperties.role,
          )
        ) ?? [];

      const selectedRoles: string[] = [];
      const positionTitle: string =
        question.getPropertyValue(
          ProfileSearchMultipleQuestionProperties.positionTitle,
        ) ?? "Position";

      const dynamicPanel = <QuestionPanelDynamicModel>question.contentQuestion;
      dynamicPanel.panels.forEach((panel) => {
        const rolesQuestion = <QuestionDropdownModel>(
          panel.getElementByName("role")
        );

        const selectedRole = rolesQuestion.value;

        selectedRoles.push(selectedRole);

        if (selectedRole) {
          const configuredRole = roles.find(
            (role) => role.value === selectedRole,
          );
          if (configuredRole?.trackPositions) {
            const positionQuestion = <QuestionDropdownModel>(
              panel.getElementByName("position")
            );
            positionQuestion.visible = true;
            positionQuestion.choices = Array.from(
              { length: configuredRole.max! },
              (_, index) => {
                return {
                  value: index + 1,
                  text: (index + 1).toString(),
                };
              },
            );
            positionQuestion.title = `${configuredRole?.text} ${positionTitle}`;
          } else {
            const positionQuestion = <QuestionDropdownModel>(
              panel.getElementByName("position")
            );
            positionQuestion.visible = false;
            positionQuestion.value = undefined;
          }
        }

        if (!session.adminMode) {
          const availableRoles = roles.filter((r) => {
            const count = selectedRoles.filter(
              (role) => role === r.value,
            ).length;
            return count < r.max!;
          });

          if (!rolesQuestion.value) {
            rolesQuestion.choices = availableRoles;
          }
          if (roles.length && !availableRoles.length) {
            dynamicPanel.allowAddPanel = false;
          } else {
            dynamicPanel.allowAddPanel = true;
          }
        }
      });

      return newValues;
    },
  };

  if (
    !ComponentCollection.Instance.getCustomQuestionByName(
      ProfileSearchMultipleQuestionType,
    )
  ) {
    ComponentCollection.Instance.add(multiple_profile_search_object);
  }
};
