import { ComponentPropsWithoutRef, useRef, useState } from "react";

interface EllipsisTooltipProps {
  children: React.ReactNode;
  className?: string;
}

const EllipsisTooltip: React.FC<EllipsisTooltipProps> = ({
  children,
  className,
}) => {
  const cellRef = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    if (cellRef.current) {
      const isOverflowing =
        cellRef.current.scrollWidth > cellRef.current.clientWidth ||
        cellRef.current.scrollHeight > cellRef.current.clientHeight;
      setShowTooltip(isOverflowing);
    }
  };

  return (
    <div
      className={className}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setShowTooltip(false)}
      ref={cellRef}
    >
      {children}
      {showTooltip && (
        <div
          className="absolute max-w-40 bg-neutral-light p-2 rounded shadow-md z-10 break-words whitespace-normal"
          style={{
            top: `${
              (cellRef?.current?.getBoundingClientRect().top || 0) -
              (cellRef?.current?.getBoundingClientRect().height || 0)
            }px`,
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};
export default EllipsisTooltip;
