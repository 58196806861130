import React from "react";
import { get } from "lodash";
import { NewAppExcelToExcelSheetProps } from "shared/components/pillar-table/export/advanced/AppExportToExcelSheet";

export const getWorksheetData = <T>(
  data: Array<T>,
  worksheetElement: React.ReactElement<NewAppExcelToExcelSheetProps<T>>,
): (string | null)[][] => {
  // We need to separate mapping getters and getting cell values from data because Children.map
  // flattens any nested arrays, so we can't return cell data as array from Children.map
  const columnGetters = React.Children.map(
    worksheetElement.props.children,
    (columnChild) => {
      if (React.isValidElement(columnChild) && columnChild.props.children) {
        return columnChild.props.children;
      }

      return React.isValidElement(columnChild)
        ? (columnChild.props as any).valueProperty!
        : null;
    },
  )!.filter(Boolean);

  const sheetTable = data.map((item) => {
    return columnGetters.map((getter) => {
      return typeof getter === "function"
        ? getter(item, data)
        : get(item, getter);
    });
  });

  const columnChildCounts: number[] = [];

  const tableColsCount = Math.max(...sheetTable.map((row) => row.length));

  for (let col = 0; col < tableColsCount; col++) {
    let maxNestedColumnsCount = 1;

    for (let row = 0; row < sheetTable.length; row++) {
      const cellValue = sheetTable[row][col];

      if (
        Array.isArray(cellValue) &&
        cellValue.length > maxNestedColumnsCount
      ) {
        maxNestedColumnsCount = cellValue.length;
      }
    }

    columnChildCounts.push(maxNestedColumnsCount);
  }

  const sheetTableExtendedColumns: (string | null)[][] = [];

  for (let rowIndex = 0; rowIndex < sheetTable.length; rowIndex++) {
    const newRow: (string | null)[] = [];

    for (let colIndex = 0; colIndex < tableColsCount; colIndex++) {
      const cell = sheetTable[rowIndex][colIndex];
      const cellArray = Array.isArray(cell) ? cell : [cell];

      // If there are several columns created by one (Table Col "Category" is array -> Excel cols "Category 1", "Category 2", ... , "Category N"),
      // then we should add N cells to each row in place of one
      const newCellValue: (string | null)[] = new Array(
        columnChildCounts[colIndex],
      )
        .fill(null)
        .map((_, index) => cellArray[index]?.toString() || null);

      newRow.push(...newCellValue);
    }

    sheetTableExtendedColumns.push(newRow);
  }

  return [
    getSheetTableHeaders(worksheetElement, columnChildCounts, data),
    ...sheetTableExtendedColumns,
  ];
};

const getSheetTableHeaders = <T>(
  worksheetElement: React.ReactElement<NewAppExcelToExcelSheetProps<T>>,
  columnChildCounts: number[],
  data: T[],
): string[] => {
  // Header can be either static (string) or dynamic (function).
  // Dynamic one is needed in cases when we calculate columns count basing on received data.
  // F. e. we need to show cols "Expertise 1", "Expertise 2", ... , "Expertise N",
  const headersFromProps = React.Children.map(
    worksheetElement.props.children,
    (child) =>
      React.isValidElement(child)
        ? (child.props as any).dynamicHeader || (child.props as any).header
        : null,
  )!.filter(Boolean);

  return headersFromProps
    .map((headerProp, colIndex) => {
      return Array(columnChildCounts[colIndex])
        .fill(null)
        .map((_, relativeIndex) => {
          return typeof headerProp === "string"
            ? headerProp // Static header
            : headerProp(relativeIndex, data); // Dynamic header function provided
        });
    })
    .flat(2);
};
