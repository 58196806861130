import {
  ContactInformationView,
  ContactInformationDto,
} from "shared/mappers/database/profile/contact-information";
import {
  ProfileDto,
  ProfileView,
} from "shared/mappers/database/profile/profile";

//TODO: This is a bit temporary,  I'd rather the default email by set by an ID in society settings instead of this string ~ GS 8/16/23
export const defaultEmailFromProfile = (
  profile: ProfileView | ProfileView | ProfileDto,
): string | null => {
  return (
    profile?.contactInformation?.find(
      (ci: ContactInformationView | ContactInformationDto) =>
        ci.use?.find(
          (ciuse) =>
            ciuse.description === "Mail" && ci.email && ci.email !== "",
        ),
    )?.email ??
    profile?.contactInformation?.find(
      (ci: ContactInformationView | ContactInformationDto) =>
        ci.email && ci.email !== "",
    )?.email ??
    null
  );
};
